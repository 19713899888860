import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactDom from "react-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as CallIcon } from "../../../../assets/imgs/call-icon.svg";
import { ReactComponent as MicIcon } from "../../../../assets/imgs/mic-icon.svg";
import { ReactComponent as PrevCallIcon } from "../../../../assets/imgs/prev-call-icon.svg";
import { ReactComponent as NextCallIcon } from "../../../../assets/imgs/next-call-icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeMute } from "@fortawesome/free-solid-svg-icons";
import classes from "./OutgoingCallBar.module.css";
import { useTwilioPhoneOutgoing } from "../../../../shared/components/hooks/useTwilioPhoneOutgoing/useTwilioPhoneOutgoing";

const OutgiongCallBar = () => {
  const navigate = useNavigate();

  const {
    onPhone,
    activeCallContactId,
    callerId,
    callIsMuted,
    muteCallHandler,
    hangUpCallHandler,
  } = useTwilioPhoneOutgoing();

  //show notification if contact modal closes (path is callcenter && call is active)
  const location = useLocation();
  const pathname = location.pathname;

  const openContactModal = () => {
    navigate(`/app/call-center/contacts/${activeCallContactId}/notes`);
  };

  return pathname === "/app/call-center" && callerId || pathname === "/app/calendar" && callerId
    ? ReactDom.createPortal(
        <div
          className={classes["outgoing-call-bar"]}
          onClick={openContactModal}
        >
          <div className={classes["call-info"]}>
            <span className={classes["outgoing-text"]}>
              {onPhone ? "Connected" : "Outgiong call..."}
            </span>
            <span className={classes["caller-name"]}>
              {callerId ? callerId : "Unknown"}
            </span>
          </div>
          <div className={classes["call-buttons"]}>
            <button
              className={`${classes["call-button"]} ${
                callIsMuted ? classes["muted"] : ""
              }`}
              onClick={muteCallHandler}
            >
              <FontAwesomeIcon icon={faVolumeMute} width="100%" />
            </button>
            <button
              className={`${classes["call-button"]} ${classes["hangup-button"]}`}
              onClick={hangUpCallHandler}
            >
              <CallIcon />
            </button>
          </div>
        </div>,
        document.getElementById("modal-root")
      )
    : null;
};

export default OutgiongCallBar;
