import { createSlice, current } from "@reduxjs/toolkit";

const pipelineSlice = createSlice({
  name: "PipelineSlice",
  initialState: {
    pipelines: [],
    stages: [],
    deals: [],
    addedNewPipelineId: null,
    isLoading: false
  },
  reducers: {
    replacePipelineData(state, action) {
      state.pipelines = action.payload.pipelines
    },
    addPipeline(state, action) {
      state.pipelines.push(action.payload);
      state.addedNewPipelineId = action.payload.id
    },
    setAddedNewPipelineId(state, action) {
      state.addedNewPipelineId = action.payload;
    },
    updatePipeline(state, action) {
      const currentPipeline = state.pipelines.find((pipeline) => pipeline.id === action.payload.id);
      currentPipeline.name = action.payload.name;
      currentPipeline.stages = action.payload.stages;
      currentPipeline.creator = action.payload.creator;
    },
    deletePipeline(state, action) {
      state.pipelines = state.pipelines.filter((pipeline) => pipeline.id !== action.payload);
    },
    replaceStageData(state, action) {
      state.stages = action.payload.stages
    },
    addStage(state, action) {
      const currentPipeline = state.pipelines.find((pipeline) => pipeline.id === action.payload.belongsTo);
      currentPipeline.stages.push(action.payload.id);
      state.stages.push(action.payload);
    },
    updateStage(state, action) {
      const currentStage = state.stages.find((stage) => stage.id === action.payload.id);
      currentStage.name = action.payload.name;
      currentStage.deals = action.payload.deals;
      currentStage.creator = action.payload.creator;
      currentStage.belongsTo = action.payload.belongsTo;
    },
    deleteStage(state, action) {
      state.stages = state.stages.filter((stage) => stage.id !== action.payload);
    },
    replaceDealData(state, action) {
      state.deals = action.payload.deals
    },
    addDeal(state, action) {
      state.deals.push(action.payload);
    },
    updateDeal(state, action) {
      const currentDeal = state.deals.find((deal) => deal.id === action.payload.id);
      currentDeal.contact = action.payload.contact;
      currentDeal.description = action.payload.description;
      currentDeal.belongsTo = action.payload.belongsTo;
      currentDeal.creator = action.payload.creator;
    },
    deleteDeal(state, action) {
      state.deals = state.deals.filter((deal) => deal.id !== action.payload);
    },
    isLoading(state, action) {
      state.isLoading = action.payload;
    }
  }
});

export const pipelineActions = pipelineSlice.actions;
export default pipelineSlice.reducer;