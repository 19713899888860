import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Form from "../../../../shared/components/UIElements/Forms/Form";
import useInput from "../../../../shared/components/hooks/user-input";
import { createContact } from "../../../../store/contact-actions";
import { formatPhoneNumber } from "../../../../shared/components/utils/formatPhoneNumber";

const ContactForm = (props) => {

  //Instantiate react hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //Import auth state to add user id to creator
  const auth = useSelector(state => state.userReducer);

  //Handle name input/validation with useInput hook
  const {
    value: enteredFirstName,
    isValid: firstNameIsValid,
    hasError: firstNameInputHasError,
    valueChangeHandler: firstNameChangeHandler,
    inputBlurHandler: firstNameBlurHandler,
    reset: resetFirstNameInput,
  } = useInput((value) => value.trim() !== "");

  const {
    value: enteredSpouseName,
    isValid: spouseNameIsValid,
    hasError: spouseNameInputHasError,
    valueChangeHandler: spouseNameChangeHandler,
    // inputBlurHandler: firstNameBlurHandler, -> not a required input
    reset: resetSpouseNameInput,
  } = useInput(value => value || " "); //no validation because not a required input

  const {
    value: enteredLastName,
    isValid: lastNameIsValid,
    hasError: lastNameInputHasError,
    valueChangeHandler: lastNameChangeHandler,
    inputBlurHandler: lastNameBlurHandler,
    reset: resetLastNameInput,
  } = useInput((value) => value.trim() !== "");

  const {
    value: enteredEmail,
    isValid: emailIsValid,
    hasError: emailInputHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmailInput,
  } = useInput((value) => value.includes("@"));

  const {
    value: enteredPhoneNumber,
    isValid: phoneNumberIsValid,
    hasError: phoneNumberInputHasError,
    valueChangeHandler: phoneNumberChangeHandler,
    inputBlurHandler: phoneNumberBlurHandler,
    reset: resetPhoneNumberInput,
  } = useInput((value) => value.trim() !== "" && value.replace(/[^0-9]+/g, "")[0] === "1" ? value.replace(/[^0-9]+/g, "").length === 11 : value.replace(/[^0-9]+/g, "").length === 10 && /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(value.trim()));

  const {
    value: enteredSecondaryPhoneNumber,
    isValid: secondaryPhoneNumberIsValid,
    hasError: secondaryPhoneNumberInputHasError,
    valueChangeHandler: secondaryPhoneNumberChangeHandler,
    // inputBlurHandler: secondaryPhoneNumberBlurHandler, -> not a required input
    reset: resetSecondaryPhoneNumberInput,
  } = useInput((value) => value || " "); //no validation because not a required input

  const {
    value: enteredStreetNumber,
    isValid: streetNumberIsValid,
    hasError: streetNumberInputHasError,
    valueChangeHandler: streetNumberChangeHandler,
    // inputBlurHandler: streetNumberBlurHandler, -> not a required input
    reset: resetStreetNumberInput,
  } = useInput((value) => value || " "); //no validation because not a required input

  const {
    value: enteredCityName,
    isValid: cityNameIsValid,
    hasError: cityNameInputHasError,
    valueChangeHandler: cityNameChangeHandler,
    // inputBlurHandler: cityNameBlurHandler, -> not a required input
    reset: resetCityNameInput,
  } = useInput((value) => value || " "); //no validation because not a required input

  const {
    value: enteredStateName,
    isValid: stateNameIsValid,
    hasError: stateNameInputHasError,
    valueChangeHandler: stateNameChangeHandler,
    // inputBlurHandler: stateNameBlurHandler, -> not a required input
    reset: resetStateNameInput,
  } = useInput((value) => value || " "); //no validation because not a required input

  const {
    value: enteredZipCode,
    isValid: zipCodeIsValid,
    hasError: zipCodeInputHasError,
    valueChangeHandler: zipCodeChangeHandler,
    // inputBlurHandler: zipCodeBlurHandler, -> not a required input
    reset: resetZipCodeInput,
  } = useInput((value) => value || " "); //no validation because not a required input

  const {
    value: enteredContactType,
    isValid: contactTypeIsValid,
    hasError: contactTypeInputHasError,
    valueChangeHandler: contactTypeChangeHandler,
    inputBlurHandler: contactTypeBlurHandler,
    reset: resetContactTypeInput,
  } = useInput((value) => value !== "");

  const {
    value: enteredStatus,
    isValid: statusIsValid,
    hasError: statusInputHasError,
    valueChangeHandler: statusChangeHandler,
    inputBlurHandler: statusBlurHandler,
    reset: resetStatusInput,
  } = useInput((value) => value !== "");

  const {
    value: enteredSource,
    isValid: sourceIsValid,
    hasError: sourceInputHasError,
    valueChangeHandler: sourceChangeHandler,
    inputBlurHandler: sourceBlurHandler,
    reset: resetSourceInput,
  } = useInput((value) => value !== "");

  //Initialize form fields
  const formFields = [
    {
      id: "input1",
      eleType: "input",
      type: "text",
      label: "First Name",
      value: enteredFirstName,
      errorMessage: "Name cannot be blank",
      onChange: firstNameChangeHandler,
      onBlur: firstNameBlurHandler,
      isInvalid: firstNameInputHasError,
      autoFocus: true
    },
    {
      id: "input2",
      eleType: "input",
      type: "text",
      label: "Spouse Name",
      value: enteredSpouseName,
      onChange: spouseNameChangeHandler,
      isInvalid: spouseNameInputHasError,
    },
    {
      id: "input3",
      eleType: "input",
      type: "text",
      label: "Last Name",
      value: enteredLastName,
      errorMessage: "Last name cannot be blank",
      onChange: lastNameChangeHandler,
      onBlur: lastNameBlurHandler,
      isInvalid: lastNameInputHasError,
    },
    {
      id: "input4",
      eleType: "input",
      type: "email",
      label: "Email",
      value: enteredEmail,
      errorMessage: "Email cannot be blank and must include @",
      onChange: emailChangeHandler,
      onBlur: emailBlurHandler,
      isInvalid: emailInputHasError,
    },
    {
      id: "input5",
      eleType: "input",
      type: "phone",
      label: "Mobile Number",
      value: formatPhoneNumber(enteredPhoneNumber),
      errorMessage: "Must be exactly 10 numbers",
      onChange: phoneNumberChangeHandler,
      onBlur: phoneNumberBlurHandler,
      isInvalid: phoneNumberInputHasError,
    },
    {
      id: "input6",
      eleType: "input",
      type: "phone",
      label: "Work Number",
      value: enteredSecondaryPhoneNumber,
      onChange: secondaryPhoneNumberChangeHandler,
      // onBlur: secondaryPhoneNumberBlurHandler, -> not a required input
      isInvalid: secondaryPhoneNumberInputHasError,
    },
    {
      id: "input7",
      eleType: "input",
      type: "input",
      label: "Street Number",
      value: enteredStreetNumber,
      onChange: streetNumberChangeHandler,
      // onBlur: streetNumberBlurHandler, -> not a required input
      isInvalid: streetNumberInputHasError,
    },
    {
      id: "input8",
      eleType: "input",
      type: "input",
      label: "City Name",
      value: enteredCityName,
      onChange: cityNameChangeHandler,
      // onBlur: cityNameBlurHandler, -> not a required input
      isInvalid: cityNameInputHasError,
    },
    {
      id: "input9",
      eleType: "input",
      type: "input",
      label: "State Name",
      value: enteredStateName,
      onChange: stateNameChangeHandler,
      // onBlur: stateNameBlurHandler, -> not a required input
      isInvalid: stateNameInputHasError,
    },
    {
      id: "input10",
      eleType: "input",
      type: "input",
      label: "Zip Code",
      value: enteredZipCode,
      onChange: zipCodeChangeHandler,
      // onBlur: zipCodeBlurHandler, -> not a required input
      isInvalid: zipCodeInputHasError,
    },
    {
      id: "input11",
      eleType: "select",
      type: "select",
      label: "Contact Type",
      options: [
        { name: 'Lead', value: 'lead' },
        { name: 'Contact', value: 'contact' }
      ],

      value: enteredContactType,
      errorMessage: "Please make a selection",
      onChange: contactTypeChangeHandler,
      onBlur: contactTypeBlurHandler,
      isInvalid: contactTypeInputHasError,
    },
    {
      id: "input12",
      eleType: "select",
      type: "select",
      label: "Status",
      options: [
        { name: 'Unnasigned', value: 'unassigned' },
        { name: 'Red', value: 'red' },
        { name: 'Yellow', value: 'yellow' },
        { name: 'Green', value: 'green' },
      ],
      value: enteredStatus,
      errorMessage: "Please make a selection",
      onChange: statusChangeHandler,
      onBlur: statusBlurHandler,
      isInvalid: statusInputHasError,
    },
    {
      id: "input13",
      eleType: "select",
      type: "select",
      label: "Source",
      options: [
        { name: 'facebook', value: 'facebook' },
        { name: 'referral', value: 'referral' },
        { name: 'email', value: 'email' },
        { name: 'royal tax', value: 'royal tax' },
        { name: 'smart assets', value: 'smart assets' }
      ],
      value: enteredSource,
      errorMessage: "Please make a selection",
      onChange: sourceChangeHandler,
      onBlur: sourceBlurHandler,
      isInvalid: sourceInputHasError,
    }
  ];

  let formIsValid = false;

  if (
    firstNameIsValid &&
    spouseNameIsValid &&
    lastNameIsValid &&
    emailIsValid &&
    phoneNumberIsValid &&
    secondaryPhoneNumberIsValid &&
    streetNumberIsValid &&
    cityNameIsValid &&
    stateNameIsValid &&
    zipCodeIsValid &&
    contactTypeIsValid &&
    statusIsValid &&
    sourceIsValid
  ) {
    formIsValid = true;
  }


  //Add new contact
  const submitHandler = (event) => {
    event.preventDefault();

    props.setIsLoadingHandler();

    dispatch(
      createContact({
        firstName: enteredFirstName,
        spouseName: enteredSpouseName,
        lastName: enteredLastName,
        email: enteredEmail,
        phone: enteredPhoneNumber,
        secondaryPhone: enteredSecondaryPhoneNumber,
        streetNumber: enteredStreetNumber,
        cityName: enteredCityName,
        stateName: enteredStateName,
        zipCode: enteredZipCode,
        contactType: enteredContactType,
        status: enteredStatus,
        source: enteredSource,
        creator: auth.userId
      })

      //Inputs not added to new contact fomr
      // dateOfBirth: req.body.dateOfBirth,
      // spouseDateOfBirth: req.body.spouseDateOfBirth,
      // assignedTo: req.body.assignTo,
      // campaignType: req.body.campaignType,
      // source: req.body.source,
      // dealHeat: req.body.dealHeat,
      // referralSource: req.body.referralSource,
      // recent: req.body.recent,
      // attendedEvent: req.body.attendedEvent,
      // eventVenue: req.body.eventVenue,
      // eventLocation: req.body.eventLocation,
      // eventTopic: req.body.eventTopic,
      // creator: req.body.creator,
    );

    //Reset input fields
    resetFirstNameInput();
    resetSpouseNameInput();
    resetLastNameInput();
    resetEmailInput();
    resetPhoneNumberInput();
    resetSecondaryPhoneNumberInput();
    resetStreetNumberInput();
    resetCityNameInput();
    resetStateNameInput();
    resetZipCodeInput();
    resetContactTypeInput();
    resetStatusInput();
    resetSourceInput();
  };



  return (
    <Form
      formFields={formFields}
      formIsValid={formIsValid}
      onSubmit={submitHandler}
      onCancelSubmit={props.closeModal}
      color='button--blue'
      label='Add Contact'
      margin="0"
      width="125px"
      size=""
      isLoading={props.isLoading}
    />
  );
};

export default ContactForm;
