import mergeClasses from 'classnames';
import classes from "./Dot.module.css";


const Dot = (props) => {
  let disabled = "disabled";
  let color;
  let calling = props.calling;
  let isOnline = props.isOnline;
  let isActive = props.isActive;
  let size = props.size;

  if (props.color !== "") {
    disabled = "";
    color = props.color;
  } else {
    color = "unassigned";
  }

  return (
    <div
      className={
        mergeClasses(
          classes.dot,
          classes[color],
          classes.calling,
          isOnline ? classes.isOnline : '',
          isActive ? classes.isActive : '',
          size && classes[size]
        )
      }
        
      style={{
        width: props.width ? props.width : "",
        height: props.height ? props.height : "",
      }}
      id={classes[disabled]}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};

export default Dot;


