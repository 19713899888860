import classes from "./MessageForm.module.css";
import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { sendMessage } from "../../../store/twilio-actions";

const MessageForm = (props) => {

  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const messageTextArea = useRef();

  const messsageInputHandler = (event) => {
    setMessage(event.target.value);
    messageTextArea.current.style.height = "5px";
    messageTextArea.current.style.height =
      messageTextArea.current.scrollHeight + "px";
  };

  //sends message and clears inputs
  const submitHandler = (event) => {
    event.preventDefault();

    const newMessage = {
      message: message,
      to: props.contact.phone,
      belongsTo: props.contact.id,
      creator: props.contact.creator
    };

    dispatch(sendMessage(newMessage));

    setMessage("");
    messageTextArea.current.style.height = '1.3rem'
  };

  return (
    <div className={classes["message-form"]}>
      <form onSubmit={submitHandler}>
        <div className={classes["form-container"]}>
          <div className={classes["form__control"]}>
            <textarea
              className={classes["text-area-element"]}
              rows="1"
              onChange={messsageInputHandler}
              ref={messageTextArea}
              value={message}
              placeholder="Type your message..."
              autoFocus
            />
          </div>
          <button
            className={classes["message-button"]}
            disabled={message === ""}
          >
            <FontAwesomeIcon
              icon={faPaperPlane}
              color={message === "" ? "rgba(0, 0, 0, 0.3)" : "white"}
            />
          </button>
        </div>
      </form>
    </div>
  );
};

export default MessageForm;
