import { useState, useEffect, useRef } from "react";
import Papa, { parse } from "papaparse";
import { useDispatch, useSelector } from "react-redux";
import { filterProps } from "framer-motion";
import { createContact, importContacts } from "../../../store/contact-actions";
import SpinnerButton from "../UIElements/SpinnerButton/SpinnerButton";
import classes from "./CSVImport.module.css";
import { Link, useNavigate } from "react-router-dom";
import Modal from "../UIElements/Modal/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileArrowDown,
  faCircleExclamation,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import useClickAwayListener from "../hooks/useClickAwayListener/useClickAwayLIstener";
import FieldMapItems from "./FieldMapItems";
import FileUploader from "../UIElements/FileUploader/FileUploader";
import UploadCSVImg from "../../../assets/imgs/upload-csv-image.png";
import UploadCSVImgError from "../../../assets/imgs/upload-csv-image-error.png";
import { contactActions } from "../../../store/contact";
import CloseX from "../UIElements/CloseX/CloseX";

//Allowed extensions for input files
const ALLOWED_FILE_EXTENSIONS = ["csv"];

const CSVImport = (props) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  //displays csv drag and drop and manual file uploader
  const [isImportDragDropOpen, setIsImportDragDropOpen] = useState(true);

  //displays csv field mapping component
  const [isCSVFieldMapperOpen, setIsCSVFieldMapperOpen] = useState(false);

  //csv data converted to json with papa parse
  const [parsedData, setParsedData] = useState([]);

  //error set if uploaded file extension is not a  csv
  const [error, setError] = useState('');

  //unmatched csv header columns
  const [unmatchedKeyCount, setUnmatchedKeyCount] = useState(0);

  //upload import progress from store
  const serverSideImportProgress = useSelector((state) => state.contactReducer.serverImportComplete);

  //file and import loading state
  const [isLoading, setIsLoading] = useState();

  //close file upload/drag-and-drop 
  const closeImportDragDropHandler = () => {
    setIsImportDragDropOpen(false);
    setIsCSVFieldMapperOpen(true);
  };

  //Close modal handler
  const closeModalHandler = () => {
    navigate("/app/call-center/");
  };

  //reset import modal to drag/drop/manual file uploader
  const closeCSVImportModalHandler = () => {
    setIsCSVFieldMapperOpen(false);
    setIsImportDragDropOpen(true);
    setError("");
    setParsedData([]);
  };

  //counts mismatched keys and adds to state
  const mismatchedKeyCountHandler = (count) => {
    setUnmatchedKeyCount(count);
  };

  const fileRef = useRef();

  const handleFileChange = (e) => {
    setError("");

    const uploadedFile = e[0];

    //validate against allowed extensions
    const fileExtension = uploadedFile?.type.split("/")[1];
    if (!ALLOWED_FILE_EXTENSIONS.includes(fileExtension)) {
      setError("Please upload a csv file.");
      return;
    }

    const reader = new FileReader();

    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, { header: true });
      const parsedData = [...csv.data];
     
      //sort keys and remove properties with empty values
      const sortKeys = parsedData.map((obj) => {
        return Object.keys(obj)
          .sort((a, b) => {
            a = a.toLowerCase();
            b = b.toLowerCase();
            if (a === b) return 0;
            return a < b ? -1 : 1;
          })
          .reduce((objEntries, key) => {
            objEntries[key] = obj[key];
            return objEntries;
          }, {});
      });

      //remove properties with empty or undefined values
     const filterEmptyValues = sortKeys.map((obj, i) => {
        Object.keys(obj).map((key) => {
          const value = obj[key];
          if (value === null || value === undefined || value === "") {
            delete obj[key];
          }
        });
      })

      //remove empty rows
      const filterEmptyObjects = sortKeys.filter(obj => {
        return Object.entries(obj).length != 0
      })

      //check if csv file is empty
      if (filterEmptyObjects.length <= 0) {
        setError("CSV is blank");
      } else {
        setParsedData(filterEmptyObjects);
        setIsCSVFieldMapperOpen(true);
        closeImportDragDropHandler();
      }
    };

    reader.readAsText(uploadedFile);
  };

  //maps uploaded csv columns to pre-set properties
  const updateKeyName = (oldKey, newKey) => {
    if (parsedData[0][newKey] !== undefined) {
      return;
    }

    parsedData.forEach((contact) => {
  
      //return if property does not exist for contact
      if (!contact[oldKey || !contact.newKey]){
        return;
      }

      const updateContactKey = Object.defineProperty(
        contact,
        newKey,
        Object.getOwnPropertyDescriptor(contact, oldKey)
      );

      delete contact[oldKey];

      const contactIndex = parsedData.indexOf(contact);

      const copiedParsedData = [...parsedData];

      const saveContact = (copiedParsedData[contactIndex] = contact);

      //sort keys alphabetically
      const sortKeys = copiedParsedData.map((obj) => {
        return Object.keys(obj)
          .sort((a, b) => {
            a = a.toLowerCase();
            b = b.toLowerCase();
            if (a === b) return 0;
            return a < b ? -1 : 1;
          })
          .reduce((objEntries, key) => {
            objEntries[key] = obj[key];
            return objEntries;
          }, {});
      });

      setParsedData(sortKeys);
    });
  };

  const onImportHandler = () => {
    setIsLoading(true);
    dispatch(importContacts(parsedData));
  };

  useEffect(() => {
    if (serverSideImportProgress) {
      setIsLoading(false);
    }

    if (serverSideImportProgress) {
      navigate("/app/call-center/");

      return () => {
        dispatch(contactActions.serverSideImportComplete(false));
      };
    }
  }, [serverSideImportProgress]);

  return (
    <>
      <Modal marginTop="2%" closeModal={closeModalHandler} maxWidth="800px">
        <div className={classes["csv-import-modal"]}>
          <header className={classes["header"]}>
            <div className={classes["breadcrumbs"]} style={{ flex: "2" }}></div>
            <div className={classes["header-actions"]}>
              <CloseX onClick={closeModalHandler} />
            </div>
          </header>
          <div style={{ padding: "1.5rem 2.5rem" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className={classes["title"]}>
                {isImportDragDropOpen && <h2>Import contacts</h2>}
                {isCSVFieldMapperOpen && !error && <h3>Match column labels to contact information</h3>}
              </div>
            </div>
            <div className={classes["description"]}>
              {isImportDragDropOpen && (
                <>
                  <p>Upload your own csv or download our template</p>
                  <div className={classes["download-template"]}>
                    <a
                      href="https://api.dotscrm.io/assets/docs/import-template.csv"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faFileArrowDown} />
                    </a>
                  </div>
                </>
              )}
              {isCSVFieldMapperOpen && !error && (
                <div className={classes["number-of-contacts"]}>
                  <p>
                    {parsedData?.length} contact
                    {parsedData?.length >= 1 ? "s" : ""} recgonized in this
                    file.
                  </p>
                </div>
              )}
            </div>
            {isImportDragDropOpen && (
              <>
                <div className={classes["drag-and-drop-files"]}>
                  <div className={classes["drag-and-drop-files__container"]}>
                    <FileUploader
                      img={UploadCSVImg}
                      imgError={UploadCSVImgError}
                      size="6rem"
                      onUploadFilesHandler={handleFileChange}
                      onCloseFileUpload={closeImportDragDropHandler}
                      hasError={error}
                    />
                    <div className={classes["formatting"]}>
                      Some data formats, such as dates and numbers, may not be
                      recognized.
                    </div>
                  </div>
                </div>
              </>
            )}
            {isCSVFieldMapperOpen && !error && (
              <>
                <div className={classes["fields"]}>
                  <FieldMapItems
                    parsedData={parsedData}
                    updateKeyName={updateKeyName}
                    onMismatchedKeyCount={mismatchedKeyCountHandler}
                  />
                </div>
                {unmatchedKeyCount >= 1 && (
                  <div className={classes["unmatched-key-warning"]}>
                    <FontAwesomeIcon icon={faCircleExclamation} />
                    <p>You have {unmatchedKeyCount} unmatched fields.</p>
                  </div>
                )}
                {!unmatchedKeyCount >= 1 && (
                  <div className={classes["unmatched-key-success"]}>
                    <FontAwesomeIcon icon={faCheck} />
                    <p>Your fileds are matched and ready for importing.</p>
                  </div>
                )}
              </>
            )}
            <div className={classes["strike-and-upload"]}>
              <div className={classes["strike-through-separator"]}>OR</div>
              <div className={classes["upload-from-url"]}>
                Upload from URL
                <div className={classes["upload-from-url__container"]}>
                  <div className={classes["upload-from-url__input"]}>
                    <input />
                  </div>
                  <SpinnerButton
                    size="button--small"
                    padding="0.5rem"
                    margin="0"
                    color="button--white"
                  >
                    Upload
                  </SpinnerButton>
                </div>
              </div>
            </div>
          </div>
          <div className={classes["menu_separator"]}></div>
          <div
            style={{
              display: "flex",
              gap: "0.5rem",
              padding: "1.5rem 2.5rem",
            }}
          >
            <div className={classes["support"]}>
              <FontAwesomeIcon icon={faCircleExclamation} color="gray" />
              <p>Support</p>
            </div>
            <div className={classes["actions"]}>
              <SpinnerButton
                size="button--small"
                margin="0"
                width="100px"
                color="button--white"
                onClick={isCSVFieldMapperOpen ? closeCSVImportModalHandler : closeModalHandler}
              >
                {isCSVFieldMapperOpen ? 'Discard' : 'Cancel'}
              </SpinnerButton>
              <SpinnerButton
                size="button--small"
                margin="0"
                width="100px"
                color="button--blue"
                disabled={unmatchedKeyCount >= 1 || isImportDragDropOpen}
                onClick={onImportHandler}
                isLoading={isLoading}
              >
                Import
              </SpinnerButton>
            </div>
          </div>
        </div>
      </Modal>
      <div className={classes["csv-import"]} onClick={props.onPopperHandler}>
        <input
          type="File"
          id="file"
          accept=".csv"
          name="fileName"
          onChange={handleFileChange}
          ref={fileRef}
        />
        {/* {submitIsVisible ? submit : props.children} */}
      </div>
    </>
  );
};

export default CSVImport;
