import Layout from "../../shared/components/Layout/Layout";
import { Outlet } from "react-router-dom";
import CalendarBox from "../components/CalendarBox";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

const Calendar = () => {
  return (
    <Layout>
      <DndProvider backend={HTML5Backend}>
        <CalendarBox />
      </DndProvider>
      <Outlet />
    </Layout>
  );
};

export default Calendar;
