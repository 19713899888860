import classes from "./EventItem.module.css";
import CalendarIcon from "../UIElements/CalendarIcon/CalendarIcon";
import EventSchedulerPopup from "./EventSchedulerPopup";
import { convertTime } from "../utils/convertTime";
import Dot from "../UIElements/Dot/Dot";
import useClickAwayListener from "../hooks/useClickAwayListener/useClickAwayLIstener";
import React, { useState, useRef, useEffect } from "react";
import { usePopper } from "react-popper";
import { ordinal_suffix_of } from "../utils/ordinalSuffix";
import { useDrag } from "react-dnd";

const EventItem = (props) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];


  //Reschedule event popper
  const [isEventReschedulerOpen, setisEventReschedulerOpen] = useState(false);
  const [referenceScheduledEvent, setReferenceScheduledEvent] = useState(null);
  const [eventRescheduler, setEventRescheduler] = useState(null);

  const eventReschedulerPopper = usePopper(
    referenceScheduledEvent,
    eventRescheduler,
    {
      placement: "bottom",
      modifiers: [
        { name: "offset", options: { offset: [0, 5] } },
        {
          name: "preventOverflow",
          options: {
            altAxis: true,
            padding: 10,
          },
        },
      ],
    }
  );

  const toggleEventReschedulerPopperHandler = (event) => {
    if (event) { event.stopPropagation(); }
    setisEventReschedulerOpen((prevState) => !prevState);
  };

  const eventReschedulerClickAwayRef = useRef(null);
  useClickAwayListener(eventReschedulerClickAwayRef, toggleEventReschedulerPopperHandler, referenceScheduledEvent);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: "events",
    item: { event: props.event },
    collect: (monitor) => ({
      isDraggin: !!monitor.isDragging()
    })
  }), [props]);

  return (
    <div ref={drag}>
      <div
        className={`${classes["event-item"]}`}
        ref={setReferenceScheduledEvent}
        onClick={toggleEventReschedulerPopperHandler}
        style={{ height: props.cellHeight && props.cellHeight }}
      >
        <div className={classes["details"]}>
          {props.event.eventType === "appointment" ? <Dot color="green" isActive width="10px" height="10px" /> : ""}
          {props.event.eventType === "follow-up" ? <Dot color="yellow" isActive width="10px" height="10px" /> : ""}

          {/*show contact name if contact and event type includes follow-up or appointment*/}
          {(props.event?.eventType === "follow-up" || props.event?.eventType === "appointment") && props.event?.belongsTo && <div className={classes["title"]} >
            {props.event.firstName + " " + props.event.lastName}
          </div>}

          {/*show event name if no event type*/}
          {props.event?.eventName && props.event?.eventType !== "follow-up" && props.event?.eventType !== "appointment" && <div className={classes["title"]}>{props.event?.eventName}</div>}

          {/*show event name if event type but does not includes contact (belongs to)*/}
          {(props.event?.eventType === "follow-up" || props.event?.eventType === "appointment") && !props.event?.belongsTo && <div className={classes["title"]} >
            {props.event.eventName}
          </div>}

          {/*show contact name if no event name or event type of follow-up or appointment*/}
          {props.event?.firstName && !props.event?.eventName && props.event?.eventType !== "follow-up" && props.event?.eventType !== "appointment" && <div className={classes["title"]}>{props.event?.firstName + " " + props.event?.lastName}</div>}
        </div>
        <div
          className={classes["delete-event"]}
          onClick={(e) => {
            props.deleteEventHandler(e, props.event);
          }}
        >
          x
        </div>
      </div>
      {
        isEventReschedulerOpen && (
          <div ref={eventReschedulerClickAwayRef}>
            <div
              ref={setEventRescheduler}
              className={classes.popper}
              style={eventReschedulerPopper.styles.popper}
              {...eventReschedulerPopper.attributes.popper}
            >
              <EventSchedulerPopup
                closePopper={toggleEventReschedulerPopperHandler}
                title={props.title}
                event={props.event}
                rescheduler={true}
                id={props.event.id}
              />
            </div>
          </div>
        )
      }
    </div>
  );
};

export default EventItem;
