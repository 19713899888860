import ReactDom from "react-dom";
import { NavLink, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Modal from "../../UIElements/Modal/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faPhone,
  faCircleInfo
} from "@fortawesome/free-solid-svg-icons";
import classes from "./SettingsModal.module.css";
import mergeClasses from "classnames";


const SettingsModal = () => {
  const navigate = useNavigate();

  //Close modal, navigate to previous page
  const closeModalHandler = () => {
    navigate("/app/call-center/");
  };

  return ReactDom.createPortal(
    <Modal closeModal={closeModalHandler} height="100%" maxWidth="900px">
      <div className={classes["settings-modal"]}>
        <div className={classes.aside}>
          <div className={mergeClasses(classes.title, classes.settings)}>
            Settings
          </div>
          <div className={classes.items}>
            <NavLink
              to="/app/call-center/settings/account"
              className={({ isActive }) =>
                isActive
                  ? mergeClasses(classes.itemLink, classes.active)
                  : undefined
              }
            >
              <div className={classes.item}>
                <div className={classes.icon}>
                  <FontAwesomeIcon icon={faUser} color="gray" />
                </div>
                <div className={classes["item-title"]}>Account</div>
              </div>
            </NavLink>
            <NavLink
              to="/app/call-center/settings/phone"
              className={({ isActive }) =>
                isActive
                  ? mergeClasses(classes.itemLink, classes.active)
                  : undefined
              }
            >
              <div className={classes.item}>
                <div className={classes.icon}>
                  <FontAwesomeIcon icon={faPhone} color="gray" />
                </div>
                <div className={classes["item-title"]}>Phone</div>
              </div>
            </NavLink>
            <NavLink
              to="/app/call-center/settings/theme"
              className={({ isActive }) =>
                isActive
                  ? mergeClasses(classes.itemLink, classes.active)
                  : undefined
              }
            >
              <div className={classes.item}>
                <div className={classes.icon}>
                  <FontAwesomeIcon icon={faCircleInfo} color="gray" />
                </div>
                <div className={classes["item-title"]}>Theme</div>
              </div>
            </NavLink>
          </div>
        </div>
        <Outlet />
      </div>
    </Modal>,
    document.getElementById("modal-root")
  );
};

export default SettingsModal;
