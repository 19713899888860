import classes from "./ViewMode.module.css";

const ViewMode = (props) => {
  return (
    <div className={classes["view-mode"]}>
      <div onClick={props.updateView} className={`${classes["view"]} ${props.view === "day" ? classes["active"] : ""}`} data-view="day">Day</div>
      <div className={classes["horizontal-line"]} ></div>
      <div onClick={props.updateView} className={`${classes["view"]} ${props.view === "week" ? classes["active"] : ""}`} data-view="week">Week</div>
      <div className={classes["horizontal-line"]}></div>
      <div onClick={props.updateView} className={`${classes["view"]} ${props.view === "month" ? classes["active"] : ""}`} data-view="month">Month</div>
    </div>
  );
};

export default ViewMode;
