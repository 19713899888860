import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { twilioActions } from "../../../../store/twilio";
import { makeOutboundCall } from "../../../../store/twilio-actions";
import { uiActions } from "../../../../store/ui";
import { notificationActions } from "../../../../store/notifications";

export const useTwilioPhoneOutgoing = (contactId) => {
  const dispatch = useDispatch();

  const [callIsMuted, setCallIsMuted] = useState(false);
  const [onPhone, setOnPhone] = useState(false);
  const [phoneButtonColor, setPhoneButtonColor] = useState("unassigned");

  //import twilio device and active call from store
  const device = useSelector((state) => state.twilioReducer.twilioDevice);
  const call = useSelector((state) => state.twilioReducer.currentCall);

  //updates call button color according to the calls status
  //updated by twilio callback to backend -> frontend -> store
  const callStatus = useSelector((state) => state.twilioReducer.callStatus);
  const userHasPhoneNumber = useSelector(state => state.userReducer.user.userSettings.phone.number);
  const callOutcome = useSelector((state) => state.twilioReducer.callOutcome);

  const resetCallOutcome = (payload) => {
    // dispatch(twilioActions.callOutcome(''))
  }

  //sets the list item call button opacity to 1 to convey active call
  //commpares activeCallerId set by the makeCallHandler to the contact iterated in listitems.js
  const callIsActiveForContact = useSelector(
    (state) => state.twilioReducer.activeCallContactId
  ).includes(contactId);

  //get contact and assign callerId
  const activeCallContactId = useSelector(
    (state) => state.twilioReducer.activeCallContactId
  );
  
  const contact = useSelector((state) =>
    state.contactReducer.contacts.find(
      (contact) => contact.id === activeCallContactId
    )
  );

  let callerId;
  if (contact) {
    callerId = contact.firstName + " " + contact.lastName;
  }

  //handlers set local state for ui updates in listItem.js
  const makeCallHandler = (event, phoneNumber, contactId) => {
    if (event) {
      event.stopPropagation();
    }
    if (phoneNumber) {
      setOnPhone(true);
    }
    dispatch(makeOutboundCall(phoneNumber, contactId));
  };

  const hangUpCallHandler = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setOnPhone(false);
    setCallIsMuted(false);
    device.disconnectAll();
  };

  const muteCallHandler = (event) => {
    if (event) {
      event.stopPropagation();
    }
    if (call.isMuted() === true) {
      call.mute(false);
      setCallIsMuted(false);
    } else {
      call.mute();
      setCallIsMuted(true);
    }
  };

  useEffect(() => {
    if (callStatus === "initiated") {
      setPhoneButtonColor("green");
    }
    if (callStatus === "ringing") {
      setPhoneButtonColor("green");
    }
    if (callStatus === "busy") {
      setPhoneButtonColor("unassigned");
    }
    if (callStatus === "failed") {
      setPhoneButtonColor("unassigned");
    }
    if (callStatus === "in-progress") {
      setPhoneButtonColor("red");
    }
    if (callStatus === "completed") {
      setPhoneButtonColor("unassigned");
    }
  }, [callStatus]);

  return {
    onPhone,
    callIsActiveForContact,
    activeCallContactId,
    callIsMuted,
    phoneButtonColor,
    callStatus,
    callOutcome,
    callerId,
    userHasPhoneNumber,
    makeCallHandler,
    hangUpCallHandler,
    muteCallHandler,
    resetCallOutcome
  };
};