import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Notes from "./Notes";
import NotesForm from "./NotesForm";
import NotesHeader from "./NotesHeader";
import { fetchNotes, contactNotesAbortController } from "../../../store/contact-note-actions";
import { fetchDealNotes, dealNotesAbortController } from '../../../store/deal-note-actions';
import classes from "./NotesBox.module.css";

const NotesBox = () => {
  const dispatch = useDispatch();

  const params = useParams();

  //get contact/deal ids from params
  const contactId = params.contactId;
  const dealId = params.dealId;

  //check if notes were fetched
  const localContactNotes = useSelector((state) => state.contactNoteReducer.notes);
  const localDealNotes = useSelector((state) => state.dealNoteReducer.notes);

  //checks state for notes, fetches if false
  useEffect(() => {
    if (contactId && !localContactNotes.find((contact) => contact.belongsTo === contactId)) {
      dispatch(fetchNotes(contactId));
    } else {
      return;
    }
    // fire abort here
    return () => {
      dispatch(contactNotesAbortController());
    };
  }, [contactId]);

  //checks state for notes, fetches if false
  useEffect(() => {
    if (dealId && !localDealNotes.find((deal) => deal.belongsTo === dealId)) {
      dispatch(fetchDealNotes(dealId));
    } else {
      return;
    }
    // fire abort here
    return () => {
      dispatch(dealNotesAbortController());
    };
  }, [dealId]);

  return (
    <>
      <Notes contactId={contactId} dealId={dealId}/>
      <div className={classes["notes-form"]}>
        <NotesForm contactId={contactId} dealId={dealId}/>
      </div>
    </>
  );
};

export default NotesBox;
