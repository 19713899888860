import React, { useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import classes from "./ContactCommunications.module.css";

const ContactCommunications = (props) => {
  return (
    <div className={classes["contact-communications"]}>
      <div className={classes["contact-communications__menu"]}>
        <NavLink
          to="notes"
          className={({ isActive }) =>
            isActive ? classes["active"] : undefined
          }
        >
          Notes
        </NavLink>
        <NavLink
          to="messages"
          className={({ isActive }) =>
            isActive ? classes["active"] : undefined
          }
        >
          Messages
        </NavLink>
        <NavLink
          to="recordings"
          className={({ isActive }) =>
            isActive ? classes["active"] : undefined
          }
        >
          Recordings
        </NavLink>
      </div>
      <Outlet />
    </div>
  );
};

export default ContactCommunications;
