import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../UIElements/Button/Button";
import UserBadge from "../UIElements/UserBadge/UserBadge";
import PDFIcon from "../../../assets/imgs/pdf-icon.png";
import CSVIcon from "../../../assets/imgs/csv-icon.png";
import MP4Icon from "../../../assets/imgs/mp4-icon.png";
import MOVIcon from "../../../assets/imgs/mov-icon.png";
import DownloadIcon from "../../../assets/imgs/download-icon.png";
import { updateNote, deleteNote } from "../../../store/contact-note-actions";
import classes from "./Note.module.css";
import { RxTrash } from "react-icons/rx";
import { CiEdit } from "react-icons/ci";
import SpinnerButton from "../UIElements/SpinnerButton/SpinnerButton";
import React, { useRef } from "react";

const Note = (props) => {
  const dispatch = useDispatch();

  const user = useSelector(state => state.userReducer.user)

  const noteTextareaRef = useRef();

  const [noteInput, setNoteInput] = useState("");

  const [editNoteIsActive, setEditNoteIsActive] = useState(false);

  //Import notes otherwise request new note
  let enteredNote;
  let images = [];
  let downloads = [];

  if (!props.noteData.files) {
    enteredNote = props.noteData.text;
  }

  if (props.noteData.files) {
    enteredNote = props.noteData.text;

    props.noteData.files.forEach((uploadedFile) => {
      const { mimetype, originalname, path } = uploadedFile;

      if (
        mimetype === "image/jpg" ||
        mimetype === "image/jpeg" ||
        mimetype === "image/png"
      ) {
        images.push({
          imageUrl: process.env.REACT_APP_BACKEND_URL + "/" + path,
        });
      } else {
        let icon;
        if (mimetype === "application/pdf") {
          icon = PDFIcon;
        }
        if (mimetype === "text/csv") {
          icon = CSVIcon;
        }
        if (mimetype === "image/heic") {
          icon = DownloadIcon;
        }
        if (mimetype === "video/mp4") {
          icon = MP4Icon;
        }
        if (mimetype === "video/quicktime") {
          icon = MOVIcon;
        }
        downloads.push({
          downloadUrl: process.env.REACT_APP_BACKEND_URL + "/" + path,
          icon: icon,
          fileName: originalname,
        });
      }
    });
  }

  useEffect(() => {
    setNoteInput(enteredNote);
  }, []);

  const noteInputHandler = (updatedValue) => {
    setNoteInput(updatedValue.target.value);
    noteTextareaRef.current.style.height = "5px"
    noteTextareaRef.current.style.height = noteTextareaRef.current.scrollHeight + "px"
  };

  useEffect(() => {
    if (noteTextareaRef.current) {
      noteTextareaRef.current.style.height = "10px"
      noteTextareaRef.current.style.height = noteTextareaRef.current.scrollHeight + "px"
    }
  }, [noteInput, editNoteIsActive])

  const editNoteHandler = () => {
    setEditNoteIsActive(true);
  };

  const cancelNoteUpdateHandler = (event) => {
    event.preventDefault();
    setEditNoteIsActive(false);
  };

  const updateNoteHandler = (event) => {
    event.preventDefault();
    dispatch(
      updateNote({
        noteId: props.noteData.id,
        text: noteInput,
        creator: props.noteData.creator,
        belongsTo: props.noteData.belongsTo,
      })
    );
    setTimeout(() => {
      setEditNoteIsActive(false);
    }, 60);
  };

  const deleteNoteHandler = () => {
    dispatch(deleteNote({ noteId: props.noteData.id }));
  };

  const convertTime = (isoTime) => {
    var date = isoTime;
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var newformat = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return hours + ":" + minutes + newformat;
  };

  const doubleClickNoteHandler = (e) => {
    if (e.detail === 2) {
        setEditNoteIsActive(true);
    }
};

  let noteContent;

  if (editNoteIsActive) {
    noteContent = (
      <div className={classes.note}>
        <div className={classes["user-details"]}>
          {/* <UserBadge user={} /> */}
          <UserBadge size="small" color="orange">
            {user.userName}
          </UserBadge>
          <div className={classes["user-name"]}>{user.userName}</div>
          <div className={classes["date-time"]}>
            <div className={classes["heading-date"]}>
              {convertTime(new Date(props.noteData.createdAt))}{" "}
              {new Date(props.noteData.createdAt).getMonth() + 1}/
              {new Date(props.noteData.createdAt).getDate()}/
              {new Date(props.noteData.createdAt).getFullYear()}
            </div>
          </div>
        </div>
        <div className={classes["container"]}>
          <form>
            <div className={classes["form__control"]}>
              <textarea className={classes["text-area-element"]}  value={noteInput} onChange={noteInputHandler} rows="1" ref={noteTextareaRef} autoFocus/>
              <div className={classes["update-buttons"]}>
                <div className={classes["cancel-button"]}>
                  <SpinnerButton
                    onClick={cancelNoteUpdateHandler}
                    customClasses="button--white"
                  >
                    Cancel
                  </SpinnerButton>
                </div>
                <div className={classes["update-button"]}>
                  <SpinnerButton onClick={(event) => updateNoteHandler(event)} customClasses="button--blue">
                    Update
                  </SpinnerButton>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  } else {
    noteContent = (
      <div className={classes["note"]}>
        <div className={classes["user-details"]}>
          <UserBadge size="small" color="purple">
            {user.userName}
          </UserBadge>
          <div className={classes["user-name"]}>{user.userName}</div>
          <div className={classes["date-time"]}>
            <div className={classes["heading-date"]}>
              {convertTime(new Date(props.noteData.createdAt))}{" "}
              {new Date(props.noteData.createdAt).getMonth() + 1}/
              {new Date(props.noteData.createdAt).getDate()}/
              {new Date(props.noteData.createdAt).getFullYear()}
            </div>
          </div>
          <div className={classes["actions-buttons"]}>
            <div
              className={classes["actions-button"]}
              onClick={editNoteHandler}
            >
              <CiEdit size="25px"className={classes["edit-icon"]}/>
            </div>
            <div
              className={classes["actions-button"]}
              onClick={deleteNoteHandler}
            >
              <RxTrash size="20px" className={classes["trash-icon"]}/>
            </div>
          </div>
        </div>
        <div className={classes["content"]} onClick={doubleClickNoteHandler}>
          <p>{enteredNote}</p>
          {images.map((image) => {
            return (
              <div
                className={classes["image-file"]}
                onClick={() => props.onZoomModal(image.imageUrl)}
              >
                <img src={image.imageUrl} style={{ height: 180, width: 180 }} />
              </div>
            );
          })}
          {downloads.map((download) => {
            return (
              <div className={classes["downloadable-file"]} onClick={()=> window.open(download.downloadUrl, "_blank")}>
                <img src={download.icon} alt="" />
                <a href={download.downloadUrl} target="_blank">
                  {download.fileName}
                </a>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return <>{noteContent}</>;
};

export default Note;
