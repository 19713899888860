import ContactForm from "./ContactForm";
import "./NewContact.css";

const NewContact = (props) => {
  return (
    <div className="new-contact">
      <ContactForm
        closeModal={props.closeModal}
        isLoading={props.isLoading}
        setIsLoadingHandler={props.setIsLoadingHandler}
      />
    </div>
  );
};

export default NewContact;
