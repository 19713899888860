import classes from "./HomePage.module.css";
import Logo from "../../assets/imgs/logo.png";
import Image1 from "../../assets/imgs/mobile.png";
import Img2 from "../../assets/imgs/feature.png";
import Img3 from "../../assets/imgs/manage.png";
import XSvg from "../../assets/imgs/x.svg";
import InstaSvg from "../../assets/imgs/insta.svg";
import LinkedInSvg from "../../assets/imgs/linkedin.svg";
import YouTubeSvg from "../../assets/imgs/youtube.svg";
import React, { useRef, useEffect } from "react";
import mergeClasses from "classnames";
import { Link } from 'react-router-dom';

export default function HomePage() {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // Data points
    const data = {
      green: [
        { x: 1, y: 1 },
        { x: 2, y: 90 },
        { x: 3, y: 75 },
        { x: 4, y: 85 },
        { x: 5, y: 80 },
        { x: 6, y: 85 },
        { x: 7, y: 78 },
        { x: 8, y: 88 },
      ],
      yellow: [
        { x: 1, y: 1 },
        { x: 2, y: 30 },
        { x: 3, y: 25 },
        { x: 4, y: 35 },
        { x: 5, y: 30 },
        { x: 6, y: 45 },
        { x: 7, y: 35 },
        { x: 8, y: 40 },
      ],
      pink: [
        { x: 1, y: 1 },
        { x: 2, y: 10 },
        { x: 3, y: 8 },
        { x: 4, y: 10 },
        { x: 5, y: 7 },
        { x: 6, y: 12 },
        { x: 7, y: 6 },
        { x: 8, y: 8 },
      ],
    };

    const setupCanvas = () => {
      const dpr = window.devicePixelRatio || 1;
      const rect = canvas.getBoundingClientRect();
      canvas.width = rect.width * dpr;
      canvas.height = rect.height * dpr;
      ctx.scale(dpr, dpr);
      canvas.style.width = `${rect.width}px`;
      canvas.style.height = `${rect.height}px`;
      return rect;
    };

    const drawChart = () => {
      const rect = setupCanvas();
      const { width, height } = rect;
      const padding = {
        top: height * 0.1,
        right: width * 0.05,
        bottom: height * 0.15,
        left: width * 0.1,
      };
      const chartWidth = width - (padding.left + padding.right);
      const chartHeight = height - (padding.top + padding.bottom);

      ctx.clearRect(0, 0, width, height);

      for (let i = 0; i < 8; i++) {
        const x = padding.left + (i * chartWidth) / 7;
        ctx.fillStyle = "#FFFFFF";
        ctx.font = `${Math.max(width * 0.02, 10)}px Arial`;
        ctx.textAlign = "center";
        ctx.fillText(i + 1, x, height - padding.bottom / 2);
      }

      for (let i = 0; i <= 5; i++) {
        const y = padding.top + (i * chartHeight) / 5;
        ctx.fillStyle = "#FFFFFF";
        ctx.textAlign = "right";
        ctx.fillText((100 - i * 20).toString(), padding.left - 10, y + 5);
      }

      const drawLine = (points, color) => {
        ctx.strokeStyle = color;
        ctx.lineWidth = Math.max(width * 0.003, 2);
        ctx.beginPath();
        points.forEach((point, index) => {
          const x = padding.left + ((point.x - 1) * chartWidth) / 7;
          const y = padding.top + chartHeight - (point.y * chartHeight) / 100;
          if (index === 0) ctx.moveTo(x, y);
          else ctx.lineTo(x, y);
        });
        ctx.stroke();
      };

      drawLine(data.green, "#7CE98C");
      drawLine(data.yellow, "#FEEA46");
      drawLine(data.pink, "#E8898A");
    };

    drawChart();

    let resizeTimeout;
    window.addEventListener("resize", () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(drawChart, 100);
    });

    return () => {
      window.removeEventListener("resize", () => {
        clearTimeout(resizeTimeout);
        resizeTimeout = setTimeout(drawChart, 100);
      });
    };
  }, []);

  return (
    <div className={classes["home-page"]}>
      <div className={classes["beta-banner"]}>
        <p className={classes["text"]}>
          Join our beta and get free unlimited access to&nbsp;
          <Link to="/auth/login" class="text">
            standard
          </Link>
        </p>
      </div>

      <nav className="navbar navbar-expand-lg">
        <div className={mergeClasses("container", classes.container)}>
          <a className={"navbar-brand"} href="/">
            <img src={Logo} alt="Logo" className={classes["nav-logo"]} />
          </a>

          <div
            className={mergeClasses("d-lg-none")}
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <svg
              width="20"
              height="13"
              viewBox="0 0 20 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                y="0.346153"
                width="20"
                height="1.53846"
                rx="0.769231"
                fill="white"
              />
              <rect
                y="5.73077"
                width="20"
                height="1.53846"
                rx="0.769231"
                fill="white"
              />
              <rect
                y="11.1154"
                width="20"
                height="1.53846"
                rx="0.769231"
                fill="white"
              />
            </svg>
          </div>

          <div
            className={mergeClasses(
              classes["collapse"],
              classes["navbar-collapse"],
              "justify-content-end",
              "collapse",
              "navbar-collapse"
            )}
            id="navbarNav"
          >
            <div
              className={mergeClasses("navbar-nav", classes["auth-buttons"])}
            >
              <Link
                to="/auth/login"
                className={mergeClasses("nav-link", classes["auth-link"])}
              >
                Sign In
              </Link>
              <span
                className={mergeClasses(
                  classes["divider"],
                  "d-none",
                  "d-lg-inline"
                )}
              >
                |
              </span>
              <Link
                to="/auth/login"
                className={mergeClasses("nav-link", classes["auth-link"])}
              >
                Sign Up
              </Link>
            </div>
          </div>
        </div>
      </nav>

      <section className={mergeClasses("container", classes.hero)}>
        <div>
          <div>
            <h2 className={classes["hero-title"]}>
              Convert More Leads with Dots
            </h2>
          </div>
          <div>
            <p className={classes["hero-subtitle"]}>
              Make calls, send texts, schedule appointments, track deals all
              from one intuitive system.
            </p>
          </div>
        </div>
        <div className={classes["get-started"]}>
          <div>
            <Link to="/auth/login">
            <button className={classes["hero-button"]}>Get Started</button>
            </Link>
          </div>
          <div>
            <p className={classes["advisors-text"]}>
              Developed by Financial Advisors
            </p>
          </div>
        </div>
      </section>

      <section className={classes["stats"]}>
        <div className={mergeClasses("container", classes.container)}>
          <div className={classes["stats-content"]}>
            <div className={classes["stat-item"]}>
              <div className={classes["stat-number"]}>400%</div>
              <div className={classes["stat-label"]}>
                Increased Call
                <br />
                Volume
              </div>
            </div>
            <div className={classes["stat-item"]}>
              <div className={classes["stat-number"]}>10x</div>
              <div className={classes["stat-label"]}>
                Appointments
                <br />
                Set Per Hour
              </div>
            </div>
            <div
              className={mergeClasses(
                "col-md-3",
                "col-sm-6",
                classes["col-md-3"],
                classes["col-sm-6"]
              )}
            >
              <div className={classes["stat-item"]}>
                <div className={classes["stat-number"]}>200%</div>
                <div className={classes["stat-label"]}>
                  Productivity
                  <br />
                  Increase
                </div>
              </div>
            </div>
            <div className={classes["stat-item"]}>
              <div className={classes["stat-number"]}>5x</div>
              <div className={classes["stat-label"]}>
                Productivity
                <br />
                Increase
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className={mergeClasses(
          "container",
          classes["chart"],
          classes.container
        )}
      >
        <div
          className={mergeClasses(
            "row",
            "g-4",
            "align-items-center",
            "d-flex",
            "justify-content-between",
            classes["row"]
          )}
        >
          <div
            className={mergeClasses(
              "col-lg-4",
              "col-md-12",
              classes["col-lg-4"],
              classes["col-md-12"]
            )}
          >
            <h2 className={classes["chart-heading"]}>Reach Clients Faster</h2>
            <p className={classes["subtitle"]}>
              Devour the competition with AI-powered auto and predictive
              dialing. Increase your hourly call volume by 400% or more!
            </p>
            <div className={classes["items-main"]}>
              <div className={classes["chart-items"]}>
                <div className={classes["bullet"]}>
                  <span
                    className={mergeClasses(
                      "icon-bullet",
                      "manual",
                      classes["icon-bullet"],
                      classes["manual"]
                    )}
                  ></span>
                  <h3>Manual Dialing</h3>
                </div>
                <p>
                  Dial your contacts with a simple click from their contact card,
                  or enter phone numbers directily into the app to make a call.
                </p>
              </div>

              <div className={classes["chart-items"]}>
                <div className={classes["bullet"]}>
                  <span
                    className={mergeClasses(
                      "icon-bullet",
                      "auto",
                      classes["icon-bullet"],
                      classes["auto"]
                    )}
                  ></span>
                  <h3>Auto Dialing</h3>
                </div>
                <p>
                  Increase your dials per hours by activing auto dialer, an advanced
                  system that recognizes voice machines to drop your recording or hang up.
                </p>
              </div>

              <div className={classes["chart-items"]}>
                <div className={mergeClasses("bullet", classes["bullet"])}>
                  <span
                    className={mergeClasses(
                      "icon-bullet",
                      "ai",
                      classes["icon-bullet"],
                      classes["ai"]
                    )}
                  ></span>
                  <h3>AI Dialing</h3>
                </div>
                <p>
                  Maximize your dialing with our AI predicting dialer, make several
                  calls at once and get connected with the first to answer - it's fast!
                </p>
              </div>
            </div>
          </div>

          <div
            className={mergeClasses(
              "col-lg-8",
              "col-md-12",
              "chart-wrapper",
              classes["col-lg-8"],
              classes["col-md-12"],
              classes["chart-wrapper"]
            )}
          >
            <div
              className={mergeClasses(
                "chart-container",
                classes["chart-container"]
              )}
            >
              <canvas ref={canvasRef}></canvas>
            </div>
            <div className={classes["mobile-view"]}>
              <div className={classes["items"]}>
                <div className={classes["items-content"]}>
                  <div
                    className={mergeClasses(
                      "icon-bullet",
                      "manual",
                      classes["icon-bullet"],
                      classes["manual"]
                    )}
                  ></div>
                  <p>Manual Dialing</p>
                </div>
                <div className={classes["items-content"]}>
                  <div
                    className={mergeClasses(
                      "icon-bullet",
                      "auto",
                      classes["icon-bullet"],
                      classes["auto"]
                    )}
                  ></div>
                  <p>Auto-dialing</p>
                </div>
                <div className={classes["items-content"]}>
                  <div
                    className={mergeClasses(
                      "icon-bullet",
                      "ai",
                      classes["icon-bullet"],
                      classes["ai"]
                    )}
                  ></div>
                  <p>AI-Dialing</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={mergeClasses("feature", classes.feature)}>
        <div className={mergeClasses("container", classes.container)}>
          <div className={mergeClasses("row", classes.row)}>
            <div className={mergeClasses("col-12", classes["col-12"])}>
              <h2 className={classes["feature-heading"]}>
                Full-Feature Call Center
              </h2>
              <p className={classes["feature-subTitle"]}>
                Our cloud-based application lets you call, text, or email your
                contacts within any browser, so you can pick up where you
                started from any device.
              </p>
            </div>
          </div>

          <div
            className={mergeClasses(
              "row",
              "g-4",
              "feature-content",
              "align-items-center",
              "d-flex",
              "justify-content-between",
              classes["row"],
              classes["feature-content"]
            )}
          >
            <div
              className={mergeClasses(
                "col-lg-4",
                "col-md-12",
                "order-lg-2",
                "order-md-2",
                "order-2",
                classes["col-lg-4"],
                classes["col-md-12"]
              )}
            >
              <div className={classes["feature-cards"]}>
                <div
                  className={mergeClasses(
                    "calling-card",
                    "feature-card",
                    classes["calling-card"],
                    classes["feature-card"]
                  )}
                >
                  <div className={classes["title-icon"]}>
                    <h3>Calling</h3>
                    <div
                      className={mergeClasses(
                        "icon-container",
                        "calling-icon",
                        classes["icon-container"],
                        classes["calling-icon"]
                      )}
                    >
                      <div className={classes["icon-svg"]}></div>
                    </div>
                  </div>
                  <div>
                    <p>
                    Our in-browser call center gives you 3 powerful options for 
                    dialling your leads and contacts: manual, auto, and AI.
                    </p>
                  </div>
                </div>

                <div
                  className={mergeClasses(
                    "texting-card",
                    "feature-card",
                    classes["texting-card"],
                    classes["feature-card"]
                  )}
                >
                  <div className={classes["title-icon"]}>
                    <h3>Texting</h3>
                    <div
                      className={mergeClasses(
                        "icon-container",
                        "texting-icon",
                        classes["icon-container"],
                        classes["texting-icon"]
                      )}
                    >
                      <div className={classes["icon-svg"]}></div>
                    </div>
                  </div>
                  <div>
                    <p>
                      Makes texting your contacts a breeze with a slack-like
                      slide-out menu that organizes your text messaging.
                    </p>
                  </div>
                </div>

                <div
                  className={mergeClasses(
                    "email-card",
                    "feature-card",
                    classes["email-card"],
                    classes["feature-card"]
                  )}
                >
                  <div className={classes["title-icon"]}>
                    <h3>Email</h3>
                    <div
                      className={mergeClasses(
                        "icon-container",
                        "email-icon",
                        classes["icon-container"],
                        classes["email-icon"]
                      )}
                    >
                      <div className={classes["icon-svg"]}></div>
                    </div>
                  </div>
                  <div>
                    <p>
                      Email integreation with your favorite email provider 
                      allows you to keep all your communication in one place.
                    </p>
                  </div>
                </div>

                <div
                  className={mergeClasses(
                    "notes-card",
                    "feature-card",
                    classes["notes-card"],
                    classes["feature-card"]
                  )}
                >
                  <div className={classes["title-icon"]}>
                    <h3>Notes, Recordings, Tasks</h3>
                    <div
                      className={mergeClasses(
                        "icon-container",
                        "notes-icon",
                        classes["icon-container"],
                        classes["notes-icon"]
                      )}
                    >
                      <div className={classes["icon-svg"]}></div>
                    </div>
                  </div>
                  <div>
                    <p>
                      Keep all your notes, recordings, and tasks organized
                      within each contact card to keep track deal progress.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={mergeClasses(
                "col-lg-8",
                "col-md-12",
                "order-lg-2",
                "order-md-1",
                "order-1",
                "feature-rigt-content",
                classes["col-lg-8"],
                classes["col-md-12"],
                classes["feature-rigt-content"]
              )}
            >
              <div className={classes["feature-sm-img"]}>
                <img
                  src={Image1}
                  alt="feature"
                  className={classes["img-fluid"]}
                />
              </div>
              <div className={classes["feature-img"]}>
                <img
                  src={Img2}
                  alt="feature"
                  className={classes["img-fluid"]}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className={mergeClasses(
          "container",
          classes["manage-section"],
          classes.container
        )}
      >
        <div className="row">
          <div className="col-lg-8">
            <div className={classes["manage-content"]}>
              <h2 className={classes["manage-heading"]}>Book and Manage</h2>
              <p className={classes["manage-subTitle"]}>
                Schedule leads directly to our in-app calendar, manage your
                appointments and events all in-app.
              </p>
            </div>
            <div className={classes["manage-img"]}>
              <img src={Img3} alt="feature" className="img-fluid" />
            </div>
          </div>
          <div className="col-lg-4">
            <div className={classes["time-left-content"]}>
              <div className={classes["time-slots"]}>
                <div className={classes["time"]}>10am</div>
                <div className={classes["time"]}>11am</div>
                <div className={classes["time"]}>12pm</div>
                <div className={classes["time"]}>1pm</div>
                <div className={classes["time"]}>2pm</div>
                <div className={classes["time"]}>3pm</div>
                <div className={classes["time"]}>4pm</div>
                <div className={classes["time"]}>5pm</div>
                <div className={classes["time"]}>6pm</div>
                <div className={classes["time"]}>7pm</div>
                <div className={classes["time"]}>8pm</div>
                <div className={classes["time"]}>9pm</div>
              </div>

              <div className={classes["book-card-main"]}>
                <div
                  className={mergeClasses(
                    classes["book-card"],
                    classes["green"]
                  )}
                >
                  <div className={classes["content"]}>
                    <div
                      className={mergeClasses(
                        classes["dot"],
                        classes["dot-green"]
                      )}
                    ></div>
                    <div>
                      <h4>James Ballarta</h4>
                      <p>TOA Merrill to Schwab</p>
                    </div>
                  </div>
                </div>
                <div
                  className={mergeClasses(
                    classes["book-card"],
                    classes["green"]
                  )}
                >
                  <div className={classes["content"]}>
                    <div
                      className={mergeClasses(
                        classes["dot"],
                        classes["dot-green"]
                      )}
                    ></div>
                    <div>
                      <h4>James Ballarta</h4>
                      <p>TOA Merrill to Schwab</p>
                    </div>
                  </div>
                </div>
                <div className={classes["empty"]}></div>
                <div
                  className={mergeClasses(
                    classes["book-card"],
                    classes["green"]
                  )}
                >
                  <div className={classes["content"]}>
                    <div
                      className={mergeClasses(
                        classes["dot"],
                        classes["dot-green"]
                      )}
                    ></div>
                    <div>
                      <h4>James Ballarta</h4>
                      <p>TOA Merrill to Schwab</p>
                    </div>
                  </div>
                </div>
                <div
                  className={mergeClasses(
                    classes["book-card"],
                    classes["yellow"]
                  )}
                >
                  <div className={classes["content"]}>
                    <div
                      className={mergeClasses(
                        classes["dot"],
                        classes["dot-yellow"]
                      )}
                    ></div>
                    <div>
                      <h4>James Ballarta</h4>
                      <p>TOA Merrill to Schwab</p>
                    </div>
                  </div>
                </div>
                <div
                  className={mergeClasses(
                    classes["book-card"],
                    classes["green"]
                  )}
                >
                  <div className={classes["content"]}>
                    <div
                      className={mergeClasses(
                        classes["dot"],
                        classes["dot-green"]
                      )}
                    ></div>
                    <div>
                      <h4>James Ballarta</h4>
                      <p>TOA Merrill to Schwab</p>
                    </div>
                  </div>
                </div>
                <div className={classes["empty"]}></div>
                <div className={classes["empty"]}></div>
                <div
                  className={mergeClasses(
                    classes["book-card"],
                    classes["green"]
                  )}
                >
                  <div className={classes["content"]}>
                    <div
                      className={mergeClasses(
                        classes["dot"],
                        classes["dot-green"]
                      )}
                    ></div>
                    <div>
                      <h4>James Ballarta</h4>
                      <p>TOA Merrill to Schwab</p>
                    </div>
                  </div>
                </div>
                <div
                  className={mergeClasses(
                    classes["book-card"],
                    classes["yellow"]
                  )}
                >
                  <div className={classes["content"]}>
                    <div
                      className={mergeClasses(
                        classes["dot"],
                        classes["dot-yellow"]
                      )}
                    ></div>
                    <div>
                      <h4>James Ballarta</h4>
                      <p>TOA Merrill to Schwab</p>
                    </div>
                  </div>
                </div>
                <div className={classes["empty"]}></div>
                <div
                  className={mergeClasses(
                    classes["book-card"],
                    classes["yellow"]
                  )}
                >
                  <div className={classes["content"]}>
                    <div
                      className={mergeClasses(
                        classes["dot"],
                        classes["dot-yellow"]
                      )}
                    ></div>
                    <div>
                      <h4>James Ballarta</h4>
                      <p>TOA Merrill to Schwab</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={mergeClasses(classes["workflows-section"])}>
        <div className={mergeClasses("container", classes["container"])}>
          <div className={classes["heading-content"]}>
            <div>
              <h2>Convert More Leads with Customizable Workflows</h2>
              <p>
                Manage deals and tasks with fully customizable kanban style
                workflows and pipelines.
              </p>
            </div>
          </div>
          <div className={classes["kanban-wrapper"]}>
            <div>
              <h4>AUM Pipeline</h4>
            </div>
            <div className={classes["board"]}>
              <div className={classes["kanban-card"]}>
                <div
                  className={mergeClasses(
                    "kanban-header",
                    classes["kanban-header"]
                  )}
                >
                  <h5>Proposal</h5>
                </div>
                <div className={classes["task-list"]} data-column="proposal">
                  <div className={classes["task"]} draggable="true">
                    <div className={classes["dot"]}></div>
                    <div>
                      <h2>James Ballarta</h2>
                      <p>$1,000,000 IRA Transfer</p>
                    </div>
                  </div>
                  <div className={classes["task"]} draggable="true">
                    <div className={classes["dot"]}></div>
                    <div>
                      <h2>James Ballarta</h2>
                      <p>$1,000,000 IRA Transfer</p>
                    </div>
                  </div>
                  <div className={classes["task"]} draggable="true">
                    <div className={classes["dot"]}></div>
                    <div>
                      <h2>James Ballarta</h2>
                      <p>$1,000,000 IRA Transfer</p>
                    </div>
                  </div>
                  <div className={classes["task"]} draggable="true">
                    <div className={classes["dot"]}></div>
                    <div>
                      <h2>James Ballarta</h2>
                      <p>$1,000,000 IRA Transfer</p>
                    </div>
                  </div>
                  <div className={classes["task"]} draggable="true">
                    <div className={classes["dot"]}></div>
                    <div>
                      <h2>James Ballarta</h2>
                      <p>$1,000,000 IRA Transfer</p>
                    </div>
                  </div>
                  <div className={classes["task"]} draggable="true">
                    <div className={classes["dot"]}></div>
                    <div>
                      <h2>James Ballarta</h2>
                      <p>$1,000,000 IRA Transfer</p>
                    </div>
                  </div>
                </div>

                <div
                  className={mergeClasses(
                    "task-input-container",
                    classes["task-input-container"]
                  )}
                >
                  <input
                    type="text"
                    className={classes["task-input"]}
                    placeholder="+ Add Deal"
                  />
                </div>
              </div>

              <div className={classes["kanban-card"]}>
                <div className={classes["kanban-header"]}>
                  <h5>Application</h5>
                </div>
                <div className={classes["task-list"]} data-column="application">
                  <div className={classes["task"]} draggable="true">
                    <div className={classes["dot"]}></div>
                    <div>
                      <h2>James Ballarta</h2>
                      <p>$1,000,000 IRA Transfer</p>
                    </div>
                  </div>
                  <div className={classes["task"]} draggable="true">
                    <div className={classes["dot"]}></div>
                    <div>
                      <h2>James Ballarta</h2>
                      <p>$1,000,000 IRA Transfer</p>
                    </div>
                  </div>
                  <div className={classes["task"]} draggable="true">
                    <div className={classes["dot"]}></div>
                    <div>
                      <h2>James Ballarta</h2>
                      <p>$1,000,000 IRA Transfer</p>
                    </div>
                  </div>
                  <div className={classes["task"]} draggable="true">
                    <div className={classes["dot"]}></div>
                    <div>
                      <h2>James Ballarta</h2>
                      <p>$1,000,000 IRA Transfer</p>
                    </div>
                  </div>
                  <div className={classes["task"]} draggable="true">
                    <div className={classes["dot"]}></div>
                    <div>
                      <h2>James Ballarta</h2>
                      <p>$1,000,000 IRA Transfer</p>
                    </div>
                  </div>
                  <div className={classes["task"]} draggable="true">
                    <div className={classes["dot"]}></div>
                    <div>
                      <h2>James Ballarta</h2>
                      <p>$1,000,000 IRA Transfer</p>
                    </div>
                  </div>
                </div>
                <div className={classes["task-input-container"]}>
                  <input
                    type="text"
                    className={classes["task-input"]}
                    placeholder="+ Add Deal"
                  />
                </div>
              </div>

              <div className={classes["kanban-card"]}>
                <div className={classes["kanban-header"]}>
                  <h5>Signatures</h5>
                </div>
                <div className={classes["task-list"]} data-column="signatures">
                  <div className={classes["task"]} draggable="true">
                    <div className={classes["dot"]}></div>
                    <div>
                      <h2>James Ballarta</h2>
                      <p>$1,000,000 IRA Transfer</p>
                    </div>
                  </div>
                  <div className={classes["task"]} draggable="true">
                    <div className={classes["dot"]}></div>
                    <div>
                      <h2>James Ballarta</h2>
                      <p>$1,000,000 IRA Transfer</p>
                    </div>
                  </div>
                  <div className={classes["task"]} draggable="true">
                    <div className={classes["dot"]}></div>
                    <div>
                      <h2>James Ballarta</h2>
                      <p>$1,000,000 IRA Transfer</p>
                    </div>
                  </div>
                  <div className={classes["task"]} draggable="true">
                    <div className={classes["dot"]}></div>
                    <div>
                      <h2>James Ballarta</h2>
                      <p>$1,000,000 IRA Transfer</p>
                    </div>
                  </div>
                  <div className={classes["task"]} draggable="true">
                    <div className={classes["dot"]}></div>
                    <div>
                      <h2>James Ballarta</h2>
                      <p>$1,000,000 IRA Transfer</p>
                    </div>
                  </div>
                  <div className={classes["task"]} draggable="true">
                    <div className={classes["dot"]}></div>
                    <div>
                      <h2>James Ballarta</h2>
                      <p>$1,000,000 IRA Transfer</p>
                    </div>
                  </div>
                </div>
                <div className={classes["task-input-container"]}>
                  <input
                    type="text"
                    className={classes["task-input"]}
                    placeholder="+ Add Deal"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className={mergeClasses("footer", classes["footer"])}>
        <div className={mergeClasses("container", classes["container"])}>
          <div className={mergeClasses("row", classes["row"])}>
            <div
              className={mergeClasses(
                "col-lg-3",
                "col-md-4",
                classes["logo-icons"]
              )}
            >
              <div className={classes["footer-logo"]}>
                <img src={Logo} alt="logo" />
              </div>
              <div className={classes["social-icons"]}>
                <a href="/">
                  <img src={XSvg} alt="X" />
                </a>
                <a href="/">
                  <img src={InstaSvg} alt="Instagram" />
                </a>
                <a href="/">
                  <img src={YouTubeSvg} alt="YouTube" />
                </a>
                <a href="/">
                  <img src={LinkedInSvg} alt="LinkedIn" />
                </a>
              </div>
            </div>
            <div
              className={mergeClasses("col-lg-3", "col-md-4", classes["links"])}
            >
              <h5>Use cases</h5>
              <ul
                className={mergeClasses(
                  "list-unstyled",
                  classes["list-unstyled"]
                )}
              >
                <li>
                  <a
                    href="/"
                    className={mergeClasses(
                      "text-light",
                      classes["text-light"]
                    )}
                  >
                    UI design
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    className={mergeClasses(
                      "text-light",
                      classes["text-light"]
                    )}
                  >
                    UX design
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    className={mergeClasses(
                      "text-light",
                      classes["text-light"]
                    )}
                  >
                    Wireframing
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    className={mergeClasses(
                      "text-light",
                      classes["text-light"]
                    )}
                  >
                    Diagramming
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    className={mergeClasses(
                      "text-light",
                      classes["text-light"]
                    )}
                  >
                    Brainstorming
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    className={mergeClasses(
                      "text-light",
                      classes["text-light"]
                    )}
                  >
                    Online whiteboard
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    className={mergeClasses(
                      "text-light",
                      classes["text-light"]
                    )}
                  >
                    Team collaboration
                  </a>
                </li>
              </ul>
            </div>
            <div
              className={mergeClasses("col-lg-3", "col-md-4", classes["links"])}
            >
              <h5>Explore</h5>
              <ul
                className={mergeClasses(
                  "list-unstyled",
                  classes["list-unstyled"]
                )}
              >
                <li>
                  <a
                    href="/"
                    className={mergeClasses(
                      "text-light",
                      classes["text-light"]
                    )}
                  >
                    Design
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    className={mergeClasses(
                      "text-light",
                      classes["text-light"]
                    )}
                  >
                    Prototyping
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    className={mergeClasses(
                      "text-light",
                      classes["text-light"]
                    )}
                  >
                    Development features
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    className={mergeClasses(
                      "text-light",
                      classes["text-light"]
                    )}
                  >
                    Design systems
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    className={mergeClasses(
                      "text-light",
                      classes["text-light"]
                    )}
                  >
                    Collaboration features
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    className={mergeClasses(
                      "text-light",
                      classes["text-light"]
                    )}
                  >
                    Design process
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    className={mergeClasses(
                      "text-light",
                      classes["text-light"]
                    )}
                  >
                    FigJam
                  </a>
                </li>
              </ul>
            </div>
            <div
              className={mergeClasses("col-lg-3", "col-md-4", classes["links"])}
            >
              <h5>Resources</h5>
              <ul
                className={mergeClasses(
                  "list-unstyled",
                  classes["list-unstyled"]
                )}
              >
                <li>
                  <a
                    href="/"
                    className={mergeClasses(
                      "text-light",
                      classes["text-light"]
                    )}
                  >
                    Blog
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    className={mergeClasses(
                      "text-light",
                      classes["text-light"]
                    )}
                  >
                    Best practices
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    className={mergeClasses(
                      "text-light",
                      classes["text-light"]
                    )}
                  >
                    Colors
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    className={mergeClasses(
                      "text-light",
                      classes["text-light"]
                    )}
                  >
                    Color wheel
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    className={mergeClasses(
                      "text-light",
                      classes["text-light"]
                    )}
                  >
                    Support
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    className={mergeClasses(
                      "text-light",
                      classes["text-light"]
                    )}
                  >
                    Developers
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    className={mergeClasses(
                      "text-light",
                      classes["text-light"]
                    )}
                  >
                    Resource library
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
