import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import Layout from "../../shared/components/Layout/Layout";
import ListBox from "../components/ListBox/ListBox";
import { uiActions } from "../../store/ui";
import { ReactComponent as CallIcon } from "../../assets/imgs/call-icon.svg";
import classes from "./CallCenter.module.css";
import DialPad from "../components/Twilio/DialPad/DialPad";
import useClickAwayListener from "../../shared/components/hooks/useClickAwayListener/useClickAwayLIstener";

const CallCenter = () => {
  const dispatch = useDispatch();

  const isActiveCall = useSelector((state) => state.twilioReducer.currentCall);
  const isShowDialPad = useSelector((state) => state.uiReducer.isShowDialPad);
  const [phoneNumber, setPhoneNumber] = useState("");

  const toggleDialPadHandler = () => {
    if (isActiveCall) {
      return;
    }
    dispatch(uiActions.setIsShowDialPad());
  };

  const clickAwayRef = useRef(null);
  useClickAwayListener(clickAwayRef, toggleDialPadHandler, "");

  //set user 'online' to accept calls if they have a number and device is ready
  const twilioDeviceRegistrationStatus = useSelector((state) => state.twilioReducer.deviceReadyForRegistration);

  return (
    <Layout>
      <ListBox />
      {!isShowDialPad && !isActiveCall && (
        <div
          onClick={toggleDialPadHandler}
          className={classes["dial-pad-button"]}
        >
          <button
            className={`${classes["call-button"]} ${classes["answer-button"]}`}
          >
            <CallIcon />
          </button>
        </div>
      )}
      {isShowDialPad ? (
        <div className={classes.dialPad}>
          <DialPad
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            clickAwayRef={clickAwayRef}
          />
        </div>
      ) : null}
      <Outlet />
    </Layout>
  );
};

export default CallCenter;
