import React, { useState, useRef, useEffect } from "react";
import classes from "./FieldMapItems.module.css";
import FieldMapItem from "./FieldMapItem";

const FieldMapItems = ({ parsedData, updateKeyName, onMismatchedKeyCount }) => {

  //sets and updates the index state when 
  //user presses the prev/next buttons
  const [currentIndex, setCurrentIndex] = useState(0);

  const slideRightHandler = () => {
    if (
      currentIndex ===
      Math.ceil(Object.keys(parsedData[0]).length / 4) - 1
    ) {
      return setCurrentIndex(0);
    }
    return setCurrentIndex(currentIndex + 1);
  };

  const slideLeftHandler = () => {
    if (currentIndex === 0) {
      return;
    }
    return setCurrentIndex(currentIndex - 1);
  };

  //default field properties used to compare against
  //user's csv file headers
  const defaultKeys = [
    "firstName",
    "lastName",
    "dateOfBirth",
    "spouseName",
    "spouseDateOfBirth",
    "email",
    "phone",
    "secondaryPhone",
    "streetNumber",
    "cityName",
    "stateName",
    "zipCode",
    "contactType",
    "client",
    "status",
    "dealHeat",
    "creator",
    "assignedTo",
    "recent",
    "referralSource",
    "source",
    "campaignName",
    "adName",
    "eventType",
    "attendedEvent",
    "eventLocation",
    "eventVenue",
    "eventDate",
  ];

  //checks if user keys are included in default keys
  const matchFields = (key) => {

    //validate keys against defaults
    const validateKey = defaultKeys.includes(key);

    if (validateKey) {
      return true;
    }

    //convert to camelcase and validate against defaults
    //camelCase and test validate against default, if true
    //update key to match default
    const splitKey = key.split(" ");
    const lowerCaseFirstLetter = splitKey[0].charAt(0).toLowerCase() + splitKey[0].slice(1);
    const replaceFirstWord = splitKey.splice(0, 1, lowerCaseFirstLetter);
    const camelCasedKey = splitKey.join("");

    if (defaultKeys.includes(camelCasedKey)) {
      updateKeyName(key, camelCasedKey);
      return true;
    }

    //validate special cases
    if (
      key === "Street Address" ||
      camelCasedKey === "streetAddress" ||
      key === "Address" ||
      camelCasedKey === "address"
    ) {
      updateKeyName(key, "streetNumber");
      return true;
    }

    if (
      key.split(" ").join("").toLowerCase() === "dateofbirth" ||
      key.split(" ").join("").toLowerCase() === "dob" ||
      key.split(" ").join("").toLowerCase() === "birthdate"
    ) {
      updateKeyName(key, "dateOfBirth");
      return true;
    }
  
    return false;
  };

    //count and total unmatched keys
    useEffect(() => {
      if (parsedData.length) {
        let count = 0;
        Object.keys(parsedData[0]).map((key, index) => {
          if (!matchFields(key)) {
            count = count + 1
          }
        })
        onMismatchedKeyCount(count);
      }
    }, [parsedData]);

  return (
    <>
      <div className={classes["container"]}>
        {!parsedData.length && <div></div>}
        {parsedData.length &&
          Object.entries(parsedData[0]).map((item, index) => {
            return (
              <FieldMapItem
                key={index}
                item={item}
                index={index}
                matched={matchFields(item[0])}
                currentIndex={currentIndex}
                updateKeyName={updateKeyName}
                defaultKeys={defaultKeys}
              />
            );
          })}
      </div>
      <div className={classes["actions"]}>
        <div onClick={slideLeftHandler}>{"<"}</div>
        <div onClick={slideRightHandler}>{">"}</div>
      </div>
    </>
  );
};

export default FieldMapItems;
