//Import react-based dependencies
import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: "uiSlice",
  initialState: {
    notification: {status: "red", title:" testing", message: "testing notification"},
    incomingCallNotifcation: false,
    isShowDialPad: false,
  },
  reducers: {
    showNotification(state, action) {
      console.log(action)
      if (action.payload === undefined) {
        state.notification = false;
        return;
      }
      state.notification = {
        status: action.payload.status,
        title: action.payload.title,
        message: action.payload.message,
      };
    },
    setIncomingCallNotification(state, action) {
      state.incomingCallNotifcation = action.payload;
    },
    setIsShowDialPad(state, action) {
      state.isShowDialPad = !state.isShowDialPad;
    }
  },
});

export const uiActions = uiSlice.actions;

export default uiSlice.reducer;
