import { createSlice } from "@reduxjs/toolkit";

const taskSlice = createSlice({
  name: "taskSlice",
  initialState: {
    tasks: []
  },
  reducers: {
    replaceTaskData(state, action) {
      state.tasks = action.payload.tasks;
    },
    addTask(state, action) {
      state.tasks.push(action.payload);
    },
    updateTask(state, action) {
      const currentTask = state.tasks.find(
        (task) => task.id === action.payload.id
      );
      currentTask.name = action.payload.name;
      currentTask.description = action.payload.description;
      currentTask.contactId = action.payload.contactId;
      currentTask.completed = action.payload.completed;
      currentTask.belongsTo = action.payload.belongsTo;
      currentTask.creator = action.payload.creator;
    },
    deleteTask(state, action) {
        state.tasks = state.tasks.filter((task) => task.id !== action.payload);
    }
  }
});

export const taskActions = taskSlice.actions;
export default taskSlice.reducer;
