import { createSlice } from "@reduxjs/toolkit";

const voiceMessageSlice = createSlice({
    name: "voiceMessage",
    initialState: {
        voiceMessages: [],
        recentVoiceMessages: []
    },
    reducers: {
        replaceVoiceMessageData (state, action) {
            const voiceMessages = [];
            action.payload.forEach(vm => voiceMessages.push(vm));
            state.voiceMessages = voiceMessages;
        },
        addVoiceMessage(state, action) {
            state.voiceMessages.push(action.payload);
        },
        addRecentVoiceMessage(state, action) {
            state.recentVoiceMessages.push(action.payload);
        },
        updateVoiceMessage(state, action) {
            const currentMessage = state.voiceMessages.find(vm => vm.id === action.payload.id);
            currentMessage.contactName = action.payload.contactName;
            currentMessage.from = action.payload.from;
            currentMessage.to = action.payload.to;
            currentMessage.sid = action.payload.sid;
            currentMessage.direction = action.payload.direction;
            currentMessage.recordingUrl = action.payload.recordingUrl;
            currentMessage.transcriptionText = action.payload.transcriptionText;
            currentMessage.listened = action.payload.listened;
            currentMessage.belongsTo = action.payload.belongsTo;
            currentMessage.id = action.payload.id;
        },
        updateRecentVoiceMessages(state, action) {
            // const newVoiceMessages = state.recentVoiceMessages.filter(vm => vm.belongsTo !== action.payload.belongsTo);
            // state.recentVoiceMessages = newVoiceMessages;

        }
    }
});

export const voiceMessageActions = voiceMessageSlice.actions;

export default voiceMessageSlice.reducer;