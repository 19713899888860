import store from "./index";
import { pipelineActions } from "./pipeline";
const KEY = 'last-opened-pipeline-localstorage';

//-----------------// CREATE //-----------------//
export const createPipeline = (pipelineData) => {

  return async (dispatch) => {
    const token = store.getState().userReducer.token;
    const pipelineName = pipelineData.name;
    dispatch(pipelineActions.isLoading(true));

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/pipelines`,
        {
          method: "POST",
          body: JSON.stringify({
            name: pipelineName
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + token,
          }
        });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message);
      }

      //save as last opened pipeline to redirect user
      const lastOpenedPipelineId = responseData.pipeline.id;
      localStorage.setItem(KEY, lastOpenedPipelineId)

      dispatch(pipelineActions.addPipeline(responseData.pipeline));

      dispatch(pipelineActions.isLoading(false));

    } catch (error) {
      console.log(error)
    }

  }
}

export const createStage = (stageData) => {
  return async (dispatch) => {
    const token = store.getState().userReducer.token;
    const stageName = stageData.stageName;
    const pipelineId = stageData.pipelineId;

    dispatch(pipelineActions.isLoading(true));

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/pipelines/stages`,
        {
          method: "POST",
          body: JSON.stringify({
            name: stageName,
            belongsTo: pipelineId
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + token,
          }
        });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message);
      }

      dispatch(pipelineActions.addStage(responseData.stage));

      dispatch(pipelineActions.isLoading(false));
    } catch (error) {
      console.log(error)
    }

  }
}

export const createDeal = (dealData) => {
  return async (dispatch) => {
    const token = store.getState().userReducer.token;
    const contactId = dealData.contactId;
    const description = dealData.description;
    const belongsTo = dealData.belongsTo;

    dispatch(pipelineActions.isLoading(true));

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/pipelines/deals`,
        {
          method: "POST",
          body: JSON.stringify({
            contactId: contactId,
            description: description,
            belongsTo: belongsTo
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + token,
          }
        });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message);
      }

      dispatch(fetchStagesByUserId())

      dispatch(pipelineActions.addDeal(responseData.deal));

      dispatch(pipelineActions.isLoading(false));

    } catch (error) {
      console.log(error)
    }
  }
}

//-----------------// READ //-----------------//
export const fetchPipelinesByUserId = () => {
  return async (dispatch) => {
    const userId = store.getState().userReducer.user.userId;
    const token = store.getState().userReducer.token;

    let pipelines;
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/pipelines/user/${userId}`,
        {
          headers: {
            Authorization: "Bearer " + token
          }
        });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message);
      }

      pipelines = responseData.pipelines;

      dispatch(pipelineActions.replacePipelineData({
        pipelines: pipelines || []
      }))

    } catch (error) {
      console.log(error)
    }
  }
}

export const fetchStagesByUserId = () => {
  return async (dispatch) => {
    const userId = store.getState().userReducer.user.userId;
    const token = store.getState().userReducer.token;

    let stages;
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/pipelines/stages/user/${userId}`,
        {
          headers: {
            Authorization: "Bearer " + token
          }
        });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message);
      }

      stages = responseData.stages;

      dispatch(pipelineActions.replaceStageData({
        stages: stages || []
      }))

    } catch (error) {
      console.log(error)
    }

  }
}

export const fetchDealsByUserId = () => {
  return async (dispatch) => {
    const userId = store.getState().userReducer.user.userId;
    const token = store.getState().userReducer.token;

    let deals;
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/pipelines/deals/user/${userId}`,
        {
          headers: {
            Authorization: "Bearer " + token
          }
        });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message);
      }

      deals = responseData.deals;

      dispatch(pipelineActions.replaceDealData({
        deals: deals || []
      }))

    } catch (error) {
      console.log(error)
    }

  }
}

//-----------------// UPDATE //-----------------//
export const updatePipeline = (pipelineData) => {
  return async (dispatch) => {
    const token = store.getState().userReducer.token;

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/pipelines/${pipelineData.id}`,
        {
          method: "PATCH",
          body: JSON.stringify({
            name: pipelineData.name,
            stages: pipelineData.stages,
            belongsTo: pipelineData.belongsTo,
            creator: pipelineData.creator
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + token
          }
        });;

      const responseData = await response.json();

      dispatch(pipelineActions.updatePipeline(responseData.pipeline))

    } catch (error) {

    }
  }
}

export const updateStage = (stageData) => {

  return async (dispatch) => {

    //optimistic update
    dispatch(pipelineActions.updateStage(stageData))

    const token = store.getState().userReducer.token;

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/pipelines/stages/${stageData.id}`,
        {
          method: "PATCH",
          body: JSON.stringify({
            name: stageData.name,
            deals: stageData.deals,
            belongsTo: stageData.belongsTo,
            creator: stageData.creator
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + token
          }
        });;

      const responseData = await response.json();

    } catch (error) {

    }
  }
}

export const updateDeal = (dealData) => {
  console.log(dealData)
  return async (dispatch) => {
    const token = store.getState().userReducer.token;

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/pipelines/deals/${dealData.id}`,
        {
          method: "PATCH",
          body: JSON.stringify({
            contact: dealData.contact,
            description: dealData.description,
            belongsTo: dealData.belongsTo,
            creator: dealData.creator
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + token
          }
        });;

      const responseData = await response.json();

      dispatch(pipelineActions.updateDeal(responseData.deal))

    } catch (error) {

    }
  }
}

//-----------------// DELETE //-----------------//
export const deletePipeline = (pipelineData) => {
  return async (dispatch) => {
    const token = store.getState().userReducer.token;

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/pipelines/${pipelineData.id}`,
        {
          method: "DELETE",
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + token
          }
        });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message);
      }

      dispatch(pipelineActions.deletePipeline(pipelineData.id));

    } catch (error) {
      console.log(error);
    }
  }
}

export const deleteStage = (stageData) => {
  return async (dispatch) => {
    const token = store.getState().userReducer.token;

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/pipelines/stages/${stageData.id}`,
        {
          method: "DELETE",
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + token
          }
        });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message);
      }

      dispatch(pipelineActions.deleteStage(stageData.id));

    } catch (error) {
      console.log(error);
    }
  }
}

export const deleteDeal = (dealData) => {
  return async (dispatch) => {
    const token = store.getState().userReducer.token;

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/pipelines/deals/${dealData.id}`,
        {
          method: "DELETE",
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + token
          }
        });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message);
      }

      dispatch(pipelineActions.deleteDeal(dealData.id));

    } catch (error) {

    }
  }
}