const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
const newDay = new Date().getDay();
const day = days[newDay];

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const newMonth = new Date().getMonth();
export const month = months[newMonth];

export const date = new Date().getDate();

const hours = new Date().getHours();
const minutes = new Date().getMinutes();
export const time = hours + ':' + minutes


