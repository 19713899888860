import { useSelector } from "react-redux";
import ReactDom from "react-dom";
import { useNavigate } from "react-router-dom";
import Modal from "../UIElements/Modal/Modal";
import classes from "./ChooseNumberModal.module.css";
import Dot from "../UIElements/Dot/Dot";
import SpinnerButton from "../UIElements/SpinnerButton/SpinnerButton";
import ChooseNumberGif from "../../../assets/imgs/choose-number.gif";
import { userActions } from "../../../store/user";
import { useDispatch } from "react-redux";

const ChooseNumberModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

    const firstLogin = useSelector((state) => state.userReducer.user.firstLogin);

  //Close modal handler by navigating to previous page
  const closeModalHandler = () => {
    navigate("/app/call-center/");
  };

  const setupNumberHandler = () => {
    navigate("/app/call-center/settings/phone");
    dispatch(userActions.firstLogin(false));
  };

  return ReactDom.createPortal(
    <>
      {firstLogin && (
        <Modal closeModal={closeModalHandler} maxWidth="600px" marginTop="10vh">
          <div className={classes["choose-number-modal"]}>
            <div className={classes["header"]}>
              <div className={classes["header-title"]}>
                Configure Your Phone Number
              </div>
            </div>
            <div className={classes["menu_seperator"]}></div>
            <div className={classes["content"]}>
              <p>
                To make calls and receive text messages you must setup a phone
                number. Setting up a phone number is simple, and only takes a
                couple minutes.
              </p>
              <div className={classes["phone-gif"]}>
                <img
                  src={ChooseNumberGif}
                  alt=""
                  className={classes["number-gif"]}
                />
              </div>
            </div>

            <div className={classes["menu_seperator"]}></div>
            <div className={classes["footer"]}>
              <SpinnerButton
                color="button--blue"
                size="button--small"
                // BtnAlign="button--right"
                onClick={setupNumberHandler}
              >
                Begin Setup
              </SpinnerButton>
            </div>
          </div>
        </Modal>
      )}
    </>,
    document.getElementById("modal-root")
  );
};

export default ChooseNumberModal;
