import { useDispatch, useSelector } from "react-redux";
import ReactDom from "react-dom";
import classes from "./Notification.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faX, faExclamation, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { notificationActions } from "../../../../store/notifications";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Notification = () => {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { status, title, message, link } = useSelector(
    (state) => state.notificationReducer.toastNotification
  );

  const isShowMenuNotification = useSelector(
    (state) => state.notificationReducer.isShowMenuNotification
  );

  const closeNotification = () => {
    dispatch(notificationActions.setToastNotification());
  };

  const clickLinkHandler = () => {
    closeNotification();
    navigate(link)
  }

  useEffect(() => {
    const timeOut = setTimeout(() => {
        dispatch(notificationActions.setToastNotification());
    }, 8000);

    return () => {clearTimeout(timeOut)}
  }, [status])

  return status && !isShowMenuNotification
    ? ReactDom.createPortal(
        <div className={`${classes["notification"]} ${classes[status]}`}>
          <div className={`${classes["icon"]} ${classes[status]}`}>
            {status === "success" && <FontAwesomeIcon icon={faCheck} />}
            {status === "warning" && <FontAwesomeIcon icon={faTriangleExclamation} />}
            {status === "error" && <FontAwesomeIcon icon={faExclamation} />}
          </div>
          <div className={`${classes["content"]} ${classes[status]}`}>
            <h3>{title}</h3>
            <p>{message} {link && <span className={classes.link} onClick={clickLinkHandler}><u>here</u>.</span>}</p>
          </div>
          <div className={classes["close"]} onClick={closeNotification}>
            <FontAwesomeIcon icon={faX} size="xs" onClick={closeNotification}/>
          </div>
        </div>,
        document.getElementById("modal-root")
      )
    : null;
};

export default Notification;