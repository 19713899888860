import { createSlice } from "@reduxjs/toolkit";

const fileUploaderSlice = createSlice({
    name: 'fileUploader',
    initialState: {
        dropDepth: 0,
        inDropZone: false,
        fileProgressList: [],
    },
    reducers: {
        setDropDepth(state, action) {
            const { increment, decrement, clear } = action.payload;
            if (increment) {
                state.dropDepth = state.dropDepth + increment;
            }
            if (decrement) {
                state.dropDepth = state.dropDepth - decrement;
            }
            if (clear) {
                state.dropDepth = clear;
            }
        },
        setInDropZone(state, action) {
            state.inDropZone = action.payload;
        }
    }
})

export const fileUploaderActions = fileUploaderSlice.actions;

export default fileUploaderSlice.reducer;