import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import ListBoxHeading from "./ListBoxHeading";
import Checkbox from "../../../shared/components/UIElements/Checkbox/Checkbox";
import StatusBar from "../../../shared/components/UIElements/StatusBar/StatusBar";
import NewContactModal from "../../../shared/components/Modal/NewContactModal";
import Dot from "../../../shared/components/UIElements/Dot/Dot";
import FlexApi from "twilio/lib/rest/FlexApi";
import Button from "../../../shared/components/UIElements/Button/Button";
import { filterActions } from "../../../store/filter";
import { checkboxActions } from "../../../store/checkbox";
import { modalActions } from "../../../store/modal";
import { usePopper } from "react-popper";
import classes from "./ListBoxHeader.module.css";
import useClickAwayListener from "../../../shared/components/hooks/useClickAwayListener/useClickAwayLIstener";
import CloseX from "../../../shared/components/UIElements/CloseX/CloseX";
import filter from "../../../assets/imgs/filter.png";
import Input from "../../../shared/components/UIElements/Input/Input";
import { BsPerson } from "react-icons/bs";

const ListBoxHeader = (props) => {
  const dispatch = useDispatch();

  const userId = useSelector((state) => state.userReducer.user.userId);

  const filteredContacts = useSelector(
    (state) => state.contactReducer.filteredContacts
  );

  //Initiate Modal State, handle open/close
  const showNewContactModal = useSelector(
    (state) => state.modalReducer.modals.showNewContactModal
  );

  const searchTerm = useSelector((state) => state.filterReducer.searchTerm);

  const showModalHandler = (id) => {
    dispatch(modalActions.openNewContactModal());
  };

  const closeModalHandler = () => {
    dispatch(modalActions.closeModal());
  };

  //Popper
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start"
  });

  const togglePopperHandler = () => {
    setIsPopperOpen((prev) => !prev);
  };

  //Close popper on click away
  const clickAwayRef = useRef(null);
  useClickAwayListener(clickAwayRef, togglePopperHandler, referenceElement);

  const filters = useSelector((state) => state.filterReducer.filters);

  const addFilterHandler = (e, filterProp) => {
    const name = e.target.dataset.name;
    dispatch(filterActions.addFilter({ value: name, key: filterProp }));
  };

  const removeFilterHandler = (e, filterProp) => {
    const name = e.target.dataset.name;
    dispatch(filterActions.removeFilter({ value: name, key: filterProp }));
  };

  //sets age filter
  const [enteredMinAge, setEnteredMinAge] = useState("");
  const [enteredMaxAge, setEnteredMaxAge] = useState("");
  const [enteredAgeHasError, setEnteredAgeHasError] = useState(false);
  
  const setDobHandler = (event, filterProp) => {
    if (event.target.dataset.age_range === "min") {
      setEnteredMinAge(event.target.value);
    }

    if (event.target.dataset.age_range === "max") {
      setEnteredMaxAge(event.target.value);
    }
  };

  useEffect(() => {
    if (+enteredMinAge > +enteredMaxAge) {
      setEnteredAgeHasError(true);
      return;
    }
    if (!enteredMinAge || !enteredMaxAge) {
      return;
    }
    setEnteredAgeHasError(false);
    dispatch(
      filterActions.addFilter({
        value: { min: enteredMinAge, max: enteredMaxAge },
        key: "age"
      })
    );
  }, [enteredMaxAge, enteredMinAge]);

  useEffect(() => {
    if (!filters.age) {
      setEnteredMinAge("");
      setEnteredMaxAge("");
    }
  }, [filters]);

  const saveFiltersHandler = () => {
    //save cookies to filters here
  };

  const clearFiltersHandler = () => {
    dispatch(filterActions.clearFilters());
  };

  const onSearchHandler = (e) => {
    const toLowerCase = e.target.value.toLowerCase();
    dispatch(filterActions.setSearchTerm(toLowerCase));
  };

  return (
    <>
      {showNewContactModal && (
        <NewContactModal onCloseModal={togglePopperHandler} />
      )}
      <div className={classes["list-box__header"]}>
        <ListBoxHeading onOpenModal={showModalHandler} />
        <div className={classes["list-box__filters"]}>
          <div className={classes["list-box__filters__search-bar"]}>
            <input
              type="search"
              placeholder="Search..."
              autoComplete="off"
              className={classes.search}
              onChange={onSearchHandler}
              value={searchTerm}
            />
            <div
              className={classes["list-box__filters__add-filter"]}
              ref={setReferenceElement}
              onClick={togglePopperHandler}
            >
              <img
                src={filter}
                style={{ width: "1.5rem", filter: "invert(1)" }}
              />
            </div>
          </div>
          <div className={classes["list-box__filters__active"]}>
            {Object.keys(filters).map((filter) => {
              return (
                <div
                  key={filter}
                  className={classes["list-box__filters__active__filter"]}
                  data-name={filters[filter]}
                  onClick={(e) => {
                    removeFilterHandler(e, filter);
                  }}
                >
                  {filter === "age"
                    ? filter +
                      " " +
                      filters[filter]?.min +
                      "-" +
                      filters[filter]?.max
                    : filters[filter]}
                </div>
              );
            })}
            <div className={classes.resultsCount}>
              <BsPerson size=".8rem" />
              {filteredContacts.length}
            </div>
            {isPopperOpen && (
              <div ref={clickAwayRef}>
                <div
                  ref={setPopperElement}
                  className={classes.popper}
                  style={styles.popper}
                  {...attributes.popper}
                >
                  <div className={classes["list-box__filters"]}>
                    <div className={classes["list-box__filters-header"]}>
                      <div
                        className={classes["list-box__filters-header__title"]}
                      >
                        Select Filters for List
                      </div>
                      <div onClick={togglePopperHandler}>
                        <CloseX />
                      </div>
                    </div>
                    <div className={classes["menu_seperator"]}></div>
                    {/* ********** Contact Type ********** */}
                    <div className={classes["list-box__filter"]}>
                      <label className={classes["list-box__filter__title"]}>
                        Contact Type
                      </label>
                      <div className={classes["list-box__filter__container"]}>
                        <div
                          className={`${classes["list-box__filters__filter"]} ${
                            Object.values(filters).includes("lead")
                              ? classes.active
                              : ""
                          }`}
                          data-name="lead"
                          onClick={(e) => {
                            addFilterHandler(e, "contactType");
                          }}
                        >
                          Lead
                        </div>
                        <div
                          className={`${classes["list-box__filters__filter"]} ${
                            Object.values(filters).includes("contact")
                              ? classes.active
                              : ""
                          }`}
                          data-name="contact"
                          onClick={(e) => {
                            addFilterHandler(e, "contactType");
                          }}
                        >
                          Contact
                        </div>
                        <div
                          className={`${classes["list-box__filters__filter"]} ${
                            Object.values(filters).includes("partner")
                              ? classes.active
                              : ""
                          }`}
                          data-name="partner"
                          onClick={(e) => {
                            addFilterHandler(e, "contactType");
                          }}
                        >
                          Partner
                        </div>
                      </div>
                    </div>
                    <div className={classes["menu_seperator"]}></div>
                    {/* ********** SOURCE ********** */}
                    <div className={classes["list-box__filter"]}>
                      <label className={classes["list-box__filter__title"]}>
                        Source
                      </label>
                      <div className={classes["list-box__filter__container"]}>
                        <div
                          className={`${classes["list-box__filters__filter"]} ${
                            Object.values(filters).includes("facebook")
                              ? classes.active
                              : ""
                          }`}
                          data-name="facebook"
                          onClick={(e) => {
                            addFilterHandler(e, "source");
                          }}
                        >
                          Facebook
                        </div>
                        <div
                          className={`${classes["list-box__filters__filter"]} ${
                            Object.values(filters).includes("referral")
                              ? classes.active
                              : ""
                          }`}
                          data-name="referral"
                          onClick={(e) => {
                            addFilterHandler(e, "source");
                          }}
                        >
                          Referral
                        </div>
                        <div
                          className={`${classes["list-box__filters__filter"]} ${
                            Object.values(filters).includes("email")
                              ? classes.active
                              : ""
                          }`}
                          data-name="email"
                          onClick={(e) => {
                            addFilterHandler(e, "source");
                          }}
                        >
                          Email
                        </div>
                        <div
                          className={`${classes["list-box__filters__filter"]} ${
                            Object.values(filters).includes("Royal Tax")
                              ? classes.active
                              : ""
                          }`}
                          data-name="Royal Tax"
                          onClick={(e) => {
                            addFilterHandler(e, "source");
                          }}
                        >
                          Royal Tax
                        </div>
                        <div
                          className={`${classes["list-box__filters__filter"]} ${
                            Object.values(filters).includes("smart assets")
                              ? classes.active
                              : ""
                          }`}
                          data-name="smart assets"
                          onClick={(e) => {
                            addFilterHandler(e, "source");
                          }}
                        >
                          Smart Assets
                        </div>
                      </div>
                    </div>
                    <div className={classes["menu_seperator"]}></div>
                    {/* ********** STATUS ********** */}
                    <div className={classes["list-box__filter"]}>
                      <label className={classes["list-box__filter__title"]}>
                        Status
                      </label>
                      <div className={classes["list-box__filter__container"]}>
                        <div
                          className={`${classes["list-box__filters__filter"]} ${
                            Object.values(filters).includes("unassigned")
                              ? classes.active
                              : ""
                          }`}
                          data-name="unassigned"
                          onClick={(e) => {
                            addFilterHandler(e, "status");
                          }}
                        >
                          Unassigned
                        </div>
                        <div
                          className={`${classes["list-box__filters__filter"]} ${
                            Object.values(filters).includes("red")
                              ? classes.active
                              : ""
                          }`}
                          data-name="red"
                          onClick={(e) => {
                            addFilterHandler(e, "status");
                          }}
                        >
                          Red
                        </div>
                        <div
                          className={`${classes["list-box__filters__filter"]} ${
                            Object.values(filters).includes("yellow")
                              ? classes.active
                              : ""
                          }`}
                          data-name="yellow"
                          onClick={(e) => {
                            addFilterHandler(e, "status");
                          }}
                        >
                          Yellow
                        </div>
                        <div
                          className={`${classes["list-box__filters__filter"]} ${
                            Object.values(filters).includes("green")
                              ? classes.active
                              : ""
                          }`}
                          data-name="green"
                          onClick={(e) => {
                            addFilterHandler(e, "status");
                          }}
                        >
                          Green
                        </div>
                        <div
                          className={`${classes["list-box__filters__filter"]} ${
                            Object.values(filters).includes("scheduled")
                              ? classes.active
                              : ""
                          }`}
                          data-name="scheduled"
                          onClick={(e) => {
                            addFilterHandler(e, "status");
                          }}
                        >
                          Scheduled
                        </div>
                      </div>
                    </div>
                    <div className={classes["menu_seperator"]}></div>
                    {/* ********** Age RANGE ********** */}
                    <div className={classes["list-box__filter"]}>
                      <label className={classes["list-box__filter__title"]}>
                        Age Range
                      </label>
                      <div className={classes["list-box__filter__container"]}>
                        <div
                          className={
                            classes.formControl +
                            " " +
                            (enteredAgeHasError ? classes.invalid : "")
                          }
                        >
                          <input
                            data-age_range="min"
                            value={enteredMinAge}
                            onChange={(e) => setDobHandler(e, "age")}
                            maxLength="2"
                            type="text"
                            placeholder="min"
                          />
                        </div>
                        <div
                          className={
                            classes.formControl +
                            " " +
                            (enteredAgeHasError ? classes.invalid : "")
                          }
                        >
                          <input
                            data-age_range="max"
                            value={enteredMaxAge}
                            onChange={(e) => setDobHandler(e, "age")}
                            maxLength="2"
                            type="text"
                            placeholder="max"
                          />
                        </div>
                      </div>
                      {enteredAgeHasError && (
                        <div className={classes["error-message"]}>
                          Min age must be less than max age
                        </div>
                      )}
                    </div>
                    <div className={classes["menu_seperator"]}></div>
                    {/* ********** DEAL TYPE ********** */}
                    {(userId === "63547706462f1d865d3ed6e8" ||
                      userId === "6403af98d764a5582efdfe69") && (
                      <>
                        <div className={classes["list-box__filter"]}>
                          <label className={classes["list-box__filter__title"]}>
                            Deal Type
                          </label>
                          <div
                            className={classes["list-box__filter__container"]}
                          >
                            <div
                              className={`${
                                classes["list-box__filters__filter"]
                              } ${
                                Object.values(filters).includes("aum")
                                  ? classes.active
                                  : ""
                              }`}
                              data-name="aum"
                              onClick={(e) => {
                                addFilterHandler(e, "dealType");
                              }}
                            >
                              AUM
                            </div>
                            <div
                              className={`${
                                classes["list-box__filters__filter"]
                              } ${
                                Object.values(filters).includes("annuity")
                                  ? classes.active
                                  : ""
                              }`}
                              data-name="annuity"
                              onClick={(e) => {
                                addFilterHandler(e, "dealType");
                              }}
                            >
                              Annuity
                            </div>
                            <div
                              className={`${
                                classes["list-box__filters__filter"]
                              } ${
                                Object.values(filters).includes("life")
                                  ? classes.active
                                  : ""
                              }`}
                              data-name="life"
                              onClick={(e) => {
                                addFilterHandler(e, "dealType");
                              }}
                            >
                              Life Insurance
                            </div>
                            <div
                              className={`${
                                classes["list-box__filters__filter"]
                              } ${
                                Object.values(filters).includes("ltc")
                                  ? classes.active
                                  : ""
                              }`}
                              data-name="ltc"
                              onClick={(e) => {
                                addFilterHandler(e, "dealType");
                              }}
                            >
                              Long-term Care
                            </div>
                          </div>
                        </div>
                        <div className={classes["menu_seperator"]}></div>
                      </>
                    )}
                    {/* ********** EVENT Type ********** */}
                    {(userId === "63547706462f1d865d3ed6e8" ||
                      userId === "6403af98d764a5582efdfe69") && (
                      <>
                        <div className={classes["list-box__filter"]}>
                          <label className={classes["list-box__filter__title"]}>
                            Event Type
                          </label>
                          <div
                            className={classes["list-box__filter__container"]}
                          >
                            <div
                              className={`${
                                classes["list-box__filters__filter"]
                              } ${
                                Object.values(filters).includes("seminar")
                                  ? classes.active
                                  : ""
                              }`}
                              data-name="seminar"
                              onClick={(e) => {
                                addFilterHandler(e, "eventType");
                              }}
                            >
                              Seminar
                            </div>
                            <div
                              className={`${
                                classes["list-box__filters__filter"]
                              } ${
                                Object.values(filters).includes("webinar")
                                  ? classes.active
                                  : ""
                              }`}
                              data-name="webinar"
                              onClick={(e) => {
                                addFilterHandler(e, "eventType");
                              }}
                            >
                              Webinar
                            </div>
                          </div>
                        </div>
                        <div className={classes["menu_seperator"]}></div>
                      </>
                    )}
                    {/* ********** EVENT LOCATION ********** */}
                    {(userId === "63547706462f1d865d3ed6e8" ||
                      userId === "6403af98d764a5582efdfe69") && (
                      <>
                        <div className={classes["list-box__filter"]}>
                          <label className={classes["list-box__filter__title"]}>
                            Event Location
                          </label>
                          <div
                            className={classes["list-box__filter__container"]}
                          >
                            <div
                              className={`${
                                classes["list-box__filters__filter"]
                              } ${
                                Object.values(filters).includes("newport")
                                  ? classes.active
                                  : ""
                              }`}
                              data-name="newport"
                              onClick={(e) => {
                                addFilterHandler(e, "eventLocation");
                              }}
                            >
                              Newport
                            </div>
                            <div
                              className={`${
                                classes["list-box__filters__filter"]
                              } ${
                                Object.values(filters).includes("anaheim")
                                  ? classes.active
                                  : ""
                              }`}
                              data-name="anaheim"
                              onClick={(e) => {
                                addFilterHandler(e, "eventLocation");
                              }}
                            >
                              Anaheim
                            </div>
                            <div
                              className={`${
                                classes["list-box__filters__filter"]
                              } ${
                                Object.values(filters).includes(
                                  "rancho cucamonga"
                                )
                                  ? classes.active
                                  : ""
                              }`}
                              data-name="rancho cucamonga"
                              onClick={(e) => {
                                addFilterHandler(e, "eventLocation");
                              }}
                            >
                              Rancho
                            </div>
                            <div
                              className={`${
                                classes["list-box__filters__filter"]
                              } ${
                                Object.values(filters).includes("victorville")
                                  ? classes.active
                                  : ""
                              }`}
                              data-name="victorville"
                              onClick={(e) => {
                                addFilterHandler(e, "eventLocation");
                              }}
                            >
                              Victorville
                            </div>
                          </div>
                        </div>
                        <div className={classes["menu_seperator"]}></div>
                      </>
                    )}
                    {/* ********** REFERRAL SOURCE ********** */}
                    {(userId === "63547706462f1d865d3ed6e8" ||
                      userId === "6403af98d764a5582efdfe69") && (
                      <>
                        <div className={classes["list-box__filter"]}>
                          <label className={classes["list-box__filter__title"]}>
                            Referral Source
                          </label>
                          <div
                            className={classes["list-box__filter__container"]}
                          >
                            <div
                              className={`${
                                classes["list-box__filters__filter"]
                              } ${
                                Object.values(filters).includes(
                                  "Stephanie Crowe"
                                )
                                  ? classes.active
                                  : ""
                              }`}
                              data-name="Stephanie Crowe"
                              onClick={(e) => {
                                addFilterHandler(e, "referralSource");
                              }}
                            >
                              Stephanie Crowe
                            </div>
                            <div
                              className={`${
                                classes["list-box__filters__filter"]
                              } ${
                                Object.values(filters).includes("Nathan Crowe")
                                  ? classes.active
                                  : ""
                              }`}
                              data-name="Nathan Crowe"
                              onClick={(e) => {
                                addFilterHandler(e, "referralSource");
                              }}
                            >
                              Nathan Crowe
                            </div>
                          </div>
                        </div>
                        <div className={classes["menu_seperator"]}></div>
                      </>
                    )}
                    {/* ********** CLEAR/SAVE FILTERS ********** */}
                    <div
                      style={{
                        display: "flex",
                        gap: "1rem",
                        justifyContent: "flex-end"
                      }}
                    >
                      <button className={classes["save-filters-button"]}>
                        Save Filters
                      </button>
                      <button
                        className={classes["clear-filters-button"]}
                        onClick={clearFiltersHandler}
                      >
                        Clear Filters
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ListBoxHeader;
