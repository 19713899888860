import { useRef, useEffect } from "react";

const useClickAwayListener = (ref, onClick, custom) => {

  useEffect(() => {
    //Click away event doesn't include event target, fire onclick
    const handleClickAway = (event) => {
      if (ref.current && !ref.current.contains(event.target) && (custom !== "" ? !custom.contains(event.target) : true)) {
        onClick();
      }
    };
    //bind event
    document.addEventListener("mousedown", handleClickAway);

    //clean up listener
    return () => {
      document.removeEventListener("mousedown", handleClickAway);
    };
  }, [ref, onClick]);
};

export default useClickAwayListener;