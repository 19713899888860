import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  filters: {
    status: "unassigned",
    contactType: "lead",
  },
  searchTerm: "",
  leadStatus: "unassigned",
};

const filterSlice = createSlice({
  name: "statusFilter",
  initialState,
  reducers: {
    addFilter(state, action) {

      const value = action.payload.value; //value
      const key = action.payload.key; //key
      
      state.filters = { ...state.filters, [key]: value };
    },
    removeFilter(state, action) {
      const key = action.payload.key; //key
      delete state.filters[key]
    },
    clearFilters(state, action) {
      state.filters = {};
    },
    setSearchTerm(state, action) {
      state.searchTerm = action.payload;
    },
    setLeadStatus(state, action) {
      state.leadStatus = action.payload;
    },
  },
});

export const filterActions = filterSlice.actions;

export default filterSlice.reducer;
