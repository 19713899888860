import React, { useEffect, useState } from "react";
import ReactDom from "react-dom";
import Modal from "../../UIElements/Modal/Modal";
import Dot from "../../UIElements/Dot/Dot";
import { Link, useNavigate } from "react-router-dom";
import classes from "./NotificationModal.module.css";
import SpinnerButton from "../../UIElements/SpinnerButton/SpinnerButton";

const NotificationModal = (props) => {
  const navigate = useNavigate();

  const cancelHandler = () => {
    props.notificaionModal()
  }

  const [selectValue, setSelectValue] = useState("");

  const selectChangeHandler = (event) => {
    setSelectValue(event.target.value)
  }

  return (
    <>
      <Modal maxWidth="500px" marginTop="20vh" closeModal={props.onCloseNotificationModal}>
        <div className={classes["notification-modal"]}>
          <div className={classes["title"]}>
            <h3>{props.title}</h3>
          </div>
          <div className={classes["body"]}>
            <p>
              {props.body}
            </p>
            {props.selectOptions && <div className={classes.customSelect}>
              <select onChange={selectChangeHandler}>
                {props.selectOptions.map(option => {
                  return (
                    <>
                      <option value="" selected disabled hidden>
                        {props.selectLabel}
                      </option>
                      <option name={option.name} value={option.value}>{option.value}</option>
                    </>
                  )
                })}
              </select>
            </div>}
          </div>
          <div className={classes["footer"]}>
            <div className={classes["learn-more"]}>
              <Link to={"/"}>{props.learnMore}</Link>
            </div>
            <div className={classes["actions"]}>
              <SpinnerButton color={props.cancelBtnColor ? props.cancelBtnColor : "button--gray"} onClick={props.cancelButton}>Cancel</SpinnerButton>
              <SpinnerButton color={props.confirmBtnColor ? props.confirmBtnColor : "button--blue"} onClick={(event) => props.confirmButton(event, selectValue)} disabled={props.dispositionIsValid}>Confirm</SpinnerButton>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NotificationModal;
