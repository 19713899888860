import event, { eventActions } from "./event";
import store from "./index";
import { uiActions } from "./ui";
import { notificationActions } from "./notifications";
import { createNote } from "./contact-note-actions";

export const fetchEvents = () => {
  const token = store.getState().userReducer.token;
  const userId = store.getState().userReducer.user.userId;
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/events/users/${userId}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message);
      }

      // dispatch to store
      dispatch(
        eventActions.replaceEventData({
          events: responseData || [],
        })
      );

    } catch (error) {
      // dispatch(
      //   notificationActions.setToastNotification({
      //     status: "error",
      //     title: "Error",
      //     message: error.message,
      //   })
      // );
    }
  }
}

export const createEvent = (eventData) => {
  const token = store.getState().userReducer.token;
  return async (dispatch) => {
    //set event loading to true
    dispatch(eventActions.setIsEventLoading(true));
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/events`,
        {
          method: "POST",
          body: JSON.stringify({
            firstName: eventData.firstName,
            lastName: eventData.lastName,
            eventName: eventData.eventName,
            eventType: eventData.eventType,
            eventStarts: eventData.eventStarts,
            eventEnds: eventData.eventEnds,
            eventNote: eventData.eventNote,
            eventInvitees: eventData.eventInvitees,
            remindMe: eventData.remindMe,
            remindContact: eventData.remindContact,
            reminderNote: eventData.reminderNote,
            // creator: eventData.creator -> handled in backend
            belongsTo: eventData.belongsTo
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + token,
          }
        });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message);
      }

      //if note was added by user save it to the contact's notes
      if (responseData.event.eventNote) {
        const newNote = {
          text: eventData.eventNote,
          belongsTo: eventData.belongsTo,
        };

        try {
          dispatch(createNote(newNote));
        } catch (error) {
          console.log(error)
        }
      }
      console.log('dispatching event to store')
      //dispatch to store
      dispatch(eventActions.addEvent(responseData.event));

      //set event loading to false
      dispatch(eventActions.setIsEventLoading(false));

    } catch (error) {

    }
  }
}

export const updateEvent = (eventData) => {
  const token = store.getState().userReducer.token;
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/events/${eventData.id}`,
        {
          method: "PATCH",
          body: JSON.stringify({
            id: eventData.id,
            firstName: eventData.firstName,
            lastName: eventData.lastName,
            eventName: eventData.eventName,
            eventType: eventData.eventType,
            eventStarts: eventData.eventStarts,
            eventEnds: eventData.eventEnds,
            eventNote: eventData.eventNote,
            eventInvitees: eventData.eventInvitees,
            remindMe: eventData.remindMe,
            remindContact: eventData.remindContact,
            reminderNote: eventData.reminderNote,
            creator: eventData.creator,
            belongsTo: eventData.belongsTo
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + token,
          }
        }
      );

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message);
      }

      //dispatch to store
      dispatch(eventActions.updateEvent(responseData.event));

    } catch (error) {
      console.log(error)
    }
  }
}

export const deleteEvent = (eventData) => {
  const token = store.getState().userReducer.token;
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/events/${eventData.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      const responseData = await response.json();

      //dispatch to store
      dispatch(eventActions.deleteEvent(eventData.id));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error",
          message: error.message || "Something went wrong, please try again.",
        })
      );
    }
  };
};
