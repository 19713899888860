import { useState } from "react";
import MainNavigation from "../Navigation/MainNavigation/MainNavigation";
import SideDrawer from "../Navigation/SideDrawer/SideDrawer";
import Main from "../Navigation/Main/Main";
import CSSTransition from "react-transition-group/CSSTransition";
import CommunicationSideDrawer from "../CommunicationSideDrawer/ComminicationSideDrawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import classes from "./Layout.module.css";
import { useSelector, useDispatch } from "react-redux";
import { uiActions } from "../../../store/ui";

const Layout = (props) => {

  const dispatch = useDispatch();

  const [navDrawerIsOpen, setNavDrawerIsOpen] = useState(true);

  const toggleNavDrawerHandler = () => {setNavDrawerIsOpen((prevState) => !prevState)};

  return (
    <>
      <MainNavigation onToggleDrawer={toggleNavDrawerHandler} />
      <CSSTransition
        mountOnEnter
        unmountOnExit
        in={navDrawerIsOpen}
        classNames={{
          enterActive: classes["nav-enter"],
          exitActive: classes["nav-exit"],
        }}
        timeout={300}
      >
        <SideDrawer drawerIsOpen={navDrawerIsOpen} />
      </CSSTransition>

      <Main className={classes["main"]} drawerIsOpen={navDrawerIsOpen}>
        {props.children}
      </Main>

        {/* <CommunicationSideDrawer commDrawerIsOpen={commDrawerIsOpen} closeCommDrawerHandler={closeCommDrawerHandler}/> */}
     
    </>
  );
};

export default Layout;
