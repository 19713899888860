import { createSlice, current } from "@reduxjs/toolkit";

const notificationsSlice = createSlice({
  name: "notification",
  initialState: {
    isShowMenuNotification: false,
    isShowToastNotification: false,
    toastNotification: {
        status: null,
        title: null,
        message: null,
        link: null
    },
  },
  reducers: {
    setIsShowMenuNotification(state, action) {
      state.isShowMenuNotification = !state.isShowMenuNotification;
    },
    setIsShowToastNotification(state, action) {
      state.isShowToastNotification = !state.isShowToastNotification;
    },
    setToastNotification(state, action) {
      if (action.payload === undefined) {
        state.toastNotification = false;
        return;
      }
      state.toastNotification = {
        status: action.payload.status,
        title: action.payload.title,
        message: action.payload.message,
        link: action.payload.link
      };
    },
  },
});

export const notificationActions = notificationsSlice.actions;

export default notificationsSlice.reducer;
