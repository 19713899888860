import ReactDom from "react-dom";
import Modal from "../UIElements/Modal/Modal";
import Dot from "../UIElements/Dot/Dot";
import { useSelector } from "react-redux";
import classes from "./ZoomModal.module.css";

const ZoomModal = (props) => {
  const imageUrl = useSelector((state) => state.modalReducer.imageUrl);

  return (
    <>
      {ReactDom.createPortal(
        <Modal closeModal={props.onCloseModal}>
          <div className={classes["zoom-modal"]}>
            <img src={imageUrl} alt="" />
          </div>
        </Modal>,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default ZoomModal;
