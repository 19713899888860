//Import named components
import { uiActions } from "./ui";
import { contactNoteActions } from "./contact-note";
import store from "./index";
import axios from "axios";

let ABORT_FETCH_CONTROLLER = null;

export const contactNotesAbortController = () => {
  return async (dispatch) => {
    ABORT_FETCH_CONTROLLER.abort();
  };
};

export const fetchNotes = (contactId) => {
  ABORT_FETCH_CONTROLLER = new AbortController();

  const token = store.getState().userReducer.token;
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/contact-notes/contacts/${contactId}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
          signal: ABORT_FETCH_CONTROLLER.signal,
        }
      );

      const responseData = await response.data;

      dispatch(contactNoteActions.replaceNoteData({ notes: responseData.notes }));
    } catch (error) {
      if (axios.isCancel(error)) {
      } else {
        dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Error",
            message: error.message || "Something went wrong, please try again.",
          })
        );
      }
    }
  };
};

export const createNote = (noteData) => {
  const token = store.getState().userReducer.token;
  return async (dispatch) => {
    //set event loading to true
    dispatch(contactNoteActions.setIsNoteLoading(true));
    try {
      const formData = new FormData();
      formData.append("text", noteData.text);
      if (noteData.file) {
        noteData.file.forEach((file) => {
          formData.append("file", file);
        });
      }
      formData.append("belongsTo", noteData.belongsTo);
      formData.append("creator", noteData.creator);

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/contact-notes`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
          onUploadProgress: (data) => {
            const progress = Math.round((100 * data.loaded) / data.total);

            if (progress > 0) {
              dispatch(
                contactNoteActions.setUploadProgress(
                  Math.round((100 * data.loaded) / data.total)
                )
              );
            }
            if (progress === 100) {
              dispatch(contactNoteActions.setUploadProgress(0));
            }
          },
        }
      );

      dispatch(contactNoteActions.addNote({ note: response.data.note }));

      //set note loading to false
      dispatch(contactNoteActions.setIsNoteLoading(false));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error",
          message: error.message || "Something went wrong, please try again.",
        })
      );
    }
  };
};

export const updateNote = (noteData) => {
  const token = store.getState().userReducer.token;
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/contact-notes/${noteData.noteId}`,
        {
          method: "PATCH",
          body: JSON.stringify({
            id: noteData.noteId,
            text: noteData.text,
            creator: noteData.creator,
            belongsTo: noteData.belongsTo,
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      const responseData = await response.json();

      //dispatch to store
      dispatch(contactNoteActions.updateNote(responseData.note));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error",
          message: error.message || "Something went wrong, please try again.",
        })
      );
    }
  };
};

export const deleteNote = (noteData) => {
  const token = store.getState().userReducer.token;
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/contact-notes/${noteData.noteId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      const responseData = await response.json();

      //dispatch to store
      dispatch(contactNoteActions.deleteNote(noteData.noteId));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error",
          message: error.message || "Something went wrong, please try again.",
        })
      );
    }
  };
};
