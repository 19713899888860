import { useCallback, useEffect, useRef, useState } from "react";
import classes from "./Call.module.css";
import { IoPlaySharp, IoPauseSharp } from "react-icons/io5";
import { CiRead, CiUnread } from "react-icons/ci";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";
import { convertTime } from "../../utils/convertTime";
import { useTwilioPhoneOutgoing } from "../../hooks/useTwilioPhoneOutgoing/useTwilioPhoneOutgoing";
import { ReactComponent as CallIcon } from "../../../../assets/imgs/call-icon.svg";
import { FiPhoneMissed } from "react-icons/fi";

const Call = ({ missedCall, setReviewedMissedCall }) => {
  const [isChecked, setIsChecked] = useState(false);

  const {
    onPhone,
    callIsMuted,
    callIsActiveForContact,
    callStatus,
    callOutcome,
    makeCallHandler,
    hangUpCallHandler,
    muteCallHandler,
  } = useTwilioPhoneOutgoing();


  const isCheckedHandler = () => {
    setIsChecked(true);
    const timer = setTimeout(() => {
      setReviewedMissedCall(missedCall);
      setIsChecked(false);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  };

  return (
    <div className={classes["container"]}>
      <div className={classes["call"]}>
        <div className={classes["details"]}>
          <div style={{ display: "flex", marginTop: "3px" }}>
            <FiPhoneMissed size="16px" color="#C25950" />
          </div>
          <div className={classes["sender-details"]}>
            <div className={classes["sender-name"]}>
              {missedCall.contactName}
            </div>
            {missedCall.contactName === "Unknown" && (
              <div className={classes["sender-number"]}>
                {formatPhoneNumber(missedCall.from)}
              </div>
            )}
            <div className={classes["received"]}>
              {convertTime(new Date(missedCall.createdAt))}{" "}
              {new Date(missedCall.createdAt).getMonth() + 1}/
              {new Date(missedCall.createdAt).getDate()}/
              {new Date(missedCall.createdAt).getFullYear()}
            </div>
          </div>
          <div className={classes["controls"]}>
            <div className={classes["controls"]}>
              <button
                className={`${
                  !callIsActiveForContact ? classes["answer-button"] : ""
                } ${callIsActiveForContact && classes["hangup-button"]} ${
                  classes["button"]
                }`}
                onClick={
                  !callIsActiveForContact
                    ? (event) =>
                        makeCallHandler(
                          event,
                          missedCall.from,
                          missedCall.belongsTo
                        )
                    : hangUpCallHandler
                }
              >
                {!callIsActiveForContact ? <CallIcon /> : <CallIcon />}
              </button>
            </div>
            <div className={classes["mark-as-listened"]}>
              <input
                type="radio"
                checked={isChecked}
                onClick={isCheckedHandler}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Call;
