import classes from "./PipelineStage.module.css";
import React, { useEffect, useState, useRef, useCallback } from "react";
import Input from "../../shared/components/UIElements/Input/Input";
import SpinnerButton from "../../shared/components/UIElements/SpinnerButton/SpinnerButton";
import { CiMenuKebab } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import PlusIcon from "../../shared/components/UIElements/PlusIcon/PlusIcon";
import { usePopper } from "react-popper";
import useClickAwayListener from "../../shared/components/hooks/useClickAwayListener/useClickAwayLIstener";
import { CiEdit } from "react-icons/ci";
import { RxTrash } from "react-icons/rx";
import {
  updateStage,
  createStage,
  createDeal,
  deleteStage
} from "../../store/pipeline-actions";
import { useNavigate } from "react-router-dom";
import { FaRegTrashAlt } from "react-icons/fa";
import PipelineDeal from "./PipelineDeal";
import NotificationModal from "../../shared/components/Modal/NotificationModal/NotificationModal";
import { delay } from "../../shared/components/utils/delay";
import { Droppable, Draggable } from "react-beautiful-dnd";
import mergeClasses from 'classnames';

const PipelineStage = ({ stages, stage, deals, index }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  //import contacts from store for filtering
  const contacts = useSelector((state) => state.contactReducer.contacts);

  //edit stage name
  const [isEditStageOpen, setIsEditStageOpen] = useState(false);
  const [updatedStageName, setUpdatedStageName] = useState("");

  //show delete stage
  const [isDeleteStageOpen, setIsDeleteStageOpen] = useState(false);

  //open add new deal
  const [isAddDealOpen, setIsAddDealOpen] = useState(false);

  //search, filter, select, and add contact to deal
  const [userEnteredContactName, setUserEnteredContactName] = useState("");
  const [filteredContacts, setFilteredContacts] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedContactHasErrors, setSelectedContactHasErrors] =
    useState(false);

  //add user entered description to deal
  const [enteredDescription, setEnteredDescription] = useState("");
  const [enteredDescriptionHasErrors, setEnteredDescriptionHasErrors] =
    useState(false);

  //open edit stage handler
  const isEditStageOpenHandler = () => {
    setIsEditStageOpen(true);
    toggleEditKebabMenuPopperHandler();
  };

  //sets updated stage name
  const editStageNameInputHandler = (e) => {
    setUpdatedStageName(e.target.value);
  };

  //updates stage name to action creator
  const updatedStageNameHandler = () => {
    dispatch(
      updateStage({
        ...stage,
        name: updatedStageName
      })
    );
    setIsEditStageOpen(false);
  };

  //updates deal id order for sorting
  const updateDealArrayHandler = (updatedDealArray) => {
    console.log(updatedDealArray);
  };

  //opens delete stage modal
  const openDeleteStageHandler = () => {
    toggleEditKebabMenuPopperHandler();
    setIsDeleteStageOpen(true);
  };

  //close delete stage handler
  const closeDeleteStageHandler = () => {
    setIsDeleteStageOpen(false);
  };

  //dispatches delete stage action creator
  const deleteStageHandler = () => {
    setIsDeleteStageOpen(false);
    dispatch(deleteStage(stage));
  };

  //sets stage name value to input for editing
  useEffect(() => {
    setUpdatedStageName(stage.name);
  }, [stage]);

  //sets new deal open for user inputs
  const isOpenAddDealHandler = () => {
    setIsAddDealOpen(true);
  };

  //add selected contact to deal handler
  const addContactHandler = (e, selectedContact) => {
    setSelectedContact(selectedContact);
  };

  //remove selected contact from deal handler
  const removeContactHandler = () => {
    setSelectedContact(null);
  };

  //add deal description to deal handler
  const descriptionInputHandler = (e) => {
    setEnteredDescriptionHasErrors(false);
    setEnteredDescription(e.target.value);
  };

  //dispatches add card action creator
  const addDealHandler = () => {
    if (!selectedContact) {
      setSelectedContactHasErrors(true);
      return;
    }

    if (!enteredDescription) {
      setEnteredDescriptionHasErrors(true);
      return;
    }

    dispatch(
      createDeal({
        contactId: selectedContact.id,
        description: enteredDescription,
        belongsTo: stage.id
      })
    );

    setIsAddDealOpen(false);
    setSelectedContact(null);
    setUserEnteredContactName("");
    setFilteredContacts(null);
    setEnteredDescription("");
    setSelectedContactHasErrors(false);
  };

  //cancel add card
  const cancelAddCardHandler = () => {
    setIsAddDealOpen(false);
    setSelectedContact(null);
    setUserEnteredContactName("");
    setFilteredContacts(null);
    setEnteredDescription("");
    setSelectedContactHasErrors(false);
    setEnteredDescriptionHasErrors(false);
  };

  //routes to new contact if one doesn't exist
  const addNewContactHandler = () => {
    navigate("/app/call-center/contacts/new");
  };

  //filter contacts from user's inputs
  const onSearchHandler = (e) => {
    setUserEnteredContactName(e.target.value);
    if (e.target.value === "") {
      setFilteredContacts(null);
      return;
    }
    const searchTerm = e.target.value.toLowerCase();
    setFilteredContacts(
      contacts.filter((selectedContact) => {
        return (
          selectedContact.firstName.toLowerCase().includes(searchTerm) ||
          selectedContact.lastName.toLowerCase().includes(searchTerm) ||
          (
            selectedContact.firstName.toLowerCase() +
            " " +
            selectedContact.lastName.toLowerCase()
          ).includes(searchTerm)
        );
      })
    );
  };

  //edit stage popper
  const [isEditStageMenuOpen, setIsEditStageMenuOpen] = useState(false);
  const [referenceKebabIcon, setReferenceKebabIcon] = useState(null);
  const [editStageMenu, setEditStageMenu] = useState(null);

  const editStageMenuPopper = usePopper(referenceKebabIcon, editStageMenu, {
    placement: "bottom",
    modifiers: [
      { name: "offset", options: { offset: [0, 0] } },
      {
        name: "preventOverflow",
        options: {
          altAxis: true,
          padding: 10
        }
      }
    ]
  });

  const toggleEditKebabMenuPopperHandler = () => {
    setIsEditStageMenuOpen((prevState) => !prevState);
  };

  const kebabIconClickAwayRef = useRef(null);

  useClickAwayListener(
    kebabIconClickAwayRef,
    toggleEditKebabMenuPopperHandler,
    referenceKebabIcon
  );


  return (
    <>
      {isDeleteStageOpen && (
        <NotificationModal
          onCloseNotificationModal={closeDeleteStageHandler}
          title={`Delete stage ${stage.name}?`}
          body="Deleting this stage cannot be undone and all corresponding deals will be lost."
          cancelButton={closeDeleteStageHandler}
          confirmButton={deleteStageHandler}
        />
      )}
      <Draggable draggableId={stage.id} index={index}>
        {(provided, snapshot) => {
          return (
            <div
              className={
                mergeClasses(
                  classes.stage,
                  snapshot.isDragging && classes.isDragging
                )
              }
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              {!isEditStageOpen && (
                <div className={classes.header}>
                  <div className={classes.stageName}>{stage.name}</div>
                  <div className={classes.kebab} ref={setReferenceKebabIcon}>
                    <CiMenuKebab
                      className={classes.kebabMenu}
                      onClick={toggleEditKebabMenuPopperHandler}
                      size="24px"
                    />
                  </div>
                </div>
              )}
              {isEditStageOpen && (
                <div>
                  <Input
                    value={updatedStageName}
                    onChange={editStageNameInputHandler}
                    autoFocus
                  />
                  <div className={classes.actions}>
                    <SpinnerButton
                      size="button--small"
                      color="button--blue"
                      onClick={updatedStageNameHandler}
                    >
                      Save name
                    </SpinnerButton>
                    <SpinnerButton
                      size="button--small"
                      color="button--white"
                      onClick={() => setIsEditStageOpen(false)}
                    >
                      Cancel
                    </SpinnerButton>
                  </div>
                </div>
              )}
              <Droppable droppableId={stage.id} index={index} type="task">
                {(provided, snapshot) => {
                  return (
                    <div
                      className={
                        mergeClasses(
                          classes.dealList,
                          snapshot.isDraggingOver && classes.dragginOver
                        )
                      }
                      key={index}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {deals?.map((deal, index) => {
                        return (
                          <PipelineDeal
                            key={deal.id}
                            index={index}
                            deal={deal}
                            stage={stage}
                            stages={stages}
                          />
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  );
                }}
              </Droppable>
              {isAddDealOpen && (
                <div className={classes.deal}>
                  <div className={classes.contactDetails}>
                    <div className={classes.contactName}>
                      {!selectedContact && (
                        <div className={classes.contactNameInput}>
                          <div
                            className={`${classes["form-control"]} ${selectedContactHasErrors ? classes["invalid"] : ""
                              }`}
                          >
                            <input
                              type="search"
                              placeholder="Type contact's name..."
                              autoFocus
                              onChange={onSearchHandler}
                              value={userEnteredContactName}
                            />
                          </div>
                          {filteredContacts && (
                            <div className={classes.filteredContactsList}>
                              {filteredContacts &&
                                filteredContacts.map((selectedContact) => {
                                  return (
                                    <div
                                      key={selectedContact.id}
                                      onClick={(e) => {
                                        addContactHandler(e, selectedContact);
                                      }}
                                    >
                                      {selectedContact.firstName +
                                        " " +
                                        selectedContact.lastName}
                                    </div>
                                  );
                                })}
                              {!filteredContacts.length && (
                                <div
                                  onClick={addNewContactHandler}
                                  className={classes.addNewContact}
                                >
                                  Add new contact?:{" "}
                                  <span>{userEnteredContactName}</span>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                      {selectedContact && (
                        <div className={classes.addedContact}>
                          <div className={classes.contact}>
                            {selectedContact.firstName +
                              " " +
                              selectedContact.lastName +
                              " "}
                          </div>
                          <FaRegTrashAlt
                            onClick={removeContactHandler}
                            size="12px"
                          />
                        </div>
                      )}
                    </div>
                    <div className={classes.descriptionInput}>
                      <div
                        className={`${classes["form-control"]} ${enteredDescriptionHasErrors ? classes["invalid"] : ""
                          }`}
                      >
                        <input
                          className={classes.input}
                          type="text"
                          value={enteredDescription}
                          onChange={descriptionInputHandler}
                          placeholder="Description"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classes.horizontalLine}></div>
                  <div className={classes.actions + " " + classes.newDeal}>
                    <SpinnerButton
                      margin=".5rem 0 .5rem 0"
                      color="button--white"
                      onClick={cancelAddCardHandler}
                    >
                      Cancel
                    </SpinnerButton>
                    <SpinnerButton
                      margin=".5rem 0 .5rem 0"
                      color="button--blue"
                      onClick={addDealHandler}
                    >
                      Save
                    </SpinnerButton>
                  </div>
                </div>
              )}
              <div className={classes.addDeal} onClick={isOpenAddDealHandler}>
                <PlusIcon>Add Deal</PlusIcon>
              </div>
              {isEditStageMenuOpen && (
                <div ref={kebabIconClickAwayRef}>
                  <div
                    ref={setEditStageMenu}
                    className={classes.popper}
                    style={editStageMenuPopper.styles.popper}
                    {...editStageMenuPopper.attributes.popper}
                  >
                    <div>
                      <ul className={classes.editStageMenu}>
                        <li
                          className={classes.menuItem}
                          onClick={isEditStageOpenHandler}
                        >
                          <CiEdit size="25px" className={classes.editIcon} />
                          Edit Name
                        </li>
                        <li
                          className={classes.menuItem}
                          onClick={openDeleteStageHandler}
                        >
                          <RxTrash size="20px" className={classes.trashIcon} />
                          Delete Stage
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        }}
      </Draggable>
    </>
  );
};

export default PipelineStage;
