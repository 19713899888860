import classes from "./SpinnerButton.module.css";
import mergeClasses from 'classnames';

const SpinnerButton = (props) => {
  return (
    <button
      className={mergeClasses(
        classes.button,
        classes[props.customClasses],
        classes[props.color],
        classes[props.size],
        classes[props.btnAlign],
        props.isLoading ? classes["button--loading"] : ""
      )}
      style={{
        padding: props.padding ? props.padding : "",
        margin: props.margin ? props.margin : "",
        width: props.width ? props.width : "",
        height: props.height ? props.height : "",
        backgroundColor: props.inlineBgColor ? props.inlineBgColor : "",
        color: props.inlineFontColor ? props.inlineFontColor : "",
        border: props.inlineBorder ? props.inlineBorder : ""
      }}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.icon && props.children && <><div className={classes.icon}>{props.icon}</div><div className={classes["button__text"]}>{props.children}</div></>}
      {props.icon && !props.children && <div className={classes.icon}>{props.icon}</div>}
      {!props.icon && props.children && <div className={classes["button__text"]}>{props.children}</div>}
    </button>
  );
};

export default SpinnerButton;
