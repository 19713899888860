import classes from "./Emails.module.css";

const Emails = (props) => {
    return (
        <div className={classes["emails"]}>
            <div className={classes["no-emails"]}>
                You have no unread emails...
            </div>
        </div>
    )
};

export default Emails;
