import React, { useState, useEffect, useReducer } from "react";
import mergeClasses from "classnames";
import { BsVolumeMuteFill } from "react-icons/bs";
import { MdOutlineFlashAuto } from "react-icons/md";
import { MdOutlineMoveDown } from "react-icons/md";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useTwilioPhoneOutgoing } from "../../../hooks/useTwilioPhoneOutgoing/useTwilioPhoneOutgoing";
import { useSelector, useDispatch } from "react-redux";
import { twilioActions } from "../../../../../store/twilio";
import { notificationActions } from "../../../../../store/notifications";
import { Link, useNavigate } from "react-router-dom";
import classes from "./Dialer.module.css";

const Dialer = ({
  contactId,
  contact,
  nextContactHandler,
  previousContactHandler,
  setIsDispositionOpen,
  dispositionIconRef
}) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  //import outgoing call logic
  const {
    onPhone,
    callIsMuted,
    callIsActiveForContact,
    callStatus,
    callOutcome,
    userHasPhoneNumber,
    makeCallHandler,
    hangUpCallHandler,
    muteCallHandler,
    resetCallOutcome
  } = useTwilioPhoneOutgoing(contactId);

  //instatiate auto dialing state
  const [isAutoDialing, setIsAutoDialing] = useState(false);
  const [isAutoDialingPaused, setIsAutoDialingPaused] = useState(false);
  
  //user disconnted state
  const [userHungup, setUserHungup] = useState(false);

  //import machine detection from twilio reducer
  const machineDetected = useSelector(
    (state) => state.twilioReducer.isAnsweredByMachine
  );

  //import human detection from twilio reducer
  const humanDetected = useSelector(
    (state) => state.twilioReducer.isAnsweredByHuman
  );

  //reset machine detection
  const setMachineDetected = (payload) => {
    dispatch(twilioActions.setMachineStatus({ machineDetected: payload }));
  };

  //reset human detection
  const setHumanDetected = (payload) => {
    dispatch(twilioActions.setMachineStatus({ humanDetected: payload }));
  };

  //manual dial to current contact
  const onMakeCallHandler = () => {
    //make call
    makeCallHandler("", contact.phone, contactId);
  };

  //hang up call and reset states
  const onHangUpCallHandler = () => {
    setIsAutoDialing(false);
    setHumanDetected(false);
    setMachineDetected(false);
    hangUpCallHandler();
    setUserHungup(true);
  };

  //throw error if contact does not have a phone number
  const noPhoneNumberNotification = () => {
    dispatch(
      notificationActions.setToastNotification({
        status: "error",
        title: "Error",
        message:
          "The contact does not have a phone number, please update contact or move to the next one."
      })
    );
    return;
  };

  //throw error if user has not setup their phone number
  const noUserPhoneNumberNotification = () => {
    dispatch(
      notificationActions.setToastNotification({
        status: "error",
        title: "Error",
        message: `You must setup a phone number before making calls. You can set one up by clicking`,
        link: "/app/call-center/settings/phone"
      })
    );
  };

  //start auto dialing session
  const autoDialHandler = (e, status) => {
    //prevent call if no user phone number, notification handled in
    if (!userHasPhoneNumber) {
      noUserPhoneNumberNotification();
      return;
    }

    //if no contact phone number throw error
    if (!contact.phone) {
      noPhoneNumberNotification();
      return;
    }

    //if auto dialer paused
    if (isAutoDialingPaused) {
      //get next contact
      const nextContact = nextContactHandler();

      //if no phone for next contact throw error
      if (!nextContact.phone) {
        setIsAutoDialingPaused(false);
        noPhoneNumberNotification();
        return;
      }

      //start auto dialer
      setIsAutoDialingPaused(false);
      setIsAutoDialing(true);

      //make next call with next contact phone/id
      makeCallHandler("", nextContact.phone, nextContact.id);

      //reset human detection
      setHumanDetected(false);
      return;
    }

    //start call
    if (!isAutoDialing) {
      makeCallHandler("", contact.phone, contactId);
    }

    //end call
    if (isAutoDialing) {
      hangUpCallHandler();
    }

    //toggle auto dialer
    setIsAutoDialing(status);
  };

  //if a machine is detected, hang up and call next contact
  useEffect(() => {
    if (isAutoDialing && machineDetected) {
      //hangup call
      hangUpCallHandler();

      //reset machine detection
      setMachineDetected(false);

      //get next contact
      const nextContact = nextContactHandler();

      //if no phone for next contact throw error
      if (!nextContact.phone) {
        setIsAutoDialing(false);
        noPhoneNumberNotification();
        return;
      }

      //make next call with next contact phone/id
      makeCallHandler("", nextContact.phone, nextContact.id);
    }
  }, [machineDetected]);

  //if a human is detected, pause auto dialer
  useEffect(() => {
    if (isAutoDialing && humanDetected) {
      setIsAutoDialingPaused(true);
      setIsAutoDialing(false);
    }
  }, [humanDetected]);

  // //if call not disconnected by user, make next call
  // useEffect(() => {
  //   if (callOutcome === "disconnected" && !userHungup) {
  //     //hangup call
  //     hangUpCallHandler();

  //     //reset machine detection
  //     setMachineDetected(false);

  //     //get next contact
  //     const nextContact = nextContactHandler();

  //     //if no phone for next contact throw error
  //     if (!nextContact.phone) {
  //       setIsAutoDialing(false);
  //       noPhoneNumberNotification();
  //       return;
  //     }

  //     //make next call with next contact phone/id
  //     makeCallHandler("", nextContact.phone, nextContact.id);

  //     //reset userHungup to false
  //     setUserHungup(false);
  //   }
  // }, [callOutcome]);

  // console.log(callOutcome, 'dialer')

  return (
    <div className={classes["call-controls"]}>
      <div className={classes.callButtons}>
        {!callIsActiveForContact && (
          <button
            className={mergeClasses(
              classes["call-control"],
              classes["answer-button"]
            )}
            onClick={onMakeCallHandler}
          />
        )}

        {callIsActiveForContact && (
          <button
            className={mergeClasses(
              classes["call-control"],
              classes["muted-button"],
              callIsMuted && classes.active
            )}
            onClick={muteCallHandler}
          >
            <BsVolumeMuteFill size="12px" />
          </button>
        )}

        <button
          className={mergeClasses(
            classes["call-control"],
            classes["hangup-button"]
          )}
          onClick={onHangUpCallHandler}
        />
      </div>

      <div className={classes.nextPrevButtons}>
        <button
          className={mergeClasses(classes["call-control"], classes.prevButton)}
          onClick={isAutoDialing ? null : previousContactHandler}
        >
          <MdKeyboardArrowLeft size="30px" />
        </button>
        <button
          className={mergeClasses(classes["call-control"], classes.nextButton)}
          onClick={isAutoDialing ? null : nextContactHandler}
        >
          <MdKeyboardArrowRight size="30px" />
        </button>
      </div>
      <div className={classes.auxiliaries}>
        <div
          className={classes.disposition}
          ref={dispositionIconRef}
          // onClick={toggleDispositionHandler}
        >
          <MdOutlineMoveDown size="14px" />
        </div>
        <div
          className={mergeClasses(
            classes.autoDial,
            isAutoDialing && classes.active,
            isAutoDialingPaused && classes.paused
          )}
          onClick={
            isAutoDialing
              ? (e) => autoDialHandler(e, false)
              : (e) => autoDialHandler(e, true)
          }
        >
          <MdOutlineFlashAuto size="14px" />
        </div>
      </div>
    </div>
  );
};

export default Dialer;
