import React, { useState, useEffect, useRef } from "react";
import mergeClasses from 'classnames';
import classes from "./Textarea.module.css";

const Textarea = (props) => {
  const textareaRef = useRef();
  
  useEffect(() => {
    if (props.value) {
      textareaRef.current.style.height = "10px";
      textareaRef.current.style.height = textareaRef.current.scrollHeight + "px";
    }
  }, [props.value]);

  return (
    <div className={
      mergeClasses(
        classes.formControl,
        props.hasErrors ? classes.invalid : ''
      )
    }>
      <textarea
        ref={textareaRef}
        className={
          mergeClasses(
            classes.textarea,
            props.border ? classes.border : ''
          )
        }
        rows={props.rows}
        onChange={props.onChange}
        onFocus={props.onFocus}
        value={props.value}
        placeholder={props.placeHolder}
        autoFocus={props.autoFocus}
        style={{ 
          fontSize: props.fontSize ? props.fontSize : '',
          backgroundColor: props.backgroundColor && props.backgroundColor}}
      />
    </div>
  );
};

export default Textarea;
