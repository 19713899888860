import { useDispatch, useSelector } from "react-redux";
import Dots from "../Dots/Dots";
import classes from "./StatusBar.module.css";

const StatusBar = (props) => {
  const dispatch = useDispatch();

  //Get status (callback from props)
  // const status = useSelector(props.status);

  //Update status (action handler through props)
  const statusUpdateHanlder = (event) => {
    event.stopPropagation();
    // dispatch(
    //   props.statusUpdateHandler({
    //     status: event.target.className,
    //     id: props.id,
    //   })
    // );
  };

  return (
    <div className={classes.statusBar}  style={{justifyContent: props.align ? 'flex-end' : ''}}>
    <Dots
      onClick={props.onDotsHandler}
      selectedDot={props.status}
      redDotRef={props.redDotRef}
      yellowDotRef={props.yellowDotRef}
      greenDotRef={props.greenDotRef}
      align={props.align}
    />
    </div>
  );
};

export default StatusBar;
