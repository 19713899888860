
import React, { useEffect, useState } from "react";
import classes from "./CalendarBoxHeader.module.css";
import ViewMode from "./ViewMode";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import CalendarMonth from "./CalendarMonth";
import CalendarWeek from "./CalendarWeek";
import CalendarDay from "./CalendarDay";

const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];
const MONTHSFULL = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const DAYSFULL = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

const CalendarBoxHeader = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentWeek, setCurrentWeek] = useState(Math.floor((new Date() - new Date(new Date().getFullYear(), 0, 1)) / (24 * 60 * 60 * 1000) / 7));
  const [currentDay, setCurrentDay] = useState(new Date().getDate());
  const [today, setToday] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
  const [view, setView] = useState("month");
  const [currentDate, setCurrentDate] = useState(new Date());

  const updateView = (event) => { setView(event.target.dataset.view) };

  const todayHandler = () => {
    setCurrentYear(new Date().getFullYear());
    setCurrentMonth(new Date().getMonth());
    setCurrentDay(new Date().getDate());
    setCurrentWeek(Math.floor((new Date().getTime() - new Date(new Date().getFullYear(), 0, 1)) / (24 * 60 * 60 * 1000) / 7));
  };

  //move calendar by day/month/year when pressing next handler
  const nextHandler = () => {
    if (view === "month") {
      if (currentMonth + 1 === 12) {
        const janStartDay = new Date(currentYear + 1, 0, 1).getDate; //correct
        const daysInPrevDec = Array.from({ length: new Date(currentYear, 11 + 1, 0).getDate() }, (v, i) => i + 1); //correct
        const janOffsetDays = janStartDay > 0 ? daysInPrevDec.slice(-janStartDay) : ""; //correct
        setCurrentYear(currentYear + 1);
        setCurrentMonth(0);
        setCurrentWeek(Math.floor((((new Date(currentYear + 1, 0, 1).getTime()) - new Date(currentYear + 1, 0, 1).getTime()) / (24 * 60 * 60 * 1000) + janOffsetDays.length) / 7))
        return;
      }
      const janStartDay = new Date(currentYear, 0, 1).getDay(); //correct
      const daysInPrevDec = Array.from({ length: new Date(currentYear, 11 + 1, 0).getDate() }, (v, i) => i + 1); //correct
      const janOffsetDays = janStartDay > 0 ? daysInPrevDec.slice(-janStartDay) : ""; //correct
      setCurrentMonth(currentMonth + 1);

      //check if daylight savings
      function hasDST(date = new Date()) {
        const january = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
        const july = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
        return Math.max(january, july) !== date.getTimezoneOffset();
      }

      //set week view to first week of month (factored for DST)
      setCurrentWeek(Math.floor((((hasDST(new Date(currentYear, currentMonth + 1, 1)) ? (new Date(currentYear, currentMonth + 1, 1).getTime() + (60 * 60 * 1000)) : new Date(currentYear, currentMonth + 1, 1).getTime()) - new Date(currentYear, 0, 1).getTime()) / (24 * 60 * 60 * 1000) + janOffsetDays.length) / 7));

      //set day view to first day of current month
      setCurrentDay(new Date(currentYear, currentMonth + 1, 1).getDate());
    }

    if (view === "week") {
      if (currentWeek + 1 === 53) {
        setCurrentYear(currentYear + 1);
        setCurrentMonth(0);
        setCurrentWeek(0);
        setCurrentDay(new Date(currentYear + 1, 0, 1).getDate());
        return;
      }

      setCurrentWeek(currentWeek + 1);
      const janStartTime = new Date(currentYear, 0, 1).getTime();
      const convWeekToMs = (currentWeek + 1) * 7 * 24 * 60 * 60 * 1000;
      const currMonth = new Date(janStartTime + convWeekToMs).getMonth();
      setCurrentMonth(currMonth);

      const janStartDay = new Date(currentYear, 0, 1).getDay();
      const daysInPrevDec = Array.from({ length: new Date(currentYear - 1, 11 + 1, 0).getDate() }, (v, i) => i + 1);
      const janOffsetDays = janStartDay > 0 ? daysInPrevDec.slice(-janStartDay) : "";
      setCurrentDay(new Date(new Date(new Date((currentWeek + 1) * 7 * 24 * 60 * 60 * 1000).getTime() + (new Date(currentYear, 0, 1).getTime() - janOffsetDays.length * 24 * 60 * 60 *1000)).getTime()).getDate());
    }

    if (view === "day") {
      const currentMonthLastDay = new Date(currentYear, currentMonth + 1, 0).getDate();
      const lastDayOfPrevMonth = new Date(currentYear, currentMonth, 0).getDate();

      const janStartDay = new Date(currentYear, 0, 1).getDay();
      const daysInPrevDec = Array.from({ length: new Date(currentYear - 1, 11 + 1, 0).getDate() }, (v, i) => i + 1);
      const janOffsetDays = janStartDay > 0 ? daysInPrevDec.slice(-janStartDay) : "";

      //check if daylight savings
      function hasDST(date = new Date()) {
        const january = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
        const july = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
        return Math.max(january, july) !== date.getTimezoneOffset()
      }

      if (currentDay + 1 === currentMonthLastDay + 1) {
        if (currentMonth + 1 === 12) {
          setCurrentYear(currentYear + 1);
          setCurrentMonth(0);
          setCurrentWeek(0);
          setCurrentDay(new Date(currentYear + 1, 0, 1).getDate());
          return;
        }        
        setCurrentMonth(currentMonth + 1);
        setCurrentDay(1);

        //set week view to first week of month (factored for DST)
        setCurrentWeek(Math.floor((((hasDST(new Date(currentYear, currentMonth, currentDay + 1)) ? (new Date(currentYear, currentMonth, currentDay + 1).getTime() + (60 * 60 * 1000)) : new Date(currentYear, currentMonth, currentDay + 1).getTime()) - new Date(currentYear, 0, 1).getTime()) / (24 * 60 * 60 * 1000) + janOffsetDays.length) / 7));
        return;
      }

      setCurrentDay(currentDay + 1);

      //set week view to first week of month (factored for DST)
      setCurrentWeek(Math.floor((((hasDST(new Date(currentYear, currentMonth, currentDay + 1)) ? (new Date(currentYear, currentMonth, currentDay + 1).getTime() + (60 * 60 * 1000)) : new Date(currentYear, currentMonth, currentDay + 1).getTime()) - new Date(currentYear, 0, 1).getTime()) / (24 * 60 * 60 * 1000) + janOffsetDays.length) / 7));
    }
  };

  //move calendar by day/month/year when pressing prev handler
  const prevHandler = () => {
    if (view === "month") {
      if (currentMonth - 1 === -1) {
        const janStartDay = new Date(currentYear - 1, 0, 1).getDay();
        const daysInPrevDec = Array.from({ length: new Date(currentYear - 1, 11 + 1, 0).getDate() }, (v, i) => i + 1);
        const janOffsetDays = janStartDay > 0 ? daysInPrevDec.slice(-janStartDay) : "";
        setCurrentYear(currentYear - 1);
        setCurrentMonth(11);
        setCurrentWeek(Math.floor((((new Date(currentYear - 1, 11, 1).getTime()) - new Date(currentYear - 1, 0, 1).getTime()) / (24 * 60 * 60 * 1000) + janOffsetDays.length) / 7))
        return;
      }
      const janStartDay = new Date(currentYear, 0, 1).getDay();
      const daysInPrevDec = Array.from({ length: new Date(currentYear - 1, 11 + 1, 0).getDate() }, (v, i) => i + 1);
      const janOffsetDays = janStartDay > 0 ? daysInPrevDec.slice(-janStartDay) : "";
      setCurrentMonth(currentMonth - 1); //can we set month using similar set current week

      //check if daylight savings
      function hasDST(date = new Date()) {
        const january = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
        const july = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
        return Math.max(january, july) !== date.getTimezoneOffset()
      }

      //set week view to first week of month (factored for DST)
      setCurrentWeek(Math.floor((((hasDST(new Date(currentYear, currentMonth - 1, 1)) ? (new Date(currentYear, currentMonth - 1, 1).getTime() + (60 * 60 * 1000)) : new Date(currentYear, currentMonth - 1, 1).getTime()) - new Date(currentYear, 0, 1).getTime()) / (24 * 60 * 60 * 1000) + janOffsetDays.length) / 7));

      //set day view to first day of current month
      setCurrentDay(new Date(currentYear, currentMonth - 1, 1).getDate());
    }

    if (view === "week") {
      if (currentWeek - 1 === -1) {
        setCurrentYear(currentYear - 1);
        setCurrentMonth(11);
        setCurrentWeek(52);
        setCurrentDay(new Date(new Date((currentWeek - 1) * 7 * 24 * 60 * 60 * 1000).getTime() + new Date(currentYear - 1, 0, 1).getTime()).getDate());
        return;
      }
      setCurrentWeek(currentWeek - 1);
      const janStartTime = new Date(currentYear, 0, 1).getTime();
      const convCurrWeekToMs = (currentWeek - 1) * 7 * 24 * 60 * 60 * 1000;
      const currMonth = new Date(janStartTime + convCurrWeekToMs).getMonth();
      setCurrentMonth(currMonth);

      const janStartDay = new Date(currentYear, 0, 1).getDay();
      const daysInPrevDec = Array.from({ length: new Date(currentYear - 1, 11 + 1, 0).getDate() }, (v, i) => i + 1);
      const janOffsetDays = janStartDay > 0 ? daysInPrevDec.slice(-janStartDay) : "";
      setCurrentDay(new Date(new Date(new Date((currentWeek - 1) * 7 * 24 * 60 * 60 * 1000).getTime() + (new Date(currentYear, 0, 1).getTime() - janOffsetDays.length * 24 * 60 * 60 *1000)).getTime()).getDate());
      
    }

    if (view === "day") {
      const currentMonthFirstDay = new Date(currentYear, currentMonth, 1).getDate();
      const lastDayOfPrevMonth = new Date(currentYear, currentMonth, 0).getDate();

      const janStartDay = new Date(currentYear, 0, 1).getDay();
      const daysInPrevDec = Array.from({ length: new Date(currentYear - 1, 11 + 1, 0).getDate() }, (v, i) => i + 1);
      const janOffsetDays = janStartDay > 0 ? daysInPrevDec.slice(-janStartDay) : "";

      //check if daylight savings
      function hasDST(date = new Date()) {
        const january = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
        const july = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
        return Math.max(january, july) !== date.getTimezoneOffset()
      }

      //update to last day of previous month if current day and curent month first day -1 are equal
      if (currentDay - 1 === currentMonthFirstDay - 1) {
        //update to last day of previous year if current month -1 === -1 
        if (currentMonth - 1 === -1) {
          setCurrentYear(currentYear - 1);
          setCurrentMonth(11);
          setCurrentWeek(52);
          setCurrentDay(new Date(currentYear - 1, 11 + 1, 0).getDate());
          return;
        }
        setCurrentMonth(currentMonth - 1);
        setCurrentDay(lastDayOfPrevMonth);

        //set week view to first week of month (factored for DST)
        setCurrentWeek(Math.floor((((hasDST(new Date(currentYear, currentMonth, currentDay - 1)) ? (new Date(currentYear, currentMonth, currentDay - 1).getTime() + (60 * 60 * 1000)) : new Date(currentYear, currentMonth, currentDay - 1).getTime()) - new Date(currentYear, 0, 1).getTime()) / (24 * 60 * 60 * 1000) + janOffsetDays.length) / 7));
        return;
      }

      setCurrentDay(currentDay - 1);

      //set week view to first week of month (factored for DST)
      setCurrentWeek(Math.floor((((hasDST(new Date(currentYear, currentMonth, currentDay - 1)) ? (new Date(currentYear, currentMonth, currentDay - 1).getTime() + (60 * 60 * 1000)) : new Date(currentYear, currentMonth, currentDay - 1).getTime()) - new Date(currentYear, 0, 1).getTime()) / (24 * 60 * 60 * 1000) + janOffsetDays.length) / 7));
    }
  };

  const updateMonthHandler = (month) => {
    if (month === -1 ) {
      setCurrentMonth(11)
      return;
    }
    setCurrentMonth(month)
  }

  //sets view day passed in from user clicking 'see more' events in month view
  const seeMoreEventsHandler = (e, data) => {
    if (data.nextDay) {
      setCurrentMonth(data.currentMonth + 1);
    }
    
    setCurrentYear(data.currentYear);
    setCurrentDay(data.day);
    setView("day");
  }

  return (
    <>
      <div className={classes["header"]}>
        <div className={classes["title"]}>
          {view !== "day" ? <h3>
            {MONTHS[currentMonth]}{" "}
            <span style={{ fontWeight: "normal" }}>{currentYear}</span>
          </h3> :

            <h3>
              {MONTHS[currentMonth]}{" "}
              {new Date(currentYear, currentMonth, currentDay).getDate()}{", "}
              <span style={{ fontWeight: "normal" }}>{currentYear}</span>
              <div>
                {DAYSFULL[new Date(currentYear, currentMonth, currentDay).getDay()]}{" "}
              </div>
            </h3>
          }
        </div>
        <div className={classes["mode"]}>
          <ViewMode updateView={updateView} view={view} />
        </div>
        <div className={classes["next-today-prev"]}>
          <div onClick={prevHandler}>
            <MdKeyboardArrowLeft />
          </div>
          <div onClick={todayHandler}>Today</div>
          <div onClick={nextHandler} >
            <MdKeyboardArrowRight />
          </div>
        </div>
      </div>
      {view === "day" && (
        <CalendarDay
          months={MONTHS}
          days={DAYSFULL}
          currentYear={currentYear}
          currentMonth={currentMonth}
          currentWeek={currentWeek}
          currentDay={currentDay}
          today={today} 
          onUpdateMonth={updateMonthHandler}/>
      )}
      {view === "week" && (
        <CalendarWeek
          months={MONTHS}
          days={DAYS}
          currentYear={currentYear}
          currentMonth={currentMonth}
          currentWeek={currentWeek}
          today={today}
        />
      )}
      {view === "month" && (
        <CalendarMonth
          currentYear={currentYear}
          currentMonth={currentMonth}
          today={today}
          months={MONTHS}
          days={DAYS}
          seeMoreEvents={seeMoreEventsHandler}
        />
      )}
    </>
  );
};

export default CalendarBoxHeader;
