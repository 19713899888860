import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Dot from "../../../shared/components/UIElements/Dot/Dot";
import StatusBar from "../../../shared/components/UIElements/StatusBar/StatusBar";
import { updateContact } from "../../../store/contact-actions";
import { useTwilioPhoneOutgoing } from "../../../shared/components/hooks/useTwilioPhoneOutgoing/useTwilioPhoneOutgoing";
import CalendarIcon from "../../../shared/components/UIElements/CalendarIcon/CalendarIcon";
import classes from "./ListItem.module.css";
import SpinnerButton from "../../../shared/components/UIElements/SpinnerButton/SpinnerButton";
import { MdOutlineCalendarToday } from "react-icons/md";

const ListItem = (props) => {
  const dispatch = useDispatch();
  const {
    callIsActiveForContact,
  } = useTwilioPhoneOutgoing(props.contact);

  //Update contact status
  const updateContactStatusHandler = (event) => {
    event.stopPropagation();
    dispatch(
      updateContact({
        ...props.contact,
        status: event.target.dataset.color,
      })
    );
  };

  //Restore deleted contact
  const contactDeleted = useSelector(
    (state) =>
      state.contactReducer.contacts.find(
        (contact) => contact.id === props.contactId
      ).status === "red"
  );

  const restoreLeadStatusHandler = (event) => {
    event.stopPropagation();
    dispatch(
      updateContact({
        ...props.contact,
        status: "unassigned",
      })
    );
  };

  return (
    <div
      className={`${classes["list-item"]} ${callIsActiveForContact ? classes["active"] : ""}`}
      ref={props.lastItemref}
      onClick={(event) => props.onOpenModal(event, props.contact.id)}
    >
      <div
        className={classes["list-item__contact-details__wrapper"]}
      >
        <div className={classes["list-item__contact-details"]}>
          <div className={classes["list-item__contact-details__line-1"]}>
            <div className={classes["list-item__contact-details__name"]}>
              {props.contact?.spouseName && props.contact.firstName + " & " + props.contact?.spouseName + ' ' + props.contact.lastName}
              {!props.contact?.spouseName && props.contact.firstName + ' ' + props.contact.lastName}
            </div>
            {contactDeleted ? (
              <SpinnerButton
                customClasses="button--white"
                size='button--small'
                onClick={restoreLeadStatusHandler}
              >
                Restore
              </SpinnerButton>
            ) : (
              <StatusBar
                status={props.contact.status}
                onDotsHandler={updateContactStatusHandler}
                id={props.contact.id}
                align='flex-end'
              />
            )}
          </div>
          <div className={classes["list-item__contact-details__line-2"]}>
            <CalendarIcon color="red" width="14px">
              Last Contacted: March 3rd
            </CalendarIcon>
            <div className={classes["list-item__actions"]}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListItem;
