import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { updatePipeline } from "../../store/pipeline-actions";
import { useParams } from "react-router-dom";
import SpinnerButton from "../../shared/components/UIElements/SpinnerButton/SpinnerButton";
import Input from "../../shared/components/UIElements/Input/Input";
import classes from "./EditPipeline.module.css";

const EditPipeline = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const params = useParams();

  //import pipeline from store using params
  const pipeline = useSelector((state) =>
    state.pipelineReducer.pipelines.find(
      (pipeline) => pipeline.id === params.pipelineId
    )
  );

  const [updatedPipelineName, setUpdatedPipelineName] = useState("");

  //set updated pipeline name with user inputs
  const editPipelineNameInputHandler = (e) => {
    setUpdatedPipelineName(e.target.value);
  };

  //cancel add pipeline
  const cancelAddPipelineHandler = () => {
    navigate(-1);
  };

  //dispatch updated pipeline name
  const updatePipelineNameHandler = () => {
    dispatch(
      updatePipeline({
        ...pipeline,
        name: updatedPipelineName
      })
    );
    navigate(`/app/pipeline/${pipeline.id}`);
  };

  useEffect(() => {
    setUpdatedPipelineName(pipeline.name);
  }, []);

  return (
    <div className={classes.editPipeline}>
      <Input
        label="Name this Pipeline"
        value={updatedPipelineName}
        onChange={editPipelineNameInputHandler}
        autoFocus
      />
      <div className={classes.actions}>
        <SpinnerButton color="button--blue" onClick={updatePipelineNameHandler}>
          Update Pipeline
        </SpinnerButton>
        <SpinnerButton color="button--white" onClick={cancelAddPipelineHandler}>
          Cancel
        </SpinnerButton>
      </div>
    </div>
  );
};

export default EditPipeline;
