import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SpinnerButton from "../UIElements/SpinnerButton/SpinnerButton";
import Messages from "../MessagesBox/Messages";
import MessageForm from "../MessagesBox/MessageForm";
import { updateMessage } from "../../../store/twilio-actions";
import { useTwilioPhoneOutgoing } from "../hooks/useTwilioPhoneOutgoing/useTwilioPhoneOutgoing";
import { BiMessageRoundedError } from "react-icons/bi";
import { LuArrowRightToLine } from "react-icons/lu";
import { BsTelephone } from "react-icons/bs";
import mergeClasses from "classnames";
import { convertTime } from "../utils/convertTime";
import classes from "./CommunicationSideDrawerMessages.module.css";

const CommunicationSideDrawerMessages = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activeContact, setActiveContact] = useState();
  const contacts = useSelector((state) => state.contactReducer.contacts);
  const contact = useSelector(
    (state) =>
      state.contactReducer.contacts.filter(
        (contact) => contact.id === activeContact
      )[0]
  );

  const userId = useSelector((state) => state.userReducer.user.userId);
  const localMessages = useSelector((state) => state.messageReducer.messages);
  const contactsLocalMessages = localMessages.filter(
    (con) => con.belongsTo === contact?.id
  );
  const recentMessages = useSelector(
    (state) => state.messageReducer.recentMessages
  );
  const contactsRecentMesssages = recentMessages.find(
    (con) => con.belongsTo === contact?.id
  );

  const [unformattedMessages, setUnformattedMessages] = useState([]);
  const [formattedMessages, setFormattedMessages] = useState([]);

  const token = useSelector((state) => state.userReducer.token);

  useEffect(() => {
    if (localMessages.length) {
      const formattedMessages = [];

      localMessages.map((message) => {
        if (!message.belongsTo) {
          return;
        }
        if (
          formattedMessages.some((contact) => {
            return contact["belongsTo"] === message["belongsTo"];
          })
        ) {
          formattedMessages.forEach((contact) => {
            if (contact["belongsTo"] === message["belongsTo"]) {
              contact["messages"].push({ ...message });
            }
          });
        } else {
          let addContact = {};
          const contactName = contacts?.filter(
            (contact) => contact.id === message["belongsTo"]
          );
          addContact["contactName"] =
            contactName[0]?.firstName + " " + contactName[0]?.lastName;
          addContact["belongsTo"] = message["belongsTo"];
          addContact["messages"] = [{ ...message }] || [];
          addContact["contactId"] = message["belongsTo"];
          formattedMessages.push(addContact);
        }
      });
      setFormattedMessages(formattedMessages);
    }
  }, [localMessages]);

  useEffect(() => {
    //sets all unread messages to read (true)
    if (contactsLocalMessages.some((message) => message.read === false)) {
      //filter for unread messages
      const unread = contactsLocalMessages.filter((message) => {
        return message.read === false;
      });

      //dispatches read messages
      unread.forEach((message) => {
        const updatedMessage = {
          ...message,
          read: true
        };
        dispatch(updateMessage(updatedMessage));
      });
    }
  }, [localMessages, activeContact, props.commDrawerIsOpen]);

  const openContactModalHandler = () => {
    if (!contact?.id) {
      return;
    }
    navigate(`/app/call-center/contacts/${contact?.id}/notes`);
  };

  const setActiveContactHandler = (e, contact) => {
    setActiveContact(contact.contactId);
  };

  const closeCommDrawerHandler = () => {
    navigate('/')
  }

  //set active contact by to most recent message received
  useEffect(() => {
    if (!activeContact) {
      setActiveContact(localMessages[localMessages.length - 1]?.belongsTo);
    }
  }, [localMessages]);

  const {
    onPhone,
    callIsMuted,
    callIsActiveForContact,
    callStatus,
    callOutcome,
    makeCallHandler,
    hangUpCallHandler,
    muteCallHandler
  } = useTwilioPhoneOutgoing(contact?.id);

  return (
    <>
      <div className={classes.left}>
        <div className={classes.title}>Messages</div>
        <div className={classes.contacts}>
          {formattedMessages.length ? (
            formattedMessages.map((contact) => {
              return (
                <div
                  key={contact.contactId}
                  className={mergeClasses(
                    classes.contact,
                    activeContact === contact.contactId ? classes.active : ""
                  )}
                  onClick={(e) => {
                    setActiveContactHandler(e, contact);
                  }}
                >
                  <div className={classes.contactDetails}>
                    <div className={classes.contactNameLastMessage}>
                      <div className={classes.contactName}>
                        {contact?.contactName}
                      </div>
                      <div className={classes.IconlastNMessage}>
                        <div className={classes.lastMessage}>
                          {
                            contact.messages[contact.messages.length - 1]
                              .message
                          }
                        </div>
                      </div>
                    </div>
                    <div className={classes.lastMessageTime}>
                      {convertTime(
                        new Date(
                          contact.messages[
                            contact?.messages.length - 1
                          ].createdAt
                        )
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className={classes.noMessages}>
              <div className={classes.noMessagesIcon}>
                <BiMessageRoundedError size="5rem" />
              </div>
              <h4>No Messages</h4>
              <p className={classes.noMessage}>
                You can start a text conversation from the message tab inside
                each contact.
              </p>
            </div>
          )}
        </div>
      </div>
      <div className={classes.right}>
        <div className={classes.header}>
          <div className={classes.contactDetails}>
            <div className={classes.nameEmail}>
              <div className={classes.contactName}>
                {contact ? contact.firstName + " " + contact.lastName : ""}
              </div>
              <div className={classes.contactEmail}>{contact?.email}</div>
            </div>
            <div className={classes.actions}>
              <SpinnerButton
                color="button--white"
                inlineBorder="1px solid lightgray"
                height="35px"
                size="button--small"
                icon={<BsTelephone />}
                onClick={
                  !callIsActiveForContact
                    ? (event) =>
                        makeCallHandler(event, contact?.phone, contact?.id)
                    : hangUpCallHandler
                }
              >
                {!callIsActiveForContact ? "Call" : "Hang up"}
              </SpinnerButton>
              <SpinnerButton
                color="button--blue"
                height="35px"
                size="button--small"
                onClick={openContactModalHandler}
              >
                View Profile
              </SpinnerButton>
              <div
                className={classes.close}
                onClick={closeCommDrawerHandler}
              >
                <LuArrowRightToLine />
              </div>
            </div>
          </div>
        </div>
        <Messages
          messageData={
            formattedMessages.filter((contact) => {
              return contact.contactId === activeContact;
            })[0]?.messages
          }
        />
        <div style={{ padding: "1rem" }}>
          <MessageForm contact={contact} />
        </div>
      </div>
    </>
  );
};

export default CommunicationSideDrawerMessages;
