import classes from "./CloseX.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

const CloseX = (props) => {

  return (
    <div className={classes["close"]} onClick={props.onClick}>
      <FontAwesomeIcon icon={faX} size="xs" />
    </div>
  );
};

export default CloseX;
