import { convertTime } from "../utils/convertTime";
import classes from "./Message.module.css";

const Message = (props) => {
  return (
    <div
      className={classes["message-container"]}
      style={{
        alignItems:
          props.message.direction === "outbound-api"
            ? "flex-end"
            : "flex-start",
      }}
    >
      <div
        className={classes["message"]}
        style={{
          backgroundColor:
            props.message.direction === "outbound-api"
              ? "rgba(39, 151, 235, 0.8)"
              : "rgb(220 220 220 / 60%)",
          color:
            props.message.direction === "outbound-api"
              ? "white"
              : "black",
          borderRadius:
            props.message.direction === "outbound-api"
              ? "1rem 1rem 0rem 1rem"
              : "1rem 1rem 1rem 0rem",
        }}
      >
        {props.message.message}
      </div>
      <div className={classes["date"]}>
        {convertTime(new Date(props.message.createdAt))}{" "}
        {new Date(props.message.createdAt).getMonth() + 1}/
        {new Date(props.message.createdAt).getDate()}/
        {new Date(props.message.createdAt).getFullYear()}
      </div>
    </div>
  );
};

export default Message;
