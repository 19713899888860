//converts date of birth srting to two digit age

export const dobToAge = (dateString) => {

    const currentDate = new Date().getTime();
    const dateOfBirth = new Date(dateString).getTime();
    const dobToCurrent = currentDate - dateOfBirth;
    const currentAge = dobToCurrent / (1000 * 60 * 60 * 24 * 365);

    return currentAge.toString().split('.')[0];
}