import { createSlice } from "@reduxjs/toolkit";

const twilioSlice = createSlice({
    name: 'twilio',
    initialState: {
        twilioDevice: {},
        callStatus: null,
        callOutcome: null,
        currentCall: '',
        callerId: '',
        activeCallContactId: '',
        //auto dialer state below
        isAnsweredByHuman: false,
        isAnsweredByMachine: false,
        manualDial: true,
        autoDial: false,
        isShowAutoDialNotification: false,
        isAutoDialPaused: false,
        isShowAutoDialNotification: false,
        isAutoDialPaused: false,
        deviceReadyForRegistration: false,
        isBusy: false
    },
    reducers: {
        connectTwilioDevice(state, action) {
            state.twilioDevice = action.payload
        },
        setCallStatus(state, action) {
            state.callStatus = action.payload
        },
        setCurrentCall(state, action) {
            state.currentCall = action.payload;
        },
        setCallerId(state, action) {
            if (action.payload === 'Unknown Caller') {
                state.callerId = action.payload;
            } else {
                const firstName = action.payload.firstName;
                const lastName = action.payload.lastName;
                state.callerId = `${firstName} ${lastName}`
            }

        },
        setContactIdForActiveCall(state, action) {
            state.activeCallContactId = action.payload;
        },
        setMachineStatus(state, action) {
           if (Object.keys(action.payload)[0] === "machineDetected") {
            state.isAnsweredByMachine = action.payload.machineDetected;
           }
           if (Object.keys(action.payload)[0] === "humanDetected") {
            state.isAnsweredByHuman = action.payload.humanDetected;
           }

        },
        callOutcome(state, action) {
            state.callOutcome = action.payload;
        },
        setManualDial(state, action) {
            state.manualDial = action.payload;
        },
        setAutoDial(state, action) {
            state.autoDial = action.payload;
        },
        setIsShowAutoDialNotification(state, action) {
            state.isShowAutoDialNotification = action.payload;
        },
        setIsAutoDialPaused(state, action) {
            state.isAutoDialPaused = action.payload;
        },
        setDeviceReadyForRegistration(state, action) {
            state.deviceReadyForRegistration = action.payload
        },
        setIsBusy(state, action) {
            state.isBusy = action.payload;
        }
    }
});

export const twilioActions = twilioSlice.actions;

export default twilioSlice.reducer;