import { useEffect } from "react";
import Layout from "../../shared/components/Layout/Layout";
import PipelineBox from "../components/PipelineBox";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Pipeline = () => {

  const navigate = useNavigate();

  //update pipeline route if user has pipelines
  const pipelines = useSelector((state) => state.pipelineReducer.pipelines);

  useEffect(() => {
    if (!pipelines.length){
      navigate('add');
      return;
    }

  }, [pipelines])
  
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default Pipeline;
