import classes from "./WeekCell.module.css";
import EventItem from "../../shared/components/Events/EventItem";
import { useDispatch, useSelector } from "react-redux";
import { deleteEvent, updateEvent } from "../../store/event-actions";
import React, { useState, useRef, useEffect } from "react";
import { usePopper } from "react-popper";
import useClickAwayListener from "../../shared/components/hooks/useClickAwayListener/useClickAwayLIstener";
import EventSchedulerPopup from "../../shared/components/Events/EventSchedulerPopup";
import EventItemWeek from "../../shared/components/Events/EventItemWeek";
import { useDrop } from "react-dnd";
import mergeClasses from "classnames";

const WeekCell = (props) => {

  const dispatch = useDispatch();

  const eventSchedulerOpen = useSelector(
    (state) => state.eventReducer.isEventSchedulerPopupOpen
  );

  const [hoveringSameCell, setHoveringSameCell] = useState(false);

  //Schedule event popper
  const [isEventSchedulerOpen, setIsEventSchedulerOpen] = useState(false);
  const [referenceSideBarEventScheduler, setReferenceSideBarEvenScheduler] =
    useState(null);
  const [eventScheduler, setEventScheduler] = useState(null);

  const eventSchedulerPopper = usePopper(
    referenceSideBarEventScheduler,
    eventScheduler,
    {
      placement: "bottom-end",
      modifiers: [
        { name: "offset", options: { offset: [0, 5] } },
        {
          name: "preventOverflow",
          options: {
            altAxis: true,
            padding: 10
          }
        }
      ]
    }
  );

  const doubleClickToggleEventScheduler = (e) => {
    if (eventSchedulerOpen || isEventSchedulerOpen) {
      return;
    }
    if (e.detail === 2) {
      setIsEventSchedulerOpen((prevState) => !prevState);
    }
  };

  const closeEventHandler = () => {
    setIsEventSchedulerOpen((prevState) => !prevState);
  };

  const eventSchedulerClickAwayRef = useRef(null);
  useClickAwayListener(
    eventSchedulerClickAwayRef,
    closeEventHandler,
    referenceSideBarEventScheduler
  );

  const cellHeight = (eventData) => {
    const startTime = eventData.eventStarts;
    const startTimeHours = new Date(startTime).getHours() * 60;
    const startTimeMins = new Date(startTime).getMinutes();

    const endTime = eventData.eventEnds;
    const endTimeHours = new Date(endTime).getHours() * 60;
    const endTimeMins = new Date(endTime).getMinutes();

    const updatedStartTime = (startTimeHours + startTimeMins) / 60;
    const updatedEndTime = (endTimeHours + endTimeMins) / 60;

    const height = `${(updatedEndTime - updatedStartTime) * 3}rem`;

    return height;
  };

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: "events",
      hover: (item, monitor) => {
        const eventStartsDate = new Date(item.event.eventStarts).getDate();
        const eventCellIndex = item.cellIndex;

        const hoveredCellDate = props.day;
        const hoveredCellIndex = props.index;

        //if hovering same cell index set same day to true to avoid animation
        if (
          eventStartsDate === hoveredCellDate &&
          eventCellIndex === hoveredCellIndex
        ) {
          setHoveringSameCell(true);
          return;
        }
      },
      drop: (item, monitor) => {
        //update the dragged events day/month/year to the dropped day/month/year

        //set consts from dragged event
        const event = item.event;
        const eventStartTime = event.eventStarts;
        const eventEndTime = event.eventEnds;

        let updatedStartDate = new Date(eventStartTime).setFullYear(
          props.currentYear,
          props.currentMonth,
          props.day
        );

        let updatedEndDate = new Date(eventEndTime).setFullYear(
          props.currentYear,
          props.currentMonth,
          props.day
        );

        if (props.prevMonthDay) {
          updatedStartDate = new Date(updatedStartDate).setFullYear(props.currentYear, props.currentMonth - 1)
          updatedEndDate = new Date(updatedStartDate).setFullYear(props.currentYear, props.currentMonth - 1)
        }

        if (props.nextMonthDay) {
          updatedStartDate = new Date(updatedStartDate).setFullYear(props.currentYear, props.currentMonth + 1)
          updatedEndDate = new Date(updatedStartDate).setFullYear(props.currentYear, props.currentMonth + 1)
        }


        //update start by dropped cell index, add start/end time difference to set end date time
        const startEndTimeDiff = new Date(eventEndTime).getTime() - new Date(eventStartTime).getTime()
        updatedStartDate = new Date(updatedStartDate).setHours(props.index, new Date(eventStartTime).getMinutes())
        updatedEndDate = new Date(updatedStartDate).getTime() + startEndTimeDiff

        
        const updatedEvent = {
          ...event,
          eventStarts: new Date(updatedStartDate).toISOString(),
          eventEnds: new Date(updatedEndDate).toISOString()
        };

        //dispatch to store
        dispatch(updateEvent(updatedEvent));
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver()
      })
    }),
    [props]
  );

  //resets hovering same day to false
  useEffect(() => {
    if (isOver) {
      setHoveringSameCell(false);
    }
  }, [isOver]);

  return (
    <div ref={drop}>
      <div
        className={mergeClasses(
          classes["week-cell"],
          props.prevMonthDay || props.nextMonthDay ? classes["disabled"] : "",
          isOver && !hoveringSameCell ? classes.isOver : ""
        )}
        onClick={doubleClickToggleEventScheduler}
        ref={setReferenceSideBarEvenScheduler}
      >
        {props.events
          ? props.events.map((event) => (
            <EventItemWeek
              key={event.id}
              event={event}
              cellHeight={cellHeight(event)}
              events={props.events}
              sameDayEvents={props.sameDayEvents}
              cellIndex={props.index}
            />
          ))
          : ""}
        {isEventSchedulerOpen && (
          <div ref={eventSchedulerClickAwayRef}>
            <div
              ref={setEventScheduler}
              className={classes.popper}
              style={eventSchedulerPopper.styles.popper}
              {...eventSchedulerPopper.attributes.popper}
            >
              <EventSchedulerPopup
                closePopper={closeEventHandler}
                // eventType="general"
                currentYear={props.currentYear}
                currentMonth={props.currentMonth}
                day={props.day}
                prevDay={props.prevMonthDay}
                nextDay={props.nextMonthDay}
                preselectedStartTime={props.index}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WeekCell;
