import React, { useState, useEffect } from "react";
import PlusIcon from "../../shared/components/UIElements/PlusIcon/PlusIcon";
import { useDispatch, useSelector } from "react-redux";
import { createPipeline } from "../../store/pipeline-actions";
import Input from "../../shared/components/UIElements/Input/Input";
import classes from "./AddPipeline.module.css";
import SpinnerButton from "../../shared/components/UIElements/SpinnerButton/SpinnerButton";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { pipelineActions } from "../../store/pipeline";
const KEY = "last-opened-pipeline-localstorage";

const AddPipeline = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const pipelines = useSelector((state) => state.pipelineReducer.pipelines);
  const newPipelineId = useSelector((state) => state.pipelineReducer.addedNewPipelineId);
  const isLoading = useSelector(state => state.pipelineReducer.isLoading);

  let lastOpenedPipelineId = localStorage.getItem(KEY);

  const [newPipelineName, setNewPipelineName] = useState("");

  //sets new pipeline name with user inputs
  const pipelineNameInputHandler = (e) => {
    setNewPipelineName(e.target.value);
  };

  //cancel add pipeline
  const cancelAddPipelineHandler = () => {
    if (!location.state) {
      navigate(-1);
    } else {
      navigate(-1);
    }
  };

  //creates new pipeline with dispatch to action creator
  const addPipelineHandler = () => {
    if (newPipelineName === "") {
      return;
    }

    dispatch(createPipeline({ name: newPipelineName }));
    setNewPipelineName("");
  }

  useEffect(() => {
    if (newPipelineId) {
      navigate(`/app/pipeline/${newPipelineId}`)
      dispatch(pipelineActions.setAddedNewPipelineId());
    }
  }, [newPipelineId])


  return (
    <div className={classes.addPipeline}>
      <Input
        label={!pipelines.length ? "Create a Pipeline" : "Name this Pipeline"}
        value={newPipelineName}
        onChange={pipelineNameInputHandler}
        autoFocus
      />
      <div className={classes.actions}>
        <SpinnerButton color="button--blue" onClick={addPipelineHandler} isLoading={isLoading}>
          Add Pipeline
        </SpinnerButton>
        <SpinnerButton
          color="button--white"
          onClick={cancelAddPipelineHandler}
        >
          Cancel
        </SpinnerButton>
      </div>
    </div>
  );
};

export default AddPipeline;
