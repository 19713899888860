import React, { useRef, useState } from "react";
import classes from "./FieldMapItem.module.css";
import { ReactComponent as EditIcon } from "../../../assets/imgs/edit-icon.svg";
import { usePopper } from "react-popper";
import SpinnerButton from "../UIElements/SpinnerButton/SpinnerButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { CiEdit } from "react-icons/ci";

const FieldMapItem = ({
  item,
  currentIndex,
  updateKeyName,
  matched,
  defaultKeys
}) => {
  //match field popper
  const [reference, setReference] = useState();
  const [popper, setPopper] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const { styles, attributes } = usePopper(reference, popper, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 0]
        }
      }
    ]
  });

  const selectRef = useRef();

  const cancelHandler = (event) => {
    event.preventDefault();
    setIsOpen((prevState) => !prevState);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    const mismatchedKeyValue = item[0];
    const matchedKeyValue = selectRef.current.value;
    updateKeyName(mismatchedKeyValue, matchedKeyValue);
    setIsOpen(false);
  };

  let content;

  if (matched) {
    content = (
      <>
        <div
          className={`${classes["item"]}`}
          style={{
            transform: `translate(-${currentIndex * 400}%)`,
            zIndex: isOpen ? "999" : ""
          }}
        >
          <div className={`${classes["properties"]}`}>
            <div
              className={`${classes["edit"]}`}
              ref={setReference}
              onClick={() => {
                setIsOpen((prevState) => !prevState);
              }}
            >
              <CiEdit size="25px" className={classes["edit-icon"]} />
            </div>
            <div className={`${classes["key"]}`}>{item[0]}</div>
            <div>
              {!item[1] ? (
                ""
              ) : (
                <input
                  type="checkbox"
                  className={classes["checkbox"]}
                  checked
                />
              )}
            </div>
          </div>
          <div className={`${classes["value"]}`}>{item[1]}</div>
        </div>
        {isOpen && (
          <div
            ref={setPopper}
            style={styles.popper}
            {...attributes.popper}
            className={classes["popper"]}
          >
            <div className={classes["title"]}>
              Select a field for this column
            </div>
            <div className={classes["menu_seperator"]}></div>
            <form className={classes["form"]} onSubmit={submitHandler}>
              <div className={classes["form__control"]}>
                <select ref={selectRef}>
                  {defaultKeys.map((option, index) => {
                    const splitCamelCase = option.split(/(?=[A-Z])/);
                    const firstLetterUpperCase = splitCamelCase.map((word) => {
                      return word.charAt(0).toUpperCase() + word.slice(1);
                    });
                    const titleCase = firstLetterUpperCase.join(" ");
                    return (
                      <>
                        <option selected disabled hidden>
                          Select field:
                        </option>
                        <option key={index} value={option}>
                          {titleCase}
                        </option>
                      </>
                    );
                  })}
                </select>
              </div>
              <div className={classes["actions"]}>
                <SpinnerButton
                  color="button--white"
                  size="button--small"
                  onClick={cancelHandler}
                >
                  Cancel
                </SpinnerButton>
                <SpinnerButton color="button--blue" size="button--small">
                  Confirm
                </SpinnerButton>
              </div>
            </form>
          </div>
        )}
      </>
    );
  } else {
    content = (
      <>
        <div
          className={`${classes["item"]} ${classes["caution"]}`}
          style={{
            transform: `translate(-${currentIndex * 400}%)`,
            zIndex: isOpen ? "999" : ""
          }}
        >
          <div className={`${classes["properties"]} ${classes["caution"]}`}>
            <div
              className={`${classes["edit"]} ${classes["caution"]}`}
              ref={setReference}
              onClick={() => {
                setIsOpen((prevState) => !prevState);
              }}
            >
              <CiEdit size="25px" className={classes["edit-icon"]} />
            </div>
            <div className={`${classes["key"]}`}>{item[0]}</div>
            <div>
              {/* add help tip for mismatched field */}
              <FontAwesomeIcon icon={faCircleExclamation} />
            </div>
          </div>
          <div className={`${classes["value"]}`}>{item[1]}</div>
        </div>
        {isOpen && (
          <div
            ref={setPopper}
            style={styles.popper}
            {...attributes.popper}
            className={classes["popper"]}
          >
            <div className={classes["title"]}>
              Select a field for this column
            </div>
            <div className={classes["menu_seperator"]}></div>
            <form className={classes["form"]} onSubmit={submitHandler}>
              <div className={classes["form__control"]}>
                <select ref={selectRef}>
                  {defaultKeys.map((option, index) => {
                    const splitCamelCase = option.split(/(?=[A-Z])/);
                    const firstLetterUpperCase = splitCamelCase.map((word) => {
                      return word.charAt(0).toUpperCase() + word.slice(1);
                    });
                    const titleCase = firstLetterUpperCase.join(" ");
                    return (
                      <>
                        <option selected disabled hidden>
                          Select field:
                        </option>
                        <option key={index} value={option}>
                          {titleCase}
                        </option>
                      </>
                    );
                  })}
                </select>
              </div>
              <div className={classes["actions"]}>
                <SpinnerButton
                  color="button--white"
                  size="button--small"
                  onClick={cancelHandler}
                >
                  Cancel
                </SpinnerButton>
                <SpinnerButton color="button--blue" size="button--small">
                  Confirm
                </SpinnerButton>
              </div>
            </form>
          </div>
        )}
      </>
    );
  }

  return <>{content}</>;
};

export default FieldMapItem;
