import { createSlice } from "@reduxjs/toolkit";


const eventSlice = createSlice({
    name: "event",
    initialState: {
        events: [],
        isEventSchedulerPopupOpen: false,
        isEventLoading: false
    },
    reducers: {
        replaceEventData(state, action) {
            const events = [];
            action.payload.events.forEach(event => events.push(event));
            state.events = events;
        },
        addEvent(state, action) {
            state.events.push(action.payload)
        },
        updateEvent(state, action) {
            const currentEvent = state.events.find(event => event.id === action.payload.id);
            currentEvent.firstName = action.payload.firstName;
            currentEvent.lastName = action.payload.lastName;
            currentEvent.eventName = action.payload.eventName;
            currentEvent.eventType = action.payload.eventType;
            currentEvent.eventStarts = action.payload.eventStarts;
            currentEvent.eventEnds = action.payload.eventEnds;
            currentEvent.eventNote = action.payload.eventNote;
            currentEvent.eventInvitees = action.payload.eventInvitees;
            currentEvent.remindMe = action.payload.remindMe;
            currentEvent.remindContact = action.payload.remindContact;
            currentEvent.reminderNote = action.payload.reminderNote;
            currentEvent.creator = action.payload.creator;
            currentEvent.belongsTo = action.payload.belongsTo;
        },
        deleteEvent(state, action) {
            state.events = state.events.filter((event) => event.id !== action.payload);
        },
        isEventSchedulerPopupOpenHandler(state, action) {
            state.isEventSchedulerPopupOpen = action.payload;
        },
        setIsEventLoading(state, action) {
            state.isEventLoading = action.payload;
        }
    }
});

export const eventActions = eventSlice.actions;

export default eventSlice.reducer;