import { useEffect } from "react";
import classes from "./ProgressBar.module.css";

const ProgressBar = (props) => {
  return (
    <div className={classes["progress-bar-container"]} style={{ height: props.height }}>
      <div
        className={classes["progress-bar__filler"]}
        style={{ width: props.progress + "%", backgroundColor: props.color}}
      >
        <span className={classes["progress-bar__label"]}>
          {props.noText ? "" : props.progress + "%"}
        </span>
      </div>
    </div>
  );
};

export default ProgressBar;
