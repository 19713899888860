import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import mergeClasses from "classnames";
import { BsCheck2 } from "react-icons/bs";
import { IoIosClose } from "react-icons/io";
import { CiEdit } from "react-icons/ci";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";
import { updateContact } from "../../../../store/contact-actions";
import PlusIcon from "../../UIElements/PlusIcon/PlusIcon";
import classes from "./ContactsDetails.module.css";

const ContactsDetails = ({ contact }) => {
  const dispatch = useDispatch();

  //contact details

  const [streetNumber, setStreetNumber] = useState("");
  const [editStreetNumberIsOpen, setEditStreetNumberIsOpen] = useState(false);
  const [editCityStateZipIsOpen, setEditCityStateZipIsOpen] = useState(false);

  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [editPhoneNumberIsOpen, setEditPhoneNumberIsOpen] = useState(false);

  const [emailAddress, setEmailAddress] = useState("");
  const [editEmailAddressIsOpen, setEditEmailAddressIsOpen] = useState(false);

  const [inputHasErrors, setInputHasErrors] = useState();

  //update street number
  const editStreetNumberIsOpenHandler = () => {
    if (contact.streetNumber) {
      setStreetNumber(contact.streetNumber);
    }
    setEditStreetNumberIsOpen((prevState) => !prevState);
  };

  const streetInputHandler = (event) => {
    setStreetNumber(event.target.value);
  };

  const updateStreetNumberHandler = () => {
    dispatch(
      updateContact({
        ...contact,
        streetNumber: streetNumber
      })
    );
    setEditStreetNumberIsOpen((prevState) => !prevState);
    setStreetNumber("");
  };

  //update city, state and zip code
  const editCityStateZipIsOpenHandler = () => {
    if (contact.cityName) {
      setCity(contact.cityName);
    }
    if (contact.stateName) {
      setState(contact.stateName);
    }
    if (contact.zipCode) {
      setZip(contact.zipCode);
    }
    setEditCityStateZipIsOpen((prevState) => !prevState);
  };

  const cityInputHandler = (event) => {
    setCity(event.target.value);
  };

  const stateInputHandler = (event) => {
    setState(event.target.value);
  };

  const zipInputHandler = (event) => {
    setZip(event.target.value);
  };

  const updateCityStateZipHandler = () => {
    dispatch(
      updateContact({
        ...contact,
        cityName: city,
        stateName: state,
        zipCode: zip
      })
    );
    setEditCityStateZipIsOpen((prevState) => !prevState);
    setCity("");
    setState("");
    setZip("");
  };

  //update phone number
  const editPhoneNumberIsOpenHandler = () => {
    if (contact.phone) {
      setPhoneNumber(contact.phone);
    }
    setEditPhoneNumberIsOpen((prevState) => !prevState);
  };

  const phoneNumberInputHandler = (event) => {
    setPhoneNumber(event.target.value);
    if (
      event.target.value.trim() !== "" &&
        event.target.value.replace(/[^0-9]+/g, "")[0] === "1"
        ? event.target.value.replace(/[^0-9]+/g, "").length === 11
        : event.target.value.replace(/[^0-9]+/g, "").length === 10 &&
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
          event.target.value.trim()
        )
    ) {
      setInputHasErrors(false);
    } else {
      setInputHasErrors(true);
    }
  };

  const updatePhoneNumberHandler = () => {
    if (inputHasErrors) {
      return;
    }
    dispatch(
      updateContact({
        ...contact,
        phone: phoneNumber
      })
    );
    setEditPhoneNumberIsOpen((prevState) => !prevState);
    setPhoneNumber("");
  };

  //update email address
  const editEmailAddressIsOpenHandler = () => {
    if (contact.email) {
      setEmailAddress(contact.email);
    }
    setEditEmailAddressIsOpen((prevState) => !prevState);
  };

  const emailAddressInputHandler = (event) => {
    setEmailAddress(event.target.value);
  };

  const updateEmailAddressHandler = () => {
    dispatch(
      updateContact({
        ...contact,
        email: emailAddress
      })
    );
    setEditEmailAddressIsOpen((prevState) => !prevState);
    setEmailAddress("");
  };

  useEffect(() => {
    setEditStreetNumberIsOpen(false);
    setEditCityStateZipIsOpen(false);
    setStreetNumber("");
    setCity("");
    setState("");
    setZip("");
    setPhoneNumber("");
    setEmailAddress("");
  }, [contact]);

  return (
    <div className={classes.contactsDetails}>
      <div className={classes.fullName}>
        {contact?.spouseName
          ? contact.firstName +
          " & " +
          contact?.spouseName +
          " " +
          contact.lastName
          : contact.firstName + " " + contact.lastName}
      </div>
      <div className={classes.address}>
        <div>
          <ul>
            {/*----------------- street number ------------------ */}
            <li>
              <div
                className={mergeClasses(
                  classes.streetNumber,
                  inputHasErrors && classes.invalid
                )}
              >
                {/*render street number*/}
                {contact.streetNumber && !editStreetNumberIsOpen && (
                  <>
                    {contact.streetNumber}
                    <div className={classes.edit}>
                      <CiEdit
                        size="20px"
                        onClick={editStreetNumberIsOpenHandler}
                      />
                    </div>
                  </>
                )}
                {/*edit street number*/}
                {editStreetNumberIsOpen && (
                  <>
                    <input
                      autoFocus
                      value={streetNumber}
                      onChange={streetInputHandler}
                      placeholder="Street number"
                    />
                    <BsCheck2
                      size="22px"
                      className={classes.save}
                      onClick={updateStreetNumberHandler}
                    />
                    <IoIosClose
                      size="29px"
                      className={classes.cancel}
                      onClick={editStreetNumberIsOpenHandler}
                    />
                  </>
                )}
                {/*add street number*/}
                {!contact.streetNumber && !editStreetNumberIsOpen && (
                  <>
                    <div>Street number</div>
                    <div onClick={editStreetNumberIsOpenHandler}>
                      <PlusIcon />
                    </div>
                  </>
                )}
              </div>
            </li>
            {/*----------------- city, state, and zip ------------------ */}
            <li>
              <div
                className={mergeClasses(
                  classes.cityStateZip,
                  inputHasErrors && classes.invalid
                )}
              >
                {/*render city, state, and zip*/}
                {contact.cityName && !editCityStateZipIsOpen && (
                  <>
                    {contact.cityName +
                      ", " +
                      contact.stateName +
                      " " +
                      contact.zipCode}
                    <div className={classes.edit}>
                      <CiEdit
                        size="20px"
                        onClick={editCityStateZipIsOpenHandler}
                      />
                    </div>
                  </>
                )}
                {/*edit city, state, and zip*/}
                {editCityStateZipIsOpen && (
                  <>
                    <input
                      autoFocus
                      value={city}
                      style={{ width: "5rem" }}
                      onChange={cityInputHandler}
                      placeholder="City"
                    />
                    ,
                    <input
                      value={state}
                      style={{ width: "3rem" }}
                      onChange={stateInputHandler}
                      placeholder="State"
                    />
                    <input
                      value={zip}
                      style={{ width: "3rem" }}
                      onChange={zipInputHandler}
                      placeholder="Zip"
                    />
                    <BsCheck2
                      size="17px"
                      className={classes["save"]}
                      onClick={updateCityStateZipHandler}
                    />
                    <IoIosClose
                      size="24px"
                      className={classes["cancel"]}
                      onClick={editCityStateZipIsOpenHandler}
                    />
                  </>
                )}
                {/*add city, state, and zip*/}
                {!contact.cityName && !editCityStateZipIsOpen && (
                  <>
                    <div>City, State, Zip</div>
                    <div onClick={editCityStateZipIsOpenHandler}>
                      <PlusIcon />
                    </div>
                  </>
                )}
              </div>
            </li>
            {/*----------------- phone number ------------------ */}
            <li>
              <div
                className={mergeClasses(
                  classes.phoneNumber,
                  inputHasErrors && classes.invalid
                )}
              >
                {/*render phone number*/}
                {contact.phone && !editPhoneNumberIsOpen && (
                  <>
                    {formatPhoneNumber(contact.phone)}
                    <div className={classes.edit}>
                      <CiEdit
                        size="20px"
                        onClick={editPhoneNumberIsOpenHandler}
                      />
                    </div>
                  </>
                )}
                {/*edit phone number*/}
                {editPhoneNumberIsOpen && (
                  <>
                    <input
                      autoFocus
                      value={phoneNumber}
                      style={{ width: "6rem" }}
                      onChange={phoneNumberInputHandler}
                      placeholder="Phone number"
                    />
                    <BsCheck2
                      size="17px"
                      className={classes.save}
                      onClick={updatePhoneNumberHandler}
                    />
                    <IoIosClose
                      size="24px"
                      className={classes.cancel}
                      onClick={editPhoneNumberIsOpenHandler}
                    />
                    {inputHasErrors && (
                      <div className={classes.error}>
                        Must be exactly 10 numbers
                      </div>
                    )}
                  </>
                )}
                {/*add phone number*/}
                {!contact.phone && !editPhoneNumberIsOpen && (
                  <>
                    <div>Phone number</div>
                    <div onClick={editPhoneNumberIsOpenHandler}>
                      <PlusIcon />
                    </div>
                  </>
                )}
              </div>
            </li>
            {/*----------------- email address ------------------ */}
            <li>
              <div
                className={mergeClasses(
                  classes.emailAddress,
                  inputHasErrors && classes.invalid
                )}
              >
                {/*render email number*/}
                {contact.email && !editEmailAddressIsOpen && (
                  <>
                    {contact.email}
                    <div className={classes.edit}>
                      <CiEdit
                        size="20px"
                        onClick={editEmailAddressIsOpenHandler}
                      />
                    </div>
                  </>
                )}
                {/*edit email address*/}
                {editEmailAddressIsOpen && (
                  <>
                    <input
                      autoFocus
                      value={emailAddress}
                      onChange={emailAddressInputHandler}
                      placeholder="Email address"
                    />
                    <BsCheck2
                      size="22px"
                      className={classes.save}
                      onClick={updateEmailAddressHandler}
                    />
                    <IoIosClose
                      size="29px"
                      className={classes.cancel}
                      onClick={editEmailAddressIsOpenHandler}
                    />
                  </>
                )}
                {/*add email address*/}
                {!contact.email && !editEmailAddressIsOpen && (
                  <>
                    <div>Email address</div>
                    <div onClick={editEmailAddressIsOpenHandler}>
                      <PlusIcon />
                    </div>
                  </>
                )}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ContactsDetails;
