import { createSlice, current } from "@reduxjs/toolkit";

const initialContactState = {
  contacts: [],
  filteredContacts: [],
  addedContact: null,
  updatedContacts: false,
  isLoading: false,
  uploadProgress: 0,
  serverImportComplete: false
};
const contactSlice = createSlice({
  name: "contacts",
  initialState: initialContactState,
  reducers: {
    replaceContactData(state, action) {

      let contacts = action.payload.contacts;

      //offset timezone for dates of birth 
      const offsetDobTimezone = ((dob) => {
        const dobString = new Date(dob);
        const offset = new Date(dobString).getTimezoneOffset() / 60;
        const addOffset = dobString.getHours() + offset;
        const update = new Date(dobString.setHours(addOffset)).toString();
        return update;
      })

      //update contact and spouse's date of birth to offset timezone
      const updateContactDob = contacts.map(contact => {
        if (!contact.dateOfBirth) {
          return contact;
        }
        contact['dateOfBirth'] = offsetDobTimezone(contact.dateOfBirth);
        contact['spouseDateOfBirth'] = offsetDobTimezone(contact.dateOfBirth);
        return contact;
      })

      state.contacts = updateContactDob;

    },
    replaceFilteredContacts(state, action) {
      state.filteredContacts = action.payload;
    },
    addContact(state, action) {
      // state.addedContact = true;
      const {
        firstName,
        lastName,
        spouseName,
        email,
        phone,
        status,
        streetNumber,
        cityName,
        stateName,
        zipCode,
        creator,
        source,
        recent,
        eventType,
        attendedEvent,
        eventLocation,
        contactType,
        client,
        dealHeat,
        notes
      } = action.payload;

      state.contacts.push({
        id: action.payload.id,
        firstName,
        lastName,
        spouseName,
        email,
        phone,
        streetNumber,
        cityName,
        stateName,
        zipCode,
        creator,
        status,
        source,
        recent,
        eventType,
        attendedEvent,
        eventLocation,
        contactType,
        client,
        dealHeat,
        notes
      });
      state.addedContact = action.payload.id;
    },
    setAddedContact(state, action) {
      state.addedContact = action.payload;
    },
    importContacts(state, action) {
      state.contacts.push(...action.payload)
    },
    updateContact(state, action) {
      const currentContact = state.contacts.find(contact => contact.id === action.payload.id);
      currentContact.firstName = action.payload.firstName;
      currentContact.spouseName = action.payload.spouseName;
      currentContact.lastName = action.payload.lastName;
      currentContact.email = action.payload.email;
      currentContact.phone = action.payload.phone;
      currentContact.secondaryPhone = action.payload.secondaryPhone;
      currentContact.streetNumber = action.payload.streetNumber;
      currentContact.cityName = action.payload.cityName;
      currentContact.stateName = action.payload.stateName;
      currentContact.zipCode = action.payload.zipCode;
      currentContact.dateOfBirth = action.payload.dateOfBirth;
      currentContact.spouseDateOfBirth = action.payload.spouseDateOfBirth;
      currentContact.assignedTo = action.payload.assignTo;
      currentContact.contactType = action.payload.contactType;
      currentContact.campaignType = action.payload.campaignType;
      currentContact.status = action.payload.status;
      currentContact.source = action.payload.source;
      currentContact.dealHeat = action.payload.dealHeat;
      currentContact.referralSource = action.payload.referralSource;
      currentContact.recent = action.payload.recent;
      currentContact.eventType = action.payload.eventType;
      currentContact.attendedEvent = action.payload.attendedEvent;
      currentContact.eventVenue = action.payload.eventVenue;
      currentContact.eventLocation = action.payload.eventLocation;
      currentContact.eventTopic = action.payload.eventTopic;
      currentContact.creator = action.payload.creator;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setUploadProgress(state, action) {
      state.uploadProgress = action.payload
    },
    serverSideImportComplete(state, action) {
      state.serverImportComplete = action.payload;
    }
  },
});

export const contactActions = contactSlice.actions;

export default contactSlice.reducer;
