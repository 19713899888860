import classes from "./Calls.module.css";
import Call from "./Call";

const Calls = ({ missedCalls, setReviewedMissedCall }) => {
  let content;

  if (missedCalls.length > 0) {
    content = missedCalls.map((call) => {
      return (
        <Call
          missedCall={call}
          setReviewedMissedCall={setReviewedMissedCall}
        />
      );
    });
  } else {
    content = (
      <div className={classes["no-messages"]}>
        You have no missed calls...
      </div>
    );
  }

  return <div className={classes["calls"]}>{content}</div>;
};

export default Calls;
