import { useSelector } from "react-redux";
import classes from "./UserBadge.module.css";
import mergeClasses from "classnames";

const UserBadge = (props) => {

  const color = useSelector(state => state.userReducer.user.userSettings.badge)
  
  let firstInitial;

  if (props.children) {
    if (props.children.split(" ")[0]) {
      firstInitial = props.children.split(" ")[0].charAt(0);
    }
  }

  let lastInitial;
  if (props.children) {
    if (props.children.split(" ")[1]) {
      lastInitial = props.children.split(" ")[1].charAt(0);
    }
  }
  return (
    <div
      className={mergeClasses(
        classes["user-badge"],
        props.size ? classes[props.size] : classes["default"]
      )}
      style={{backgroundColor: color ? color : ''}}
      onClick={props.onClick}
    >
      <span
        className={mergeClasses(
          classes["firstInitial"],
          props.size ? classes[props.size] : classes["default"]
        )}
      >
        {firstInitial}
      </span>
    </div>
  );
};

export default UserBadge;
