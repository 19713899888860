import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectAll: false,
    isChecked: []
}

const CheckboxSlice = createSlice({
    name: 'checkbox', 
    initialState: initialState,
    reducers: {
        selectAllContacts(state, action) {
            const { contacts } = action.payload;
            state.selectAll = !state.selectAll;
            state.isChecked = contacts;
            if (!state.selectAll) {
                state.isChecked = [];
            }
        },
        selectContact(state, action) {
            const { checked, id } = action.payload;
            const parsedId = Number(id);
            state.isChecked.push(parsedId)
            if (!checked){
                state.isChecked = (state.isChecked.filter(contact => contact !== parsedId))
            }
        }


    }
});

export const checkboxActions = CheckboxSlice.actions;

export default CheckboxSlice.reducer;