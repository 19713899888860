import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchMessages,
  abortController
} from "../../../store/twilio-actions";
import Messages from "./Messages";
import classes from "./MessagesBox.module.css";
import { updateMessage } from "../../../store/twilio-actions";
import { messageActions } from "../../../store/message";
import MessageForm from "./MessageForm";

const MessagesBox = () => {
  const dispatch = useDispatch();

  const params = useParams();
  const contactId = params.contactId;
  const contact = useSelector((state) => state.contactReducer.contacts).find(
    (contact) => contact.id === contactId
  );
  const creator = useSelector((state) => state.userReducer.user.userId);

  //state for store and contact messages
  const localMessages = useSelector((state) => state.messageReducer.messages);
  const contactsLocalMessages = localMessages.filter(
    (contact) => contact.belongsTo === contactId
  );
  const recentMessages = useSelector(
    (state) => state.messageReducer.recentMessages
  );
  const contactsRecentMesssages = recentMessages.find(
    (contact) => contact.belongsTo === contactId
  );

  //fetch if messages aren't available in store or if a new message is received
  useEffect(() => {
    if (contactsLocalMessages.length === 0 || contactsRecentMesssages) {
      dispatch(fetchMessages(contactId));
    }

    return () => {
      //abort fetch request if modal is closed
      dispatch(abortController());
    };
  }, []);

  useEffect(() => {
    //clears message popup notification
    if (contactsRecentMesssages) {
      dispatch(messageActions.updateRecentMessages(contactsRecentMesssages));
    }

    //sets all unread messages to read (true)
    if (contactsLocalMessages.some((message) => message.read === false)) {
      //filter for unread messages
      const unread = contactsLocalMessages.filter((message) => {
        return message.read === false;
      });

      //dispatches read messages
      unread.forEach((message) => {
        const updatedMessage = {
          ...message,
          read: true
        };
        dispatch(updateMessage(updatedMessage));
      });
    }
  }, [localMessages]);

  return (
    <>
      <Messages contactId={contactId} />
      <MessageForm contact={contact}/>
    </>
  );
};

export default MessagesBox;
