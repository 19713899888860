import { twilioActions } from "./twilio";
import { Device } from "@twilio/voice-sdk";
import { uiActions } from "./ui.js";
import { messageActions } from "./message";
import { voiceMessageActions } from "./voiceMessage";
import { callActions } from "./call";
import store from "./index";
import axios from "axios";
import { notificationActions } from "./notifications";

let ABORT_FETCH_CONTROLLER = null;

export const abortController = () => {
  return async (dispatch) => {
    ABORT_FETCH_CONTROLLER.abort();
  };
};

export const connectTwilioDevice = () => {
  return async (dispatch) => {
    try {
      let authToken = store.getState().userReducer.token;
      let userId = store.getState().userReducer.user.userId;
      const ttl = 3600000;
      const refreshBuffer = 30000;

      const createToken = async (authToken) => {
        //create Twilio voice token
        console.log("fetching device token...");

        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/twilio/token`,
          {
            headers: {
              Authorization: "Bearer " + authToken,
              userId: userId,
            },
          }
        );

        if (!response.ok) {
          throw new Error("failed to create token");
        }
        const data = await response.json();

        return data.token;
      };

      const token = await createToken(authToken);

      //initilize twilio device with token
      console.log("intialzing device with token...");

      let device;

      device = new Device(token, {
        codecPreferences: ["opus", "pcmu"],
        allowIncomingWhileBusy: true,
      });
      
      //mute outgoing call tone
      device.audio.outgoing(false)

      // console.log(device.audio.availableInputDevices)

      console.log("device ready for registration...");

      //renew device token every 1 hour
      setInterval(async () => {
        const newToken = await createToken(authToken);
        device.updateToken(newToken);
      }, ttl - refreshBuffer);

      //Dispatch twilio device to store
      dispatch(twilioActions.connectTwilioDevice(device));

      dispatch(twilioActions.setDeviceReadyForRegistration(true));

    } catch (error) {
      console.log(error);
    }
  };
};

export const toggleDeviceOnOffIncomingCalls = (isOnline) => {
  return async (dispatch) => {
    const userPhoneNumber = store.getState().userReducer.user.userSettings.phone?.number;

    if (!userPhoneNumber) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error",
          message: "You must setup a phone number before going online to accept calls."
        })
      );
      return;
    }

    let device = store.getState().twilioReducer.twilioDevice;
    //check if device has loaded to avoid crash
    if (JSON.stringify(device) === '{}') return;
    if (isOnline) {
      device.unregister()
      console.log("unregister");
      device.on("unregistered", () => {
        console.log("device unregistered and will not accept calls...");
      });

    } else {
      console.log("register");
      device.register();
      device.on("registered", () => {
        console.log("device ready and dispatched...");
      });

      device.on("error", (twilioError, call) => {
        if (twilioError.code === 31402) {
          dispatch(
            uiActions.showNotification({
              status: "error",
              title: "Error",
              message: "Microphone not detected.",
            })
          );
        }
        if (twilioError.code === 31401) {
          dispatch(
            uiActions.showNotification({
              status: "error",
              title: "Error",
              message: "You have denied Dots' access to your microphone.",
            })
          );
        }
      });

      //Executes when inbound call is received
      device.on("incoming", (call) => {
        //Dispatch connection to ui reducer for call notification
        dispatch(uiActions.setIncomingCallNotification(true));

        //Dispatch connection to twilio reducer for connecting in app
        dispatch(twilioActions.setCurrentCall(call));

        //Set caller ID if incoming phone matches contact
        //Get inbound phone number from connection object
        const phoneNumber = call.parameters.From;

        //Remove special charactes and white space
        const parsedNumber = phoneNumber.replace(/[^\d]/g, "");

        //Import contacts from contact reducer
        const contacts = store.getState().contactReducer.contacts;

        //Match contact number with inbound nmber
        const matchingContact = contacts.find((contact) => {
          return contact.phone === parsedNumber;
        });

        //Set caller Id to contact name or unknown
        if (matchingContact) {
          dispatch(twilioActions.setCallerId(matchingContact));
        } else {
          dispatch(twilioActions.setCallerId("Unknown Caller"));
        }

        call.on("accept", () => {
          console.log("Agent accepted call.");
          //Set line busy if agent answers
          dispatch(twilioActions.setIsBusy(true))
        });

        call.on("cancel", () => {
          dispatch(twilioActions.setCurrentCall());
          dispatch(twilioActions.setCallerId(""));
          dispatch(uiActions.setIncomingCallNotification(false));
          console.log("Call has been canceled.");
        });

        call.on("disconnect", (call) => {
          dispatch(twilioActions.setCurrentCall());
          dispatch(twilioActions.setCallerId(""));
          dispatch(uiActions.setIncomingCallNotification(false));
          console.log("Call disconnected by user.");
          //Set line open when call is disconnected
          dispatch(twilioActions.setIsBusy(false))
        });
      });
    }
  };
};

export const makeOutboundCall = (phoneNumber, contactId) => {
  return async (dispatch) => {
    const device = store.getState().twilioReducer.twilioDevice;
    const userPhoneNumber = store.getState().userReducer.user.userSettings.phone.number;

    if (!userPhoneNumber) {
      dispatch(
        notificationActions.setToastNotification({
          status: "error",
          title: "Error",
          message: "You must setup a phone number before making calls. You can set one up by clicking",
          link: "/app/call-center/settings/phone"
        })
      );
      return;
    }

    if (!phoneNumber) {
      dispatch(
        notificationActions.setToastNotification({
          status: "error",
          title: "Error",
          message:
            "The contact does not have a phone number, please update contact or move to the next one."
        })
      );
      return;
    }

    const call = await device.connect({
      params: {
        To: phoneNumber,
        callerID: userPhoneNumber,
      }
    });

    dispatch(twilioActions.setCurrentCall(call));

    if (contactId) {
      dispatch(twilioActions.setContactIdForActiveCall(contactId));
    }

    call.on("accept", (call) => {
      console.log("calling...");
    });

    call.on("disconnect", (call) => {
      dispatch(twilioActions.setCurrentCall(""));
      dispatch(twilioActions.setContactIdForActiveCall(""));
      dispatch(twilioActions.callOutcome("disconnected"));
      console.log("call disconnected");
    });

    call.on("cancel", (call) => {
      dispatch(twilioActions.setCurrentCall(""));
      dispatch(twilioActions.setContactIdForActiveCall(""));
      dispatch(twilioActions.callOutcome("cancelled"));
      console.log("call canceled");
    });

    call.on("busy", (call) => {
      dispatch(twilioActions.setCurrentCall(""));
      dispatch(twilioActions.setContactIdForActiveCall(""));
      dispatch(twilioActions.callOutcome("busy"));
      console.log("call busy");
    });

    call.on("reject", (call) => {
      dispatch(twilioActions.setCurrentCall(""));
      dispatch(twilioActions.setContactIdForActiveCall(""));
      dispatch(twilioActions.callOutcome("rejected"));
      console.log("call reject");
    });

    call.on("failed", (call) => {
      dispatch(twilioActions.setCurrentCall(""));
      dispatch(twilioActions.setContactIdForActiveCall(""));
      dispatch(twilioActions.callOutcome("failed"));
      console.log("call failed");
    });

    call.on("error", (call) => {
      if (call.code === 31402) {
        dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Error",
            message: "Your microphone is not connected.",
          })
        );
      }
      dispatch(twilioActions.setCurrentCall(""));
      dispatch(twilioActions.setContactIdForActiveCall(""));
      dispatch(twilioActions.callOutcome("error"));
      console.log("Call Error", call);
    });
  };
};

export const fetchMessages = (contactId) => {
  console.log('hitting fetch messages')
  ABORT_FETCH_CONTROLLER = new AbortController();

  const token = store.getState().userReducer.token;
  const userId = store.getState().userReducer.user.userId;

  return async (dispatch) => {

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/twilio/messages/${contactId}`,
        {
          headers: {
            Authorization: "Bearer " + token,
            userId: userId
          },
          signal: ABORT_FETCH_CONTROLLER.signal,
        }
      );

      const responseData = await response.data;

      dispatch(messageActions.replaceMessageData({ messages: responseData.messages }));
    } catch (error) {
      console.log(error)
    }

  }
}

export const fetchMessagesByUserId = (contactId) => {
  ABORT_FETCH_CONTROLLER = new AbortController();

  const token = store.getState().userReducer.token;
  const userId = store.getState().userReducer.user.userId;

  return async (dispatch) => {

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/twilio/messages/user/${userId}`,
        {
          headers: {
            Authorization: "Bearer " + token,
            userId: userId
          },
        }
      );

      const responseData = await response.json();

      dispatch(messageActions.replaceMessageData({ messages: responseData || [] }));

    } catch (error) {
      console.log(error);
    }

  }
}


export const sendMessage = (messageData) => {
  return async (dispatch) => {
    const token = store.getState().userReducer.token;
    const userPhoneNumber = store.getState().userReducer.user.userSettings.phone.number;

    if (!userPhoneNumber) {
      console.log('hitting this')
      dispatch(
        notificationActions.setToastNotification({
          status: "error",
          title: "Error",
          message: "You must setup a phone number before sending messages. You can set one up by clicking",
          link: "/app/call-center/settings/phone"
        })
      );
      return;
    }

    if (!messageData.to) {
      dispatch(
        notificationActions.setToastNotification({
          status: "error",
          title: "Error",
          message:
            "The contact does not have a phone number, please update contact or move to the next one."
        })
      );
      return;
    }

    messageData = {
      ...messageData,
      from: userPhoneNumber
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/twilio/outbound-message`,
        messageData,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      if (response.statusText !== "OK") {
        throw new Error("Sending message failed.");
      }

      dispatch(messageActions.addMessage(response.data.message));

      // dispatch(noteActions.addNote({ note: response.data.note }));
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error",
          message: error.message || "Something went wrong, please try again.",
        })
      );
    }
  };
};

export const updateMessage = (messageData) => {
  return async (dispatch) => {
    const token = store.getState().userReducer.token;
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/twilio/messages/${messageData.id}`,
        messageData,
        {
          headers: {
            Authorization: "Bearer " + token,
          }
        }
      );

      if (response.statusText !== "OK") {
        throw new Error("Updating message failed.");
      }

      dispatch(messageActions.updateMessage(response.data.message));

    } catch (error) {

    }
  }
}

export const fetchVoiceMessages = (voiceMessageData) => {
  return async (dispatch) => {
    let userId = store.getState().userReducer.user.userId;
    const token = store.getState().userReducer.token;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/twilio/voice-messages/user/${userId}`,
        {
          headers: {
            Authorization: "Bearer " + token,
            userId: userId
          }
        }
      );

      if (response.statusText !== "OK") {
        throw new Error("Fetch voice messages failed.");
      }

      dispatch(voiceMessageActions.replaceVoiceMessageData(response.data))

    } catch (error) {

    }
  }
}

export const updateVoiceMessage = (voiceMessageData) => {
  return async (dispatch) => {
    let userId = store.getState().userReducer.user.userId;
    const token = store.getState().userReducer.token;
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/twilio/voice-messages/user/${userId}`,
        voiceMessageData,
        {
          headers: {
            Authorization: "Bearer " + token,
          }
        }
      );

      if (response.statusText !== "OK") {
        throw new Error("Updating message failed.");
      }

      dispatch(voiceMessageActions.updateVoiceMessage(response.data.voiceMessage));

    } catch (error) {

    }
  }
}

export const fetchCalls = (callData) => {
  return async (dispatch) => {
    let userId = store.getState().userReducer.user.userId;
    const token = store.getState().userReducer.token;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/twilio/calls/user/${userId}`,
        {
          headers: {
            Authorization: "Bearer " + token,
            userId: userId
          }
        }
      );

      if (response.statusText !== "OK") {
        throw new Error("Fetch voice messages failed.");
      }

      dispatch(callActions.replaceCallData(response.data))

    } catch (error) {

    }
  }
}

export const updateCall = (callData) => {
  return async (dispatch) => {
    let userId = store.getState().userReducer.user.userId;
    const token = store.getState().userReducer.token;
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/twilio/calls/user/${userId}`,
        callData,
        {
          headers: {
            Authorization: "Bearer " + token,
          }
        }
      );

      if (response.statusText !== "OK") {
        throw new Error("Updating message failed.");
      }

      dispatch(callActions.updateCall(response.data.call));

    } catch (error) {

    }
  }
}