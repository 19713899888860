import React, { useState } from "react";
import MessagePng from "../../../../assets/imgs/message-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRightFromBracket,
  faGear,
  faAngleLeft,
} from "@fortawesome/free-solid-svg-icons";
import classes from "./Messages.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateMessage } from "../../../../store/twilio-actions";
import Dot from "../../UIElements/Dot/Dot";
import { convertTime } from "../../utils/convertTime";

const Messages = (props) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  //open messages for contact and set new messages read: true
  const messageClickHandler = (contact) => {
    props.closePopper();
    const contactId = contact.contactId;
    navigate(`/app/call-center/contacts/${contactId}/messages`)
  };

  //opens drop down to reveal contact's recent messages
  const [showMessages, setShowMessages] = useState([]);

  const openMessagesHandler = (event, contact) => {
    event.stopPropagation();

    if (showMessages.includes(event.target.id)) {
      setShowMessages((prevState) => {
        return prevState.filter((contact) => {
          return contact !== event.target.id;
        });
      });
    } else {
      setShowMessages((prevState) => {
        return [...prevState, event.target.id];
      });
    }
  };

  //create new array to store each contact's messages
  //add notification count for the number of messages
  let messages;

  if (props.unreadMessages.length > 0) {
    const contactUnreadMessages = [];

    //iterate over unread messages
    const addCountUnreadMessages = props.unreadMessages.map((message) => {

      //checks message to see if it's contact has been added to array
      if (contactUnreadMessages.some((contact) => { return contact["contactName"] == message["contactName"] })) {

          contactUnreadMessages.forEach((contact) => {
          if (contact["contactName"] == message["contactName"]) {
            contact["messages"].push({ ...message });
            contact["notifications"]++;
          }
        });
      } else {
        let addNotifications = {};
        addNotifications["contactName"] = message["contactName"];
        addNotifications["contactId"] = message["belongsTo"];
        addNotifications["messages"] = [{ ...message }];
        addNotifications["notifications"] = 1;
        contactUnreadMessages.push(addNotifications);
      }
    });

    //if messages, then return consolidate message 
    //display each message when user clicks right angle
    //else show default message "no messages"
    messages = contactUnreadMessages.map((contact) => {
      return (
        <div
          className={classes["message"]}
          onClick={(event) => messageClickHandler(contact)}
        >
          <div className={classes["message-details"]}>
            <div className={classes["sender-details"]}>
              <div>{contact.contactName || "Unknown"}</div>
              <div className={classes["icon-date"]}>
                <div className={classes["icon"]}>
                  <img src={MessagePng} />
                </div>
                <div className={classes["last-message"]}>
                  <div>Last message:</div>
                  <div className={classes["date"]}>
                    {convertTime(new Date(contact.messages.at(-1).createdAt))}{" "}
                    {new Date(contact.messages.at(-1).createdAt).getMonth() + 1}
                    /{new Date(contact.messages.at(-1).createdAt).getDate()}/
                    {new Date(contact.messages.at(-1).createdAt).getFullYear()}
                  </div>
                </div>
              </div>
            </div>
            <div className={classes["count-expand"]}>
              <div className={classes["unread-count"]}>
                {contact.notifications}
              </div>
              <div
                id={contact.contactName}
                className={`${classes["expand-messages"]} ${showMessages.includes(contact.contactName)
                    ? classes["active"]
                    : ""
                  }`}
                onClick={(event) => openMessagesHandler(event, contact)}
              >
                <FontAwesomeIcon
                  onClick={(event) => openMessagesHandler(event, contact)}
                  id={contact.contactName}
                  icon={faAngleLeft}
                  className="fa-xs"

                />
              </div>
            </div>
          </div>
          {showMessages.includes(contact.contactName) && (
            <div className={classes["expanded-messages"]}>
              {contact.messages.map((message) => {
                return (
                  <>
                    <div className={classes["expanded-message"]}>
                      <div className={classes["expanded-message-details"]}>
                        <div className={classes["expanded-message-text"]}>
                          {message.message}
                        </div>
                        <div className={classes["message-time"]}>
                          Received: {convertTime(new Date(message.createdAt))}
                        </div>
                      </div>
                      {/* <div>
                          <Dot color={`${'green'}`} isActive size="dot--x-small"/>
                        </div> */}
                    </div>
                  </>
                );
              })}
            </div>
          )}
        </div>
      );
    });
  } else {
    messages = (
      <div className={classes["no-messages"]}>
        You have no unread messages...
      </div>
    );
  }

  return <div className={classes["messages"]}>{messages}</div>;
};

export default Messages;
