import React, { useState, useRef } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { useDispatch } from "react-redux";
import { BsTelephone } from "react-icons/bs";
import { useSelector } from "react-redux";
import { PiVoicemailFill } from "react-icons/pi";
import { AiOutlineMessage, AiOutlineMail } from "react-icons/ai";
import { uiActions } from "../../../store/ui";
import CommunicationSideDrawerMessages from "./CommunicationSideDrawerMessages";
import { createPortal } from "react-dom";
import classes from "./CommunicationSideDrawer.module.css";
import {
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useOutlet
} from "react-router-dom";

const CommunicationsSideDrawer = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const nodeRef = useRef();

  const [fireCommAnimation, setFireCommAnimation] = useState(false);

  // const commDrawerIsOpen = useSelector((state) => state.uiReducer.isShowCommSideDrawer);

  const closeCommDrawerHandler = () => {
    navigate("/");
  };

  const backdropRef = useRef();
  const sideDrawerRef = useRef();

  const location = useLocation();
  const currentOutlet = useOutlet();

  return createPortal(
    <div
      className={classes.backdrop}
      onClick={closeCommDrawerHandler}
      ref={backdropRef}
    >
      <div
        className={classes.communicationSideDrawer}
        onClick={(e) => {
          e.stopPropagation();
        }}
        ref={sideDrawerRef}
      >
        <div className={classes.commBar}>
          <NavLink
            className={({ isActive }) => (isActive ? classes.active : "")}
            to="messages"
          >
            <div>
              <AiOutlineMessage />
            </div>
          </NavLink>
          {/* <div><AiOutlineMail /></div>
          <NavLink
            className={({ isActive }) => (isActive ? classes.active : "")}
            to="calls"
          >
            <div>
              <BsTelephone />
            </div>
          </NavLink>
          <NavLink
            className={({ isActive }) => (isActive ? classes.active : "")}
            to="voicemails"
          >
            <div>
              <PiVoicemailFill />
            </div>
          </NavLink> */}
        </div>

        <div className={classes.commContainer} ref={nodeRef}>
          {currentOutlet}
        </div>
      </div>
    </div>,
    document.getElementById("modal-root")
  );
};

export default CommunicationsSideDrawer;

{
  /* <CSSTransition
          mountOnEnter
          unmountOnExit
          in={location.pathname}
          classNames={{
            enterActive: classes.backdropEnter,
            enterDone: classes.backdropEnterActive,
            exitActive: classes.backdropExit,
            exitDone: classes.backdropExitActive
          }}
          timeout={100}
          onEnter={() => setFireCommAnimation(true)}
          onExit={() => setFireCommAnimation(false)}
          nodeRef={backdropRef}
        > */
}

{
  /* <CSSTransition
              mountOnEnter
              unmountOnExit
              in={fireCommAnimation}
              classNames={{
                enterActive: classes.commEnter,
                enterDone: classes.commEnterActive,
                exitActive: classes.commExit,
                exitDone: classes.commExitActive
              }}
              timeout={100}
              nodeRef={sideDrawerRef}
            > */
}
