import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../../store/user-actions";
import UserBadge from "../../UIElements/UserBadge/UserBadge";
import { usePopper } from "react-popper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRightFromBracket,
  faGear,
  faAngleLeft
} from "@fortawesome/free-solid-svg-icons";
import useClickAwayListener from "../../../../shared/components/hooks/useClickAwayListener/useClickAwayLIstener";
import { useGoogleLogout } from "@leecheuk/react-google-login";
import NotificationBell from "../../../../assets/imgs/bell.png";
import MessageIcon from "../../../../assets/imgs/message-notification-2.png";
import Messages from "./Messages";
import classes from "./MainNavigation.module.css";
import { notificationActions } from "../../../../store/notifications";
import VoiceMessages from "./VoiceMessages";
import { updateVoiceMessage } from "../../../../store/twilio-actions";
import { updateCall } from "../../../../store/twilio-actions";
import Calls from "./Calls";
import Emails from "./Emails";
import { uiActions } from "../../../../store/ui";

const MainNavigation = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userName = useSelector((state) => state.userReducer.user.userName);
  const userEmail = useSelector((state) => state.userReducer.user.userEmail);
  const userId = useSelector((state) => state.userReducer.user.userId);
  const isLoggedIn = useSelector((state) => state.userReducer.isLoggedIn);

  const clientId = `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`;
  const onLogoutSuccess = (res) => {};
  const onFailure = (res) => {};
  const { signOut } = useGoogleLogout({ clientId, onLogoutSuccess, onFailure });

  const [activeNotification, setActiveNotification] = useState("messages");

  const activeNotificationHandler = (event) => {
    setActiveNotification(event.target.id);
  };

  //Fetch unread messages for notifications when messages state changes
  const messages = useSelector((state) => state.messageReducer.messages);
  const recentMessages = useSelector(
    (state) => state.messageReducer.recentMessages
  );
  const [unreadMessages, setUnreadMessages] = useState([]);
  const [newMessageBlink, setNewMessageBlink] = useState(false);

  const token = useSelector((state) => state.userReducer.token);

  useEffect(() => {
    const fetchUnreadMessages = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/twilio/messages/user/${userId}`,
          {
            headers: {
              Authorization: "Bearer " + token
            }
          }
        );

        const responseData = await response.json();

        //aded this to fix bug, if issue check here first
        if (!responseData.messages?.length) {
          return;
        }

        const filterForUnread = responseData.filter((message) => {
          if (message.read === undefined) {
            return;
          } else {
            return message.read === false;
          }
        });

        setUnreadMessages(filterForUnread);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUnreadMessages();
  }, [messages, recentMessages]);

  //Fetch unheard voice messages for notifications when missed calls changes
  const voiceMessages = useSelector(
    (state) => state.voiceMessageReducer.voiceMessages
  );
  const [unheardVoiceMessages, setUnheardVoiceMessages] = useState([]);

  useEffect(() => {
    const fetchUnheardVoiceMessages = async () => {
      const filterForUnheard = voiceMessages.filter((voiceMessage) => {
        if (voiceMessage.listened === undefined) {
          return;
        } else {
          return voiceMessage.listened === false;
        }
      });
      setUnheardVoiceMessages(filterForUnheard);
    };

    fetchUnheardVoiceMessages(voiceMessages);
  }, [voiceMessages]);

  //update voice message 'listened' to true to remove from list
  const setListenedToVoiceMessage = (voiceMessage) => {
    const updatedVoiceMessage = {
      ...voiceMessage,
      listened: true
    };
    dispatch(updateVoiceMessage(updatedVoiceMessage));
  };

  // //Fetch missed calls for notifications
  const calls = useSelector((state) => state.callReducer.calls);
  const [missedCalls, setMissedCalls] = useState([]);

  useEffect(() => {
    const fetchCalls = async () => {
      const filterForMissedCalls = calls.filter((call) => {
        if (call.reviewed === undefined) {
          return;
        } else {
          return call.reviewed === false;
        }
      });
      setMissedCalls(filterForMissedCalls);
    };

    fetchCalls();
  }, [calls]);

  //update missed call 'reviewed' to 'true' to remove from list
  const setReviewedMissedCall = (missedCall) => {
    const updatedCall = {
      ...missedCall,
      reviewed: true
    };
    dispatch(updateCall(updatedCall));
  };

  //Profile/Settings popper
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [referenceElementUserBadge, setReferenceElementUserBadge] =
    useState(null);
  const [settingsMenu, setSettingsMenu] = useState(null);

  const settingsPopper = usePopper(referenceElementUserBadge, settingsMenu, {
    placement: "bottom-start",
    modifiers: [{ name: "offset", options: { offset: [0, 5] } }]
  });

  const toggleSettingsPopperHandler = () => {
    setIsSettingsOpen((prevState) => !prevState);
  };

  const settingsClickAwayRef = useRef(null);
  useClickAwayListener(
    settingsClickAwayRef,
    toggleSettingsPopperHandler,
    referenceElementUserBadge
  );

  //Notifications popper
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [
    referenceElementNotificationBell,
    setReferenceElementNotificationBell
  ] = useState(null);
  const [notificationMenu, setNotificationMenu] = useState(null);

  const notificationsPopper = usePopper(
    referenceElementNotificationBell,
    notificationMenu,
    {
      placement: "bottom-start",
      modifiers: [{ name: "offset", options: { offset: [0, 5] } }]
    }
  );

  //prioritizes missed notifications in menu
  useEffect(() => {
    if (unheardVoiceMessages.length) {
      setActiveNotification("voicemail");
    }
    if (missedCalls.length) {
      setActiveNotification("calls");
    }
    if (unreadMessages.length) {
      setActiveNotification("emails");
    }
    if (unreadMessages.length) {
      setActiveNotification("messages");
    }
    return () => {};
  }, [voiceMessages, messages, isNotificationsOpen]);

  const toggleNotificationsPopperHandler = () => {
    dispatch(notificationActions.setIsShowMenuNotification());
    setIsNotificationsOpen((prevState) => !prevState);
  };

  const notificationClickAwayRef = useRef(null);
  useClickAwayListener(
    notificationClickAwayRef,
    toggleNotificationsPopperHandler,
    referenceElementNotificationBell
  );

  const openSettingsModalHandler = () => {
    setIsSettingsOpen((prevState) => !prevState);
    navigate(`/app/call-center/settings/account`);
  };

  const isShowCommSideDrawerHandler = () => {
    navigate("communications/messages");
  };

  const logOutHandler = () => {
    signOut();
    dispatch(logout());
  };

  return (
    <div className={classes.header}>
      <div className={classes["navigation-menu"]}>
        <ul>
          <li>
            <button
              className={classes["side-drawer__menu-btn"]}
              onClick={props.onToggleDrawer}
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
          </li>
          <li className={classes["navigation-menu__icon"]}>Dots</li>
          <li>
            <ul className={classes["right-icon-group"]}>
              <li onClick={isShowCommSideDrawerHandler}>
                <img
                  src={MessageIcon}
                  style={{
                    filter: "invert(100%)",
                    width: "1.4rem",
                    marginTop: "0.25rem"
                  }}
                />
              </li>
              <li>
                <ul className={classes["notification-bell"]}>
                  <li
                    ref={setReferenceElementNotificationBell}
                    onClick={toggleNotificationsPopperHandler}
                    style={{ position: "relative", marginTop: "0.5rem" }}
                  >
                    {(unheardVoiceMessages.length >= 1 ||
                      unreadMessages.length >= 1 ||
                      missedCalls.length > 1) && (
                      <span className={classes["notification-count"]}>
                        {unreadMessages.length +
                          unheardVoiceMessages.length +
                          missedCalls.length >=
                        99
                          ? "99"
                          : unreadMessages.length +
                            unheardVoiceMessages.length +
                            missedCalls.length}
                      </span>
                    )}
                    <img
                      src={NotificationBell}
                      style={{ filter: "invert(100%)", width: "1rem" }}
                    />
                  </li>
                </ul>
              </li>
              <li
                className={classes["user-badge"]}
                ref={setReferenceElementUserBadge}
              >
                <UserBadge onClick={toggleSettingsPopperHandler}>
                  {userName}
                </UserBadge>
              </li>
            </ul>
          </li>
          {isNotificationsOpen && (
            <div ref={notificationClickAwayRef}>
              <div
                ref={setNotificationMenu}
                className={classes.popper}
                style={notificationsPopper.styles.popper}
                {...notificationsPopper.attributes.popper}
              >
                <div className={classes["notifications-menu"]}>
                  <div className={classes["notifications-menu__item"]}>
                    <div className={classes["notifications-header"]}>
                      <div className={classes["notification-type"]}>
                        {/* <div
                          id="all"
                          className={`${
                            activeNotification === "all"
                              ? classes["current-tab"]
                              : ""
                          }`}
                          onClick={activeNotificationHandler}
                        >
                          All{" "}
                          <span
                            id="all"
                            className={classes["unread-count"]}
                            onClick={activeNotificationHandler}
                          >
                            25
                          </span>
                        </div> */}
                        {/* <div
                          id="leads"
                          className={`${
                            activeNotification === "leads"
                              ? classes["current-tab"]
                              : ""
                          }`}
                          onClick={activeNotificationHandler}
                        >
                          Leads{" "}
                          <span
                            id="leads"
                            className={classes["unread-count"]}
                            onClick={activeNotificationHandler}
                          >
                            2
                          </span>
                        </div> */}
                        <div
                          id="messages"
                          className={
                            activeNotification === "messages"
                              ? classes["current-tab"]
                              : ""
                          }
                          onClick={activeNotificationHandler}
                        >
                          Messages{" "}
                          <span
                            id="messages"
                            className={`${classes["unread-count"]} ${
                              newMessageBlink ? classes["active"] : ""
                            }`}
                            onClick={activeNotificationHandler}
                          >
                            {unreadMessages.length}
                          </span>
                        </div>
                        <div
                          id="emails"
                          className={
                            activeNotification === "emails"
                              ? classes["current-tab"]
                              : ""
                          }
                          onClick={activeNotificationHandler}
                        >
                          Emails{" "}
                          <span
                            id="emails"
                            className={classes["unread-count"]}
                            onClick={activeNotificationHandler}
                          >
                            0
                          </span>
                        </div>
                        <div
                          id="calls"
                          className={
                            activeNotification === "calls"
                              ? classes["current-tab"]
                              : ""
                          }
                          onClick={activeNotificationHandler}
                        >
                          Calls{" "}
                          <span
                            id="calls"
                            className={classes["unread-count"]}
                            onClick={activeNotificationHandler}
                          >
                            {missedCalls.length}
                          </span>
                        </div>
                        <div
                          id="voicemail"
                          className={
                            activeNotification === "voicemail"
                              ? classes["current-tab"]
                              : ""
                          }
                          onClick={activeNotificationHandler}
                        >
                          Voicemail{" "}
                          <span
                            id="voicemail"
                            className={classes["unread-count"]}
                            onClick={activeNotificationHandler}
                          >
                            {unheardVoiceMessages.length}
                          </span>
                        </div>
                      </div>
                      <FontAwesomeIcon icon={faGear} size="lg" color="gray" />
                    </div>
                  </div>
                  <div className={classes["menu_separator"]}></div>
                  {activeNotification === "messages" && (
                    <div className={classes["notifications-menu__item"]}>
                      <Messages
                        unreadMessages={unreadMessages}
                        closePopper={toggleNotificationsPopperHandler}
                      />
                    </div>
                  )}
                  {activeNotification === "emails" && (
                    <div className={classes["notifications-menu__item"]}>
                      <Emails
                        unreadMessages={unreadMessages}
                        closePopper={toggleNotificationsPopperHandler}
                      />
                    </div>
                  )}
                  {activeNotification === "voicemail" && (
                    <div className={classes["notifications-menu__item"]}>
                      <VoiceMessages
                        unheardVoiceMessages={unheardVoiceMessages}
                        setListenedToVoiceMessage={setListenedToVoiceMessage}
                      />
                      {/* {unheardVoiceMessages.map(voiceMessage => {
                        return <VoiceMessage setListenedToVoiceMessage={setListenedToVoiceMessage} voiceMessage={voiceMessage} />
                      })} */}
                    </div>
                  )}
                  {activeNotification === "calls" && (
                    <div className={classes["notifications-menu__item"]}>
                      <Calls
                        missedCalls={missedCalls}
                        setReviewedMissedCall={setReviewedMissedCall}
                      />
                      {/* {unheardVoiceMessages.map(voiceMessage => {
                        return <VoiceMessage setListenedToVoiceMessage={setListenedToVoiceMessage} voiceMessage={voiceMessage} />
                      })} */}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {isSettingsOpen && (
            <div ref={settingsClickAwayRef}>
              <div
                ref={setSettingsMenu}
                className={classes.popper}
                style={settingsPopper.styles.popper}
                {...settingsPopper.attributes.popper}
              >
                <div className={classes["user-menu"]}>
                  <div
                    className={`${classes["user-menu__item"]} ${classes["settings"]}`}
                  >
                    <div
                      className={classes["settings-header"]}
                      onClick={openSettingsModalHandler}
                    >
                      <div className={classes["settings-header__container"]}>
                        <div
                          className={
                            classes["settings-header__container__upper"]
                          }
                        >
                          <UserBadge
                            color="purple"
                            size="large"
                            onClick={toggleSettingsPopperHandler}
                          >
                            {userName}
                          </UserBadge>

                          <div>
                            <p className={classes["user-name"]}>{userName}</p>
                            <p className={classes["user-email"]}>{userEmail}</p>
                          </div>
                        </div>
                        <div
                          className={
                            classes["settings-header__container__lower"]
                          }
                        >
                          <div className={classes["user-menu__item__icon"]}>
                            <FontAwesomeIcon
                              icon={faGear}
                              size="lg"
                              color="gray"
                            />
                          </div>
                          <div className={classes["user-menu__item__title"]}>
                            Settings
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classes["menu_separator"]}></div>
                  <div
                    className={`${classes["user-menu__item"]} ${classes["settings"]}`}
                  >
                    <div
                      className={classes["user-menu__item__logout"]}
                      style={{}}
                      onClick={logOutHandler}
                    >
                      <div className={classes["user-menu__item__icon"]}>
                        <FontAwesomeIcon
                          icon={faRightFromBracket}
                          size="lg"
                          color="gray"
                        />
                      </div>
                      <div className={classes["user-menu__item__title"]}>
                        {isLoggedIn && "Log out"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};

export default MainNavigation;
