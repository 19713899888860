import { userActions } from "./user";
import { contactActions } from "./contact";
import { uiActions } from "./ui";
import { fetchContactData } from "./contact-actions.js";
import store from "./index";
import {
  fetchVoiceMessages,
  fetchCalls,
  toggleDeviceOnOffIncomingCalls
} from "./twilio-actions";
import { notificationActions } from "./notifications";
import { fetchEvents } from "./event-actions";
import { connectTwilioDevice } from "./twilio-actions";
import { twilioActions } from "./twilio";
import { fetchMessagesByUserId } from "./twilio-actions";
import {
  fetchStagesByUserId,
  fetchPipelinesByUserId,
  fetchDealsByUserId
} from "./pipeline-actions";
import { fetchTasks } from "./task-actions";

export const signup = (name, email, password) => {
  return async (dispatch) => {
    dispatch(userActions.setIsLoading(true));
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/users/signup`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            name: name,
            email: email,
            password: password
          })
        }
      );

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message);
      }

      dispatch(
        userActions.login({
          userId: responseData.userId,
          userName: responseData.userName,
          userEmail: responseData.userEmail,
          userSettings: responseData.userSettings,
          token: responseData.token
        })
      );
      dispatch(userActions.firstLogin(true));
      dispatch(fetchContactData());
    } catch (error) {
      // dispatch(
      //   uiActions.showNotification({
      //     status: "error",
      //     title: "Error",
      //     message: error.message || "Something went wrong, please try again.",
      //   })
      // );
    }
    dispatch(userActions.setIsLoading(false));
  };
};

export const login = (email, password) => {
  return async (dispatch) => {
    dispatch(userActions.setIsLoading(true));
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/users/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            email: email,
            password: password
          })
        }
      );

      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.message);
      }

      dispatch(
        userActions.login({
          userId: responseData.userId,
          userName: responseData.userName,
          userEmail: responseData.userEmail,
          userSettings: responseData.userSettings,
          token: responseData.token
        })
      );
      dispatch(fetchContactData());
      dispatch(fetchVoiceMessages());
      dispatch(fetchCalls());
      dispatch(fetchEvents());
      dispatch(fetchMessagesByUserId());
      dispatch(fetchPipelinesByUserId());
      dispatch(fetchStagesByUserId());
      dispatch(fetchDealsByUserId());
      dispatch(fetchTasks());
    } catch (error) {
      console.log(error);
      dispatch(
        notificationActions.setToastNotification({
          status: "error",
          title: "Error",
          message: error.message
        })
      );
    }
    dispatch(userActions.setIsLoading(false));
  };
};

export const googleLogin = (name, email, password, googleToken) => {
  return async (dispatch) => {
    dispatch(userActions.setIsLoading(true));
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/users/google-login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            name: name,
            email: email,
            password: password,
            googleToken: googleToken
          })
        }
      );

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message);
      }

      dispatch(
        userActions.login({
          userId: responseData.userId,
          userName: responseData.userName,
          userEmail: responseData.userEmail,
          userSettings: responseData.userSettings,
          token: responseData.token
        })
      );
      dispatch(fetchContactData());
      dispatch(fetchVoiceMessages());
      dispatch(fetchCalls());
      dispatch(fetchEvents());
      dispatch(fetchMessagesByUserId());
      dispatch(fetchPipelinesByUserId());
      dispatch(fetchStagesByUserId());
      dispatch(fetchDealsByUserId());
      dispatch(fetchTasks());
    } catch (error) {}
    dispatch(userActions.setIsLoading(false));
  };
};

export const logout = () => {
  return async (dispatch) => {
    dispatch(toggleDeviceOnOffIncomingCalls(true));
    localStorage.removeItem('userData');
    dispatch(userActions.logout());
  };
};

// export const fetchUserData = () => {
//   return async (dispatch) => {
//     const userId = store.getState().userReducer.user.userId;
//     const token = store.getState().userReducer.token;
//     try {
//       const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/${userId}`, {
//         headers: {
//           Authorization: "Bearer " + token,
//         },
//       });

//       const responseData = await response.json();

//       dispatch(userActions.replaceUserData(responseData));

//     } catch (error) {
//       console.log(error)
//     }
//   }
// }

export const updateUser = (userData) => {
  return async (dispatch) => {
    dispatch(userActions.setIsLoading(true));
    const token = store.getState().userReducer.token;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/users/${userData.userId}`,
        {
          method: "PATCH",
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + token
          },
          body: JSON.stringify({
            id: userData.userId,
            name: userData.userName,
            email: userData.userEmail,
            settings: {
              phone: {
                number: userData.userSettings.phone.number,
                greeting: userData.userSettings.phone.greeting,
                routes: [...userData.userSettings.phone.routes]
              },
              badge: userData.userSettings.badge
            }
          })
        }
      );

      const responseData = await response.json();

      dispatch(userActions.updateUser(responseData.user));

      dispatch(userActions.setIsLoading(false));
    } catch (error) {
      console.log(error);
    }
  };
};

export const deleteUser = (userId) => {
  return async (dispatch) => {
    dispatch(userActions.setIsLoading(true));
    const token = store.getState().userReducer.token;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/users/${userId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: "Bearer " + token
          }
        }
      );

      const responseData = await response.json();

      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Confirmation",
          message: "Your account has been deleted."
        })
      );

      dispatch(logout());
      dispatch(userActions.setIsLoading(false));
    } catch (error) {}
  };
};

export const confirmPassword = (userData) => {
  return async (dispatch) => {
    dispatch(userActions.setIsLoading(true));
    const token = store.getState().userReducer.token;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/users/${userData.userId}/confirm-password`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + token
          },
          body: JSON.stringify({
            password: userData.password
          })
        }
      );

      const responseData = await response.json();
      dispatch(userActions.setIsLoading(false));
    } catch (error) {
      console.log(error);
    }
  };
};
