import React, { useState, useEffect, useRef } from "react";
import ReactDom from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXmark,
  faVolumeMute,
  faDeleteLeft,
} from "@fortawesome/free-solid-svg-icons";
import classes from "./DialPad.module.css";
import { ReactComponent as CallIcon } from "../../../../assets/imgs/call-icon.svg";
import { useTwilioPhoneOutgoing } from "../../../../shared/components/hooks/useTwilioPhoneOutgoing/useTwilioPhoneOutgoing";
import { useDispatch, useSelector } from "react-redux";

const DialPad = (props) => {
  const dispatch = useDispatch();
  const isShowDialPad = useSelector((state) => state.uiReducer.isShowDialPad);
  const call = useSelector((state) => state.twilioReducer.currentCall);

  const {
    onPhone,
    callIsMuted,
    callIsActive,
    callStatus,
    callOutcome,
    makeCallHandler,
    hangUpCallHandler,
    muteCallHandler,
  } = useTwilioPhoneOutgoing("");

  //format twilio's callstatus to modern outputs
  let formatCallStatus;
  if (callStatus === "initiated") {
    formatCallStatus = "calling...";
  }
  if (callStatus === "ringing") {
    formatCallStatus = "ringing...";
  }
  if (callStatus === "busy") {
    formatCallStatus = "busy...";
  }
  if (callStatus === "in-progress") {
    formatCallStatus = "connected...";
  }
  if (callStatus === "completed") {
    formatCallStatus = "call ended...";
  }

  //add keyboard entries to state
  //only accept entries 1-9, *, #, backspace
  const handleKeydown = (event) => {
    const key = event.key;
    const digits = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "#",
      "*",
      "+",
    ];
    if (call && digits.includes(key)) {
      call.sendDigits(key);
    }
    if (key === "Backspace") {
      props.setPhoneNumber((prevState) => {
        return prevState.substring(0, prevState.length - 1);
      });
    }
    if (key === "Enter") {
      makeCallHandler(event, props.phoneNumber);
    }
    if (props.phoneNumber.length > 10) {
      return;
    }
    if (digits.includes(key)) {
      props.setPhoneNumber((prevState) => {
        return prevState + key;
      });
    }
    return;
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeydown);
    return () => window.removeEventListener("keydown", handleKeydown);
  }, [handleKeydown]);

  //add clicked inputs to state
  const selectDigitHandler = (digit) => {
    if (call) {
      call.sendDigits(digit);
      return;
    }
    if (props.phoneNumber.length > 10) {
      return;
    }
    props.setPhoneNumber((prevState) => {
      return prevState + digit;
    });
  };

  const deleteNumberHandler = () => {
    props.setPhoneNumber("");
  };

  const deleteLastDigitHandler = () => {
    props.setPhoneNumber((prevState) => {
      return prevState.substring(0, prevState.length - 1);
    });
  };

  const formatPhone = (phoneNumber) => {
    let formatNumber;
    if (phoneNumber.slice(0, 1) === "1") {
      formatNumber =
        `${phoneNumber.slice(0, 1)}` +
        `${phoneNumber.length ? "(" : ""}` +
        `${phoneNumber.slice(1, 4)}` +
        `${phoneNumber.length ? ")" : ""}` +
        `${" "}` +
        `${phoneNumber.slice(4, 7)}` +
        `${phoneNumber.length ? "-" : ""}` +
        `${phoneNumber.slice(7, 11)}`;
    } else {
      formatNumber =
        `${phoneNumber.length ? "(" : ""}` +
        `${phoneNumber.slice(0, 3)}` +
        `${phoneNumber.length ? ")" : ""}` +
        `${" "}` +
        `${phoneNumber.slice(3, 6)}` +
        `${phoneNumber.length ? "-" : ""}` +
        `${phoneNumber.slice(6, 10)}`;
    }
    return formatNumber;
  };

  const dialEnteredNumber = () => {
    makeCallHandler("", props.phoneNumber, "");
  }

  const submitForm = (event) => {
    event.preventDefault();
  }

  return isShowDialPad ? (
    <>
      {ReactDom.createPortal(
        <div className={classes["dial-pad"]} ref={props.clickAwayRef}>
          <div className={classes["output"]}>
            <div></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div>{formatPhone(props.phoneNumber)}</div>
              <div style={{ fontSize: "0.8rem" }}>{formatCallStatus}</div>
            </div>
            {props.phoneNumber.length != 0 && !formatCallStatus ? (
              <div
                className={classes["delete-number"]}
                onClick={deleteNumberHandler}
              >
                <FontAwesomeIcon icon={faXmark} size="xs" />
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <form onSubmit={submitForm}>
            <div className={classes["inputs"]}>
              <div
                className={classes["input"]}
                onClick={() => selectDigitHandler("1")}
              >
                <div>1</div>
              </div>
              <div
                className={classes["input"]}
                onClick={() => selectDigitHandler("2")}
              >
                <div>2</div>
                <span>ABC</span>
              </div>
              <div
                className={classes["input"]}
                onClick={() => selectDigitHandler("3")}
              >
                <div>3</div>
                <span>DEF</span>
              </div>
              <div className={classes["break"]}></div>
              <div
                className={classes["input"]}
                onClick={() => selectDigitHandler("4")}
              >
                <div>4</div>
                <span>GHI</span>
              </div>
              <div
                className={classes["input"]}
                onClick={() => selectDigitHandler("5")}
              >
                <div>5</div>
                <span>JKL</span>
              </div>
              <div
                className={classes["input"]}
                onClick={() => selectDigitHandler("6")}
              >
                <div>6</div>
                <span>MNO</span>
              </div>
              <div className={classes["break"]}></div>
              <div
                className={classes["input"]}
                onClick={() => selectDigitHandler("7")}
              >
                <div>7</div>
                <span>PQRS</span>
              </div>
              <div
                className={classes["input"]}
                onClick={() => selectDigitHandler("8")}
              >
                <div>8</div>
                <span>TUV</span>
              </div>
              <div
                className={classes["input"]}
                onClick={() => selectDigitHandler("9")}
              >
                <div>9</div>
                <span>WXYZ</span>
              </div>
              <div className={classes["break"]}></div>
              <div
                className={classes["input"]}
                onClick={() => selectDigitHandler("*")}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div>*</div>
                <span></span>
              </div>
              <div
                className={classes["input"]}
                onClick={() => selectDigitHandler("0")}
              >
                <div>0</div>
                <span>+</span>
              </div>
              <div
                className={classes["input"]}
                onClick={() => selectDigitHandler("#")}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div>#</div>
                <span></span>
              </div>
            </div>
        
          <div className={classes["call-buttons"]}>
            <div></div>
            {!call ? (
              <div>
                <button
                  type="submit"
                  className={`${classes["call-button"]} ${
                    !call ? classes["answer-button"] : ""
                  }`}
                  onClick={
                    !call
                      ? dialEnteredNumber
                      : muteCallHandler
                  }
                >
                  {!call ? (
                    <CallIcon />
                  ) : (
                    <FontAwesomeIcon icon={faVolumeMute} width="100%" />
                  )}
                </button>
              </div>
            ) : (
              <div>
                <button
                type="submit"
                  className={`${classes["call-button"]} ${classes["hangup-button"]}`}
                  onClick={hangUpCallHandler}
                >
                  <CallIcon />
                </button>
              </div>
            )}
            <div onClick={deleteLastDigitHandler}>
              <FontAwesomeIcon icon={faDeleteLeft} />
            </div>
          </div>
          </form>
        </div>,
        document.getElementById("modal-root")
      )}
    </>
  ) : null;
};

export default DialPad;

{
  /* <button
className={`${classes["call-button"]} ${
  !onPhone ? classes["answer-button"] : ""
}`}
onClick={
  !onPhone
    ? (event) => makeCallHandler(event, phoneNumber)
    : muteCallHandler
}
>
{!onPhone ? (
  <CallIcon />
) : (
  <FontAwesomeIcon icon={faVolumeMute} width="100%" />
)}
</button>
<button
className={`${classes["call-button"]} ${classes["hangup-button"]}`}
onClick={hangUpCallHandler}
>
<CallIcon />
</button> */
}
