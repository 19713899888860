import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dot from "../Dot/Dot";
import { fileUploaderActions } from "../../../../store/file-uploader";
import { uploadFileToDatabase } from "../../../../store/file-uploader-actions";
import classes from "./FileUploader.module.css";
import CloseX from "../CloseX/CloseX";

const FileUploader = (props) => {

  const dispatch = useDispatch();

  const creator = useSelector((state) => state.userReducer.user.userId);
  const dropDepth = useSelector((state) => state.fileUploaderReducer.dropDepth);
  const inDropZone = useSelector((state) => state.fileUploaderReducer.inDropZone);

  const handleDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(fileUploaderActions.setDropDepth({ increment: 1 }));
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(fileUploaderActions.setDropDepth({ decrement: 1 }));

    if (dropDepth > 1) {
      return;
    }

    dispatch(fileUploaderActions.setInDropZone(false));
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();

    event.dataTransfer.dropEffect = "copy";

    dispatch(fileUploaderActions.setInDropZone(true));
  };

  const handleDrop = (event) => {

    console.log('hitting handle drop')
    event.preventDefault();
    event.stopPropagation();

    let files = [...event.dataTransfer.files];

    if (files && files.length !== 0) {
      props.onUploadFilesHandler(files);
    }
    props.onCloseFileUpload();

    event.dataTransfer.clearData();

    dispatch(fileUploaderActions.setDropDepth({ clear: 0 }));
    dispatch(fileUploaderActions.setInDropZone(false));

    props.onCloseFileUpload();
  };

  const fileUploadHandler = (event) => {
    console.log('hitting file upload handler')
    event.preventDefault();
    event.stopPropagation();

    let uploadedFile;

    if (event.target.files && event.target.files.length !== 0) {
      uploadedFile = event.target.files;

      props.onUploadFilesHandler(Object.values(uploadedFile));
    }
    // props.onCloseFileUpload();
  };

  return (
    <div
      className={
        inDropZone
          ? `${classes["drop-area"]} ${classes["inside-drop-area"]}`
          : classes["drop-area"]
      }
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
    >
      {props.closeButton && (
        <div className={classes["close-file-upload"]}>
          <CloseX onClick={props.onCloseFileUpload} />
        </div>
      )}
      {props.hasError && (
        <>
          {props.img && (
            <img
              className={classes["placeholder-image"]}
              src={props.imgError}
              style={{ width: props.size }}
            />
          )}
          <p>{props.hasError}</p>
          <label className={classes.fileUploadBtn}>
            <input
              type="file"
              multiple
              accept="*"
              id={classes.fileElem}
              onChange={fileUploadHandler}
            />
            or select files from your computer
          </label>
        </>
      )}
      {!props.hasError && (
        <>
          {props.img && (
            <img
              className={classes["placeholder-image"]}
              src={props.img}
              style={{ width: props.size }}
            />
          )}
          <p>Drag & Drop Files Here</p>
          <label className={classes.fileUploadBtn}>
            <input
              type="file"
              multiple
              accept="*"
              id={classes.fileElem}
              onChange={fileUploadHandler}
            />
            or select files from your computer
          </label>
        </>
      )}
    </div>
  );
};

export default FileUploader;
