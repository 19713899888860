import { createSlice } from "@reduxjs/toolkit";

const modalSlice = createSlice({
    name: 'modal',
    initialState: {
        modals: {
            showProfileModal: false,
            showContactProfile: false,
            showNewContactModal: false,
            showImportContactModal: false,
            showZoomModal: false
        },
        imageUrl: null,
        id: null
    },
    reducers: {
        openProfileModal(state, action) {
            state.modals.showProfileModal = true;
            state.id = action.payload;
        },
        openNewContactModal(state, action) {
            state.modals.showNewContactModal = true;
            state.id = null;
        },
        openZoomModal(state, action) {
            state.modals.showZoomModal = true;
            state.imageUrl = action.payload;
        },
        closeZoomModal(state, action) {
            state.modals.showZoomModal = false;
        },
        closeModal(state, action) {
            const modals = state.modals;
            Object.entries(modals).forEach(([key, value]) => {
                state.modals = {
                    key: false
                }
            })
        },
        updateId(state, action) {
            state.id = action.payload;
        }
    }
});

export const modalActions = modalSlice.actions;

export default modalSlice.reducer;