import ReactDom from "react-dom";
import { Navigate, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import Modal from "../UIElements/Modal/Modal";
import Dot from "../UIElements/Dot/Dot";
import NewContact from '../../../callCenter/components/ListBox/contacts/NewContact'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import classes from "./NewContactModal.module.css";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { contactActions } from "../../../store/contact";
import CloseX from "../UIElements/CloseX/CloseX";

const NewContactModal = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState();

  //check if new contact id was added
  const newContactId = useSelector(
    (state) => state.contactReducer.addedContact
  );

  //if error saving contact set loading to false
  const error = useSelector(
    (state) => state.notificationReducer.toastNotification.status
  );

  useEffect(() => {
    if (error === "error") {
      setIsLoading(false);
    }
  }, [error]);

  //navigate to new contact modal
  useEffect(() => {
    if (newContactId) {
      navigate(`/app/call-center/contacts/${newContactId}/notes`);
      dispatch(contactActions.setAddedContact());
    }
  }, [newContactId]);

  const setIsLoadingHandler = () => {
    setIsLoading((prevState) => !prevState);
  };

  const closeModalHandler = () => {
    navigate("/app/call-center");
  };

  return (
    <>
      {ReactDom.createPortal(
        <Modal maxWidth="700px" closeModal={closeModalHandler} marginTop="3%">
          <div className={classes["contact-modal"]}>
            <header>
              <div
                className={classes["breadcrumbs"]}
                style={{ flex: "2" }}
              ></div>
              <div className={classes["header-actions"]}>
                <CloseX onClick={closeModalHandler} />
              </div>
            </header>
            <div className={classes["section-1"]}>
              <div className={classes["section-1__modal-title"]}>
                {/* <FontAwesomeIcon icon={faUser} size="3x" /> */}
                <h2>Get started by adding a single contact</h2>
              </div>
              <div className={classes["section-1__modal-sub-title"]}>
                <p>
                  You can add contacts one at a time manually, or in multiples
                  by clicking the import button.
                </p>
              </div>
            </div>
            <div className={classes["section-2"]}>
              <NewContact
                closeModal={closeModalHandler}
                isLoading={isLoading}
                setIsLoadingHandler={setIsLoadingHandler}
              />
            </div>
          </div>
        </Modal>,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default NewContactModal;
