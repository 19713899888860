//Import styles
import classes from "./Main.module.css";

const Main = (props) => {
  return (
    <div
      className={`${classes.main} ${props.drawerIsOpen ? classes.active : ""}`}
    >
      {props.children}
    </div>
  );
};

export default Main;
