import classes from "./VoiceMessages.module.css";
import VoiceMessage from "./VoiceMessage";

const VoiceMessages = ({ unheardVoiceMessages, setListenedToVoiceMessage }) => {
  let content;

  if (unheardVoiceMessages.length > 0) {
    content = unheardVoiceMessages.map((voiceMessage) => {
      return (
        <VoiceMessage
          voiceMessage={voiceMessage}
          setListenedToVoiceMessage={setListenedToVoiceMessage}
        />
      );
    });
  } else {
    content = (
      <div className={classes["no-messages"]}>
        You have no unheard voice messages...
      </div>
    );
  }

  return <div className={classes["voice-messages"]}>{content}</div>;
};

export default VoiceMessages;
