import React, { useState, useEffect, useRef } from "react";
import classes from "./DealModal.module.css";
import Modal from "../../UIElements/Modal/Modal";
import { createPortal } from "react-dom";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import CloseX from "../../UIElements/CloseX/CloseX";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { VscListSelection } from "react-icons/vsc";
import PlusIcon from "../../UIElements/PlusIcon/PlusIcon";
import SpinnerButton from "../../UIElements/SpinnerButton/SpinnerButton";
import { updateDeal } from "../../../../store/pipeline-actions";
import { createTask } from "../../../../store/task-actions";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import CSSTransition from "react-transition-group/CSSTransition";
import NotesBox from "../../NotesBox/NotesBox";
import Task from "../../Task/Task";
import Textarea from "../../UIElements/Textarea/Textarea";
import { dobToAge } from "../../utils/dobToAge";

const WorkflowModal = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const descriptionTextareaRef = useRef();
  const asideRef = useRef();

  const pipelineId = params.pipelineId;
  const dealId = params.dealId;

  //import deals from store and filter for deal by id from params
  const deals = useSelector((state) => state.pipelineReducer.deals);
  const deal = deals.find((deal) => {return deal.id === dealId});

  //import contacts from store and filter for deal contact by id
  const contact = useSelector((state) => state.contactReducer.contacts).find((contact) => contact.id === deal.contactId);

  //import tasks and filter by complete/incomplete status
  const tasksIncompleted = useSelector((state) => state.taskReducer.tasks)
    .filter((task) => {
      return task.belongsTo === deal.id;
    })
    .filter((task) => task.completed === false);
  const tasksCompleted = useSelector((state) => state.taskReducer.tasks)
    .filter((task) => {
      return task.belongsTo === deal.id;
    })
    .filter((task) => task.completed === true);

  //set deal description from user inputs
  const [isAddDealDescriptionOpen, setIsAddDealDescriptionOpen] =
    useState(false);
  const [enteredDealDescription, setEnteredDealDescription] = useState("");
  const [descriptionInputHasErrors, setDealDescriptionInputHasErrors] =
    useState(false);

  //sets aside toggle from local storage, updates with handler
  const [isAsideOpen, setIsAsideOpen] = useState(
    localStorage.getItem("is-open-workflow-modal-aside") === "true"
      ? true
      : false
  );

  //open add task
  const [isAddTaskOpen, setIsAddTaskOpen] = useState(false);

  //add task name
  const [enteredTaskName, setEnteredTaskName] = useState("");
  const [taskNameInputHasErrors, setTaskNameInputHasErrors] = useState(false);

  //add task description
  const [enteredTaskDescription, setEnteredTaskDescription] = useState("");
  const [taskDescriptionInputHasErrors, setTaskDescriptionInputHasErrors] =
    useState(false);

  //open deal description for user input
  const isOpenAddDealDescriptionHandler = (e) => {
    e.stopPropagation();
    setIsAddDealDescriptionOpen(true);
  };

  //set deal description from user inputs & permits editing
  const dealDescriptionInputHandler = (e) => {
    setDealDescriptionInputHasErrors(false);
    setEnteredDealDescription(e.target.value);
  };

  //closes deal description and resets inputs
  const cancelAddDealDescriptionHandler = (e) => {
    e.stopPropagation();
    setIsAddDealDescriptionOpen(false);
    setEnteredDealDescription(deal?.description);
    setDealDescriptionInputHasErrors(false);
  };

  //add deal description with dispatch to action creator
  const addDealDescriptionHandler = () => {
    if (enteredDealDescription === "") {
      setDealDescriptionInputHasErrors(true);
      return;
    }
    setIsAddDealDescriptionOpen(false);
    dispatch(
      updateDeal({
        ...deal,
        description: enteredDealDescription
      })
    );
  };

  //update deal description with dispatch to action creator
  const updateDescriptionHandler = () => {
    if (enteredDealDescription === "") {
      setDealDescriptionInputHasErrors(true);
      return;
    }
    setIsAddDealDescriptionOpen(false);
    dispatch(
      updateDeal({
        ...deal,
        description: enteredDealDescription
      })
    );
  };

  //update deal description state from props
  useEffect(() => {
    setEnteredDealDescription(deal?.description);
  }, [deal]);

  //open add task
  const isOpenAddTaskHandler = () => {
    setIsAddTaskOpen(true);
  };

  //sets new task name with user inputs
  const taskNameInputHandler = (e) => {
    setEnteredTaskName(e.target.value);
  };

  //sets new task description with user inputs
  const taskDescriptionInputHandler = (e) => {
    setEnteredTaskDescription(e.target.value);
  };

  //add task with dispatch to action creator
  const addTaskHandler = () => {
    dispatch(
      createTask({
        taskName: enteredTaskName,
        description: enteredTaskDescription,
        belongsTo: dealId,
        contactId: contact.id
      })
    );
    setIsAddTaskOpen(false);
    setEnteredTaskName("");
    setEnteredTaskDescription("");
  };

  //cancel add task and reset inputs
  const cancelAddTaskHandler = () => {
    setIsAddTaskOpen(false);
    setEnteredTaskName("");
    setEnteredTaskDescription("");
  };

  //close modal handler by navigating to previous page
  const closeModalHandler = () => {
    navigate(`/app/pipeline/${pipelineId}`);
  };

  //open/closes/saves aside status to state and localstorage
  const toggleAsideHandler = (status) => {
    if (status === true) {
      setIsAsideOpen(true);
      localStorage.setItem("is-open-workflow-modal-aside", true);
    } else {
      setIsAsideOpen(false);
      localStorage.setItem("is-open-workflow-modal-aside", false);
    }
  };

  return createPortal(
    <Modal closeModal={closeModalHandler} height="100%" maxWidth="900px">
      <div className={classes.dealModal}>
        <div className={classes.dealModalHeader}>
          <div className={classes.breadcrumbs} style={{ flex: "2" }}></div>
          <div className={classes.headerActions}>
            <FontAwesomeIcon
              icon={faAngleUp}
              // onClick={previousContactHandler}
            />
            <FontAwesomeIcon
              icon={faAngleDown}
              // onClick={nextContactHandler}
            />
            <CloseX onClick={closeModalHandler} />
          </div>
        </div>
        <div className={classes.detailsAndAside}>
          <div className={classes.details}>
            <div className={classes.contactAndDescription}>
              <div className={classes.fullName}>
                {contact.firstName + " " + contact.lastName}
              </div>
              <div className={classes.description}>
                {deal.description ? (
                  !isAddDealDescriptionOpen ? (
                    <div onClick={isOpenAddDealDescriptionHandler}>
                      {deal.description}
                    </div>
                  ) : (
                    <div className={classes.descriptionInput}>
                      <Textarea
                        value={enteredDealDescription}
                        onChange={dealDescriptionInputHandler}
                        rows="2"
                        hasErrors={descriptionInputHasErrors}
                        border="true"
                        placeHolder="Description..."
                        autoFocus={true}
                      />
                      <div
                        className={
                          classes.actions + " " + classes.descriptionActions
                        }
                      >
                        <SpinnerButton
                          margin=".5rem 0 .5rem 0"
                          color="button--white"
                          size="button--small"
                          onClick={cancelAddDealDescriptionHandler}
                        >
                          Cancel
                        </SpinnerButton>
                        <SpinnerButton
                          margin=".5rem 0 .5rem 0"
                          color="button--blue"
                          size="button--small"
                          onClick={updateDescriptionHandler}
                        >
                          Save
                        </SpinnerButton>
                      </div>
                    </div>
                  )
                ) : (
                  <>
                    {!isAddDealDescriptionOpen ? (
                      <div
                        className={classes.iconAndDesc}
                        onClick={isOpenAddDealDescriptionHandler}
                      >
                        <VscListSelection />
                        <div>Description</div>
                      </div>
                    ) : (
                      <div className={classes.descriptionInput}>
                        <Textarea
                          value={enteredDealDescription}
                          onChange={dealDescriptionInputHandler}
                          rows="2"
                          hasErrors={descriptionInputHasErrors}
                          border="true"
                          placeHolder="Description..."
                          autoFocus={true}
                        />
                        <div
                          className={
                            classes.actions + " " + classes.descriptionActions
                          }
                        >
                          <SpinnerButton
                            margin=".5rem 0 .5rem 0"
                            color="button--white"
                            size="button--small"
                            onClick={cancelAddDealDescriptionHandler}
                          >
                            Cancel
                          </SpinnerButton>
                          <SpinnerButton
                            margin=".5rem 0 .5rem 0"
                            color="button--blue"
                            size="button--small"
                            onClick={addDealDescriptionHandler}
                          >
                            Save
                          </SpinnerButton>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className={classes.tasklistIncompleted}>
              {tasksIncompleted.map((task, index) => {
                return <Task task={task} key={index} />;
              })}
            </div>
            {!isAddTaskOpen && (
              <div className={classes.addTasks} onClick={isOpenAddTaskHandler}>
                <PlusIcon>Add task</PlusIcon>
              </div>
            )}
            {isAddTaskOpen && (
              <div className={classes.addTask}>
                <div className={classes.taskDetails}>
                  <div className={classes.taskNameInput}>
                    <Textarea
                      value={enteredTaskName}
                      onChange={taskNameInputHandler}
                      hasErrors={taskNameInputHasErrors}
                      rows="1"
                      placeHolder="Type task's name here"
                      autoFocus={true}
                    />
                  </div>
                  <div className={classes.taskDescriptionInput}>
                    <Textarea
                      value={enteredTaskDescription}
                      onChange={taskDescriptionInputHandler}
                      hasErrors={taskDescriptionInputHasErrors}
                      rows="1"
                      fontSize=".75rem"
                      placeHolder="Description"
                      autoFocus={false}
                    />
                  </div>
                </div>
                <div className={classes.horizontalLine}></div>
                <div className={classes.actions + " " + classes.newTaskActions}>
                  <SpinnerButton
                    margin=".5rem 0 .5rem 0"
                    color="button--white"
                    size="button--small"
                    onClick={cancelAddTaskHandler}
                  >
                    Cancel
                  </SpinnerButton>
                  <SpinnerButton
                    margin=".5rem 0 .5rem 0"
                    color="button--blue"
                    size="button--small"
                    onClick={addTaskHandler}
                  >
                    Save
                  </SpinnerButton>
                </div>
              </div>
            )}
            <div className={classes.tasklistCompleted}>
              {tasksCompleted.map((task, index) => {
                return <Task task={task} key={index} />;
              })}
            </div>
            <div className={classes.notesCotainer}>
              <div className={classes["contact-communications"]}>
                <div className={classes["contact-communications__menu"]}>
                  <div className={classes.active}></div>
                  <div className={classes.active}>Notes</div>
                  <div className={classes.active}></div>
                </div>
              </div>
              <NotesBox />
            </div>
          </div>
          <div
            className={classes.openAsideArrow}
            onClick={() =>
              isAsideOpen === false
                ? toggleAsideHandler(true)
                : toggleAsideHandler(false)
            }
          >
            {!isAsideOpen ? (
              <FontAwesomeIcon icon={faAngleLeft} className="fa-xs" size="xs" />
            ) : (
              <FontAwesomeIcon
                icon={faAngleRight}
                className="fa-xs"
                size="xs"
              />
            )}
          </div>
          <CSSTransition
            mountOnEnter
            unmountOnExit
            in={isAsideOpen}
            classNames={{
              enter: classes["aside-enter"],
              enterDone: classes["aside-enter-active"],
              exit: classes["aside-exit"],
              exitDone: classes["aside-exit-active"]
            }}
            timeout={1000}
            nodeRef={asideRef}
          >
            <div className={classes.aside} ref={asideRef}>
              <div className={classes["aside-item"]}>
                <p className={classes["aside-title"]}>Contact Type</p>
                <p className={classes["aside-value"]}>
                  {contact.contactType ? contact.contactType : "Unknown"}
                </p>
                <div className={classes.menuSeparator}></div>
              </div>
              {contact.campaignName === "Seminars" && (
                <>
                  <div className={classes["aside-item"]}>
                    <p className={classes["aside-title"]}>Attended Seminar</p>
                    <p className={classes["aside-value"]}>
                      {contact.attendedEvent ? "Yes" : "No"}
                    </p>
                    <div className={classes.menuSeparator}></div>
                  </div>

                  <div className={classes["aside-item"]}>
                    <p className={classes["aside-title"]}>Seminar Location</p>
                    <p className={classes["aside-value"]}>
                      {contact.eventLocation
                        ? contact.eventLocation
                        : "Unknown"}
                    </p>
                    <div className={classes.menuSeparator}></div>
                  </div>

                  <div className={classes["aside-item"]}>
                    <p className={classes["aside-title"]}>Seminar Venue</p>
                    <p className={classes["aside-value"]}>
                      {contact.eventVenue ? contact.eventVenue : "Unknown"}
                    </p>
                    <div className={classes.menuSeparator}></div>
                  </div>
                </>
              )}

              <div className={classes["aside-item"]}>
                <p className={classes["aside-title"]}>Source</p>
                <p className={classes["aside-value"]}>
                  {contact.source ? contact.source : "Unknown"}
                </p>
                <div className={classes.menuSeparator}></div>
              </div>

              {contact.dateOfBirth ? (
                <div className={classes["aside-item"]}>
                  <p className={classes["aside-title"]}>Date of Birth</p>
                  <p className={classes["aside-value"]}>
                    {new Date(`${contact.dateOfBirth}`)
                      .toISOString()
                      .substring(0, 10)}
                  </p>
                  <div className={classes.menuSeparator}></div>
                </div>
              ) : (
                ""
              )}

              {contact.dateOfBirth ? (
                <div className={classes["aside-item"]}>
                  <p className={classes["aside-title"]}>Age</p>
                  <p className={classes["aside-value"]}>
                    {dobToAge(contact.dateOfBirth)}
                  </p>
                  <div className={classes.menuSeparator}></div>
                </div>
              ) : (
                ""
              )}
              {contact.campaignName && (
                <div className={classes["aside-item"]}>
                  <p className={classes["aside-title"]}>Campaign Name</p>
                  <p className={classes["aside-value"]}>
                    {contact.campaignName ? contact.campaignName : "Unknown"}
                  </p>
                  <div className={classes.menuSeparator}></div>
                </div>
              )}
              {contact.adName && (
                <div className={classes["aside-item"]}>
                  <p className={classes["aside-title"]}>Ad Name</p>
                  <p className={classes["aside-value"]}>
                    {contact.adName ? contact.adName : "Unknown"}
                  </p>
                  <div className={classes.menuSeparator}></div>
                </div>
              )}
            </div>
          </CSSTransition>
        </div>
      </div>
    </Modal>,
    document.getElementById("modal-root")
  );
};

export default WorkflowModal;
