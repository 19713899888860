import { createSlice } from "@reduxjs/toolkit";

const callSlice = createSlice({
    name: "call",
    initialState: {
        calls: [],
        recentCalls: []
    },
    reducers: {
        replaceCallData (state, action) {
            const calls = [];
            action.payload.forEach(call => calls.push(call));
            state.calls = calls;
        },
        addCall(state, action) {
            state.calls.push(action.payload);
        },
        addRecentVoiceCall(state, action) {
            state.recentCalls.push(action.payload);
        },
        updateCall(state, action) {
            const currentCall = state.calls.find(call => call.id === action.payload.id);
            currentCall.contactName = action.payload.contactName;
            currentCall.callSid = action.payload.callSid;
            currentCall.to = action.payload.to;
            currentCall.toCity = action.payload.toCity;
            currentCall.toCountry = action.payload.toCountry;
            currentCall.toState = action.payload.toState;
            currentCall.toZip = action.payload.toZip;
            currentCall.from = action.payload.from;
            currentCall.fromCity = action.payload.fromCity;
            currentCall.fromCountry = action.payload.fromCountry;
            currentCall.fromState = action.payload.fromState;
            currentCall.fromZip = action.payload.fromZip;
            currentCall.reviewed = action.payload.reviewed;
            currentCall.answered = action.payload.answered;
            currentCall.belongsTo = action.payload.belongsTo;
            currentCall.id = action.payload.id;
        },
        updateRecentVoiceMessages(state, action) {
            // const newVoiceMessages = state.recentVoiceMessages.filter(vm => vm.belongsTo !== action.payload.belongsTo);
            // state.recentVoiceMessages = newVoiceMessages;

        }
    }
});

export const callActions = callSlice.actions;

export default callSlice.reducer;