import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { uiActions } from "../../../../store/ui.js";

export const useTwilioPhoneUtils = (contactId) => {
  const dispatch = useDispatch();

  const [callIsMuted, setCallIsMuted] = useState(false);
  const [onPhone, setOnPhone] = useState(false);

  //import twilio device and active call from store
  const call = useSelector((state) => state.twilioReducer.currentCall);
  const device = useSelector((state) => state.twilioReducer.twilioDevice);

  //import incoming caller id from store
  const callerId = useSelector((state) => state.twilioReducer.callerId);

  //sets the list item call button opacity to 1 to convey active call
  //commpares activeCallerId set by the makeCallHandler to the contact iterated in listitems.js
  const callIsActive = useSelector(
    (state) => state.twilioReducer.activeCallContactId
  ).includes(contactId);

  //get call is busy status from store
  const isBusy = useSelector(state => state.twilioReducer.isBusy);

  const answerCallHandler = () => {
    setOnPhone(true);
    call.accept();
  };

  const ignoreCallHandler = () => {
    call.ignore();
    dispatch(uiActions.setIncomingCallNotification(false));
  };

  const hangUpCallHandler = () => {
    setOnPhone(false);
    device.disconnectAll();
  };

  const muteCallHandler = () => {
    if (call.isMuted() === true) {
      call.mute(false);
      setCallIsMuted(false);
    } else {
      call.mute();
      setCallIsMuted(true);
    }
  };

  return {
    onPhone,
    callerId,
    callIsActive,
    callIsMuted,
    isBusy,
    answerCallHandler,
    ignoreCallHandler,
    hangUpCallHandler,
    muteCallHandler,
  };
};
