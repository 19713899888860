import { useEffect } from "react";
import classes from "./CalendarDay.module.css";
import DayCell from "./DayCell";
import { useSelector } from "react-redux";

const CalendarDay = (props) => {
    //import events from store
    const events = useSelector(state => state.eventReducer.events);

    //init array for days in year
    const daysInFullYear = [];

    //get prior december days equal to january start day offset
    const janStartDay = new Date(props.currentYear, 0, 1).getDay();
    const daysInPrevDec = Array.from({ length: new Date(props.currentYear - 1, 11 + 1, 0).getDate() }, (v, i) => i + 1);
    const janOffsetDays = janStartDay > 0 ? daysInPrevDec.slice(-janStartDay) : "";

    //get next january days equal to decembers last week ofset
    const decStartDay = new Date(props.currentYear, 11, 1).getDay(); //5
    const daysInNextJan = Array.from({ length: new Date(props.currentYear + 1, 0 + 1, 0).getDate() }, (v, i) => i + 1); //31
    const decDaysPlusOffset = decStartDay + new Date(props.currentYear, 11 + 1, 0).getDate(); //36
    const decOffsetDays = decDaysPlusOffset > 35 ? daysInNextJan.slice(0, 42 - decDaysPlusOffset) : daysInNextJan.slice(0, 35 - decDaysPlusOffset);

    //loop months to create array of days in year
    props.months.map((month, index) => {
        const daysInMonth = new Date(props.currentYear, index + 1, 0).getDate();
        const days = Array.from({ length: daysInMonth }, (v, i) => i + 1);
        return daysInFullYear.push(...days);
    });

    //push prev/next year's jan/feb offset days
    daysInFullYear.unshift(...janOffsetDays);
    daysInFullYear.push(...decOffsetDays);

    //slice full year of days into months
    const daysPassed = Math.ceil((new Date(props.currentYear, props.currentMonth) - new Date(props.currentYear, 0)) / (1000 * 60 * 60 * 24));
    const monthStartOffset = new Date(props.currentYear, props.currentMonth, 1).getDay();
    const daysInCurrentMonth = new Date(props.currentYear, props.currentMonth + 1, 0).getDate();
    const monthEndOffset = monthStartOffset + daysInCurrentMonth <= 35 ? 35 - (monthStartOffset + daysInCurrentMonth) : 42 - (monthStartOffset + daysInCurrentMonth);

    const formattedMonth = daysInFullYear.slice(
        janOffsetDays.length + daysPassed - monthStartOffset,
        janOffsetDays.length + daysPassed + daysInCurrentMonth + monthEndOffset);

    //slice full year of days into week 
    const formattedWeek = daysInFullYear.slice(((props.currentWeek * 7)), (props.currentWeek * 7) + 7);

    //start: parse prev/next month days for event scheduler and event filter

    //get first day of month as date string
    const firstDayOfMonth = new Date(props.currentYear, props.currentMonth, 1).getTime();
    const lastDayOfMonth = new Date(props.currentYear, props.currentMonth + 1, 0).getTime();

    //create array of date strings for each day of current week
    let daysInTimeString = [];
    for (let i = 0; i < 7; i++) {
        daysInTimeString.push(new Date(new Date(new Date(props.currentYear, 0, 1).getTime() + ((((props.currentWeek * 7)) - janOffsetDays.length + i) * 24 * 60 * 60 * 1000)).setHours(0, 0, 0, 0)).getTime())
    }

    //check if current week includes first day
    const isFirstWeek = daysInTimeString.includes(firstDayOfMonth);
    const isLastWeek = daysInTimeString.includes(lastDayOfMonth);

    const eventFilter = (date, index) => {
        const setHour = new Date(date).setHours(index);
        const formattedDate = new Date(setHour);
        const filteredEvent = events.filter(event => new Date(event.eventStarts).getTime() === formattedDate.getTime() || new Date(event.eventStarts).getTime() === formattedDate.getTime() + (30*60000));
        return filteredEvent;
    }

    useEffect(() => {
        if (isFirstWeek && formattedWeek.slice(0, formattedWeek.indexOf(1)).includes(props.currentDay)) {
            props.onUpdateMonth(props.currentMonth - 1)
        }
        if (isLastWeek && formattedWeek.slice(formattedWeek.indexOf(1)).includes(props.currentDay)) {
            props.onUpdateMonth(props.currentMonth + 1)
        }
    }, [])

    return (
        <>
            <div className={classes["cal-body"]}>
                {Array.from({ length: 24 }, (v, i) => { return i }).map((v, i) => {
                    return (
                        <div key={i} className={classes["time-table"]}>
                            <div className={classes["time-column"]}>
                                {i + 0 === 0 ? 12 + " " + "am" : i < 12 && i === i ? i + " " + "am" : i === 12 ? 12 + " " + "pm" : i - 12 + " " + "pm"}
                            </div>
                            <div className={classes["time-cells"]}>
                                <DayCell 
                                    currentYear={props.currentYear} 
                                    currentMonth={props.currentMonth} 
                                    currentDay={props.currentDay} 
                                    index={i}
                                    events={eventFilter(new Date(props.currentYear, props.currentMonth, props.currentDay), i)}
                                    sameDayEvents={events.filter(event => new Date(new Date(event.eventStarts).setHours(0, 0, 0, 0)).getTime() === new Date(props.currentYear, props.currentMonth, props.currentDay).getTime())}
                                    prevMonthDay={isFirstWeek && formattedWeek.slice(0, formattedWeek.indexOf(1)).includes(props.currentDay)}
                                    nextMonthDay={isLastWeek && formattedWeek.slice(formattedWeek.indexOf(1)).includes(props.currentDay)}
                                    />
                            </div>
                        </div>)
                })}
            </div>
        </>
    )
}

export default CalendarDay;