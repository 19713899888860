import React, { useState, useEffect } from "react";
import classes from "./CreateDealPopup.module.css";
import Select from "../UIElements/Select/Select";
import { BsFillPersonCheckFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import SpinnerButton from "../UIElements/SpinnerButton/SpinnerButton";
import Input from "../UIElements/Input/Input";
import { useDispatch } from 'react-redux';
import { createPipeline, createStage, createDeal } from "../../../store/pipeline-actions";

const CreateDealPopup = (props) => {

  const dispatch = useDispatch();

  const isLoading = useSelector(state => state.pipelineReducer.isLoading);

  //import pipelines from store
  const pipelines = useSelector(state => state.pipelineReducer.pipelines);

  //import stages from store
  const stages = useSelector(stage => stage.pipelineReducer.stages);

  //state for new pipeline and stage names
  const [enteredNewPipelineName, setEnteredNewPipelineName] = useState('');
  const [enteredNewStageName, setEnteredNewStageName] = useState('');

  //set new pipeline name from user inputs
  const newPipelineInputHandler = (event) => {
    setEnteredNewPipelineName(event.target.value);
  }

  //set new stage name from user inptus
  const newStageInputHandler = (event) => {
    setEnteredNewStageName(event.target.value);
  }

  //states for user selected pipline and stage objects
  const [selectedPipeline, setSelectedPipeline] = useState(null);
  const [pipelineStages, setPipelineStages] = useState([]);
  const [selectedPipelineStage, setSelectedPipelineStage] = useState('');

  //sets user selected pipeline and loads stages from pipeline for select options 
  const selectPipelineHandler = (event) => {

    setSelectedPipelineStage(null);
    const selectedPipeline = pipelines.filter(pipeline => pipeline.name === event.target.value);

    setSelectedPipeline(selectedPipeline[0]);

    const pipelineStages = stages.filter(stage => {
      return Object.values(selectedPipeline[0].stages).filter(stageId => { return stages.filter(stage => stage.id === stageId) }).includes(stage.id)
    })

    //make copy of pipline stages as it's not extensible
    const pipelineStagesCopy = pipelineStages.map(stage => {
      return { ...stage }
    })

    //we don't add a value field when creating stages, add that here for dropdown
    pipelineStagesCopy.forEach(stage => {
      stage['value'] = stage.name
    })

    setPipelineStages(pipelineStagesCopy);

  }

  //sets user selected stage
  const selectStageHandler = (event) => {

    //filters for stages that match user selected name
    const filteredStages = stages.filter(stage => {
      return stage.name === event.target.value;
    });

    //filters for the stage belonging to the current pipeline
    const matchingStage = filteredStages.filter(stage => {
      return selectedPipeline.id === stage.belongsTo;
    })[0];

    setSelectedPipelineStage(matchingStage)
  }

  //creates new pipeline with dispatch to action creator
  const addPipelineHandler = () => {
    dispatch(createPipeline({ name: enteredNewPipelineName }));
    setEnteredNewPipelineName("");
  }

  //creates new stage with dispatch to action creator
  const addStageHandler = () => {
    dispatch(createStage({ pipelineId: selectedPipeline.id, stageName: enteredNewStageName }));
  }

  //add description from user inputs
  const [enteredDescription, setEnteredDescription] = useState("");
  const [enteredDescriptionHasErrors, setEnteredDescriptionHasErrors] = useState(false);

  //set deal description
  const descriptionInputHandler = (event) => {
    setEnteredDescriptionHasErrors(false);
    setEnteredDescription(event.target.value);
  };

  //create new deal with dispatch to action creator
  const createDealHandler = () => {
    if (!enteredDescription) {
      setEnteredDescriptionHasErrors(true);
      return;
    }

    dispatch(createDeal({
      contactId: props.contact.id,
      description: enteredDescription,
      belongsTo: selectedPipelineStage.id
    }));

    props.closePopper();
  }


  //updates selected pipeline with new stage, sets pipelines stages for drowndown options
  useEffect(() => {

    //update selected pipeline with new stage
    const updatedSelectedPipeline = pipelines?.filter(pipeline => {
      return pipeline.id === selectedPipeline?.id
    })

    const pipelineStageIds = updatedSelectedPipeline?.[0]?.stages;

    //filter pipelines stages for all stages in store
    const stagesMatchingPipelineStageIds = stages?.filter(stageId => {
      return pipelineStageIds?.includes(stageId.id)
    });

    //make copy of pipline stages as it's not extensible
    const matchingStagesCopy = stagesMatchingPipelineStageIds?.map(stage => {
      return { ...stage }
    })

    //we don't add a value field when creating stages, add that here for dropdown
    matchingStagesCopy.forEach(stage => {
      stage['value'] = stage.name
    })

    setPipelineStages(matchingStagesCopy);

  }, [stages])

  return (
    <div className={classes.createDealPopup}>
      <div className={classes.menu}>
        <div className={classes.menuItem}>
          <div className={classes.dealTitle}>Create New Deal</div>
        </div>
        <div className={classes.menuSeparator}></div>
        <div className={classes.menuItem}>
          <div className={classes.contactAndDescription}>
            <div className={classes.contactName}>
              <BsFillPersonCheckFill color="lightgreen" />
              {props.contact.firstName + " " + props.contact.lastName + " "}
            </div>
            <div className={classes.description}>
              <div className={classes.formControl + ' ' + (enteredDescriptionHasErrors ? classes.invalid : "")}>
                <input
                  className={classes.input}
                  type="text"
                  value={enteredDescription}
                  onChange={descriptionInputHandler}
                  placeholder="Add deal description..."
                  autoFocus
                />
              </div>
            </div>
          </div>
        </div>
        {(pipelines.length !== 0) &&
          <>
            <div className={classes.menuSeparator}></div>
            <div className={classes.menuItem}>
              <Select label="Choose pipeline" value={selectedPipeline?.name} onChange={selectPipelineHandler} options={pipelines} />
            </div>
          </>
        }
        {(pipelines.length === 0) &&
          <>
            <div className={classes.menuSeparator}></div>
            <div className={classes.menuItem}>
              <div className={classes.createPipeline}>
                <div className={classes.pipelineError}>0 pipelines exist</div>
                <Input
                  label="Enter new pipeline name..."
                  value={enteredNewPipelineName}
                  onChange={newPipelineInputHandler}
                  autoFocus
                  // isInvalid={newStageNameHasErrors}
                  errorMessage="Please enter a pipeline name"
                />
              </div>
            </div>
          </>}
        {(pipelineStages?.length !== 0) &&
          <>
            <div className={classes.menuSeparator}></div>
            <div className={classes.menuItem}>
              <Select label="Choose stage" value={selectedPipelineStage?.name ? selectedPipelineStage.name : ''} onChange={selectStageHandler} options={pipelineStages} />
            </div>
          </>
        }
        {selectedPipeline && (pipelineStages?.length === 0) &&
          <>
            <div className={classes.menuSeparator}></div>
            <div className={classes.menuItem}>
              <div className={classes.createStage}>
                <div onClick={addStageHandler} className={classes.stageError}>0 stages exist for this pipeline</div>
                <Input
                  label="Enter new stage name..."
                  value={enteredNewStageName}
                  onChange={newStageInputHandler}
                  autoFocus
                  // isInvalid={newStageNameHasErrors}
                  errorMessage="Please enter a stage name"
                />
              </div>
            </div>
          </>
        }
        <div className={classes.menuItem}>
          <div className={classes.actions + " " + classes.dealActions}>
            {pipelines.length === 0 &&
              <>
                <SpinnerButton
                  margin=".5rem 0 .5rem 0"
                  color="button--white"
                  onClick={props.closePopper}
                >
                  Cancel
                </SpinnerButton>
                <SpinnerButton
                  margin=".5rem 0 .5rem 0"
                  color="button--blue"
                  // onClick={createDealHandler}
                  disabled={!enteredNewPipelineName}
                  isLoading={isLoading}
                  onClick={addPipelineHandler}
                >
                  Add Pipeline
                </SpinnerButton>
              </>
            }
            {pipelineStages.length === 0 && pipelines.length !== 0 && selectedPipeline &&
              <>
                <SpinnerButton
                  margin=".5rem 0 .5rem 0"
                  color="button--white"
                  onClick={props.closePopper}
                >
                  Cancel
                </SpinnerButton>
                <SpinnerButton
                  margin=".5rem 0 .5rem 0"
                  color="button--blue"
                  // onClick={createDealHandler}
                  disabled={!enteredNewStageName}
                  isLoading={isLoading}
                  onClick={addStageHandler}
                >
                  Add Stage
                </SpinnerButton>
              </>
            }
            {selectedPipeline && selectedPipelineStage &&
              <>
                <SpinnerButton
                  margin=".5rem 0 .5rem 0"
                  color="button--white"
                  onClick={props.closePopper}
                >
                  Cancel
                </SpinnerButton>
                <SpinnerButton
                  margin=".5rem 0 .5rem 0"
                  color="button--blue"
                  onClick={createDealHandler}
                  isLoading={isLoading}
                >
                  Add Deal
                </SpinnerButton>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateDealPopup;
