import classes from "./PlusIcon.module.css";
import { useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import mergeClasses from 'classnames';

const AddButton = (props) => {
  
  const [hover, setHover] = useState(false);

  const onEnterHandler = () => {
    setHover(true);
  }

  const onExitHandler = () => {
    setHover(false);
  };

  return (
    <div
      className={classes.addButton}
      onMouseEnter={onEnterHandler}
      onMouseLeave={onExitHandler}
      onClick={props.onClick}
    >
      <div className={
        mergeClasses(
          classes.container,
          hover ? classes.active : ""
        )
      }
        style={{
          height: props.size ? props.size : "",
          width: props.size ? props.size : ""
        }}
      >
        <BsPlusLg
          color={hover ? "white" : ""}
          size={props.size ? props.size : "14px"} />
      </div>
      <div className={mergeClasses(classes.label, hover && classes.active)} >
        {props.children}
      </div>
    </div>
  );
};

export default AddButton;


