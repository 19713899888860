import classes from "./PipelineBoard.module.css";
import Dots from "../../shared/components/UIElements/Dots/Dots";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import PipelineStage from "./PipelineStage";
import { AiOutlineColumnWidth } from "react-icons/ai";
import { createStage, updatePipeline, updateStage } from "../../store/pipeline-actions";
import Input from "../../shared/components/UIElements/Input/Input";
import SpinnerButton from "../../shared/components/UIElements/SpinnerButton/SpinnerButton";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

const PipelineBoard = (props) => {
  const dispatch = useDispatch();

  //add new stage
  const [newStageName, setNewStageName] = useState("");
  const [newStageNameHasErrors, setNewStageNameHasErrors] = useState(false);
  const [isAddStageOpen, setIsAddStageOpen] = useState(false);

  //use local state because react beautiful requires sychronous updates
  const [pipeline, setPipeline] = useState();
  const [stages, setStages] = useState();

  //sets new stage name with user inputs
  const newStageNameInputHandler = (e) => {
    setNewStageName(e.target.value);
    setNewStageNameHasErrors(false);
  };

  //creates new stage with dispatch to action creator
  const addNewStageHandler = () => {
    if (newStageName === "") {
      setNewStageNameHasErrors(true);
      return;
    }
    dispatch(
      createStage({ pipelineId: props.pipeline.id, stageName: newStageName })
    );
    setNewStageName("");
    setIsAddStageOpen(false);
  };

  //handle deal drop
  const dragEndHandler = async (result) => {
    const { destination, source, draggableId, type } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (type === 'column') {
      const newStageOrder = Array.from(pipeline.stages);
      newStageOrder.splice(source.index, 1);
      newStageOrder.splice(destination.index, 0, draggableId);

      const updatedPipeline = {
        ...pipeline,
        stages: newStageOrder
      }

      setPipeline(updatedPipeline);

      //dispatch to redux
      dispatch(updatePipeline(updatedPipeline))
      return;
    }

    const startStageIndex = stages.findIndex(
      (stage) => stage.id === source.droppableId
    );
    const startStage = stages[startStageIndex];

    const finishStageIndex = stages.findIndex(
      (stage) => stage.id === destination.droppableId
    );
    const finishStage = stages[finishStageIndex];

    //move deal within stage
    if (startStage === finishStage) {
      const stageDeals = Array.from(startStage.deals);
      stageDeals.splice(source.index, 1);
      stageDeals.splice(destination.index, 0, draggableId);

      const updatedStartStage = {
        ...startStage,
        deals: stageDeals
      };

      const copyStages = Array.from(stages);

      copyStages[startStageIndex] = updatedStartStage;

      setStages(copyStages);

      //dispatch to redux
      dispatch(updateStage(updatedStartStage));

      return;
    }

    // move deal to another stage
    const startStageDeals = Array.from(startStage.deals);
    startStageDeals.splice(source.index, 1);

    const updatedStartStage = {
      ...startStage,
      deals: startStageDeals
    };

    const finishStageDeals = Array.from(finishStage.deals);
    finishStageDeals.splice(destination.index, 0, draggableId);

    const updatedFinishStage = {
      ...finishStage,
      deals: finishStageDeals
    };

    const copyStages = Array.from(stages);

    copyStages[startStageIndex] = updatedStartStage;
    copyStages[finishStageIndex] = updatedFinishStage;

    setStages(copyStages);

    //dispatch to redux
    dispatch(updateStage(updatedStartStage));
    dispatch(updateStage(updatedFinishStage));
  };

  // //sort by stage index in pipeline stage array
  let orderedStages = [];
  pipeline?.stages.forEach((pos) => {
    const stage = stages.find((stage) => stage.id === pos);
    if (stage) {
      orderedStages.push(stage);
    }
  });



  //set stages to local state from store on render
  useEffect(() => {
    setStages([...props.stages]);
  }, [props.stages]);

  useEffect(() => {
    setPipeline(props.pipeline);
  }, [props.pipeline])

  return (
    <DragDropContext onDragEnd={dragEndHandler}>
      <Droppable droppableId="all-stages" direction="horizonal" type="column">
        {(provided, snapshot) => {
          return (
            <div
              className={classes.pipelineBoard}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {orderedStages?.map((stage, index) => {
                //sort by deal index in stage deal array
                let orderedDeals = [];
                stage.deals.forEach((pos) => {
                  const deal = props.deals.find((deal) => deal.id === pos);
                  if (deal) {
                    orderedDeals.push(deal);
                  }
                });

                return (
                  <PipelineStage
                    key={stage.id}
                    index={index}
                    stages={stages}
                    stage={stage}
                    deals={orderedDeals}
                  />
                );
              })}

              {!snapshot.isDraggingOver && (
                <div className={classes.stage}>
                  {!isAddStageOpen && (
                    <div
                      className={classes.addStage}
                      onClick={() =>
                        setIsAddStageOpen((prevState) => !prevState)
                      }
                    >
                      <AiOutlineColumnWidth />
                      <p>Add Stage</p>
                    </div>
                  )}
                  {isAddStageOpen && (
                    <div>
                      <Input
                        label="Name this stage"
                        value={newStageName}
                        onChange={newStageNameInputHandler}
                        autoFocus
                        isInvalid={newStageNameHasErrors}
                        errorMessage="Please enter a stage name"
                      />
                      <div className={classes.actions}>
                        <SpinnerButton
                          size="button--small"
                          color="button--blue"
                          onClick={addNewStageHandler}
                        >
                          Add stage
                        </SpinnerButton>
                        <SpinnerButton
                          size="button--small"
                          color="button--white"
                          onClick={() =>
                            setIsAddStageOpen((prevState) => !prevState)
                          }
                        >
                          Cancel
                        </SpinnerButton>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};

export default PipelineBoard;
