import classes from './ListBox.module.css';
import ListItems from './ListItems';
import ListBoxHeader from './ListBoxHeader';

const ListBox = props => {
    return (
        <div className={classes["list-box"]}>
            <ListBoxHeader />
            <ListItems />
        </div>
    )
};

export default ListBox;