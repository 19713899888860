import Input from "../Input/Input";
import Select from "../Select/Select";
import SpinnerButton from "../../UIElements/SpinnerButton/SpinnerButton";
import classes from "./Form.module.css";

const Form = (props) => {
  const formInputs = props.formFields.map((field) => {
    if (field.eleType === "input") {
      return (
        <Input
          key={field.id}
          type={field.type}
          name={field.name}
          label={field.label}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          isInvalid={field.isInvalid}
          errorMessage={field.errorMessage}
          autoFocus={field.autoFocus}
        />
      );
    }
    if (field.eleType === "select") {
      return (
        <Select
          key={field.id}
          label={field.label}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          isInvalid={field.isInvalid}
          options={field.options}
          errorMessage={field.errorMessage}
        />
      );
    }
    return false;
  });

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
      }}
    >
      <div className={classes["form-inputs"]}>{formInputs}</div>
      <div className={classes["form__actions"]}>
        {props.onCancelSubmit && (
          <SpinnerButton
            color="button--white"
            size={props.size}
            padding={props.padding}
            margin={props.margin}
            width={props.width}
            onClick={props.onCancelSubmit}
          >
            Cancel
          </SpinnerButton>
          // <div
          //   className={classes["form__actions__cancel-btn"]}
          //   onClick={props.onCancelSubmit}
          //   BtnColor="button--red"
          // ></div>
        )}
        <SpinnerButton
          padding={props.padding}
          margin={props.margin}
          width={props.width}
          color={props.color}
          size={props.size}
          isLoading={props.isLoading}
          disabled={!props.formIsValid}
          onClick={props.onSubmit}
        >
          {props.label}
        </SpinnerButton>
      </div>
    </form>
  );
};

export default Form;
