import React, { useState, useEffect, useRef } from "react";
import StatusBar from "../UIElements/StatusBar/StatusBar";
import classes from "./Task.module.css";
import { usePopper } from "react-popper";
import SpinnerButton from "../UIElements/SpinnerButton/SpinnerButton";
import Textarea from "../UIElements/Textarea/Textarea";
import { deleteTask, updateTask } from "../../../store/task-actions";
import { useDispatch } from 'react-redux';
import { RxTrash } from "react-icons/rx";
import { MdOutlineCancel, MdOutlineSettingsBackupRestore } from 'react-icons/md';
import useClickAwayListener from "../hooks/useClickAwayListener/useClickAwayLIstener";

const Task = (props) => {

  const dispatch = useDispatch();
  const [isOpenEditTask, setIsOpenEditTask] = useState(false);
  const [enteredTaskName, setEnteredTaskName] = useState("");
  const [taskNameInputHasErrors, setTaskNameInputHasErrors] = useState(false);
  const [enteredTaskDescription, setEnteredTaskDescription] = useState("");
  const [taskDescriptionInputHasErrors, setTaskDescriptionInputHasErrors] = useState(false);

  const openEditTaskHandler = () => {
    setIsOpenEditTask(true);
  }

  const completeTaskHandler = () => {
    dispatch(
      updateTask({
        ...props.task,
        completed: true
      })
    );
  }

  //delete task popper
  const [isDeleteTaskOpen, setIsDeleteTaskOpen] = useState(false);
  const [referenceRedDot, setReferenceRedDot] = useState(null);
  const [deleteTaskContainer, setDeleteTaskContainer] = useState(null);

  const deleteTaskPopper = usePopper(referenceRedDot, deleteTaskContainer, {
    placement: "bottom-end",
    modifiers: [
      { name: "offset", options: { offset: [0, 5] } },
      {
        name: "preventOverflow",
        options: {
          altAxis: true,
          padding: 10,
        },
      },
    ],
  });

  const toggleDeleteTaskPopperHandler = () => {
    setIsDeleteTaskOpen((prevState) => !prevState);
  };

  const deleteTaskClickAwayRef = useRef(null);
  useClickAwayListener(
      deleteTaskClickAwayRef,
      toggleDeleteTaskPopperHandler,
      referenceRedDot
  );

  const updateTaskHandler = () => {
    if (enteredTaskName === "") {
      setTaskNameInputHasErrors(true);
      return;
    }
    dispatch(
      updateTask({
        ...props.task,
        name: enteredTaskName,
        description: enteredTaskDescription,
      })
    );
    setIsOpenEditTask(false);
  }

  const cancelUpdateTaskHandler = () => {
    setIsOpenEditTask(false);
    setTaskNameInputHasErrors(false);
  }

  const taskNameInputHandler = (e) => {
    setEnteredTaskName(e.target.value);
  }

  const taskDescriptionInputHandler = (e) => {
    setEnteredTaskDescription(e.target.value);
  }

  const deleteTaskHandler = () => {
    dispatch(deleteTask({...props.task}));
    toggleDeleteTaskPopperHandler();
  }

  const retoreTaskHandler = () => {
    dispatch(
      updateTask({
        ...props.task,
        completed: false
      })
    );
  }

  useEffect(() => {
    setEnteredTaskName(props.task.name);
    setEnteredTaskDescription(props.task.description);
  }, [])

  const dotsHandler = (event) => {
    event.stopPropagation();
    if (event.target.dataset.color === "green") {
      completeTaskHandler();
    }
    if (event.target.dataset.color === "yellow") {
    }
    if (event.target.dataset.color === "red") {
      toggleDeleteTaskPopperHandler();
    }
  };

  return (
    <>
      {!isOpenEditTask ? (
        <div className={classes.task + ' ' + (props.task.completed === true ? classes.completed : '')}>
          <div className={classes.nameAndDescription}>
            <div className={classes.taskName} onClick={openEditTaskHandler}>{props.task.name}</div>
            <div className={classes.taskDescription} onClick={openEditTaskHandler}>
              {props.task.description}
            </div>
          </div>
          <div>
           {props.task.completed === false &&  <StatusBar onDotsHandler={dotsHandler} redDotRef={setReferenceRedDot} align='flex-end'/>}
            {props.task.completed === true &&  <div className={classes.restoreTask}><MdOutlineSettingsBackupRestore onClick={retoreTaskHandler} size="1rem"/></div>}
          </div>
        </div>
      ) : (
        <div className={classes.editTask}>
        <div className={classes.taskDetails}>
          <div className={classes.taskNameInput}>
            <Textarea value={enteredTaskName} onChange={taskNameInputHandler} hasErrors={taskNameInputHasErrors} rows="1" placeHolder="Type task's name" autoFocus={false}/>
          </div>
          <div className={classes.taskDescriptionInput}>
            <Textarea value={enteredTaskDescription} onChange={taskDescriptionInputHandler} hasErrors={taskDescriptionInputHasErrors} rows="1" fontSize='.75rem' placeHolder="Description" autoFocus={false}/>
          </div>
        </div>
        <div className={classes.horizontalLine}></div>
        <div className={classes.actions + ' ' + classes.newTaskActions}>
          <SpinnerButton margin=".5rem 0 .5rem 0" color="button--white" size='button--small' onClick={cancelUpdateTaskHandler}>Cancel</SpinnerButton>
          <SpinnerButton margin=".5rem 0 .5rem 0" color="button--blue" size='button--small' onClick={updateTaskHandler}>Save</SpinnerButton>
        </div>
      </div>
      )}
      {isDeleteTaskOpen && (
        <div ref={deleteTaskClickAwayRef}>
          <div
            ref={setDeleteTaskContainer}
            className={classes.popper}
            style={deleteTaskPopper.styles.popper}
            {...deleteTaskPopper.attributes.popper}
          >
            <div className={classes.deleteTask}>
            
              <h5>Are you sure?</h5>
              <div className={classes.actions}>
                <div className={classes.cancelIcon} onClick={toggleDeleteTaskPopperHandler}>
                  <MdOutlineCancel color="white" size="1.2rem"/>
                </div>
                <div className={classes.trashCan} onClick={deleteTaskHandler}>
                  <RxTrash color="white" size="1.2rem"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Task;
