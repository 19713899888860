import classes from "./MonthCell.module.css";
import EventSchedulerPopup from "../../shared/components/Events/EventSchedulerPopup";
import { usePopper } from "react-popper";
import useClickAwayListener from "../../shared/components/hooks/useClickAwayListener/useClickAwayLIstener";
import React, { useState, useRef, useEffect } from "react";
import EventItem from "../../shared/components/Events/EventItem";
import { deleteEvent, updateEvent } from "../../store/event-actions";
import { useDispatch, useSelector } from "react-redux";
import { useDrop } from "react-dnd";
import mergeClasses from "classnames";

const MonthCell = (props) => {
  const dispatch = useDispatch();

  const eventSchedulerOpen = useSelector(
    (state) => state.eventReducer.isEventSchedulerPopupOpen
  );
  const isToday =
    props.today.getTime() ===
    new Date(props.currentYear, props.currentMonth, props.day).getTime();

  const [hoveringSameDay, setHoveringSameDay] = useState(false);

  //Schedule event popper
  const [isEventSchedulerOpen, setIsEventSchedulerOpen] = useState(false);
  const [referenceSideBarEventScheduler, setReferenceSideBarEvenScheduler] =
    useState(null);
  const [eventScheduler, setEventScheduler] = useState(null);

  const eventSchedulerPopper = usePopper(
    referenceSideBarEventScheduler,
    eventScheduler,
    {
      placement: "bottom-end",
      modifiers: [
        { name: "offset", options: { offset: [0, 5] } },
        {
          name: "preventOverflow",
          options: {
            altAxis: true,
            padding: 10
          }
        }
      ]
    }
  );

  const doubleClickToggleEventScheduler = (e) => {
    if (eventSchedulerOpen || isEventSchedulerOpen) {
      return;
    }
    if (e.detail === 2) {
      setIsEventSchedulerOpen((prevState) => !prevState);
    }
  };

  const closeEventHandler = () => {
    setIsEventSchedulerOpen((prevState) => !prevState);
  };

  const eventSchedulerClickAwayRef = useRef(null);
  useClickAwayListener(
    eventSchedulerClickAwayRef,
    closeEventHandler,
    referenceSideBarEventScheduler
  );

  const deleteEventHandler = (e, event) => {
    e.stopPropagation();
    dispatch(deleteEvent(event));
  };

  let events = props.events;

  //sort events by start time
  events.sort((a, b) => {
    if (a.eventStarts < b.eventStarts) {
      return -1;
    }
    if (a.eventStarts > b.eventStarts) {
      return 1;
    }
    return 0;
  });

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: "events",
      hover: (item, monitor) => {
        //if hovering same day set same day to true to avoid animation
        if (new Date(item.event.eventStarts).getDate() === props.day) {
          setHoveringSameDay(true);
          return;
        }
      },
      drop: (item, monitor) => {
        //update the dragged events day/month/year to the dropped day/month/year

        //set consts from dragged event
        const event = item.event;
        const eventStartTime = event.eventStarts;
        const eventEndTime = event.eventEnds;

        //check if prev month's day
        if (props.prevDay) {
          //update start/end time to dropped day/month/year
          const updatedStartTime = new Date(eventStartTime).setFullYear(
            props.currentYear,
            props.currentMonth - 1,
            props.day
          );
          const updatedEndTime = new Date(eventEndTime).setFullYear(
            props.currentYear,
            props.currentMonth - 1,
            props.day
          );

          //create updated event as object
          const updatedEvent = {
            ...event,
            eventStarts: new Date(updatedStartTime).toISOString(),
            eventEnds: new Date(updatedEndTime).toISOString()
          };

          //dispatch to store
          dispatch(updateEvent(updatedEvent));

          return;
        }

        //check if next month's day
        if (props.nextDay) {
          //update start/end time to dropped day/month/year
          const updatedStartTime = new Date(eventStartTime).setFullYear(
            props.currentYear,
            props.currentMonth + 1,
            props.day
          );
          const updatedEndTime = new Date(eventEndTime).setFullYear(
            props.currentYear,
            props.currentMonth + 1,
            props.day
          );

          //create updated event as object
          const updatedEvent = {
            ...event,
            eventStarts: new Date(updatedStartTime).toISOString(),
            eventEnds: new Date(updatedEndTime).toISOString()
          };

          //dispatch to store
          dispatch(updateEvent(updatedEvent));
          return;
        }

        //check if same month
        if (!props.nextDay && !props.prevDay) {
          //update start/end time to dropped day/month/year
          const updatedStartTime = new Date(eventStartTime).setFullYear(
            props.currentYear,
            props.currentMonth,
            props.day
          );
          const updatedEndTime = new Date(eventEndTime).setFullYear(
            props.currentYear,
            props.currentMonth,
            props.day
          );

          //create updated event as object
          const updatedEvent = {
            ...event,
            eventStarts: new Date(updatedStartTime).toISOString(),
            eventEnds: new Date(updatedEndTime).toISOString()
          };

          //dispatch to store
          dispatch(updateEvent(updatedEvent));
        }
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver()
      })
    }),
    [props]
  );

  //resets hovering same day to false
  useEffect(() => {
    if (isOver) {
      setHoveringSameDay(false);
    }
  }, [isOver]);

  //display 3 events, set remaining events to 'see more' link to view calendar day
  let moreEvents = 0;
  events = events.map((event, index) => {
    if (index <= 2) {
      return (
        <EventItem key={event.id} event={event} deleteEventHandler={deleteEventHandler} />
      );
    } else {
      moreEvents = moreEvents + 1;
    }
  });

  return (
    <div
      className={mergeClasses(
        classes["day-container"],
        props.prevDay || props.nextDay ? classes.disabled : "",
        isOver && !hoveringSameDay ? classes.isOver : ""
      )}
      onClick={doubleClickToggleEventScheduler}
      ref={drop}
    >
      <div
        className={mergeClasses(
          classes.day,
          isToday && !props.prevDay && !props.nextDay ? classes.active : ""
        )}
        ref={setReferenceSideBarEvenScheduler}
      >
        {props.day}
      </div>
      {events}
      {moreEvents ? (
        <div
          className={classes["see-more"]}
          onClick={(e) => {
            props.seeMoreEvents(e, props);
          }}
        >
          {moreEvents + " " + "more..."}
        </div>
      ) : (
        ""
      )}
      {isEventSchedulerOpen && (
        <div ref={eventSchedulerClickAwayRef}>
          <div
            ref={setEventScheduler}
            className={classes.popper}
            style={eventSchedulerPopper.styles.popper}
            {...eventSchedulerPopper.attributes.popper}
          >
            <EventSchedulerPopup
              closePopper={closeEventHandler}
              // eventType="general"
              currentYear={props.currentYear}
              currentMonth={props.currentMonth}
              day={props.day}
              prevDay={props.prevDay}
              nextDay={props.nextDay}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MonthCell;
