import store from "./index";
import { uiActions } from "./ui";
import contact, { contactActions } from "./contact";
import { fileUploaderActions } from "./file-uploader";
import axios from "axios";
import { useEffect } from "react";
import { notificationActions } from "./notifications";

export const fetchContactData = () => {
  return async (dispatch) => {
    const userId = store.getState().userReducer.user.userId;
    const token = store.getState().userReducer.token;
    let contacts;
    try {
      dispatch(contactActions.setIsLoading(true));

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/contacts/user/${userId}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message);
      }

      contacts = responseData.contacts;

      //dispatch to store
      dispatch(
        contactActions.replaceContactData({
          contacts: contacts || [],
        })
      );
      dispatch(contactActions.setIsLoading(false));
    } catch (error) {
      dispatch(
        notificationActions.setToastNotification({
          status: "error",
          title: "Error",
          message: error.message,
        })
      );
    }
  };
};

export const createContact = (formData) => {
  const token = store.getState().userReducer.token;
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/contacts`,
        {
          method: "POST",
          body: JSON.stringify({
            firstName: formData.firstName,
            spouseName: formData.spouseName,
            lastName: formData.lastName,
            dateOfBirth: formData.dateOfBirth,
            spouseDateOfBirth: formData.spouseDateOfBirth,
            email: formData.email,
            phone: formData.phone,
            secondaryPhone: formData.secondaryPhone,
            streetNumber: formData.streetNumber,
            cityName: formData.cityName,
            stateName: formData.stateName,
            zipCode: formData.zipCode,
            contactType: formData.contactType,
            client: formData.client,
            status: formData.status,
            dealHeat: formData.dealHeat,
            assignedTo: formData.assignedTo,
            recent: formData.recent,
            referralSource: formData.referralSource,
            source: formData.source,
            campaignName: formData.campaignName,
            adName: formData.adName,
            eventType: formData.eventType,
            attendedEvent: formData.attendedEvent,
            eventLocation: formData.eventLocation,
            eventVenue: formData.eventVenue,
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message);
      }
      
      //dispatch to store
      dispatch(contactActions.addContact(responseData.contact));

    } catch (error) {
      dispatch(
        notificationActions.setToastNotification({
          status: "error",
          title: "Error",
          message: error.message || "Something went wrong, please try again.",
        })
      );
    }
  };
};

export const importContacts = (contacts) => {
  const token = store.getState().userReducer.token;
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/contacts/import`,
        contacts,
        {
          headers: {
            Authorization: "Bearer " + token,
            'Content-Type': 'application/json'
          },
          onUploadProgress: (data) => {
            const progress = Math.round((100 * data.loaded) / data.total);

            if (progress > 0) {
              dispatch(
                contactActions.setUploadProgress(
                  Math.round((100 * data.loaded) / data.total)
                )
              );
            }
            if (progress === 100) {
              dispatch(contactActions.setUploadProgress(0));
            }
          },
        }
      );
      if (response.status === 200) {
        dispatch(contactActions.serverSideImportComplete(true));
      }
      dispatch(contactActions.importContacts(response.data.contacts));
       
    } catch (error) {
      console.log(error)
    }
  };
};

export const updateContact = (contactData) => {
  const token = store.getState().userReducer.token;
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/contacts/${contactData.id}`,
        {
          method: "PATCH",
          body: JSON.stringify({
            firstName: contactData.firstName,
            spouseName: contactData.spouseName,
            lastName: contactData.lastName,
            dateOfBirth: contactData.dateOfBirth,
            spouseDateOfBirth: contactData.spouseDateOfBirth,
            email: contactData.email,
            phone: contactData.phone,
            secondaryPhone: contactData.secondaryPhone,
            streetNumber: contactData.streetNumber,
            cityName: contactData.cityName,
            stateName: contactData.stateName,
            zipCode: contactData.zipCode,
            contactType: contactData.contactType,
            client: contactData.client,
            status: contactData.status,
            dealHeat: contactData.dealHeat,
            creator: contactData.creator,
            assignedTo: contactData.assignedTo,
            recent: contactData.recent,
            referralSource: contactData.referralSource,
            source: contactData.source,
            campaignName: contactData.campaignName,
            adName: contactData.adName,
            eventType: contactData.eventType,
            attendedEvent: contactData.attendedEvent,
            eventLocation: contactData.eventLocation,
            eventVenue: contactData.eventVenue,
            disposition: contactData.disposition
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      const responseData = await response.json();

      //dispatch to store
      dispatch(contactActions.updateContact(responseData.contact));
      
    } catch (error) {
      dispatch(
        notificationActions.setToastNotification({
          status: "error",
          title: "Error",
          message:
            error.message || "Could not update contact, please try again.",
        })
      );
    }
  };
};
