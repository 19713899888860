import classes from "./EventSchedulerPopup.module.css";
import CloseX from "../UIElements/CloseX/CloseX";
import Calendar from "react-calendar";
import { useEffect, useState, useRef } from "react";
import {
  AiOutlineClockCircle,
  AiOutlineSwapRight,
  AiOutlineCalendar,
} from "react-icons/ai";
import { BsFillPersonCheckFill } from "react-icons/bs";
import { FaRegTrashAlt } from "react-icons/fa";
import { RiArrowDropDownFill, RiArrowDropUpFill } from "react-icons/ri";
import SpinnerButton from "../UIElements/SpinnerButton/SpinnerButton";
import { createEvent, updateEvent } from "../../../store/event-actions";
import { useDispatch } from "react-redux";
import PlusIcon from "../UIElements/PlusIcon/PlusIcon";
import { useSelector } from "react-redux";
import { convertTime } from "../utils/convertTime";
import event, { eventActions } from "../../../store/event";
import { formatPhoneNumber } from "../utils/formatPhoneNumber";
import { RxTrash } from "react-icons/rx";
import { deleteEvent } from "../../../store/event-actions";
import { useNavigate } from "react-router-dom";
import { useTwilioPhoneOutgoing } from "../hooks/useTwilioPhoneOutgoing/useTwilioPhoneOutgoing";
import { IoCall } from "react-icons/io5";
import { BsVolumeMuteFill } from "react-icons/bs";
import Textarea from "../UIElements/Textarea/Textarea";

const EventSchedulerPopup = (props) => {

  const dispatch = useDispatch()
  const navigate = useNavigate();
  const contacts = useSelector((state) => state.contactReducer.contacts);
  const [eventName, setEventName] = useState();
  const [eventNameHasError, setEventNameHasError] = useState(false);
  const [enteredContactName, setEnteredContactName] = useState("");
  const [contactHasError, setContactHasError] = useState(false);
  const [contact, setContact] = useState(null);
  const [filteredContacts, setFilteredContacts] = useState(null);
  const [isAddContact, setIsAddContact] = useState(false);
  const [isShowCalendarDropdown, setIsShowCalendar] = useState(false);
  const [eventType, setEventType] = useState("");
  const [eventTypeHasErrors, setEventTypeHasErrors] = useState();
  const [endTimeHasErrors, setEndTimefHasErrors] = useState();
  const [note, setNote] = useState();

  const calendarDropdownToggle = () => {
    setIsShowCalendar((prevState) => !prevState);
    setIsShowStartTimeDropdown(false);
    setIsShowEndTimeDropdown(false);
  };

  const {
    onPhone,
    callIsMuted,
    callIsActiveForContact,
    callStatus,
    callOutcome,
    makeCallHandler,
    hangUpCallHandler,
    muteCallHandler,
  } = useTwilioPhoneOutgoing(contact?.id);

  const [isRedmindContact, setIsRemindContact] = useState(false);
  const [isRedmindMe, setIsRedmindMe] = useState(false);

  const [startTimeDateString, setStartTimeDateString] = useState();
  const [startAmPm, setStartAmPm] = useState("pm");
  const [isShowStartTimeDropdown, setIsShowStartTimeDropdown] = useState(false);

  const [endTimeDateString, setEndTimeDateString] = useState();
  const [endAmPm, setEndAmPm] = useState("pm");
  const [isShowEndTimeDropdown, setIsShowEndTimeDropdown] = useState(false);

  //set default start/end time if not available through props
  useEffect(() => {

    if (!props.event) {
      const initializeStartDate = new Date();
      initializeStartDate.setHours("13");
      initializeStartDate.setMinutes("00");
      initializeStartDate.setSeconds(0);
      initializeStartDate.setMilliseconds(0);
      setStartTimeDateString(initializeStartDate);

      const initializeEndDate = new Date();
      initializeEndDate.setHours("13");
      initializeEndDate.setMinutes("30");
      initializeEndDate.setSeconds(0);
      initializeEndDate.setMilliseconds(0);
      setEndTimeDateString(initializeEndDate);
    }

    if (props.event) {
      setStartTimeDateString(new Date(props.event?.eventStarts));
      setEndTimeDateString(new Date(props.event?.eventEnds));
      setStartAmPm(new Date(props.event?.eventStarts).getHours() < 12 ? "am" : "pm");
      setEndAmPm(new Date(props.event?.eventEnds).getHours() < 12 ? "am" : "pm");
      setEventName(props.event?.eventName);
      setContact(...contacts.filter(con => con.id === props.event?.belongsTo))
      setEventType(props.event?.eventType);
      setIsRemindContact(props.event.remindContact);
      setIsRedmindMe(props.event?.remindMe);
      setNote(props.event?.eventNote);
    }
  }, []);


  //set date and time if available through props
  useEffect(() => {
    if (!props.prevDay && !props.nextDay && props.day && startTimeDateString && endTimeDateString) {
      setStartTimeDateString(new Date(new Date(props.currentYear, props.currentMonth, props.day).setHours(props.preselectedStartTime || props.preselectedStartTime === 0 ? props.preselectedStartTime : 13)));
      setEndTimeDateString(new Date(new Date(props.currentYear, props.currentMonth, props.day).setHours(props.preselectedStartTime || props.preselectedStartTime === 0 ? props.preselectedStartTime : 13, 30)));
      if (props.preselectedStartTime < 12) {
        setStartAmPm("am");
        setEndAmPm("am");
      } else {
        setStartAmPm("pm");
        setEndAmPm("pm");
      }
    }

    if (props.prevDay && startTimeDateString && endTimeDateString) {
      setStartTimeDateString(new Date(new Date(props.currentYear, props.currentMonth - 1, props.day).setHours(props.preselectedStartTime || props.preselectedStartTime === 0 ? props.preselectedStartTime : 13)));
      setEndTimeDateString(new Date(new Date(props.currentYear, props.currentMonth - 1, props.day).setHours(props.preselectedStartTime || props.preselectedStartTime === 0 ? props.preselectedStartTime : 13, 30)));
      if (props.preselectedStartTime < 12) {
        setStartAmPm("am");
        setEndAmPm("am");
      } else {
        setStartAmPm("pm");
        setEndAmPm("pm");
      }
    }

    if (props.nextDay && startTimeDateString && endTimeDateString) {
      setStartTimeDateString(new Date(new Date(props.currentYear, props.currentMonth + 1, props.day).setHours(props.preselectedStartTime || props.preselectedStartTime === 0 ? props.preselectedStartTime : 13)));
      setEndTimeDateString(new Date(new Date(props.currentYear, props.currentMonth + 1, props.day).setHours(props.preselectedStartTime || props.preselectedStartTime === 0 ? props.preselectedStartTime : 13, 30)));
      if (props.preselectedStartTime < 12) {
        setStartAmPm("am");
        setEndAmPm("am");
      } else {
        setStartAmPm("pm");
        setEndAmPm("pm");
      }
    }

  }, [props]);

  //set event type and remind if available from props
  useEffect(() => {
    if (props.eventType === "follow-up") {
      setEventType("follow-up");
    }
    if (props.eventType === "appointment") {
      setEventType("appointment");
    }
    if (props.event?.remindMe) {
      setIsRedmindMe(true);
    }
    if (props.event?.remindContact) {
      setIsRemindContact(true);
    }
  }, [props]);

  //START TIME: update date string to user selected time
  const startTimeHandler = (event) => {
    const hour = parseInt(event.target.value.split(":")[0]);
    const minutes = parseInt(event.target.value.split(":")[1]);
    const updatedDateString = new Date(startTimeDateString);
    updatedDateString.setHours(hour, minutes, 0, 0);
    setStartTimeDateString(updatedDateString);

    if (updatedDateString >= new Date(endTimeDateString)) {
      setEndTimeDateString(new Date(endTimeDateString?.setTime(updatedDateString?.getTime() + 30 * 60 * 1000)))
      if (new Date(endTimeDateString?.setTime(updatedDateString?.getTime() + 30 * 60 * 1000)).getHours() >= 12) {
        setEndAmPm("pm");
      }
    }
  };

  //START TIME: update am/pm with user selection
  const startAmPmHandler = (event) => {
    if (event.target.value === "am") {
      setStartAmPm(event.target.value);
      setStartTimeDateString(new Date(startTimeDateString.setTime(startTimeDateString.getTime() - 12 * 60 * 60 * 1000)));

      if (new Date(startTimeDateString) >= new Date(endTimeDateString)) {
        setEndTimeDateString(new Date(startTimeDateString?.setTime(startTimeDateString?.getTime() + 30 * 60 * 1000)))
        if (new Date(endTimeDateString?.setTime(startTimeDateString?.getTime() + 30 * 60 * 1000)).getHours() >= 12) {
          setEndAmPm("pm");
        }
      }
    }
    if (event.target.value === "pm") {
      setStartAmPm(event.target.value);
      setStartTimeDateString(new Date(startTimeDateString.setTime(startTimeDateString.getTime() + 12 * 60 * 60 * 1000)))

      if (new Date(startTimeDateString) >= new Date(endTimeDateString)) {
        setEndTimeDateString(new Date(startTimeDateString?.setTime(startTimeDateString?.getTime() + 30 * 60 * 1000)))
        if (new Date(endTimeDateString?.setTime(startTimeDateString?.getTime() + 30 * 60 * 1000)).getHours() >= 12) {
          setEndAmPm("pm");
        }
      }
    }
  };

  //START TIME: toggle dropdown to show available times
  const startTimeDropdownToggle = (event) => {
    setIsShowStartTimeDropdown((prevState) => !prevState);
    setIsShowEndTimeDropdown(false);
    setIsShowCalendar(false);
  };

  //END TIME: update date string to user selected time
  const endTimeHandler = (event) => {
    const hour = parseInt(event.target.value.split(":")[0]);
    const minutes = parseInt(event.target.value.split(":")[1]);
    const updatedDateString = new Date(endTimeDateString);
    updatedDateString.setHours(hour, minutes, 0, 0);
    setEndTimeDateString(updatedDateString);
  };

  //END TIME: update am/pm with user selection
  const endAmPmHandler = (event) => {
    if (event.target.value === "am") {
      setEndAmPm(event.target.value);
      setEndTimeDateString(new Date(endTimeDateString.setTime(endTimeDateString.getTime() - 12 * 60 * 60 * 1000)))
    }
    if (event.target.value === "pm") {
      setEndAmPm(event.target.value);
      setEndTimeDateString(new Date(endTimeDateString.setTime(endTimeDateString.getTime() + 12 * 60 * 60 * 1000)))
    }
  };

  //END TIME: toggle dropdown to show available times
  const endTimeDropdownToggle = (event) => {
    setIsShowEndTimeDropdown((prevState) => !prevState);
    setIsShowStartTimeDropdown(false);
    setIsShowCalendar(false);
    setEndTimefHasErrors(false);
  };

  //update start/end time strings from user selected calendar date
  const setTimeDateString = (event) => {

    setIsShowCalendar(false);

    const startTimeDate = new Date(event);
    const setStartTimeHours = startTimeDate.setHours(new Date(startTimeDateString).getHours());
    const setStartTimeMins = startTimeDate.setMinutes(new Date(startTimeDateString).getMinutes());
    setStartTimeDateString(startTimeDate);

    const endTimeDate = new Date(event);
    const setEndTimeHours = endTimeDate.setHours(new Date(endTimeDateString).getHours());
    const setEndTimeMins = endTimeDate.setMinutes(new Date(endTimeDateString).getMinutes());
    setEndTimeDateString(endTimeDate);

  };

  const remindContactToggle = () => {
    setIsRemindContact((prevState) => !prevState);
  };

  const remindMeToggle = () => {
    setIsRedmindMe((prevState) => !prevState);
  };

  const eventTypeHandler = (event) => {
    setEventType(event.target.id);
    setEventTypeHasErrors(false);
  }

  const toggleAddContactHandler = () => {
    setIsAddContact(prevState => !prevState);
  }

  const addContactHandler = (e, contact) => {
    setContact(contact);
    setFilteredContacts([]);
  }

  const removeContactHandler = () => {
    setContact(null);
  }

  const openContactModalHandler = () => {
    navigate(`/app/call-center/contacts/${contact?.id}/notes`);
  }

  const addNewContactHandler = () => {
    navigate("/app/call-center/contacts/new");
  }

  const eventNameHandler = (e) => {
    if (e.target.value != "") {
      setEventNameHasError(false);
    }
    setEventName(e.target.value);
  }

  const deleteEventHandler = (e, event) => {
    e.stopPropagation();
    dispatch(deleteEvent(event));
  };

  const onSearchHandler = (e) => {
    setEnteredContactName(e.target.value);
    setContactHasError(false);

    if (e.target.value === "") {
      setFilteredContacts(null);
      return;
    }

    const searchTerm = e.target.value.toLowerCase();
    setFilteredContacts(contacts.filter((contact) => {
      return contact.firstName.toLowerCase().includes(searchTerm) || contact.lastName.toLowerCase().includes(searchTerm) || (contact.firstName.toLowerCase() + " " + contact.lastName.toLowerCase()).includes(searchTerm);
    }))
  };

  const noteInputHandler = (event) => {
    setNote(event.target.value);
  }

  //returns true/false if start time is greater than end time
  const startEndTimeCompare = (hour, minutes) => {

    const copyStartDate = new Date(startTimeDateString);
    const add30Mins = new Date(copyStartDate.setTime(startTimeDateString?.getTime() + 30 * 60 * 1000))

    const copyEndDate = new Date(endTimeDateString);
    const addHourMins = new Date(copyEndDate.setHours(hour, minutes))

    const compareTimes = add30Mins > addHourMins;
    return compareTimes
  }

  //set popup open status to parent component
  useEffect(() => {
    dispatch(eventActions.isEventSchedulerPopupOpenHandler(true))
    return () => { dispatch(eventActions.isEventSchedulerPopupOpenHandler(false)) }
  }, [])

  const submitHandler = () => {
    if (!eventType && props.title) {
      setEventTypeHasErrors(true);
      return;
    }

    let formData;
    //contact data coming from props (e.g. contact modal)
    if (props.contact) {
      formData = {
        id: props.contact.id,
        firstName: props.contact.firstName,
        lastName: props.contact.lastName,
        eventName: eventName,
        eventType: eventType,
        eventStarts: startTimeDateString.toISOString(),
        eventEnds: endTimeDateString.toISOString(),
        remindMe: isRedmindMe,
        remindContact: isRedmindContact,
        eventNote: note,
        belongsTo: props.contact.id
      }
    }

    //contact data set by user with this component 
    if (contact?.id) {
      formData = {
        firstName: contact.firstName,
        lastName: contact.lastName,
        eventName: eventName,
        eventType: eventType,
        eventStarts: startTimeDateString.toISOString(),
        eventEnds: endTimeDateString.toISOString(),
        remindMe: isRedmindMe,
        remindContact: isRedmindContact,
        eventNote: note,
        belongsTo: contact.id
      }
    }

    //used for events with contact
    if (!props.contact && !contact?.id) {
      formData = {
        eventName: eventName,
        eventType: eventType,
        eventStarts: startTimeDateString.toISOString(),
        eventEnds: endTimeDateString.toISOString(),
        remindMe: isRedmindMe,
        remindContact: isRedmindContact,
        eventNote: note,
      }
    }

    //use to reschedule events with contact
    if (props.event && contact?.id) {
      formData = {
        id: props.event.id,
        firstName: contact.firstName,
        lastName: contact.lastName,
        eventName: eventName,
        eventType: eventType,
        eventStarts: startTimeDateString.toISOString(),
        eventEnds: endTimeDateString.toISOString(),
        remindMe: isRedmindMe,
        remindContact: isRedmindContact,
        eventNote: note,
        belongsTo: contact.id
      }
    }

    //use to reschedule events without contact
    if (props.event && !contact?.id) {
      formData = {
        id: props.event.id,
        eventName: eventName,
        eventType: eventType,
        eventStarts: startTimeDateString.toISOString(),
        eventEnds: endTimeDateString.toISOString(),
        remindMe: isRedmindMe,
        remindContact: isRedmindContact,
        eventNote: note,
      }
    }

    if (props.rescheduler) {
      dispatch(updateEvent(formData));
      props.closePopper()
      return;
    }

    dispatch(createEvent(formData));
    props.closePopper()
    // props.nextContactOnEventSave();
  }

  return (
    <div className={classes["event-scheduler-popup"]}>
      <div className={classes["menu"]}>
        <div className={classes["menu-item"]}>
          <div className={classes["event-name"]}>
            <div className={`${classes["form-control"]} ${eventNameHasError ? classes["invalid"] : ""}`}>

              {/*has event name with contact else sets to input*/}
              {(props.event?.eventName || !props.event?.eventName) && !props.event?.eventType && !props.title && <input type="text" placeholder="Event Name" autoFocus={!props.event?.eventName} onChange={eventNameHandler} value={eventName} />}

              {/*has event name and type with no contact else sets to input*/}
              {(props.event?.eventName || !props.event?.eventName) && props.event?.eventType && !props.title && !props.event?.belongsTo && <input type="text" placeholder="Event Name" autoFocus={!props.event?.eventName} onChange={eventNameHandler} value={eventName} />}

              {/*has event type and contact, blocks event name and title*/}
              {(props.event?.eventType === "follow-up" || props.event?.eventType === "appointment") && !props.event?.eventName && !props.title && props.event?.belongsTo && <div>{props.event?.eventType + " " + props.event?.firstName + " " + props.event?.lastName}</div>}

              {/*has event type, contact, and event name, blocks title*/}
              {(props.event?.eventType === "follow-up" || props.event?.eventType === "appointment") && props.event?.eventName && !props.title && props.event?.belongsTo && <input type="text" placeholder="Event Name" autoFocus={!props.event?.eventName} onChange={eventNameHandler} value={eventName} />}

              {props.title && <div>{props.title}</div>}

            </div>
          </div>
        </div>
        <div className={classes["menu-separator"]}></div>

        {!props.contact && !contact && !isAddContact ?
          <>
            <div className={classes["menu__item"]}>
              <div className={classes["add-contact"]}>
                {!props.contact ?
                  <PlusIcon onClick={toggleAddContactHandler} size="14px">Add Contact</PlusIcon> :
                  ""}
              </div>
            </div>
            <div className={classes["menu-separator"]}></div>
          </> : ""
        }

        {isAddContact && !contact &&
          <>
            <div className={classes["menu-item"]}>
              <div className={`${classes["form-control"]} ${contactHasError ? classes["invalid"] : ""}`}>
                <input
                  type="search"
                  placeholder="Type contact's name..."
                  autoFocus
                  onChange={onSearchHandler}
                  value={enteredContactName} />
              </div>
              {filteredContacts &&
                <div className={classes["filtered-contacts-list"]}>
                  {filteredContacts && filteredContacts.map(contact => { return <div onClick={(e) => { addContactHandler(e, contact) }}>{contact.firstName + " " + contact.lastName}</div> })}
                  {!filteredContacts.length && <div onClick={addNewContactHandler} className={classes["add-new-contact"]}>Add new contact?: <span>{enteredContactName}</span></div>}
                </div>}
            </div>
            <div className={classes["menu-separator"]}></div>
          </>
        }
        {contact &&
          <>
            <div className={classes["menu-item"]}>
              <div className={classes["added-contact"]}>
                <div className={classes["contact"]} onClick={openContactModalHandler}>
                  <BsFillPersonCheckFill color="lightgreen" />
                  <div>{contact.firstName + " " + contact.lastName + " "}</div>
                </div>
                <div className={classes["call-controls"]} >
                  <button className={`${classes["call-control"]} ${!callIsActiveForContact ? classes["answer-button"] : classes["mute-button"]} ${callIsMuted && classes["call-muted"]}`}
                    onClick={
                      !callIsActiveForContact
                        ? (event) =>
                          makeCallHandler(
                            event,
                            contact.phone,
                            contact.id
                          )
                        : muteCallHandler
                    }
                  >
                    {!callIsActiveForContact ? (
                      <IoCall size="24px" />
                    ) : <BsVolumeMuteFill />}
                  </button>
                  <button
                    className={`${classes["call-control"]} ${classes["hangup-button"]}`}
                    onClick={hangUpCallHandler}
                  >
                    <IoCall />
                  </button>
                </div>

                <FaRegTrashAlt onClick={removeContactHandler} />
              </div>
            </div>
            <div className={classes["menu-separator"]}></div>
          </>}
        <div className={`${classes["menu__item"]} ${classes["date-picker"]}`}>
          <div style={{ marginBottom: "1rem" }}>Choose event date</div>
          <div className={classes["calendar-dropdown"]}>
            <div
              className={classes["calendar-dropdown-inner"]}
              onClick={calendarDropdownToggle}
            >
              <AiOutlineCalendar color="gray" size="15" />
              <div
                style={{
                  flex: "2",
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                <span style={{ fontSize: ".7rem", color: "gray" }}>
                  Select a day
                </span>
                <span style={{ fontWeight: "600" }}>
                  {startTimeDateString?.getMonth() + 1 + "/" + startTimeDateString?.getDate() + "/" + startTimeDateString?.getFullYear()}
                </span>
              </div>
              {!isShowCalendarDropdown ? (
                <RiArrowDropDownFill color="gray" size="25" />
              ) : (
                <RiArrowDropUpFill color="gray" size="25" />
              )}
            </div>
          </div>
          {isShowCalendarDropdown && (
            <Calendar
              // tileDisabled={tileDisabled}
              className={classes["react-calendar"]}
              calendarType="US"
              showNeighboringMonth={false}
              formatShortWeekday={(locale, date) =>
                [`S`, `M`, `T`, `W`, `T`, `F`, `S`][date.getDay()]
              }
              onChange={setTimeDateString}
              value={startTimeDateString}
            />
          )}
        </div>
        <div className={`${classes["menu__item"]} ${classes["time-picker"]}`}>
          <div className={classes["start-time-dropdown"]}>
            <div
              className={classes["start-time-dropdown-inner"]}
              onClick={startTimeDropdownToggle}
            >
              <AiOutlineClockCircle color="gray" />
              <div
                style={{
                  flex: "2",
                  flexDirection: "column",
                  display: "flex",
                  gap: ".1rem",
                }}
              >
                <span style={{ fontSize: ".7rem", color: "gray" }}>
                  Starts
                </span>
                <span style={{ fontWeight: "600" }}>
                  {convertTime(new Date(startTimeDateString))}
                </span>
              </div>
              {!isShowStartTimeDropdown ? (
                <RiArrowDropDownFill color="gray" size="20" />
              ) : (
                <RiArrowDropUpFill color="gray" size="20" />
              )}
            </div>

            {isShowStartTimeDropdown && (
              <div className={classes["start-time-list"]}>
                <div className={classes["start-time"]}>
                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="start-time"
                      value={startAmPm === "am" ? "01:00" : "13:00"}
                      id="start-time-01:00"
                      checked={convertTime(new Date(startTimeDateString)).slice(0, -2) === "1:00"}
                      onChange={startTimeHandler}
                    />
                    <label htmlFor="start-time-01:00">1:00</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="start-time"
                      value={startAmPm === "am" ? "01:30" : "13:30"}
                      id="start-time-01:30"
                      checked={convertTime(new Date(startTimeDateString)).slice(0, -2) === "1:30"}
                      onChange={startTimeHandler}
                    />
                    <label htmlFor="start-time-01:30">1:30</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="start-time"
                      value={startAmPm === "am" ? "02:00" : "14:00"}
                      id="start-time-02:00"
                      checked={convertTime(new Date(startTimeDateString)).slice(0, -2) === "2:00"}
                      onChange={startTimeHandler}
                    />
                    <label htmlFor="start-time-02:00">2:00</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="start-time"
                      value={startAmPm === "am" ? "02:30" : "14:30"}
                      id="start-time-02:30"
                      checked={convertTime(new Date(startTimeDateString)).slice(0, -2) === "2:30"}
                      onChange={startTimeHandler}
                    />
                    <label htmlFor="start-time-02:30">2:30</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="start-time"
                      value={startAmPm === "am" ? "03:00" : "15:00"}
                      id="start-time-03:00"
                      checked={convertTime(new Date(startTimeDateString)).slice(0, -2) === "3:00"}
                      onChange={startTimeHandler}
                    />
                    <label htmlFor="start-time-03:00">3:00</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="start-time"
                      value={startAmPm === "am" ? "03:30" : "15:30"}
                      id="start-time-03:30"
                      checked={convertTime(new Date(startTimeDateString)).slice(0, -2) === "3:30"}
                      onChange={startTimeHandler}
                    />
                    <label htmlFor="start-time-03:30">3:30</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="start-time"
                      value={startAmPm === "am" ? "04:00" : "16:00"}
                      id="start-time-04:00"
                      checked={convertTime(new Date(startTimeDateString)).slice(0, -2) === "4:00"}
                      onChange={startTimeHandler}
                    />
                    <label htmlFor="start-time-04:00">4:00</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="start-time"
                      value={startAmPm === "am" ? "04:30" : "16:30"}
                      id="start-time-04:30"
                      checked={convertTime(new Date(startTimeDateString)).slice(0, -2) === "4:30"}
                      onChange={startTimeHandler}
                    />
                    <label htmlFor="start-time-04:30">4:30</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="start-time"
                      value={startAmPm === "am" ? "05:00" : "17:00"}
                      id="start-time-05:00"
                      checked={convertTime(new Date(startTimeDateString)).slice(0, -2) === "5:00"}
                      onChange={startTimeHandler}
                    />
                    <label htmlFor="start-time-05:00">5:00</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="start-time"
                      value={startAmPm === "am" ? "05:30" : "17:30"}
                      id="start-time-05:30"
                      checked={convertTime(new Date(startTimeDateString)).slice(0, -2) === "5:30"}
                      onChange={startTimeHandler}
                    />
                    <label htmlFor="start-time-05:30">5:30</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="start-time"
                      value={startAmPm === "am" ? "06:00" : "18:00"}
                      id="start-time-06:00"
                      checked={convertTime(new Date(startTimeDateString)).slice(0, -2) === "6:00"}
                      onChange={startTimeHandler}
                    />
                    <label htmlFor="start-time-06:00">6:00</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="start-time"
                      value={startAmPm === "am" ? "06:30" : "18:30"}
                      id="start-time-06:30"
                      checked={convertTime(new Date(startTimeDateString)).slice(0, -2) === "6:30"}
                      onChange={startTimeHandler}
                    />
                    <label htmlFor="start-time-06:30">6:30</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="start-time"
                      value={startAmPm === "am" ? "07:00" : "19:00"}
                      id="start-time-07:00"
                      checked={convertTime(new Date(startTimeDateString)).slice(0, -2) === "7:00"}
                      onChange={startTimeHandler}
                    />
                    <label htmlFor="start-time-07:00">7:00</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="start-time"
                      value={startAmPm === "am" ? "07:30" : "19:30"}
                      id="start-time-07:30"
                      checked={convertTime(new Date(startTimeDateString)).slice(0, -2) === "7:30"}
                      onChange={startTimeHandler}
                    />
                    <label htmlFor="start-time-07:30">7:30</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="start-time"
                      value={startAmPm === "am" ? "08:00" : "20:00"}
                      id="start-time-08:00"
                      checked={convertTime(new Date(startTimeDateString)).slice(0, -2) === "8:00"}
                      onChange={startTimeHandler}
                    />
                    <label htmlFor="start-time-08:00">8:00</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="start-time"
                      value={startAmPm === "am" ? "08:30" : "20:30"}
                      id="start-time-08:30"
                      checked={convertTime(new Date(startTimeDateString)).slice(0, -2) === "8:30"}
                      onChange={startTimeHandler}
                    />
                    <label htmlFor="start-time-08:30">8:30</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="start-time"
                      value={startAmPm === "am" ? "09:00" : "21:00"}
                      id="start-time-09:00"
                      checked={convertTime(new Date(startTimeDateString)).slice(0, -2) === "9:00"}
                      onChange={startTimeHandler}
                    />
                    <label htmlFor="start-time-09:00">9:00</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="start-time"
                      value={startAmPm === "am" ? "09:30" : "21:30"}
                      id="start-time-09:30"
                      checked={convertTime(new Date(startTimeDateString)).slice(0, -2) === "9:30"}
                      onChange={startTimeHandler}
                    />
                    <label htmlFor="start-time-09:30">9:30</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="start-time"
                      value={startAmPm === "am" ? "10:00" : "22:00"}
                      id="start-time-10:00"
                      checked={convertTime(new Date(startTimeDateString)).slice(0, -2) === "10:00"}
                      onChange={startTimeHandler}
                    />
                    <label htmlFor="start-time-10:00">10:00</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="start-time"
                      value={startAmPm === "am" ? "10:30" : "22:30"}
                      id="start-time-10:30"
                      checked={convertTime(new Date(startTimeDateString)).slice(0, -2) === "10:30"}
                      onChange={startTimeHandler}
                    />
                    <label htmlFor="start-time-10:30">10:30</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="start-time"
                      value={startAmPm === "am" ? "11:00" : "23:00"}
                      id="start-time-11:00"
                      checked={convertTime(new Date(startTimeDateString)).slice(0, -2) === "11:00"}
                      onChange={startTimeHandler}
                    />
                    <label htmlFor="start-time-11:00">11:00</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="start-time"
                      value={startAmPm === "am" ? "11:30" : "23:30"}
                      id="start-time-11:30"
                      checked={convertTime(new Date(startTimeDateString)).slice(0, -2) === "11:30"}
                      onChange={startTimeHandler}
                    />
                    <label htmlFor="start-time-11:30">11:30</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="start-time"
                      value={startAmPm === "am" ? "00:00" : "12:00"}
                      id="start-time-12:00"
                      checked={convertTime(new Date(startTimeDateString)).slice(0, -2) === "12:00"}
                      onChange={startTimeHandler}
                    />
                    <label htmlFor="start-time-12:00">12:00</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="start-time"
                      value={startAmPm === "am" ? "00:30" : "12:30"}
                      id="start-time-12:30"
                      checked={convertTime(new Date(startTimeDateString)).slice(0, -2) === "12:30"}
                      onChange={startTimeHandler}
                    />
                    <label htmlFor="start-time-12:30">12:30</label>
                  </div>
                </div>

                <div className={classes["am-pm"]}>
                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="am-pm"
                      value="am"
                      id="start-time-am"
                      onChange={startAmPmHandler}
                      checked={startAmPm === "am"}
                    />
                    <label htmlFor="start-time-am">AM</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="am-pm"
                      value="pm"
                      id="start-time-pm"
                      onChange={startAmPmHandler}
                      checked={startAmPm === "pm"}
                    />
                    <label htmlFor="start-time-pm">PM</label>
                  </div>
                  <button
                    className={classes["time-picker-button"]}
                    onClick={startTimeDropdownToggle}
                  >
                    Ok
                  </button>
                </div>
              </div>
            )}
          </div>
          <div
            style={{ height: "35px", display: "flex", alignItems: "center" }}
          >
            <AiOutlineSwapRight />
          </div>
          <div className={classes["end-time-dropdown"]}>
            <div
              className={classes["end-time-dropdown-inner"]}
              onClick={endTimeDropdownToggle}
              style={{ border: endTimeHasErrors ? "1px solid red" : "" }}
            >
              <AiOutlineClockCircle color="gray" />
              <div
                style={{
                  flex: "2",
                  flexDirection: "column",
                  display: "flex"
                }}
              >
                <span style={{ fontSize: ".7rem", color: "gray" }}>
                  Ends
                </span>
                <span style={{ fontWeight: "600" }}>
                  {convertTime(new Date(endTimeDateString))}
                </span>
              </div>
              {!isShowEndTimeDropdown ? (
                <RiArrowDropDownFill color="gray" size="20" />
              ) : (
                <RiArrowDropUpFill color="gray" size="20" />
              )}
            </div>
            {isShowEndTimeDropdown && (
              <div className={classes["end-time-list"]}>
                <div className={classes["end-time"]}>
                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="end-time"
                      value={endAmPm === "am" ? "01:00" : "13:00"}
                      id="01:00"
                      checked={convertTime(new Date(endTimeDateString)).slice(0, -2) === "1:00"}
                      onChange={endTimeHandler}
                      disabled={startEndTimeCompare(endAmPm === "am" ? "1" : "13", 0)}
                    />
                    <label htmlFor="01:00">1:00</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="end-time"
                      value={endAmPm === "am" ? "01:30" : "13:30"}
                      id="01:30"
                      checked={convertTime(new Date(endTimeDateString)).slice(0, -2) === "1:30"}
                      onChange={endTimeHandler}
                      disabled={startEndTimeCompare(endAmPm === "am" ? "1" : "13", 30)}
                    />{" "}
                    <label htmlFor="01:30">1:30</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="end-time"
                      value={endAmPm === "am" ? "02:00" : "14:00"}
                      id="02:00"
                      checked={convertTime(new Date(endTimeDateString)).slice(0, -2) === "2:00"}
                      onChange={endTimeHandler}
                      disabled={startEndTimeCompare(endAmPm === "am" ? "2" : "14", 0)}
                    />{" "}
                    <label htmlFor="02:00">2:00</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="end-time"
                      value={endAmPm === "am" ? "02:30" : "14:30"}
                      id="02:30"
                      checked={convertTime(new Date(endTimeDateString)).slice(0, -2) === "2:30"}
                      onChange={endTimeHandler}
                      disabled={startEndTimeCompare(endAmPm === "am" ? "2" : "14", 30)}
                    />{" "}
                    <label htmlFor="02:30">2:30</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="end-time"
                      value={endAmPm === "am" ? "03:00" : "15:00"}
                      id="03:00"
                      checked={convertTime(new Date(endTimeDateString)).slice(0, -2) === "3:00"}
                      onChange={endTimeHandler}
                      disabled={startEndTimeCompare(endAmPm === "am" ? "3" : "15", 0)}
                    />{" "}
                    <label htmlFor="03:00">3:00</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="end-time"
                      value={endAmPm === "am" ? "03:30" : "15:30"}
                      id="03:30"
                      checked={convertTime(new Date(endTimeDateString)).slice(0, -2) === "3:30"}
                      onChange={endTimeHandler}
                      disabled={startEndTimeCompare(endAmPm === "am" ? "3" : "15", 30)}
                    />{" "}
                    <label htmlFor="03:30">3:30</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="end-time"
                      value={endAmPm === "am" ? "04:00" : "16:00"}
                      id="04:00"
                      checked={convertTime(new Date(endTimeDateString)).slice(0, -2) === "4:00"}
                      onChange={endTimeHandler}
                      disabled={startEndTimeCompare(endAmPm === "am" ? "4" : "16", 0)}
                    />{" "}
                    <label htmlFor="04:00">4:00</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="end-time"
                      value={endAmPm === "am" ? "04:30" : "16:30"}
                      id="04:30"
                      checked={convertTime(new Date(endTimeDateString)).slice(0, -2) === "4:30"}
                      onChange={endTimeHandler}
                      disabled={startEndTimeCompare(endAmPm === "am" ? "4" : "16", 30)}
                    />{" "}
                    <label htmlFor="04:30">4:30</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="end-time"
                      value={endAmPm === "am" ? "05:00" : "17:00"}
                      id="05:00"
                      checked={convertTime(new Date(endTimeDateString)).slice(0, -2) === "5:00"}
                      onChange={endTimeHandler}
                      disabled={startEndTimeCompare(endAmPm === "am" ? "5" : "17", 0)}
                    />{" "}
                    <label htmlFor="05:00">5:00</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="end-time"
                      value={endAmPm === "am" ? "05:30" : "17:30"}
                      id="05:30"
                      checked={convertTime(new Date(endTimeDateString)).slice(0, -2) === "5:30"}
                      onChange={endTimeHandler}
                      disabled={startEndTimeCompare(endAmPm === "am" ? "5" : "17", 30)}
                    />{" "}
                    <label htmlFor="05:30">5:30</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="end-time"
                      value={endAmPm === "am" ? "06:00" : "18:00"}
                      id="06:00"
                      checked={convertTime(new Date(endTimeDateString)).slice(0, -2) === "6:00"}
                      onChange={endTimeHandler}
                      disabled={startEndTimeCompare(endAmPm === "am" ? "6" : "18", 0)}
                    />{" "}
                    <label htmlFor="06:00">6:00</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="end-time"
                      value={endAmPm === "am" ? "06:30" : "18:30"}
                      id="06:30"
                      checked={convertTime(new Date(endTimeDateString)).slice(0, -2) === "6:30"}
                      onChange={endTimeHandler}
                      disabled={startEndTimeCompare(endAmPm === "am" ? "6" : "18", 30)}
                    />{" "}
                    <label htmlFor="06:30">6:30</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="end-time"
                      value={endAmPm === "am" ? "07:00" : "19:00"}
                      id="07:00"
                      checked={convertTime(new Date(endTimeDateString)).slice(0, -2) === "7:00"}
                      onChange={endTimeHandler}
                      disabled={startEndTimeCompare(endAmPm === "am" ? "7" : "19", 0)}
                    />{" "}
                    <label htmlFor="07:00">7:00</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="end-time"
                      value={endAmPm === "am" ? "07:30" : "19:30"}
                      id="07:30"
                      checked={convertTime(new Date(endTimeDateString)).slice(0, -2) === "7:30"}
                      onChange={endTimeHandler}
                      disabled={startEndTimeCompare(endAmPm === "am" ? "7" : "19", 30)}
                    />{" "}
                    <label htmlFor="07:30">7:30</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="end-time"
                      value={endAmPm === "am" ? "08:00" : "20:00"}
                      id="08:00"
                      checked={convertTime(new Date(endTimeDateString)).slice(0, -2) === "8:00"}
                      onChange={endTimeHandler}
                      disabled={startEndTimeCompare(endAmPm === "am" ? "8" : "20", 0)}
                    />
                    <label htmlFor="08:00">8:00</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="end-time"
                      value={endAmPm === "am" ? "08:30" : "20:30"}
                      id="08:30"
                      checked={convertTime(new Date(endTimeDateString)).slice(0, -2) === "8:30"}
                      onChange={endTimeHandler}
                      disabled={startEndTimeCompare(endAmPm === "am" ? "8" : "20", 30)}
                    />
                    <label htmlFor="08:30">8:30</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="end-time"
                      value={endAmPm === "am" ? "09:00" : "21:00"}
                      id="09:00"
                      checked={convertTime(new Date(endTimeDateString)).slice(0, -2) === "9:00"}
                      onChange={endTimeHandler}
                      disabled={startEndTimeCompare(endAmPm === "am" ? "9" : "21", 0)}
                    />{" "}
                    <label htmlFor="09:00">9:00</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="end-time"
                      value={endAmPm === "am" ? "09:30" : "21:30"}
                      id="09:30"
                      checked={convertTime(new Date(endTimeDateString)).slice(0, -2) === "9:30"}
                      onChange={endTimeHandler}
                      disabled={startEndTimeCompare(endAmPm === "am" ? "9" : "21", 30)}
                    />{" "}
                    <label htmlFor="09:30">9:30</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="end-time"
                      value={endAmPm === "am" ? "10:00" : "22:00"}
                      id="10:00"
                      checked={convertTime(new Date(endTimeDateString)).slice(0, -2) === "10:00"}
                      onChange={endTimeHandler}
                      disabled={startEndTimeCompare(endAmPm === "am" ? "10" : "22", 0)}
                    />{" "}
                    <label htmlFor="10:00">10:00</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="end-time"
                      value={endAmPm === "am" ? "10:30" : "22:30"}
                      id="10:30"
                      checked={convertTime(new Date(endTimeDateString)).slice(0, -2) === "10:30"}
                      onChange={endTimeHandler}
                      disabled={startEndTimeCompare(endAmPm === "am" ? "10" : "22", 30)}
                    />{" "}
                    <label htmlFor="10:30">10:30</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="end-time"
                      value={endAmPm === "am" ? "11:00" : "23:00"}
                      id="11:00"
                      checked={convertTime(new Date(endTimeDateString)).slice(0, -2) === "11:00"}
                      onChange={endTimeHandler}
                      disabled={startEndTimeCompare(endAmPm === "am" ? "11" : "23", 0)}
                    />{" "}
                    <label htmlFor="11:00">11:00</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="end-time"
                      value={endAmPm === "am" ? "11:30" : "23:30"}
                      id="11:30"
                      checked={convertTime(new Date(endTimeDateString)).slice(0, -2) === "11:30"}
                      onChange={endTimeHandler}
                      disabled={startEndTimeCompare(endAmPm === "am" ? "11" : "23", 30)}
                    />{" "}
                    <label htmlFor="11:30">11:30</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="end-time"
                      value={endAmPm === "am" ? "00:00" : "12:00"}
                      id="12:00"
                      checked={convertTime(new Date(endTimeDateString)).slice(0, -2) === "12:00"}
                      onChange={endTimeHandler}
                      disabled={startEndTimeCompare(endAmPm === "am" ? "00" : "24", 0)}
                    />{" "}
                    <label htmlFor="12:00">12:00</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="end-time"
                      value={endAmPm === "am" ? "00:30" : "12:30"}
                      id="12:30"
                      checked={convertTime(new Date(endTimeDateString)).slice(0, -2) === "12:30"}
                      onChange={endTimeHandler}
                      disabled={startEndTimeCompare(endAmPm === "am" ? "00" : "12", 30)}
                    />{" "}
                    <label htmlFor="12:30">12:30</label>
                  </div>
                </div>

                <div className={classes["am-pm"]}>
                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="end-am-pm"
                      value="am"
                      id="end-am"
                      onChange={endAmPmHandler}
                      checked={endAmPm === "am"}
                    />{" "}
                    <label htmlFor="end-am">AM</label>
                  </div>

                  <div className={classes["form-control"]}>
                    <input
                      type="radio"
                      name="end-am-pm"
                      value="pm"
                      id="end-pm"
                      onChange={endAmPmHandler}
                      checked={endAmPm === "pm"}
                    />{" "}
                    <label htmlFor="end-pm">PM</label>
                  </div>
                  <button
                    className={classes["time-picker-button"]}
                    onClick={endTimeDropdownToggle}
                  >
                    Ok
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        {endTimeHasErrors && <div style={{ color: "red", marginTop: "0.5rem" }}>*Please select an end time</div>}
        <div
          className={`${classes["menu__item"]} ${classes["reminder-picker"]}`}
        >
          <div className={classes["remind-contact"]}>
            <div className={classes["form-control"]}>
              <input
                type="checkbox"
                id="remind-contact"
                checked={isRedmindContact}
                onChange={remindContactToggle}
              />
              <label htmlFor="remind-contact">Remind contact</label>
            </div>
          </div>
          <div className={classes["remind-me"]}>
            <div className={classes["form-control"]}>
              <input
                type="checkbox"
                id="remind-me"
                checked={isRedmindMe}
                onChange={remindMeToggle}
              />
              <label htmlFor="remind-me">Remind me</label>
            </div>
          </div>
        </div>
        <div className={classes["menu-separator"]}></div>
        <div className={classes["menu__item"]}>
          <div className={classes.note}>
            <Textarea className={classes["text-area-element"]} rows="1" onChange={noteInputHandler} value={note} placeholder="Note" />
          </div>
        </div>
        <div className={classes["menu-separator"]}></div>
        <div className={`${classes["menu__item"]} ${classes[""]}`}>
          <div style={{ marginBottom: "1rem" }}>Choose event type</div>
          <div className={classes["event-types"]}>
            <div className={classes["form-control"]}>
              <input
                type="radio"
                id="follow-up"
                name="event-type"
                onChange={eventTypeHandler}
                disabled={eventType === "appointment"}
                checked={eventType === "follow-up"}
              // disabled={props.eventType === "appointment"}
              />
              <label htmlFor="follow-up" style={{ border: eventTypeHasErrors ? "1px solid red" : "" }}>Follow-up</label>
            </div>
            <div className={classes["form-control"]}>
              <input
                type="radio"
                id="appointment"
                name="event-type"
                onChange={eventTypeHandler}
                disabled={eventType === "follow-up"}
                checked={eventType === "appointment"}
              // disabled={props.eventType === "follow-up"} 
              />
              <label htmlFor="appointment" style={{ border: eventTypeHasErrors ? "1px solid red" : "" }}>Appointment</label>
            </div>
          </div>
          {eventTypeHasErrors && <div style={{ color: "red", marginTop: "0.5rem" }}>*Please select an event type</div>}
        </div>
        <div className={classes["menu-separator"]}></div>
        <div className={classes["form-actions"]}>
          {props.rescheduler ? <div className={classes["delete"]}>
            {/* <RxTrash size="20px" className={classes["trash-icon"]} /> */}
            <span onClick={(e) => { deleteEventHandler(e, props.event) }}>Delete?</span>
          </div> : <div className={classes["delete"]}></div>}
          <SpinnerButton size="button--small" color="button--gray" onClick={props.closePopper}>Cancel</SpinnerButton>
          <SpinnerButton size="button--small" color="button--blue" onClick={submitHandler} disabled={!eventName && !contact && !props.contact}>{props.rescheduler ? "Reschedule" : "Schedule"}</SpinnerButton>
        </div>
      </div>
    </div >
  );
};

export default EventSchedulerPopup;
