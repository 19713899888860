import classes from "./CalendarBox.module.css";
import CalendarBoxHeader from "./CalendarBoxHeader";

const CalendarBox = () => {
  return (
    <div className={classes["calendar-box"]}>
      <CalendarBoxHeader />
    </div>
  );
};

export default CalendarBox;
