//Import react-based dependencies
import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "userSlice",
  initialState: {
    user: {
      userId: null,
      userName: null,
      userEmail: null,
      userSettings: {},
      firstLogin: false
    },
    token: null,
    isOnline: false,
    isLoading: false,
    isLoggedIn: false,
    tokenExpirationDate: null
  },
  reducers: {
    firstLogin(state, action) {
      state.user.firstLogin = action.payload;
    },
    login(state, action) {
      state.user.userId = action.payload.userId;
      state.user.userEmail = action.payload.userEmail;
      state.user.userName = action.payload.userName;
      state.user.userSettings = action.payload.userSettings;
      state.token = action.payload.token;
      const tokenExpirationDate =
        action.payload.expirationDate ||
        new Date(new Date().getTime() + 12 * 1000 * 60 * 60).toISOString();
      state.tokenExpirationDate = tokenExpirationDate;
      localStorage.setItem(
        "userData",
        JSON.stringify({
          userId: action.payload.userId,
          userName: action.payload.userName,
          userEmail: action.payload.userEmail,
          userSettings: action.payload.userSettings,
          isOnline: true,
          token: action.payload.token,
          expiration: tokenExpirationDate
        })
      );
      localStorage.setItem("is-open-workflow-modal-aside", true);
      localStorage.setItem("is-open-contact-aside", true);
      state.isLoggedIn = true;
    },
    logout: (state) => {
      //see index for logout action
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsOnline(state, action) {
      state.isOnline = action.payload;
    },
    updateUser(state, action) {
      state.user.userName = action.payload.name;
      state.user.userEmail = action.payload.email;
      state.user.userSettings = action.payload.settings;

      const storage = localStorage.getItem("userData");

      const updatedStorage = JSON.parse(storage);

      updatedStorage.userName = action.payload.name;
      updatedStorage.userEmail = action.payload.email;
      updatedStorage.userSettings = action.payload.settings;

      localStorage.setItem("userData", JSON.stringify(updatedStorage));
    }
  }
});

export const userActions = userSlice.actions;

export default userSlice.reducer;
