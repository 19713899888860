import { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import ZoomModal from "../Modal/ZoomModal";
import Note from "./Note";
import Spinner from "../UIElements/Spinner/Spinner";
import "./DateTime.js";
import { modalActions } from "../../../store/modal";
import classes from "./Notes.module.css";

const Notes = (props) => {
  const dispatch = useDispatch();

  let noteData;
  
  const contactNoteData = useSelector((state) => state.contactNoteReducer.notes.filter((note) => note.belongsTo === props.contactId));
  const dealNoteData = useSelector((state) => state.dealNoteReducer.notes.filter((note) => note.belongsTo === props.dealId));

  if (props.contactId) {
    noteData = contactNoteData;
  }

  if (props.dealId) {
    noteData = dealNoteData;
  }

  const isNoteLoading = useSelector((state) => state.contactNoteReducer.isLoading);

  //Scroll to most recent note
  const addedNote = useSelector((state) => state.contactNoteReducer.notes);

  // const endMessage = useRef(null);
  // useEffect(() => {
  //   setTimeout(() => {
  //     endMessage.current.scrollIntoViewIfNeeded();
  //   }, 300);
  // }, [addedNote]);

  //Open/close zoom modal when clicking images
  const showZoomModal = useSelector((state) => state.modalReducer.modals.showZoomModal);

  const showZoomModalHandler = (imageUrl) => {
    dispatch(modalActions.openZoomModal(imageUrl));
  };

  const closeModalHandler = () => {
    dispatch(modalActions.closeZoomModal());
  };

  //Import notes otherwise request new note
  let notes;
  if (noteData.length >= 1 && isNoteLoading) {
    notes = <Spinner customClasses="x-small" />;
  } else if (noteData.length >= 1 && !isNoteLoading) {
    notes = noteData.map((note) => {
      return (
        <Note
          key={note.id}
          noteData={note}
          onZoomModal={showZoomModalHandler}
        />
      );
    });
  } else {
    notes = (
      <p className={classes["notes-list-container__place-holder"]}>
        Add your notes, links, files, or any other relevant information...
      </p>
    );
  }
  return (
    <>
      {showZoomModal && <ZoomModal onCloseModal={closeModalHandler} />}
      <div className={classes["notes"]}>
        {notes}
        {/* Scroll to most recent message */}
        {/* <div ref={endMessage}></div> */}
      </div>
    </>
  );
};

export default Notes;
