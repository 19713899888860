import { useState, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PlusIcon from "../../UIElements/PlusIcon/PlusIcon";
import useInput from "../../hooks/user-input";
import HelpTip from "../../UIElements/HelpTip/HelpTip";
import SpinnerButton from "../../UIElements/SpinnerButton/SpinnerButton";
import { updateUser } from "../../../../store/user-actions";
import classes from "./PhoneSettings.module.css";
import CloseX from "../../UIElements/CloseX/CloseX";
import { notificationActions } from "../../../../store/notifications";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";
import { toggleDeviceOnOffIncomingCalls } from "../../../../store/twilio-actions";
import { userActions } from "../../../../store/user";
import { connectTwilioDevice } from "../../../../store/twilio-actions";
import Textarea from "../../UIElements/Textarea/Textarea";
import Input from "../../UIElements/Input/Input";

const PhoneSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.userReducer.user);
  const primaryNumber = useSelector(
    (state) => state.userReducer.user.userSettings.phone.number
  );

  const greeting = useSelector(
    (state) => state.userReducer.user.userSettings.phone.greeting
  );
  const twilioDeviceRegistrationStatus = useSelector(
    (state) => state.twilioReducer.deviceReadyForRegistration
  );
  const userPhoneNumber = useSelector(
    (state) => state.userReducer.user.userSettings.phone.number
  );

  const [greetingInput, setGreetingInput] = useState();
  const [isEditingGreeting, setIsEditingGreeting] = useState(false);
  const [greetingInputHasErrors, setGreetingInputHasErrors] = useState(false);
  const [isShowAddRoute, setIsShowAddRoute] = useState();
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchedAreaCode, setSearchedAreaCode] = useState("");
  const [selectedNumber, setSelectedNumber] = useState();

  const zipCodeFocusRef = useRef();

  const fetchAvailableNumbers = useCallback(async (areaCode) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/twilio/search-available-numbers`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            areaCode: areaCode
          })
        }
      );

      if (!response.ok) {
        throw new Error("There are no numbers available for the area code.");
      }

      const data = await response.json();

      if (searchedAreaCode === areaCode) {
        setPhoneNumbers((prevState) => [...data.phoneNumbers, ...prevState]);
      } else {
        setPhoneNumbers(data.phoneNumbers);
      }
      setIsLoading(false);
      setSearchedAreaCode(areaCode);
    } catch (error) {
      console.log(error);
      throw new Error("Could not fetch available phone numbers.");
    }
  });

  //Handle input and form validation
  const {
    value: enteredAreaCode,
    isValid: areaCodeIsValid,
    hasError: areaCodeInputHasError,
    valueChangeHandler: areaCodeChangeHandler,
    inputBlurHandler: areaCodeBlurHandler,
    reset: resetAreaCode
  } = useInput(
    (value) => value.trim() !== "" && value.length === 3 && value.length <= 4
  );

  //set form validity for submit button
  let formIsValid = false;
  if (areaCodeIsValid) {
    formIsValid = true;
  }

  const submitHandler = (e) => {
    e.preventDefault();
    fetchAvailableNumbers(enteredAreaCode);
  };

  const numberClickHandler = (phoneNumber) => {
    setSelectedNumber(phoneNumber);
  };

  const activatePhoneNumberHandler = useCallback(async (phoneNumber) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/twilio/provision-new-number`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            phoneNumber
          })
        }
      );

      if (!response.ok) {
        throw new Error("Could not activate the selected phone number.");
      }

      const responseData = await response.json();

      const activatedPhoneNumber = responseData.phoneNumber.replace(
        /[^0-9]+/g,
        ""
      );

      const userData = {
        ...user,
        userSettings: {
          ...user.userSettings,
          phone: {
            ...user.userSettings.phone,
            number: activatedPhoneNumber
          }
        }
      };

      dispatch(updateUser(userData));

      //set user 'online' to accept calls if they have a number and device is ready
      if (!twilioDeviceRegistrationStatus) {
        dispatch(connectTwilioDevice());
        dispatch(toggleDeviceOnOffIncomingCalls(false));
        dispatch(userActions.setIsOnline());
      }

      setIsLoading(false);
      navigate("/app/call-center/");
      dispatch(
        notificationActions.setToastNotification({
          status: "success",
          title: `Your Phone Number:`,
          message:
            "Your phone number is configured. You can now add contacts to make calls and send texts. You can view your number in settings under 'phone'."
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        notificationActions.setToastNotification({
          status: "error",
          title: "Error",
          message:
            "Something went wrong when trying to save your phone number, please try again."
        })
      );
    }
  });

  const greetingInputHandler = (input) => {
    setIsEditingGreeting(true);
    if (input.target.value === "") {
      setGreetingInputHasErrors(true);
      // setFirstNameInput({
      //   firstName: input.target.value,
      //   length: textWidthInPixels(placeholder, 25.5),
      // });
      return;
    }
    setGreetingInputHasErrors(false);
    setGreetingInput(input.target.value);
  };

  const updateGreetingHandler = (event) => {
    const userData = {
      ...user,
      userSettings: {
        ...user.userSettings,
        phone: {
          ...user.userSettings.phone,
          greeting: greetingInput
        }
      }
    };
    dispatch(updateUser(userData));
    setIsEditingGreeting(false);
  };

  const cancelGreetingUpdateHandler = (event) => {
    event.preventDefault();
    setIsEditingGreeting(false);
  };

  const [selectedDigit, setSelectedDigit] = useState(null);
  const [selectedRoute, setSelectedRoute] = useState(null);

  const selectDigitHandler = (e) => {
    setSelectedDigit(e.target.value);
  };

  const selectRouteHandler = (e) => {
    setSelectedRoute(e.target.value);
  };

  const showRouteHandler = () => {
    setIsShowAddRoute((prevState) => !prevState);
  };

  const addRouteHandler = useCallback(async () => {
    if (!selectedDigit || !selectedRoute) {
      alert("Please choose a digit and route!");
      return;
    }
    const userData = {
      ...user,
      userSettings: {
        ...user.userSettings,
        phone: {
          ...user.userSettings.phone,
          routes: [
            ...user.userSettings.phone.routes,
            {
              digit: selectedDigit,
              routeTo: selectedRoute
            }
          ]
        }
      }
    };
    setSelectedDigit("");
    setSelectedRoute("");
    dispatch(updateUser(userData));
  });

  const deleteRouteHandler = (event, route) => {
    const findRoute = user.userSettings.phone.routes.find(
      (i) => i.digit === route.digit
    );

    const routeIndex = user.userSettings.phone.routes.indexOf(findRoute);

    const updatedRoutes = [...user.userSettings.phone.routes];

    updatedRoutes.splice(routeIndex, 1);

    const userData = {
      ...user,
      userSettings: {
        ...user.userSettings,
        phone: {
          ...user.userSettings.phone,
          routes: [...updatedRoutes]
        }
      }
    };
    dispatch(updateUser(userData));
  };

  //remove used routes from dropdown
  const digits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "#"];
  const unusedDigits = digits.filter((digit) => {
    const usedDigits = Object.values(user.userSettings.phone.routes);
    return usedDigits.every((unusedDigit) => {
      return unusedDigit.digit != digit;
    });
  });

  const digitOptions = unusedDigits.map((option) => {
    return <option value={option}>{option}</option>;
  });

  //remove used routes from 'route to' dropdown
  const routes = [
    "routeToVoicemailBox",
    "routeToSales",
    "routeToCustomerService",
    "routeToMainMenu"
    // "routeToDialByName",
  ];
  const unusedRoutes = routes.filter((route) => {
    const usedRoutes = Object.values(user.userSettings.phone.routes);
    return usedRoutes.every((unusedRoute) => {
      return unusedRoute.routeTo != route;
    });
  });

  const routeOptions = unusedRoutes.map((route) => {
    return (
      <option value={route}>
        {route === "routeToSales"
          ? "Sales"
          : "" || route === "routeToCustomerService"
          ? "Customr Service"
          : "" || route === "routeToMainMenu"
          ? "Return to Main Menu"
          : "" || route === "routeToVoicemailBox"
          ? "Voicemail Box"
          : ""}
      </option>
    );
  });

  //Close modal, navigate to previous page
  const closeModalHandler = () => {
    navigate("/app/call-center");
  };

  useEffect(() => {
    setGreetingInput(greeting);

    // if (!primaryNumber) {
    //     zipCodeFocusRef.current.focus();
    // }
  }, []);

  return (
    <div className={classes["main"]}>
      <div className={classes["header"]}>
        <div className={classes["header-title"]}>Phone</div>
        <CloseX onClick={closeModalHandler} />
      </div>
      <div className={classes["menu_seperator"]}></div>
      <div className={classes["content"]}>
        <div className={classes["section"]}>
          {primaryNumber && (
            <div className={classes["section"]}>
              <div className={classes["title"]}>Your Primary Phone Number</div>
              <p>{formatPhoneNumber(primaryNumber)}</p>
            </div>
          )}
          {!primaryNumber && (
            <div
              className={classes["section"]}
              // style={{
              //     backgroundColor: "aliceblue",
              //     padding: "1rem",
              //     borderRadius: "0.3rem"
              // }}
            >
              <div className={classes["title"]}>
                {!selectedNumber
                  ? "Configure Your Phone Number"
                  : "Confirm Your Number"}
              </div>
              <p>
                {!selectedNumber
                  ? "Please enter a three-digit US area code and press search. This will be the phone number used for outgoing and incoming calls."
                  : "This will serve as your primary phone number. You cannot chanage your primary number, but you can add additional phone numbers."}
              </p>
              {!selectedNumber && (
                <form onSubmit={submitHandler}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                      marginBottom: "1rem",
                      justifyContent: "center"
                    }}
                  >
                    <div style={{display: 'flex', justifyContent: 'center', gap: '.5rem', alignItems: 'center'}}>
                      <Input
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        ref={zipCodeFocusRef}
                        className={classes["custom-input"]}
                        value={enteredAreaCode}
                        onChange={areaCodeChangeHandler}
                        onBlur={areaCodeBlurHandler}
                        isValid={areaCodeInputHasError}
                        label="Area Code"
                        autoFocus
                        width="30%"
                      />

                      <SpinnerButton
                        isLoading={isLoading}
                        disabled={!formIsValid}
                        color="button--blue"
                        size="button--small"
                      >
                        {searchedAreaCode != enteredAreaCode
                          ? "Search"
                          : "Load More"}
                      </SpinnerButton>
                    </div>
                  </div>
                </form>
              )}

              {selectedNumber && (
                <div className={classes["confirm-phone"]}>
                  <div
                    style={{
                      display: "flex",
                      textAlign: "center",
                      flexDirection: "column",
                      gap: "1rem",
                      marginBottom: "1.5rem"
                    }}
                  >
                    <h2>{formatPhoneNumber(selectedNumber)}</h2>
                    <div
                      style={{
                        display: "flex",
                        gap: "1rem",
                        justifyContent: "center"
                      }}
                    >
                      <SpinnerButton
                        isLoading={isLoading}
                        color="button--white"
                        size="button--medium"
                        onClick={() => setSelectedNumber("")}
                      >
                        Cancel
                      </SpinnerButton>
                      <SpinnerButton
                        isLoading={isLoading}
                        color="button--blue"
                        size="button--medium"
                        onClick={() =>
                          activatePhoneNumberHandler(selectedNumber)
                        }
                      >
                        Save
                      </SpinnerButton>
                    </div>
                  </div>
                </div>
              )}

              {!isLoading && phoneNumbers.length != 0 && (
                <div className={classes["phone-numbers"]}>
                  {phoneNumbers.map((number, index) => {
                    return (
                      <div
                        key={index}
                        className={classes["phone-number"]}
                        onClick={() => numberClickHandler(number)}
                      >
                        {formatPhoneNumber(number)}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </div>
        <div className={classes["section"]}>
          <div className={classes["title"]}>Customize Your Call Tree</div>
          <p>
            Customize your caller's experience by creating an interactive call
            tree. Complete the sections below, and within minutes you'll have a
            fully-functional call experience.
          </p>
        </div>
        <div className={classes["section"]}>
          <div className={classes["title"]}>
            <div>Greeting</div>
            <HelpTip>
              This is the message your caller's will hear when dialing your
              phone number.
            </HelpTip>
          </div>
          <p></p>
          <div className={classes.greeting}>
            <Textarea
              onChange={greetingInputHandler}
              onFocus={greetingInputHandler}
              value={greetingInput}
              placeholder="Customize your voicemail greeting and options..."
            />
          </div>
          {isEditingGreeting && (
            <div className={classes.actions}>
              <div className={classes["cancel-button"]}>
                <SpinnerButton
                  onClick={cancelGreetingUpdateHandler}
                  customClasses="button--white"
                  size="button--small"
                >
                  Cancel
                </SpinnerButton>
              </div>
              <div className={classes["update-button"]}>
                <SpinnerButton
                  onClick={updateGreetingHandler}
                  customClasses="button--blue"
                  size="button--small"
                >
                  Save
                </SpinnerButton>
              </div>
            </div>
          )}
        </div>
        <div className={classes["section"]}>
          <div className={classes["title"]}>
            <div>Call Routing Options</div>
            <HelpTip>
              Choose where your caller's are routed when they press one of the
              options you described in your greeting. All agents registered to
              the route will receive an incoming call when that option is
              chosen.
            </HelpTip>
          </div>
          {!isShowAddRoute && (
            <div className={`${classes["add-option"]}`}>
              <PlusIcon onClick={showRouteHandler}>Add Route</PlusIcon>
            </div>
          )}
          {isShowAddRoute && (
            <div className={classes.options}>
              <div className={classes["phone-buttons"]}>
                <label>Caller Presses:</label>
                <select value={selectedDigit} onChange={selectDigitHandler}>
                  <option selected>Choose a digit</option>
                  {digitOptions}
                </select>
              </div>
              <div className={classes["select-routes"]}>
                <label>Route to:</label>
                <select value={selectedRoute} onChange={selectRouteHandler}>
                  <option selected>Choose a route</option>
                  {routeOptions}
                </select>
              </div>
              <div className={classes.actions}>
                <SpinnerButton
                  onClick={showRouteHandler}
                  color="button--white"
                  size="button--small"
                  margin="0"
                >
                  Cancel
                </SpinnerButton>
                <SpinnerButton
                  onClick={addRouteHandler}
                  color="button--blue"
                  size="button--small"
                  margin="0"
                >
                  Add Route
                </SpinnerButton>
              </div>
            </div>
          )}
          <div className={classes["routes"]}>
            {user.userSettings.phone.routes.map((route, index) => {
              return (
                <div key={index} className={classes["route"]}>
                  <div>
                    If Caller Presses{" "}
                    <span style={{ fontWeight: "bold" }}>#{route.digit}</span>:
                    They're Routed to
                  </div>
                  <div style={{ flex: "2", fontWeight: "bold" }}>
                    {route.routeTo === "routeToSales"
                      ? "Sales"
                      : "" || route.routeTo === "routeToCustomerService"
                      ? "Customr Service"
                      : // : "" || route.routeTo === "routeToDialByName"
                      // ? "Dial By Name Directory"
                      "" || route.routeTo === "routeToMainMenu"
                      ? "Return to Main Menu"
                      : "" || route.routeTo === "routeToVoicemailBox"
                      ? "Voicemail Box"
                      : ""}
                  </div>
                  <button onClick={(event) => deleteRouteHandler(event, route)}>
                    delete
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhoneSettings;
