import classes from "./Select.module.css";
import mergeClasses from 'classnames';

const Select = (props) => {
  
  const selectClasses = mergeClasses(
    classes["form__control"],
    props.isInvalid ? classes["invalid"] : "");

  return (
    <div className={selectClasses}>
      <select
        onChange={props.onChange}
        value={props.value}
        onBlur={props.onBlur}
      >
        {props.options.map((option, index) => {
          return (
            <>
              <option value="" selected disabled hidden>
                {props.label}
              </option>
              <option value={option.value}>{option.name}</option>
            </>
          );
        })}
        {/* <option value="">{props.label}</option>
                <option value="unassigned">Unassigned</option>
                <option value="red">Red</option>
                <option value="yellow">Yellow</option>
                <option value="green">Green</option> */}
      </select>
    </div>
  );
};

export default Select;
