import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PlusIcon from "../../../shared/components/UIElements/PlusIcon/PlusIcon";
import { usePopper } from "react-popper";
import { userActions } from "../../../store/user";
import classes from "./ListBoxHeading.module.css";
import useClickAwayListener from "../../../shared/components/hooks/useClickAwayListener/useClickAwayLIstener";
import Dot from "../../../shared/components/UIElements/Dot/Dot";
import { toggleDeviceOnOffIncomingCalls } from "../../../store/twilio-actions";
import { ordinal_suffix_of } from "../../../shared/components/utils/ordinalSuffix";

const ListBoxHeading = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const importingContacts = useSelector((state) => state.modalReducer.modals.showImportContactModal);
  const isOnline = useSelector((state) => state.userReducer.isOnline);

  //Popper
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom",
    modifiers: [{ name: "arrow", options: { element: arrowElement } }],
  });

  const togglePopperHandler = () => {
    setIsPopperOpen(prev => !prev);
  };

  //Close popper on click away
  const clickAwayRef = useRef(null);
  useClickAwayListener(clickAwayRef, togglePopperHandler, referenceElement);

  const addContactHandler = () => {
    setIsPopperOpen((prevState) => !prevState);
    navigate("contacts/new");
  };

  const openCSVImport = () => {
    navigate("contacts/import");
    setIsPopperOpen();
  }

  //Set Date
  const days = ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"];
  const newDate = new Date();
  const day = days[newDate.getDay()];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const newMonth = new Date();
  const month = months[newMonth.getMonth()];
  const date = new Date().getDate();

  //Activate soft phone to receive calls if user has a phone number
  const userPhoneNumber = useSelector(state => state.userReducer.user.userSettings.phone.number);

  const onlineToggler = () => {
    const storedData = JSON.parse(localStorage.getItem("userData"));

    if (storedData.isOnline) {
      localStorage.setItem("userData",
        JSON.stringify({
          ...storedData,
          isOnline: false,
        }));
        dispatch(toggleDeviceOnOffIncomingCalls(true));
        dispatch(userActions.setIsOnline(false));
    } else {
      localStorage.setItem("userData",
      JSON.stringify({
        ...storedData,
        isOnline: true,
      }))
      dispatch(toggleDeviceOnOffIncomingCalls(false));
      dispatch(userActions.setIsOnline(true));
    }
  };

  return (
    <>
      <div className={classes["list-box__heading"]}>
        <div className={classes["list-box__heading__date"]}>
          <div style={{ marginRight: "0.2rem" }}>
            <Dot
              width="12px"
              height="12px"
              color={isOnline ? "green" : "unassigned"}
              isOnline={isOnline ? true : ""}
              onClick={onlineToggler}
            />
          </div>
          <h3 className={classes["list-bot__heading-_title"]}>Today</h3>
          <div className={classes["list-box__heading__day"]}>{day},</div>
          <div className={classes["list-box__heading__month"]}>{month}</div>
          <div className={classes["list-box__heading__year"]}>{ordinal_suffix_of(date)}</div>
        </div>
        <div
          className={classes["list-box__heading__add-contact"]}
          ref={setReferenceElement}
          onClick={togglePopperHandler}
        >
          <PlusIcon>Add Contact</PlusIcon>
        </div>
        {isPopperOpen && (
          <div ref={clickAwayRef}>
            <div
              ref={setPopperElement}
              className={classes.popper}
              style={styles.popper}
              {...attributes.popper}
            >
              <ul className={classes.menu}>
                <li
                  className={classes["menu_item"]}
                  onClick={addContactHandler}
                >
                  Add Contact
                </li>
                <li className={classes["menu_separator"]}></li>
                <li className={classes["menu_item"]} onClick={openCSVImport}>
                  <div>Import Contacts</div>
                </li>
              </ul>
              {/* < div ref={setArrowElement} id={classes.arrow} style={styles.arrow} /> */}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ListBoxHeading;
