import { createSlice } from "@reduxjs/toolkit";

const messageSlice = createSlice({
    name: "message",
    initialState: {
        messages: [],
        recentMessages: []
    },
    reducers: {
        replaceMessageData (state, action) {
            if (action.payload.messages.length) {
                const messages = [];
                action.payload.messages.forEach(message => messages.push(message));
                state.messages = messages;
            }
        },
        addMessage(state, action) {
            state.messages.push(action.payload);
        },
        addRecentMessage(state, action) {
            state.recentMessages.push(action.payload);
        },
        updateMessage(state, action) {
            const currentMessage = state.messages.find(message => message.id === action.payload.id);
            currentMessage.contactName = action.payload.contactName;
            currentMessage.read = action.payload.read;
            currentMessage.message = action.payload.message;
            currentMessage.numSegments = action.payload.numSegments;
            currentMessage.direction = action.payload.direction;
            currentMessage.from = action.payload.from;
            currentMessage.to = action.payload.to;
            currentMessage.numMedia = action.payload.numMedia;
            currentMessage.status = action.payload.status;
            currentMessage.sid = action.payload.sid;
            currentMessage.belongsTo = action.payload.belongsTo;
            currentMessage.id = action.payload.id;

        },
        updateRecentMessages(state, action) {
            const newMessages = state.recentMessages.filter(message => message.belongsTo !== action.payload.belongsTo);
            state.recentMessages = newMessages;

        }
    }
});

export const messageActions = messageSlice.actions;

export default messageSlice.reducer;