import classes from "./HelpTip.module.css";

const HelpTip = (props) => {
  return (
    <div className={props.children && classes["help-tip"]} style={{ color: props.color, border: props.border }}>
        <p>
          {props.children}
          <br />
          {/* <a href="#">Here is a link</a> */}
        </p>
    </div>
  );
};

export default HelpTip;
