import MonthCell from "./MonthCell";
import classes from "./CalendarMonth.module.css";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const CalendarMonth = (props) => {
    
    //import events from store
    const events = useSelector(state => state.eventReducer.events);

    //init array for days in year
    const daysInFullYear = [];

    //get prior december days equal to january start day offset
    const janStartDay = new Date(props.currentYear, 0, 1).getDay();
    const daysInPrevDec = Array.from({ length: new Date(props.currentYear - 1, 11 + 1, 0).getDate() }, (v, i) => i + 1);
    const janOffsetDays = janStartDay > 0 ? daysInPrevDec.slice(-janStartDay) : "";

    //get next january days equal to decembers last week ofset
    const decStartDay = new Date(props.currentYear, 11, 1).getDay(); //5
    const daysInNextJan = Array.from({ length: new Date(props.currentYear + 1, 0 + 1, 0).getDate() }, (v, i) => i + 1); //31
    const decDaysPlusOffset = decStartDay + new Date(props.currentYear, 11 + 1, 0).getDate(); //36
    const decOffsetDays = decDaysPlusOffset > 35 ? daysInNextJan.slice(0, 42 - decDaysPlusOffset) : daysInNextJan.slice(0, 35 - decDaysPlusOffset);

    //loop months to create array of days in year 
    props.months.map((month, index) => {
        const daysInMonth = new Date(props.currentYear, index + 1, 0).getDate();
        const days = Array.from({ length: daysInMonth }, (v, i) => i + 1);
        return daysInFullYear.push(...days);
    });

    //push prev/next year's jan/feb offset days
    daysInFullYear.unshift(...janOffsetDays);
    daysInFullYear.push(...decOffsetDays);

    //slice full year of days into months
    const daysPassed = Math.ceil((new Date(props.currentYear, props.currentMonth) - new Date(props.currentYear, 0)) / (1000 * 60 * 60 * 24));
    const monthStartOffset = new Date(props.currentYear, props.currentMonth, 1).getDay();
    const daysInCurrentMonth = new Date(props.currentYear, props.currentMonth + 1, 0).getDate();
    const monthEndOffset = monthStartOffset + daysInCurrentMonth <= 35 ? 35 - (monthStartOffset + daysInCurrentMonth) : 42 - (monthStartOffset + daysInCurrentMonth);

    const formattedMonth = daysInFullYear.slice(
        janOffsetDays.length + daysPassed - monthStartOffset,
        janOffsetDays.length + daysPassed + daysInCurrentMonth + monthEndOffset);

    // add/remove rows for longer months
    useEffect(() => {
        const calBody = document.querySelector('.CalendarMonth_cal-body__V4-eD');
        if (monthStartOffset + daysInCurrentMonth > 35) {
            calBody.style.gridTemplateRows = "repeat(6, 1fr)";
        } else {
            calBody.style.gridTemplateRows = "repeat(5, 1fr)";
        }

    }, [props.currentMonth]);

    //compare events dates from store to dates in current month and return matching event
    const eventFilter = (day, index) => {

        if (index < monthStartOffset) {
            return events.filter(event => new Date(new Date(event.eventStarts).setHours(0, 0, 0, 0)).getTime() === new Date(new Date(props.currentYear, props.currentMonth - 1, day).setHours(0, 0, 0, 0)).getTime());
        }
        if (index + 1 > monthStartOffset && index < daysInCurrentMonth + monthStartOffset) {
            return events.filter(event => new Date(new Date(event.eventStarts).setHours(0, 0, 0, 0)).getTime() === new Date(new Date(props.currentYear, props.currentMonth, day).setHours(0, 0, 0, 0)).getTime());
        }
        if (index + 1 > daysInCurrentMonth + monthStartOffset) {
            return events.filter(event => new Date(new Date(event.eventStarts).setHours(0, 0, 0, 0)).getTime() === new Date(new Date(props.currentYear, props.currentMonth + 1, day).setHours(0, 0, 0, 0)).getTime());
        }
    }

    return (
        <>
            <div className={classes["cal-head"]}>
                {props.days.map((day, index) => {
                    return <div key={index}>{day}</div>;
                })}
            </div>
            <div className={classes["cal-body"]} >
                {formattedMonth.map((day, index) => {
                    return <MonthCell
                        key={index}
                        events={eventFilter(day, index)}
                        currentYear={props.currentYear}
                        currentMonth={props.currentMonth}
                        monthStartOffset={monthStartOffset}
                        monthEndOffset={monthEndOffset}
                        daysInCurrentMonth={daysInCurrentMonth}
                        day={day}
                        index={index}
                        prevDay={index < monthStartOffset}
                        nextDay={index + 1 > daysInCurrentMonth + monthStartOffset}
                        seeMoreEvents={props.seeMoreEvents}
                        today={props.today}
                    />
                })}
            </div>
        </>
    )
}

export default CalendarMonth;