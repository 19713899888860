import ReactDom from "react-dom";
import { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Modal from "../../UIElements/Modal/Modal";
import classes from "./ContactModal.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleUp,
  faAngleDown,
  faVolumeMute,
  faTriangleExclamation,
  faAngleLeft,
  faAngleRight
} from "@fortawesome/free-solid-svg-icons";
import PlusIcon from "../../UIElements/PlusIcon/PlusIcon";

import { updateContact } from "../../../../store/contact-actions";
import { useTwilioPhoneOutgoing } from "../../hooks/useTwilioPhoneOutgoing/useTwilioPhoneOutgoing";
import { twilioActions } from "../../../../store/twilio";
import ContactCommunications from "./ContactCommunications";
import CSSTransition from "react-transition-group/CSSTransition";
import NotificationModal from "../NotificationModal/NotificationModal";
import CloseX from "../../UIElements/CloseX/CloseX";
import { usePopper } from "react-popper";
import useClickAwayListener from "../../../../shared/components/hooks/useClickAwayListener/useClickAwayLIstener";
import EventSchedulerPopup from "../../Events/EventSchedulerPopup";
import { deleteEvent } from "../../../../store/event-actions";
import EventItem from "../../Events/EventItem";
import { AiOutlineSwapRight } from "react-icons/ai";
import { ReactComponent as CalendarIcon } from "../../../../assets/imgs/sidedrawer-calendar-icon.svg";
import { ReactComponent as WorkflowIcon } from "../../../../assets/imgs/sidedrawer-workflow-icon.svg";
import CreateDealPopup from "../../Deals/CreateDealPopup";
import { dobToAge } from "../../utils/dobToAge";
import ContactsDetails from "./ContactsDetails";
import mergeClasses from "classnames";
import Dialer from './AutoDialer/Dialer';

const ContactModal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const asideRef = useRef();

  //find contact in redux from params
  const contactId = params.contactId;
  const contacts = useSelector((state) => state.contactReducer.contacts);
  const contact = contacts.find((contact) => contact.id === contactId);

  //misc state
  const [autoDial, setAutoDial] = useState(false);
  const [isAutoDialPaused, setIsAutoDialPaused] = useState(false);
  const [isShowExitAutoDialerModal, setIsShowExitAutoDialerModal] =
    useState(false);
  const [isShowDeleteContact, setIsShowDeleteContact] = useState(false);
  const [isAsideOpen, setIsAsideOpen] = useState(
    localStorage.getItem("is-open-contact-aside") === "true" ? true : false
  );

  //open/closes/saves aside status to state and localstorage
  const toggleAsideHandler = (status) => {
    if (status === true) {
      setIsAsideOpen(true);
      localStorage.setItem("is-open-contact-aside", true);
    } else {
      setIsAsideOpen(false);
      localStorage.setItem("is-open-contact-aside", false);
    }
  };

  //Load contact's events from store
  const events = useSelector((state) => state.eventReducer.events).filter(
    (event) => {
      return event.belongsTo === contactId;
    }
  );

  //Close modal handler by navigating to previous page
  const closeModalHandler = () => {
    if (autoDial || isAutoDialPaused) {
      setIsShowExitAutoDialerModal(true);
      return;
    }
    navigate("/app/call-center");
  };

  const closeNotificationModalHandler = () => {
    setIsShowExitAutoDialerModal(false);
    setIsShowDeleteContact(false);
  };

  const exitAutoDialSession = () => {
    navigate("/app/call-center");
    // hangUpCallHandler();
  };

  const deleteEventHandler = (e, event) => {
    e.stopPropagation();
    dispatch(deleteEvent(event));
  };

  const deleteContactHandler = (event, disposition) => {
    dispatch(
      updateContact({
        ...contact,
        status: "red",
        disposition: disposition
      })
    );
    nextContactHandler();
    closeNotificationModalHandler();
    if (isAutoDialPaused) {
      nextContactHandler();
    }
  };

  const restoreLeadStatusHandler = (event) => {
    event.stopPropagation();
    dispatch(
      updateContact({
        ...contact,
        status: "unassigned"
      })
    );
  };

  //filtered contacts used to find previous and next contact
  const filteredContacts = useSelector(
    (state) => state.contactReducer.filteredContacts
  );

  //route to previous contact
  const previousContactHandler = () => {
    setIsDispositionOpen(false);
    const currentContact = filteredContacts.findIndex(
      (con) => con.id === contact.id
    );
    const previousContact = filteredContacts[currentContact - 1];
    if (!previousContact) {
      return;
    }
    navigate(`/app/call-center/contacts/${previousContact.id}/notes`);
  };

  //route to next contact
  const nextContactHandler = () => {
    setIsDispositionOpen(false);
    const currentContact = filteredContacts.findIndex(
      (con) => con.id === contact.id
    );
    const nextContact = filteredContacts[currentContact + 1];
    if (!nextContact) {
      return;
    }
    navigate(`/app/call-center/contacts/${nextContact.id}/notes`);
    return nextContact
  };

  //------------------------- Poppers ----------------------//

  //schedule follow-up popper
  const [isFollowupSchedulerOpen, setIsFollowupSchedulerOpen] = useState(false);
  const [yellowDotReference, setYellowDotReference] = useState(null);
  const [followUpScheduler, setFollowupScheduler] = useState(null);

  const followupPopper = usePopper(yellowDotReference, followUpScheduler, {
    placement: "bottom-end",
    modifiers: [
      { name: "offset", options: { offset: [0, 5] } },
      {
        name: "preventOverflow",
        options: {
          altAxis: true,
          padding: 10
        }
      }
    ]
  });

  const toggleFollowupSchedulerPopperHandler = () => {
    setIsFollowupSchedulerOpen((prevState) => !prevState);
  };

  const followupSchedulerClickAwayRef = useRef(null);
  useClickAwayListener(
    followupSchedulerClickAwayRef,
    toggleFollowupSchedulerPopperHandler,
    yellowDotReference
  );

  //contact disposition popper
  const [isSetDispositionOpen, setIsDispositionOpen] = useState(false);
  const [dispositionPopperElement, setDispositionPopperElement] =
    useState(null);
  const [dispositionIconRef, setDispositionIconRef] = useState(null);

  const dispositionPopper = usePopper(
    dispositionIconRef,
    dispositionPopperElement,
    {
      placement: "bottom-start",
      modifiers: [
        { name: "offset", options: { offset: [5, 5] } },
        {
          name: "preventOverflow",
          options: {
            altAxis: true,
            padding: 10
          }
        }
      ]
    }
  );

  const toggleDispositionHandler = () => {
    setIsDispositionOpen((prevState) => !prevState);
    setIsScheduleAppointmentOpen(false);
    setIsAddNewDealOpen(false);
  };

  const selectScheduleOrPipelineClickAwayRef = useRef(null);
  useClickAwayListener(
    selectScheduleOrPipelineClickAwayRef,
    toggleDispositionHandler,
    dispositionIconRef
  );

  //schedule event popper
  const [isEventSchedulerOpen, setIsEventSchedulerOpen] = useState(false);
  const [referenceSideBarEventScheduler, setReferenceSideBarEvenScheduler] =
    useState(null);
  const [eventScheduler, setEventScheduler] = useState(null);

  const eventSchedulerPopper = usePopper(
    referenceSideBarEventScheduler,
    eventScheduler,
    {
      placement: "bottom",
      modifiers: [
        { name: "offset", options: { offset: [0, 5] } },
        {
          name: "preventOverflow",
          options: {
            altAxis: true,
            padding: 10
          }
        }
      ]
    }
  );

  const toggleEventSchedulerPopperHandler = () => {
    setIsEventSchedulerOpen((prevState) => !prevState);
  };

  const eventSchedulerClickAwayRef = useRef(null);
  useClickAwayListener(
    eventSchedulerClickAwayRef,
    toggleEventSchedulerPopperHandler,
    referenceSideBarEventScheduler
  );

  //handle contact status update
  const [contactStatus, setContactStatus] = useState();
  const [updateContactReady, setUpdateContactReady] = useState(false);

  const isEventLoading = useSelector(
    (state) => state.eventReducer.isEventLoading
  );
  const isNoteLoading = useSelector(
    (state) => state.contactNoteReducer.isNoteLoading
  );

  const updateContactStatusHandler = (event) => {
    event.stopPropagation();
    if (event.target.dataset.color === "green") {
      setContactStatus(event.target.dataset.color);
      setIsDispositionOpen(true);
    }
    if (event.target.dataset.color === "yellow") {
      //move to next contacct --> see above
      setContactStatus(event.target.dataset.color);
      setIsFollowupSchedulerOpen(true);
    }
    if (event.target.dataset.color === "red") {
      if (autoDial) {
        setIsAutoDialPaused(true);
      }
      setIsShowDeleteContact(true);
      // hangUpCallHandler();
      dispatch(twilioActions.callOutcome(""));
    }
  };

  const [isShceduleAppointmentOpen, setIsScheduleAppointmentOpen] =
    useState(false);
  const [isAddNewDealOpen, setIsAddNewDealOpen] = useState(false);

  const isScheduleAppointmentOpenHandler = async () => {
    setIsScheduleAppointmentOpen(true);
    // await dispositionPopper.update();
  };

  const isAddNewDealOpenHandler = () => {
    setIsAddNewDealOpen(true);
  };

  //passed to event scheduler and called when event is scheduled
  const nextContactOnEventSave = () => {
    setUpdateContactReady(true);
    // if (isAutoDialPaused) {
    //   setIsShowAutoDialNotification(true);
    // }
  };

  //dispatches contact status when event scheduling is complete
  useEffect(() => {
    if (updateContactReady && !isEventLoading && !isNoteLoading) {
      dispatch(
        updateContact({
          ...contact,
          status: contactStatus
        })
      );
      // nextContactHandler();
      setUpdateContactReady(false);
      setContactStatus("");
    }
  }, [isEventLoading, isNoteLoading]);

  const contactDeleted = useSelector(
    (state) =>
      state.contactReducer.contacts.find((con) => con.id === contact.id)
        .status === "red"
  );

  //create array of contacts deals
  const [contactsDeals, setContactsDeals] = useState([]);
  const pipelines = useSelector((state) => state.pipelineReducer.pipelines);
  const stages = useSelector((state) => state.pipelineReducer.stages);
  const deals = useSelector((state) => state.pipelineReducer.deals);

  //route to deal on click
  const openDealHandler = (event, pipelineId, dealId) => {
    navigate(`/app/pipeline/${pipelineId}/deal/${dealId}`);
  };

  useEffect(() => {
    setContactsDeals([]);
    deals.map((deal) => {
      const contactDeal = {};

      if (deal.contactId === contact.id) {
        //add deal id to contact deal
        contactDeal["id"] = deal.id;
        contactDeal["description"] = deal.description;

        stages.map((stage) => {
          if (stage.id === deal.belongsTo) {
            //add stage name to contact deal
            contactDeal["stage"] = stage.name;
            contactDeal["pipelineId"] = stage.belongsTo;

            pipelines.map((pipeline) => {
              if (pipeline.id === stage.belongsTo) {
                //add pipeline name to contact deal
                contactDeal["pipeline"] = pipeline.name;
              }
            });
          }
        });
      } else {
        return;
      }
      //add deal object to contacts deals array
      setContactsDeals((prevState) => [...prevState, contactDeal]);
    });
  }, [deals, contact]);

  return (
    <>
      {isShowExitAutoDialerModal && (
        <NotificationModal
          isShowExitAutoDialerModal={isShowExitAutoDialerModal}
          onCloseNotificationModal={closeNotificationModalHandler}
          title="Exit Auto Dial Session?"
          body="Exiting will deactive the auto dialer and no additional calls will be made. Press confirm to stop the auto dialer session and exit, or press cancel to continue this auto dial session."
          cancelButton={closeNotificationModalHandler}
          confirmButton={exitAutoDialSession}
        />
      )}
      {isShowDeleteContact && (
        <NotificationModal
          isShowExitAutoDialerModal={isShowExitAutoDialerModal}
          onCloseNotificationModal={closeNotificationModalHandler}
          title="Delete this contact?"
          body="Contacts can be restored from the deleted contact list. Please choose a disposition before confirming."
          cancelButton={closeNotificationModalHandler}
          confirmButton={deleteContactHandler}
          selectLabel="Select Disposition"
          selectOptions={[
            { name: "not-interested", value: "Not interested" },
            { name: "unqualified", value: "Unqualified" },
            { name: "wrong-number", value: "Wrong number" },
            { name: "invalid-number", value: "Invalid number" },
            { name: "do-not-call", value: "Do not call" },
            { name: "language-barrier", value: "Language Barrier" }
          ]}
        />
      )}
      {ReactDom.createPortal(
        <Modal closeModal={closeModalHandler} height="100%" maxWidth="900px">
          <div className={classes["contact-modal"]}>
            <div className={classes["contact-modal-header"]}>
              <div
                className={classes["breadcrumbs"]}
                style={{ flex: "2" }}
              ></div>
              <div className={classes["header-actions"]}>
                <div className={classes.prev}>
                  <FontAwesomeIcon
                    icon={faAngleUp}
                    onClick={previousContactHandler}
                  />
                </div>
                <div className={classes.next}>
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    onClick={nextContactHandler}
                  />
                </div>
                <CloseX onClick={closeModalHandler} />
              </div>
            </div>
            <div className={classes["details-and-aside"]}>
              <div className={classes["details"]}>
                <ContactsDetails contact={contact} />
                <Dialer contactId={contactId} nextContactHandler={nextContactHandler} previousContactHandler={previousContactHandler} contact={contact} setIsDispositionOpen={setIsDispositionOpen} dispositionIconRef={setDispositionIconRef}/>
                <ContactCommunications />
              </div>
              <div
                className={classes["open-aside-arrow"]}
                onClick={() =>
                  isAsideOpen === false
                    ? toggleAsideHandler(true)
                    : toggleAsideHandler(false)
                }
              >
                {!isAsideOpen ? (
                  <FontAwesomeIcon
                    icon={faAngleLeft}
                    className="fa-xs"
                    size="xs"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    className="fa-xs"
                    size="xs"
                  />
                )}
              </div>
              <CSSTransition
                mountOnEnter
                unmountOnExit
                in={isAsideOpen}
                classNames={{
                  enter: classes["aside-enter"],
                  enterDone: classes["aside-enter-active"],
                  exit: classes["aside-exit"],
                  exitDone: classes["aside-exit-active"]
                }}
                timeout={1000}
                nodeRef={asideRef}
              >
                <div className={classes.aside} ref={asideRef}>
                  <div className={classes["aside-item"]}>
                    <p className={classes["aside-title"]}>Contact Type</p>
                    <p className={classes["aside-value"]}>
                      {contact.contactType ? contact.contactType : "Unknown"}
                    </p>
                    <div className={classes.menuSeparator}></div>
                  </div>
                  <div className={classes["aside-item"]}>
                    <div className={classes["contact-status"]}>
                      <p className={classes["aside-title"]}>
                        Contact Disposition
                      </p>
                    </div>
                    <div className={classes["status-bar-container"]}>
                      {/*dropown with disposition */}
                    </div>
                    <div className={classes.menuSeparator}></div>
                  </div>
                  {contactsDeals.length !== 0 && (
                    <div className={classes["aside-item"]}>
                      <div className={classes.deals}>
                        <p className={classes["aside-title"]}>
                          {deals.length ? " Deals" : "Deal"}
                        </p>
                        {contactsDeals.map((deal) => {
                          return (
                            <div
                              key={deal.id}
                              className={classes.deal}
                              onClick={(event) => {
                                openDealHandler(
                                  event,
                                  deal.pipelineId,
                                  deal.id
                                );
                              }}
                            >
                              <div className={classes.pipelineStage}>
                                <div className={classes.pipelineName}>
                                  {deal.pipeline}
                                </div>
                                <AiOutlineSwapRight />
                                <div className={classes.stageName}>
                                  {deal.stage}
                                </div>
                              </div>
                              <div className={classes.dealDescription}>
                                {deal.description}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className={classes.menuSeparator}></div>
                    </div>
                  )}
                  <div className={classes["aside-item"]}>
                    <div
                      className={classes["scheduled-events"]}
                      ref={setReferenceSideBarEvenScheduler}
                    >
                      <p
                        className={classes["aside-title"]}
                        style={{ flex: "2" }}
                      >
                        Events
                      </p>
                      <PlusIcon onClick={toggleEventSchedulerPopperHandler} />
                    </div>
                    {events.length
                      ? events.map((event, index) => {
                          return (
                            <EventItem
                              key={index}
                              event={event}
                              deleteEventHandler={deleteEventHandler}
                              title="Reschedule Event"
                            />
                          );
                        })
                      : ""}
                    {isEventSchedulerOpen && (
                      <div ref={eventSchedulerClickAwayRef}>
                        <div
                          ref={setEventScheduler}
                          className={classes.popper}
                          style={eventSchedulerPopper.styles.popper}
                          {...eventSchedulerPopper.attributes.popper}
                        >
                          <EventSchedulerPopup
                            closePopper={toggleEventSchedulerPopperHandler}
                            contact={contact}
                            // title="Schedule an Event"
                            // eventType="general"
                          />
                        </div>
                      </div>
                    )}
                    <div className={classes.menuSeparator}></div>
                  </div>

                  {contact.campaignName === "Seminars" && (
                    <>
                      <div className={classes["aside-item"]}>
                        <p className={classes["aside-title"]}>
                          Attended Seminar
                        </p>
                        <p className={classes["aside-value"]}>
                          {contact.attendedEvent ? "Yes" : "No"}
                        </p>
                        <div className={classes.menuSeparator}></div>
                      </div>

                      <div className={classes["aside-item"]}>
                        <p className={classes["aside-title"]}>
                          Seminar Location
                        </p>
                        <p className={classes["aside-value"]}>
                          {contact.eventLocation
                            ? contact.eventLocation
                            : "Unknown"}
                        </p>
                        <div className={classes.menuSeparator}></div>
                      </div>

                      <div className={classes["aside-item"]}>
                        <p className={classes["aside-title"]}>Seminar Venue</p>
                        <p className={classes["aside-value"]}>
                          {contact.eventVenue ? contact.eventVenue : "Unknown"}
                        </p>
                        <div className={classes.menuSeparator}></div>
                      </div>
                    </>
                  )}

                  <div className={classes["aside-item"]}>
                    <p className={classes["aside-title"]}>Source</p>
                    <p className={classes["aside-value"]}>
                      {contact.source ? contact.source : "Unknown"}
                    </p>
                    <div className={classes.menuSeparator}></div>
                  </div>

                  {contact.dateOfBirth ? (
                    <div className={classes["aside-item"]}>
                      <p className={classes["aside-title"]}>Date of Birth</p>
                      <p className={classes["aside-value"]}>
                        {new Date(`${contact.dateOfBirth}`)
                          .toISOString()
                          .substring(0, 10)}
                      </p>
                      <div className={classes.menuSeparator}></div>
                    </div>
                  ) : (
                    ""
                  )}

                  {contact.dateOfBirth ? (
                    <div className={classes["aside-item"]}>
                      <p className={classes["aside-title"]}>Age</p>
                      <p className={classes["aside-value"]}>
                        {dobToAge(contact.dateOfBirth)}
                      </p>
                      <div className={classes.menuSeparator}></div>
                    </div>
                  ) : (
                    ""
                  )}
                  {contact.campaignName && (
                    <div className={classes["aside-item"]}>
                      <p className={classes["aside-title"]}>Campaign Name</p>
                      <p className={classes["aside-value"]}>
                        {contact.campaignName
                          ? contact.campaignName
                          : "Unknown"}
                      </p>
                      <div className={classes.menuSeparator}></div>
                    </div>
                  )}
                  {contact.adName && (
                    <div className={classes["aside-item"]}>
                      <p className={classes["aside-title"]}>Ad Name</p>
                      <p className={classes["aside-value"]}>
                        {contact.adName ? contact.adName : "Unknown"}
                      </p>
                      <div className={classes.menuSeparator}></div>
                    </div>
                  )}
                </div>
              </CSSTransition>
            </div>
            {isFollowupSchedulerOpen && (
              <div ref={followupSchedulerClickAwayRef}>
                <div
                  ref={setFollowupScheduler}
                  className={classes.popper}
                  style={followupPopper.styles.popper}
                  {...followupPopper.attributes.popper}
                >
                  <EventSchedulerPopup
                    closePopper={toggleFollowupSchedulerPopperHandler}
                    contact={contact}
                    title="Schedule a Follow-up"
                    eventType="follow-up"
                    nextContactOnEventSave={nextContactOnEventSave}
                  />
                </div>
              </div>
            )}
            {isSetDispositionOpen && (
              <div ref={selectScheduleOrPipelineClickAwayRef}>
                <div
                  ref={setDispositionPopperElement}
                  className={classes.popper}
                  style={dispositionPopper.styles.popper}
                  {...dispositionPopper.attributes.popper}
                >
                  {!isShceduleAppointmentOpen && !isAddNewDealOpen && (
                    <ul className={classes.dispositionPopperElement}>
                      <li
                        className={classes.menuItem}
                        onClick={isScheduleAppointmentOpenHandler}
                      >
                        <div style={{ width: "16px", height: "16px" }}>
                          <CalendarIcon />
                        </div>
                        Appointment
                      </li>
                      <li
                        className={classes.menuItem}
                        onClick={isAddNewDealOpenHandler}
                      >
                        <div style={{ width: "18px", height: "18px" }}>
                          <WorkflowIcon />
                        </div>
                        New Deal
                      </li>
                    </ul>
                  )}

                  {isShceduleAppointmentOpen && (
                    <EventSchedulerPopup
                      closePopper={toggleDispositionHandler}
                      contact={contact}
                      title="Schedule an Appointment"
                      eventType="appointment"
                      nextContactOnEventSave={nextContactOnEventSave}
                    />
                  )}
                  {isAddNewDealOpen && (
                    <CreateDealPopup
                      contact={contact}
                      closePopper={toggleDispositionHandler}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </Modal>,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default ContactModal;
