import { NavLink, Link, useLocation, useNavigate } from "react-router-dom";
import classes from "./SideDrawer.module.css";
import Backdrop from "../../UIElements/Backdrop/Backdrop";
import { ReactComponent as CalendarIcon } from "../../../../assets/imgs/sidedrawer-calendar-icon.svg";
import { ReactComponent as CallIcon } from "../../../../assets/imgs/sidedrawer-call-icon.svg";
import { ReactComponent as WorkflowIcon } from "../../../../assets/imgs/sidedrawer-workflow-icon.svg";
import { ReactComponent as TaskManagerIcon } from "../../../../assets/imgs/sidedrawer-task-manager-icon.svg";
import { useSelector } from "react-redux";
import mergeClasses from "classnames";
const KEY = "last-opened-pipeline-localstorage";

const SideDrawer = (props) => {
  //Use current path to set the li class name to active (current)
  const location = useLocation();
  const { pathname } = location;
  const parsePathname = pathname.split("/");

  //update pipeline route if user has pipelines
  const pipelines = useSelector((state) => state.pipelineReducer.pipelines);

  //use to route to last opened route
  let lastOpenedPipelineId = localStorage.getItem(KEY);

  // if pipeline from local storage no longer exist, route to most recently added pipeline
  if (
    pipelines.length &&
    pipelines.every((pipeline) => pipeline.id !== lastOpenedPipelineId)
  ) {
    const allOtherPipelines = pipelines.filter((pipeline) => pipeline.id !== lastOpenedPipelineId);
    lastOpenedPipelineId = allOtherPipelines[allOtherPipelines.length - 1].id;
    localStorage.setItem(
      KEY,
      allOtherPipelines[allOtherPipelines.length - 1].id
    );
  }

  return (
    <div className={classes["side-drawer"]}>
      <ul>
        {/*------------- Call Center Link ------------- */}
        <NavLink to="/app/call-center">
          <li
            className={mergeClasses(
              classes.listItem,
              parsePathname[2] === "call-center" ? classes.current : ""
            )}
          >
            <div className={classes.icon}>
              <CallIcon />
            </div>
            Call Center
          </li>
        </NavLink>
        {/*------------- Calendar Link ------------- */}
        <NavLink to="/app/calendar">
          <li
            className={mergeClasses(
              classes.listItem,
              parsePathname[2] === "calendar" ? classes.current : ""
            )}
          >
            <div className={classes.icon}>
              <CalendarIcon />
            </div>
            Calendar
          </li>
        </NavLink>
        {/*------------- Pipeline Link ------------- */}
        <NavLink
          to={
            pipelines.length
              ? `/app/pipeline/${lastOpenedPipelineId}`
              : "/app/pipeline/add"
          }
        >
          <li
            className={mergeClasses(
              classes.listItem,
              parsePathname[2] === "pipeline" ? classes.current : ""
            )}
          >
            <div className={classes.icon}>
              <WorkflowIcon />
            </div>
            Pipeline
          </li>
        </NavLink>
        {/*------------- Task Manager Link ------------- */}
        <NavLink to="/app/call-center">
          <li
            className={mergeClasses(
              classes.listItem,
              parsePathname[2] === "task-manager" ? classes.current : ""
            )}
          >
            <div className={classes.icon}>
              <TaskManagerIcon />
            </div>
            Task Manager{" "}
            <span style={{ fontSize: "0.6rem" }}>
              {"("}coming soon{")"}
            </span>
          </li>
        </NavLink>
      </ul>
    </div>
  );
};

export default SideDrawer;
