import React, { useState, useEffect, useRef } from "react";
import classes from "./PipelineDeal.module.css";
import { usePopper } from "react-popper";
import { CiMenuKebab } from "react-icons/ci";
import useClickAwayListener from "../../shared/components/hooks/useClickAwayListener/useClickAwayLIstener";
import { CiEdit } from "react-icons/ci";
import { RxTrash } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import {
  updateDeal,
  deleteDeal,
  updateStage
} from "../../store/pipeline-actions";
import { useNavigate } from "react-router-dom";
import NotificationModal from "../../shared/components/Modal/NotificationModal/NotificationModal";
import { Draggable } from "react-beautiful-dnd";
import { CgMoveRight } from "react-icons/cg";

const PipelineDeal = ({
  deal,
  stage,
  stages,
  index,
  onUpdateDealPositions,
  tempDeals
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef(null);
  //import contacts from store and filter against deal id
  const contact = useSelector((state) => state.contactReducer.contacts).find(
    (contact) => contact?.id === deal?.contactId
  );

  //edit deal
  const [isMoveDealOpen, setIsMoveDealOpen]= useState(false);
  const [isDeleteDealOpen, setIsDeleteDealOpen] = useState(false);

  //open deal modal
  const isDealModalOpenHandler = (e) => {
    e.stopPropagation();
    navigate(`deal/${deal.id}`);
  };

  //opens delete deal popper
  const openDeleteDealHandler = (e) => {
    e.stopPropagation();
    toggleEditKebabMenuPopperHandler();
    setIsDeleteDealOpen(true);
  };

  //closes delete deal popper
  const closeDeleteDealHandler = () => {
    toggleEditKebabMenuPopperHandler();
    setIsDeleteDealOpen(false);
  };

  //open move deal to reveal stages
  const toggleMoveDealHandler = (e) => {
    e.stopPropagation();
    setIsMoveDealOpen(prevState => !prevState);
  }

  //move deal to another stage
  const moveDealHandler = (e, destinationStage) => {
    e.stopPropagation();

    if (stage.id === destinationStage.id) return;

    //delete deal from its stage
    const startStageDeals = Array.from(stage.deals)
    const dealIndex = startStageDeals.findIndex(d => d === deal.id)
    startStageDeals.splice(dealIndex, 1)

    const updatedStartStage = {
      ...stage,
      deals: startStageDeals
    }

    const destinationStageDeals = Array.from(destinationStage.deals)
    destinationStageDeals.push(deal.id)

    const updatedDestinationStage = {
      ...destinationStage,
      deals: destinationStageDeals,
    }

    //update stage deals array for repositioning
    dispatch(updateStage(updatedStartStage))
    dispatch(updateStage(updatedDestinationStage))

    //update deal belongs to (stage)
    const updatedDeal = {
        ...deal,
        belongsTo: destinationStage.id
    }
    dispatch(updateDeal(updatedDeal));
  };

  //dispatches delete deal action creator
  const deleteDealHandler = (e) => {
    e.stopPropagation();
    dispatch(deleteDeal(deal));
    setIsDeleteDealOpen(false);
  };

  //edit deal popper
  const [isEditCardMenuOpen, setIsEditCardMenuOpen] = useState(false);
  const [referenceKebabIcon, setReferenceKebabIcon] = useState(null);
  const [editCardMenu, setEditCardMenu] = useState(null);

  const editCardMenuPopper = usePopper(referenceKebabIcon, editCardMenu, {
    placement: "bottom",
    modifiers: [
      { name: "offset", options: { offset: [0, 0] } },
      {
        name: "preventOverflow",
        options: {
          altAxis: true,
          padding: 10
        }
      }
    ]
  });

  const toggleEditKebabMenuPopperHandler = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setIsEditCardMenuOpen((prevState) => !prevState);
    setIsMoveDealOpen(false);
  };

  const kebabIconClickAwayRef = useRef(null);

  useClickAwayListener(
    kebabIconClickAwayRef,
    toggleEditKebabMenuPopperHandler,
    referenceKebabIcon
  );

  return (
    <>
      {isDeleteDealOpen && (
        <NotificationModal
          onCloseNotificationModal={closeDeleteDealHandler}
          title={`Delete ${contact.firstName}'s deal?`}
          body="Deleting this deal cannot be undone and all corresponding tasks and notes will be lost."
          cancelButton={closeDeleteDealHandler}
          confirmButton={deleteDealHandler}
        />
      )}
      <Draggable draggableId={deal.id} index={index}>
        {(provided, snapshot) => {
          return (
            <div
              className={classes.deal}
              onClick={isDealModalOpenHandler}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
            >
              <div className={classes.contactDetails}>
                <div className={classes.contactNameAndKebab}>
                  <div className={classes.contactName}>
                    {contact?.firstName + " " + contact?.lastName}
                  </div>
                  <div
                    className={classes.kebabMenu + " " + classes.kebab}
                    ref={setReferenceKebabIcon}
                  >
                    <CiMenuKebab
                      onClick={toggleEditKebabMenuPopperHandler}
                      size="24px"
                    />
                  </div>
                </div>
                <div className={classes.description}>{deal?.description}</div>
              </div>
              {isEditCardMenuOpen && (
                <div ref={kebabIconClickAwayRef}>
                  <div
                    ref={setEditCardMenu}
                    className={classes.popper}
                    style={editCardMenuPopper.styles.popper}
                    {...editCardMenuPopper.attributes.popper}
                  >
                    <div closePopper={toggleEditKebabMenuPopperHandler}>
                      <ul className={classes.editCardMenu}>
                        <li
                          className={classes.menuItem}
                          onClick={toggleMoveDealHandler}
                        >
                          <CgMoveRight
                            size="20px"
                            className={classes.moveIcon}
                          />
                          Move Deal
                        </li>
                        {isMoveDealOpen && (
                          <li>
                            <ul className={classes.stageList}>
                                {stages.map(stage => {
                                    return <li onClick={(e) => moveDealHandler(e, stage)}>{stage.name}</li>
                                })}
                            </ul>
                          </li>
                        )}
                        <li
                          className={classes.menuItem}
                          onClick={openDeleteDealHandler}
                        >
                          <RxTrash size="20px" className={classes.trashIcon} />
                          Delete Deal
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        }}
      </Draggable>
    </>
  );
};

export default PipelineDeal;
