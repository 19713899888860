import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactDom from "react-dom";
import { ReactComponent as CallIcon } from "../../../../assets/imgs/call-icon.svg";
import { ReactComponent as MicIcon } from "../../../../assets/imgs/mic-icon.svg";
import { ReactComponent as PrevCallIcon } from "../../../../assets/imgs/prev-call-icon.svg";
import { ReactComponent as NextCallIcon } from "../../../../assets/imgs/next-call-icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeMute } from "@fortawesome/free-solid-svg-icons";
import classes from "./IncomingCallBar.module.css";
import { useTwilioPhoneUtils } from "../../../../shared/components/hooks/useTwilioPhoneIncoming/useTwilioPhoneIncoming";

const IncomingCallBar = () => {
  const {
    isBusy,
    callerId,
    callIsMuted,
    answerCallHandler,
    muteCallHandler,
    ignoreCallHandler,
    hangUpCallHandler,
  } = useTwilioPhoneUtils();

  // Show notification on incoming call
  const incomingCallNotification = useSelector(
    (state) => state.uiReducer.incomingCallNotifcation
  );

  return incomingCallNotification
    ? ReactDom.createPortal(
        <div className={classes["incoming-call-bar"]}>
          <div className={classes["call-info"]}>
            <span className={classes["incoming-text"]}>
              {isBusy ? "Connected" : "Incoming call..."}
            </span>
            <span className={classes["caller-name"]}>{callerId}</span>
          </div>
          <div className={classes["call-buttons"]}>
            <button
              className={`${classes["call-button"]} ${!isBusy ? classes["answer-button"] : ""} ${callIsMuted ? classes["muted"] : ""}`} 
              onClick={!isBusy ? answerCallHandler : muteCallHandler}
            >
              {!isBusy ? (
                <CallIcon />
              ) : (
                <FontAwesomeIcon icon={faVolumeMute} width="100%" />
              )}
            </button>
            <button
              className={`${classes["call-button"]} ${classes["hangup-button"]}`}
              onClick={!isBusy ? ignoreCallHandler : hangUpCallHandler}
            >
              <CallIcon />
            </button>
          </div>
        </div>,
        document.getElementById("modal-root")
      )
    : null;
};

export default IncomingCallBar;
