import classes from './Dots.module.css';

const Dots = (props) => {

    const whiteActive = props.selectedDot === '' || props.selectedDot === 'unassigned' ? classes.active : '';
    const redActive = props.selectedDot === '' || props.selectedDot === 'red' ? classes.active : '';
    const yellowActive = props.selectedDot === '' || props.selectedDot === 'yellow' ? classes.active : '';
    const greenActive = props.selectedDot === '' || props.selectedDot === 'green' ? classes.active : '';
    return (
        <div className={classes.dots + ' ' + (props.size ? props.size : '')}>
            {/* <button type='button' className='unassigned' id={whiteActive} onClick={props.onClick}></button> */}
            <button type='button' className={classes.red} id={redActive} onClick={props.onClick} data-color='red' ref={props.redDotRef}></button>
            <button type='button' className={classes.yellow} id={yellowActive} onClick={props.onClick} data-color='yellow' ref={props.yellowDotRef}></button>
            <button type='button' className={classes.green} id={greenActive} onClick={props.onClick} data-color='green' ref={props.greenDotRef}></button>
        </div>
    )
};

export default Dots;