import { createSlice, current } from "@reduxjs/toolkit";

const dealNoteSlice = createSlice({
  name: "note",
  initialState: {
    notes: [],
    isLoading: false,
    didFetch: [],
    uploadProgress: 0,
    isNoteLoading: false
  },
  reducers: {
    replaceNoteData(state, action) {
      action.payload.notes.forEach(note => state.notes.push(note))
      
    },
    addNote(state, action) {
      state.notes.push(action.payload.note);
    },
    updateNote(state, action) {
      const currentNote = state.notes.find(
        (note) => note.id === action.payload.id
      );
      currentNote.text = action.payload.text;
      currentNote.creator = action.payload.creator;
      currentNote.belongsTo = action.payload.belongsTo;
    },
    deleteNote(state, action) {
      state.notes = state.notes.filter((note) => note.id !== action.payload);
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setUploadProgress(state, action) {
      state.uploadProgress = action.payload
    },
    setIsNoteLoading(state, action) {
      state.isNoteLoading = action.payload;
  }
  },
});

export const dealNoteActions = dealNoteSlice.actions;

export default dealNoteSlice.reducer;
