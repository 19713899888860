import store from './index';
import { taskActions } from './task';

//-----------------// CREATE //-----------------//
export const createTask = (taskData) => {
    return async (dispatch) => {
        const token = store.getState().userReducer.token;

        let task;
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/tasks`, 
            {
                method: "POST",
                body: JSON.stringify({
                    taskName: taskData.taskName,
                    description: taskData.description,
                    completed: false,
                    contactId: taskData.contactId,
                    belongsTo: taskData.belongsTo
                }),
                headers: {
                    "content-type": "application/json",
                    Authorization: "Bearer " + token
                }
            });

            const responseData = await response.json();

            dispatch(taskActions.addTask(responseData.task));
            
        } catch (error) {
            console.log(error)
        }
    }
}

//-----------------// READ //-----------------//
export const fetchTasks = () => {
    return async (dispatch) => {
        const userId = store.getState().userReducer.user.userId;
        const token = store.getState().userReducer.token;

        let tasks;
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/tasks/user/${userId}`,
                {
                    headers: {
                        Authorization: "Bearer " + token
                    }
                });

            const responseData = await response.json();

            if (!response.ok) {
                throw new Error(responseData.message);
            }

            dispatch(taskActions.replaceTaskData(responseData));

        } catch (error) {
            console.log(error)
        }
    }
}

//-----------------// UPDATE //-----------------//
export const updateTask = (taskData) => {
    return async (dispatch) => {
        const token = store.getState().userReducer.token;
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/tasks/${taskData.id}`,{
                method: "PATCH",
                body: JSON.stringify({
                  taskName: taskData.name,
                  description: taskData.description,
                  completed: taskData.completed,
                  contactId: taskData.contactId,
                  belongsTo: taskData.belongsTo,
                  creator: taskData.creator
                }),
                headers: {
                  "content-type": "application/json",
                  Authorization: "Bearer " + token
                }
            });

            const responseData = await response.json();

            dispatch(taskActions.updateTask(responseData.task));
        } catch (error) {
            console.log(error);
        }
    }
}

//-----------------// DELETE //-----------------//
export const deleteTask = (taskData) => {
    console.log(taskData)
    return async (dispatch) => {
      const token = store.getState().userReducer.token;
  
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/tasks/${taskData.id}`,
          {
            method: "DELETE",
            body: JSON.stringify({
                ...taskData
            }),
            headers: {
              "content-type": "application/json",
              Authorization: "Bearer " + token
            }
          });
  
        const responseData = await response.json();
  
        if (!response.ok) {
          throw new Error(responseData.message);
        }
  
        //dispatch to store
        dispatch(taskActions.deleteTask(taskData.id));
  
      } catch (error) {
  
      }
    }
  }