import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactDom from "react-dom";
import MessagePng from "../../../../assets/imgs/message-logo.png";
import classes from "./MessageNotification.module.css";
import CSSTransition from "react-transition-group/CSSTransition";
import { useNavigate, useParams, useLocation } from "react-router-dom";

const Message = (props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const currentPath = location.pathname;
  const messageCenterPath = '/app/call-center/communications/messages';
  
  const contactId = props.message.belongsTo;
  const [showMessage, setShowMessage] = useState(true);

  //don't show notification if contact modal is open
  const pathName = window.location.pathname.split("/");

  const getIdFromPath = (params) => {
    return params.filter(dir => {
      return /^[A-Za-z0-9]{20,}$/.test(dir)
    });
  }

  const contactIdFromPath = getIdFromPath(pathName)[0];

  //isShowNotification is true, notification will not show
  //e.g. if the notification popper is open
  const isShowNotifications = useSelector(
    (state) => state.notificationReducer.isShowMenuNotification
  );

  useEffect(() => {
    setShowMessage(true);
    const timer = setTimeout(() => {
      setShowMessage(false);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  const nodeRef = useRef();

  const closeMessageHandler = (event) => {
    event.stopPropagation();
    setShowMessage(false);
  };

    //open messages for contact and set new messages read: true
    const messageClickHandler = (event) => {
      event.stopPropagation();
      navigate(`/app/call-center/contacts/${contactId}/messages`)
    };

  return (
    <>
      {!isShowNotifications && currentPath !== messageCenterPath && contactId !== contactIdFromPath && (
        <CSSTransition
          in={showMessage}
          nodeRef={nodeRef}
          timeout={300}
          classNames={{
            enterActive: classes["enter"],
            enterDone: classes["enter-active"],
            exitActive: classes["exit"],
            exitDone: classes["exit-active"],
          }}
          unmountOnExit
        >
          <div className={classes["message"]} ref={nodeRef} onClick={messageClickHandler}>
            <div
              className={`${classes["close"]} ${classes["actions"]}`}
              onClick={closeMessageHandler}
            >
              x
            </div>
            {/* <div className={`${classes["options"]} ${classes["actions"]}`}>
              Options
            </div> */}
            <div className={classes["header"]}>
              <div className={classes["icon"]}>
                <img
                  src={MessagePng}
                  style={{ width: "1.2rem", height: "1.2rem" }}
                />
              </div>
              <div className={classes["message-source"]}>MESSAGES</div>
              <div className={classes["time-received"]}>now</div>
            </div>
            <div className={classes["sender-name"]}>
              {props.message.contactName || "Unknown"}
            </div>
            <div className={classes["sender-message"]}>
              {props.message.message}
            </div>
          </div>
        </CSSTransition>
      )}
    </>
  );
};

const MessageNotification = () => {
  const incomingMessages = useSelector(
    (state) => state.messageReducer.recentMessages
  );

  return incomingMessages.length ? ReactDom.createPortal(
    <div className={classes["message-notification"]}>
      {incomingMessages.map((message, index) => {
        return <Message key={index} message={message} />;
      })}
    </div>,
    document.getElementById("modal-root")
  ) : null
};

export default MessageNotification;
