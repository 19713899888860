import React, { useState, useEffect, useRef, useCallback } from "react";

export const useInfiniteScroll = (items, itemsPerPage) => {
  const [records, setRecords] = useState(itemsPerPage);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [viewableItems, setViewableItems] = useState([]);

  const observer = useRef();
  const lastItemRef = useCallback((node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadMore();
        }
      },
      [items]
    );
    if (node) observer.current.observe(node);
  });

  useEffect(() => {
    const viewableItems = items.slice(0, itemsPerPage);
    setRecords(itemsPerPage);
    setViewableItems(viewableItems);
  }, [items]);

  const loadMore = () => {
    setLoading(true);
    if (records >= items.length) {
      return setHasMore(false);
    } else {
      const recordEnd = records + itemsPerPage;
      const viewableItems = items.slice(records, recordEnd);
      setViewableItems((prevState) => {
        return [...prevState, ...viewableItems];
      });
      setRecords(records + itemsPerPage);
    }
    setLoading(false);
  };

  return {
    loadMore,
    viewableItems,
    loading,
    lastItemRef,
  };
};
