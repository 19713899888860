import classes from "./EventItemWeek.module.css";
import CalendarIcon from "../UIElements/CalendarIcon/CalendarIcon";
import EventSchedulerPopup from "./EventSchedulerPopup";
import { convertTime } from "../utils/convertTime";
import Dot from "../UIElements/Dot/Dot";
import useClickAwayListener from "../hooks/useClickAwayListener/useClickAwayLIstener";
import React, { useState, useRef, useEffect } from "react";
import { usePopper } from "react-popper";
import { useDrag } from "react-dnd";

const EventItemWeek = (props) => {

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  //create function that reduces event or contact name letters/numbers to fit container
  const cellRef = useRef();
  const [cellWidth, setCellWidth] = useState();

  useEffect(() => {

    const handleResize = () => {
      setCellWidth(cellRef.current?.offsetWidth)
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [])

  //Reschedule event popper
  const [isEventReschedulerOpen, setisEventReschedulerOpen] = useState(false);
  const [referenceScheduledEvent, setReferenceScheduledEvent] = useState(null);
  const [eventRescheduler, setEventRescheduler] = useState(null);

  const eventReschedulerPopper = usePopper(
    referenceScheduledEvent,
    eventRescheduler,
    {
      placement: "bottom",
      modifiers: [
        { name: "offset", options: { offset: [0, 5] } },
        {
          name: "preventOverflow",
          options: {
            altAxis: true,
            padding: 10,
          },
        },
      ],
    }
  );

  const toggleEventReschedulerPopperHandler = (event) => {
    if (event) { event.stopPropagation(); }
    setisEventReschedulerOpen((prevState) => !prevState);
  };

  const eventReschedulerClickAwayRef = useRef(null);
  useClickAwayListener(eventReschedulerClickAwayRef, toggleEventReschedulerPopperHandler, referenceScheduledEvent);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: "events",
    item: { event: props.event, cellIndex: props.cellIndex },
    collect: (monitor) => ({
      isDraggin: !!monitor.isDragging()
    })
  }), [props]);

  return (
    <div ref={drag} style={{width: '100%', height: props.cellHeight && props.cellHeight}}>
      <div
        className={`${classes["event-item"]}`}
        ref={setReferenceScheduledEvent}
        onClick={toggleEventReschedulerPopperHandler}
        style={{
          marginTop: new Date(props.event?.eventStarts).getMinutes() === 30 && "1.5rem",
          height: props.cellHeight && props.cellHeight,
          borderLeft: props.event.eventType === "appointment" ? "2px solid rgb(118, 234, 130)" : props.event.eventType === "follow-up" ? "2px solid rgb(255 234 0)" : "2px solid gray",
          marginLeft: props.sameDayEvents?.filter(event => event != props.event).filter(event => new Date(event.eventStarts).getTime() < new Date(props.event.eventStarts).getTime()).map(event => new Date(event.eventEnds).getTime() > new Date(props.event.eventStarts).getTime())[0] ? ".1rem" : ""
        }}
      >
        <div className={classes["details"]} style={{ height: props.cellHeight === "1.5rem" && "1.5rem" }} ref={cellRef}>
          {props.event.eventType === "appointment" ? <Dot color="green" isActive width="10px" height="10px" /> : ""}
          {props.event.eventType === "follow-up" ? <Dot color="yellow" isActive width="10px" height="10px" /> : ""}

          {/*show contact name if event type includes follow-up or appointment*/}
          {(props.event?.eventType === "follow-up" || props.event?.eventType === "appointment") && props.event?.belongsTo && <div className={classes["title"]} >
            {props.event.firstName + " " + props.event.lastName}
          </div>}

          {/*show event name only, exclude event type of follow-up and appointment*/}
          {props.event?.eventName && props.event?.eventType !== "follow-up" && props.event?.eventType !== "appointment" && <div className={classes["title"]}>{props.event?.eventName}</div>}

          {/*show event name if event type incldues follow-up or appointment but does not includes contact (belongs to)*/}
          {(props.event?.eventType === "follow-up" || props.event?.eventType === "appointment") && !props.event?.belongsTo && <div className={classes["title"]} >
            {props.event.eventName}
          </div>}

          {/*show contact name if no event name or event type of follow-up or appointment*/}
          {props.event?.firstName && !props.event?.eventName && props.event?.eventType !== "follow-up" && props.event?.eventType !== "appointment" && <div className={classes["title"]}>{props.event?.firstName + " " + props.event?.lastName}</div>}
        </div>
      </div>
      {
        isEventReschedulerOpen && (
          <div ref={eventReschedulerClickAwayRef}>
            <div
              ref={setEventRescheduler}
              className={classes.popper}
              style={eventReschedulerPopper.styles.popper}
              {...eventReschedulerPopper.attributes.popper}
            >
              <EventSchedulerPopup
                closePopper={toggleEventReschedulerPopperHandler}
                // contact={props.contact}
                title={props.title}
                event={props.event}
                rescheduler={true}
                id={props.event.id}
              />
            </div>
          </div>
        )
      }
    </div>
  );
};

export default EventItemWeek;
