import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SpinnerButton from "../../UIElements/SpinnerButton/SpinnerButton";
import { useNavigate } from "react-router-dom";
import CloseX from "../../UIElements/CloseX/CloseX";
import { CirclePicker } from "react-color";
import UserBadge from "../../UIElements/UserBadge/UserBadge";
import { updateUser, deleteUser } from "../../../../store/user-actions";
import { CiEdit } from "react-icons/ci";
import mergeClasses from "classnames";
import { BsCheck2 } from "react-icons/bs";
import { IoIosClose } from "react-icons/io";
import Input from "../../UIElements/Input/Input";
import NotificationModal from "../NotificationModal/NotificationModal";
import classes from './AccountSettings.module.css';

const AccountSettings = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector((state) => state.userReducer.user);
    const token = useSelector((state) => state.userReducer.token);
    const badgeColor = user.userSettings.badge;
    const isLoading = useSelector((state) => state.userReducer.isLoading);

    const closeModalHandler = () => {
        navigate("/app/call-center/");
    };

    const [name, setName] = useState("");
    const [editNameIsOpen, setEditNameIsOpen] = useState(false);

    const [email, setEmail] = useState("");
    const [newEmail, setNewEmail] = useState("");
    const [confirmedEmail, setConfirmedEmail] = useState("");
    const [newEmailHasErrors, setNewEmailHasErrors] = useState("");
    const [password, setPassword] = useState("");
    const [editEmailIsOpen, setEditEmailIsOpen] = useState(false);
    const [updatedBadgeColor, setUpdatedBadgeColor] = useState("");
    const [deleteUserIsOpen, setDeleteUserIsOpen] = useState(false);

    function handleColorChange(color) {
        setUpdatedBadgeColor(color.hex);
        dispatch(
            updateUser({
                ...user,
                userSettings: {
                    ...user.userSettings,
                    badge: color.hex
                }
            })
        );
    }

    function editNameIsOpenHandler() {
        setEditNameIsOpen((prevState) => !prevState);
    }

    function nameInputHandler(e) {
        setName(e.target.value);
    }

    function editEmailIsOpenHandler() {
        setEditEmailIsOpen(true);
    }

    function newEmailInputHandler(e) {
        setNewEmailHasErrors(false);
        setNewEmail(e.target.value);
    }

    function confirmedEmailInputHanlder(e) {
        setNewEmailHasErrors(false);
        setConfirmedEmail(e.target.value);
    }

    function passwordInputHandler(e) {
        setPassword(e.target.value);
    }

    function updateNameHandler() {
        dispatch(
            updateUser({
                ...user,
                userName: name
            })
        );
        setEditNameIsOpen(false);
    }

    async function updateEmailHandler() {
        //verfiy that emails match
        if (
            newEmail === "" ||
            confirmedEmail === "" ||
            newEmail !== confirmedEmail
        ) {
            setNewEmailHasErrors(true);
            return;
        }

        try {
            const response = await fetch(
                `${process.env.REACT_APP_BACKEND_URL}/users/${user.userId}/confirm-password`,
                {
                    method: "POST",
                    headers: {
                        "content-type": "application/json",
                        Authorization: "Bearer " + token
                    },
                    body: JSON.stringify({
                        email: user.userEmail,
                        password: password
                    })
                }
            );

            const responseData = await response.json();

            if (response) {
                dispatch(
                    updateUser({
                        ...user,
                        userEmail: newEmail
                    })
                );
            }
        } catch (error) {
            console.log(error);
        }

        setEditEmailIsOpen(false);
    }

    function cancelUpdateEmailHandler() {
        setEditEmailIsOpen(false);
        setNewEmailHasErrors(false);
        setNewEmail("");
        setConfirmedEmail("");
        setPassword("");
    }

    function deleteUserIsOpenHandler() {
        setDeleteUserIsOpen(prevState => !prevState)
    }

    function deleteUserHandler() {
        dispatch(deleteUser(user.userId));
    }

    //load state from store
    useEffect(() => {
        setName(user.userName);
        setEmail(user.userEmail);
        setUpdatedBadgeColor(badgeColor);
    }, [user]);

    return (
        <>
            {deleteUserIsOpen && (
                <NotificationModal
                    onCloseNotificationModal={deleteUserIsOpenHandler}
                    title="Delete Your User Account?"
                    body="This action cannot be undone and all user data will be permanetely lost. Billing will end immediately, any unused and prorated monies will be refunded to you."
                    cancelButton={deleteUserIsOpenHandler}
                    confirmButton={deleteUserHandler}
                    confirmBtnColor='button--red'
                />
            )}
            <div className={classes.main}>
                <div className={classes.header}>
                    <div className={classes["header-title"]}>Account</div>
                    <CloseX onClick={closeModalHandler} />
                </div>
                <div className={classes["menu_seperator"]}></div>
                <div className={classes.content}>
                    <div className={classes.section}>
                        <div className={classes.title}>Badge</div>
                        <div className={classes.user}>
                            <UserBadge color={updatedBadgeColor} size='large'>
                                {user.userName}
                            </UserBadge>
                            <div className={classes.badgeSwatch}>
                                <CirclePicker
                                    circleSize={22}
                                    circleSpacing={5}
                                    color={badgeColor}
                                    onChange={handleColorChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={
                        mergeClasses(
                            classes.section,
                            classes.userName
                        )}>
                        <div className={classes.title}>
                            Name
                        </div>
                        {!editNameIsOpen ?
                            (
                                <div className={classes.name}>
                                    <p>{name}</p>
                                    <div className={classes.edit}>
                                        <CiEdit size="20px" onClick={editNameIsOpenHandler} />
                                    </div>
                                </div>
                            ) : (
                                <div className={mergeClasses(classes.name, classes.editing)}>
                                    <input autoFocus value={name} onChange={nameInputHandler} />
                                    <BsCheck2
                                        size="22px"
                                        className={classes.save}
                                        onClick={updateNameHandler}
                                    />
                                    <IoIosClose
                                        size="29px"
                                        className={classes.cancel}
                                        onClick={editNameIsOpenHandler}
                                    />
                                </div>
                            )}
                    </div>
                    <div className={mergeClasses(classes.section, classes.userEmail)}>
                        <div className={classes.title}>Email</div>
                        {!editEmailIsOpen ?
                            (
                                <div className={classes.email}>
                                    <p>{email}</p>
                                    <div className={classes.edit}>
                                        <CiEdit size="20px" onClick={editEmailIsOpenHandler} />
                                    </div>
                                </div>
                            ) : (
                                <div className={mergeClasses(classes.email, classes.editing)}>
                                    <Input
                                        autoFocus
                                        value={newEmail}
                                        label="New email"
                                        onChange={newEmailInputHandler}
                                        isInvalid={newEmailHasErrors}
                                    />
                                    <Input
                                        value={confirmedEmail}
                                        label="Confirm new email"
                                        onChange={confirmedEmailInputHanlder}
                                        isInvalid={newEmailHasErrors}
                                    />
                                    <Input
                                        value={password}
                                        type="password"
                                        label="Enter your password"
                                        onChange={passwordInputHandler}
                                    />
                                    <div className={classes.actions}>
                                        <SpinnerButton
                                            size="button--small"
                                            color="button--white"
                                            onClick={cancelUpdateEmailHandler}
                                            isLoading={isLoading}
                                        >
                                            Cancel
                                        </SpinnerButton>
                                        <SpinnerButton
                                            size="button--medium"
                                            color="button--blue"
                                            onClick={updateEmailHandler}
                                            isLoading={isLoading}
                                        >
                                            Update email
                                        </SpinnerButton>
                                    </div>
                                </div>
                            )}
                    </div>
                    <div className={classes.section}>
                        <div className={classes.title}>Password</div>
                        <p className={classes.password}>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </p>
                    </div>
                    <div className={
                        mergeClasses(
                            classes.section,
                            classes.delete
                        )}>
                        <div className={classes.title}>Delete Account</div>
                        <p>
                            This action will delete all of your data and cannot be undone.
                        </p>
                        <SpinnerButton
                            color="button--red"
                            size="button--medium"
                            height="30px"
                            width="130px"
                            margin=".5rem 0rem"
                            onClick={deleteUserIsOpenHandler}
                        >
                            Delete Account?
                        </SpinnerButton>

                    </div>
                </div>
            </div>
        </>
    );
};

export default AccountSettings;