import classes from './Spinner.module.css';

const Spinner = props => {
    return (
        <div className={classes['spinner-container']}>
            <div className={`${classes.loader} ${classes[props.customClasses]}`}></div>
        </div>
    )
};

export default Spinner;