import { useCallback, useEffect, useRef, useState } from "react";
import classes from "./VoiceMessage.module.css";
import { IoPlaySharp, IoPauseSharp } from "react-icons/io5";
import { CiRead, CiUnread } from "react-icons/ci";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";
import { convertTime } from "../../utils/convertTime";
import { useTwilioPhoneOutgoing } from "../../hooks/useTwilioPhoneOutgoing/useTwilioPhoneOutgoing";
import { ReactComponent as CallIcon } from "../../../../assets/imgs/call-icon.svg";

const VoiceToText = ({ text }) => {
  return <div className={classes["text"]}>{text}</div>;
};

const Audio = ({ url, audioRef, setDuration }) => {
  const onLoadedMetadata = () => {
    const seconds = audioRef.current.duration;
    setDuration(seconds);
  };
  return <audio src={url} ref={audioRef} onLoadedMetadata={onLoadedMetadata} />;
};

const Controls = ({
  audioRef,
  setProgressBar,
  progressBarRef,
  duration,
  isPlaying,
  setIsPlaying,
  setIsReading,
  isReading,
  phoneNumber,
}) => {
  const playAnimationRef = useRef();

  const {
    onPhone,
    callIsMuted,
    callIsActiveForContact,
    callStatus,
    callOutcome,
    makeCallHandler,
    hangUpCallHandler,
    muteCallHandler,
  } = useTwilioPhoneOutgoing();

  const togglePlayPause = () => {
    setIsPlaying((prev) => !prev);
  };

  const toggleReading = () => {
    setIsReading((prev) => !prev);
  };

  const repeat = useCallback(() => {
    const currentTime = audioRef.current.currentTime;
    setProgressBar(currentTime);
    progressBarRef.current.style.setProperty(
      "--range-progress",
      `${(progressBarRef.current.value / duration) * 100}%`
    );
    playAnimationRef.current = requestAnimationFrame(repeat);
  }, [audioRef, duration, progressBarRef]);

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
    playAnimationRef.current = requestAnimationFrame(repeat);
  }, [isPlaying, audioRef, repeat]);

  return (
    <div className={classes["play-read-controls"]}>
      <button
        onClick={togglePlayPause}
        className={`${classes["button"]} ${classes["button--listen"]} ${
          isPlaying ? classes["active"] : ""
        }`}
      >
        {isPlaying ? (
          <IoPauseSharp color="white" />
        ) : (
          <IoPlaySharp color="white" />
        )}
      </button>
      <button
        onClick={toggleReading}
        className={`${classes["button"]} ${classes["button--read"]} ${
          isReading ? classes["active"] : ""
        }`}
      >
        {isReading ? (
          <CiUnread color="white" size="1rem" />
        ) : (
          <CiRead color="white" size="1rem" />
        )}
      </button>
      <button
        className={`${
          !onPhone ? classes["answer-button"] : ""
        } ${onPhone && classes["hangup-button"]} ${classes["button"]}`}
        onClick={
          !onPhone
            ? (event) => makeCallHandler(event, phoneNumber, "")
            : hangUpCallHandler
        }
      >
        {!onPhone ? (
          <CallIcon />
        ) : (
          <CallIcon />
        )}
      </button>
    </div>
  );
};

const ProgressBar = ({
  progressBar,
  audioRef,
  progressBarRef,
  duration,
  setProgressBar,
}) => {
  const progressChangeHandler = () => {
    audioRef.current.currentTime = progressBarRef.current.value;
    setProgressBar(progressBarRef.current.value);
  };

  const formatTime = (time) => {
    if (time && !isNaN(time)) {
      const minutes = Math.floor(time / 60);
      const formatMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
      const seconds = Math.floor(time % 60);
      const formatSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
      return `${formatMinutes}:${formatSeconds}`;
    }
    return "00:00";
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <input
        type="range"
        value={progressBar}
        max={duration}
        ref={progressBarRef}
        onChange={progressChangeHandler}
      />
      <div className={classes["audio-progress"]}>
        <div>{formatTime(audioRef.current.currentTime)}</div>
        <div>{formatTime(duration)}</div>
      </div>
    </div>
  );
};

const VoiceMessage = ({ voiceMessage, setListenedToVoiceMessage }) => {
  const progressBarRef = useRef();
  const audioRef = useRef();
  const [progressBar, setProgressBar] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState();
  const [isReading, setIsReading] = useState();
  const [isChecked, setIsChecked] = useState(false);

  const isCheckedHandler = () => {
    setIsChecked(true);
    const timer = setTimeout(() => {
      setListenedToVoiceMessage(voiceMessage);
      setIsChecked(false);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  };
  return (
    <div
      className={classes["container"]}
      style={{
        backgroundColor: isPlaying || isReading ? "rgba(0,0,0,0.03)" : "",
      }}
    >
      <div className={classes["voice-message"]}>
        <div className={classes["details"]}>
          <div className={classes["sender-details"]}>
            <div className={classes["sender-name"]}>
              {voiceMessage.contactName}
            </div>
            {voiceMessage.contactName === "Unknown" && (
              <div className={classes["sender-number"]}>
                {formatPhoneNumber(voiceMessage.from)}
              </div>
            )}
            <div className={classes["received"]}>
              {convertTime(new Date(voiceMessage.createdAt))}{" "}
              {new Date(voiceMessage.createdAt).getMonth() + 1}/
              {new Date(voiceMessage.createdAt).getDate()}/
              {new Date(voiceMessage.createdAt).getFullYear()}
            </div>
          </div>
          <div className={classes["controls"]}>
            <Controls
              audioRef={audioRef}
              setProgressBar={setProgressBar}
              progressBarRef={progressBarRef}
              duration={duration}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              isReading={isReading}
              setIsReading={setIsReading}
              phoneNumber={voiceMessage.from}
            />
            <div className={classes["mark-as-listened"]}>
              <input
                type="radio"
                checked={isChecked}
                onClick={isCheckedHandler}
              />
            </div>
          </div>
        </div>
        {isPlaying && (
          <div className={classes["progress-bar"]}>
            <ProgressBar
              audioRef={audioRef}
              progressBar={progressBar}
              progressBarRef={progressBarRef}
              duration={duration}
              setProgressBar={setProgressBar}
            />
          </div>
        )}
        {isReading && (
          <div className={classes["voice-to-text"]}>
            <VoiceToText text={voiceMessage.transcriptionText} />
          </div>
        )}
        <Audio
          url={voiceMessage.recordingUrl}
          audioRef={audioRef}
          setDuration={setDuration}
        />
      </div>
    </div>
  );
};

export default VoiceMessage;
