import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ListItem from "./ListItem";
import { contactActions } from "../../../store/contact";
import classes from "./ListItems.module.css";
import { ReactComponent as MagnifyingGlass } from "../../../assets/imgs/magnifying-glass-icon.svg";
import Spinner from "../../../shared/components/UIElements/Spinner/Spinner";
import { useInfiniteScroll } from "../../../shared/components/hooks/useInfiniteScroll/useInfiniteScroll";
import { dobToAge } from '../../../shared/components/utils/dobToAge';

const ListItems = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [filteredContacts, setFilteredContacts] = useState([]);

  useEffect(() => {
    dispatch(contactActions.replaceFilteredContacts(filteredContacts));
  }, [filteredContacts])

  const isLoading = useSelector((state) => state.contactReducer.isLoading);

  //Open profile modal
  const showModalHandler = (event, contactId) => {
    event.stopPropagation();
    navigate(`contacts/${contactId}/notes`);
  };

  //Import and filter contacts according to selected filters and search term
  const contacts = useSelector((state) => state.contactReducer.contacts);
  const filters = useSelector((state) => state.filterReducer.filters);
  const searchTerm = useSelector((state) => state.filterReducer.searchTerm);

  useEffect(() => {
    setFilteredContacts(searchContacts(contacts, filters));
  }, [filters, searchTerm, contacts]);

  const multiPropsFilter = (contacts, filters) => {
  
    const filterKeys = Object.keys(filters);
    const results = contacts.filter((contact) => {
      const contactsAge = contact.dateOfBirth ? dobToAge(contact.dateOfBirth) : null;
      return filterKeys.every((key) => {
        if (key === 'age' && contactsAge) {
          return contactsAge >= filters.age?.min && contactsAge <= filters.age?.max
        }
        return filters[key] === contact[key] 
      });
    });

    return results;
  };

  const searchContacts = (contacts, filters) => {
    const filteredContacts = multiPropsFilter(contacts, filters);
    return filteredContacts.filter((contact) => {
      if (!searchTerm) {
        return true;
      }
      return contact?.firstName.toLowerCase().includes(searchTerm) || contact?.lastName.toLowerCase().includes(searchTerm) || (contact?.firstName?.toLowerCase() + " " + contact?.lastName.toLowerCase()).includes(searchTerm);
    });
  };

  //Create an infinite scroll of contacts with infinite scroll hook
  const { loadMore, viewableItems, loading, lastItemRef } = useInfiniteScroll(
    filteredContacts,
    20
  );

  // dispatch(contactActions.replaceFilteredContacts(filteredContacts));
  
  let content;

  if (isLoading) {
    content = <Spinner customClasses="small" />;
  }

  if (viewableItems.length > 0 && !isLoading) {
    content = viewableItems.map((item, index) => {
      const lastElement = viewableItems.length === index + 1 ? lastItemRef : null 
      return (
        <ListItem
          lastItemref={lastElement}
          key={item.id}
          contactId={item.id}
          contact={{ ...item }}
          onOpenModal={showModalHandler}
        />
      );
    });
  }

  if (
    filteredContacts.length === 0 &&
    Object.keys(filters).length !== 0 &&
    !isLoading
  ) {
    content = (
      <div className={classes["no-search-results"]}>
        <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
          <MagnifyingGlass style={{ height: "2rem", width: "2rem" }} />
          <h2>Sorry!</h2>
        </div>
        <p>No matches found for your search request</p>
      </div>
    );
  }
  return (
    <>
      <div className={classes["list-items"]} style={{ overFlow: "auto" }}>
        {content}
      </div>
    </>
  );
};

export default ListItems;
