import React, { useState, useRef } from "react";
import classes from "./PipelineHeader.module.css";
import { BiCaretDown } from "react-icons/bi";
import { usePopper } from "react-popper";
import useClickAwayListener from "../../shared/components/hooks/useClickAwayListener/useClickAwayLIstener";
import { BsPlusLg, BsLayoutThreeColumns } from "react-icons/bs";
import { CiMenuKebab } from "react-icons/ci";
import { CiEdit } from "react-icons/ci";
import { RxTrash } from "react-icons/rx";
import NotificationModal from "../../shared/components/Modal/NotificationModal/NotificationModal";
import { useNavigate } from "react-router-dom";
import mergeClasses from "classnames";
import { useDispatch } from "react-redux";
import { deletePipeline } from "../../store/pipeline-actions";
const KEY = "last-opened-pipeline-localstorage";

const PipelineHeader = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isOpenDeletePipelineModal, setIsOpenDeletePipelineModal] =
    useState(false);

  //open delete pipeline menu
  const openDeletePipelineHandler = () => {
    toggleEditKebabMenuPopperHandler();
    setIsOpenDeletePipelineModal(true);
  };

  //close delete pipeline modal
  const closeDeletePipelineHandler = () => {
    setIsOpenDeletePipelineModal(false);
  };

  //delete pipeline
  const deletePipelineHandler = () => {
    dispatch(deletePipeline(props.pipeline));

    //close delete pipeline warning model
    setIsOpenDeletePipelineModal(false);

    //if deleting last pipeline route to add pipeline
    if (props.pipelines.length === 1) {
      localStorage.removeItem(KEY);
      navigate("/app/pipeline/add");
    }
    //route to last added pipeline and update local storage
    else {
      const allOtherPipelines = props.pipelines.filter((pipeline) => pipeline.id !== props.pipeline.id);
      navigate(`/app/pipeline/${allOtherPipelines[allOtherPipelines.length - 1].id}`)
      localStorage.setItem(KEY, allOtherPipelines[allOtherPipelines.length - 1].id);
    }
  };

  //set active pipeline handler
  const setActivePipelineHandler = (e, pipeline) => {
    navigate(`/app/pipeline/${pipeline.id}`);
    setIsPipelineMenuOpen(false);
    localStorage.setItem(KEY, pipeline.id);
  };

  //redirect to add new pipeline
  const addNewPipelineHandler = () => {
    navigate("/app/pipeline/add");
  };

  //redirect to edit pipeline
  const editPipelineHandler = () => {
    navigate(`/app/pipeline/${props.pipeline.id}/edit`);
  };

  //pipeline menu popper
  const [isPipelineMenuOpen, setIsPipelineMenuOpen] = useState(false);
  const [referencePipelineMenu, setReferencePipelineMenu] = useState(null);
  const [pipelineMenu, setPipelineMenu] = useState(null);

  const togglePipelineMenuHandler = () => {
    setIsPipelineMenuOpen((prevState) => !prevState);
  };

  const pipelineMenuPopper = usePopper(referencePipelineMenu, pipelineMenu, {
    placement: "bottom-start",
    modifiers: [
      { name: "offset", options: { offset: [0, 5] } },
      {
        name: "preventOverflow",
        options: {
          altAxis: true,
          padding: 10
        }
      }
    ]
  });

  //close popper when clicking on window/body
  const pipelineMenuClickAwayRef = useRef(null);

  useClickAwayListener(
    pipelineMenuClickAwayRef,
    togglePipelineMenuHandler,
    referencePipelineMenu
  );

  //edit pipeline  popper (kebab menu)
  const [isEditPipelineMenuOpen, setIsEditPipelineMenuOpen] = useState(false);
  const [referenceKebabIcon, setReferenceKebabIcon] = useState(null);
  const [editPipelineMenu, setEditPipelineMenu] = useState(null);

  const editPipelineMenuPopper = usePopper(
    referenceKebabIcon,
    editPipelineMenu,
    {
      placement: "bottom",
      modifiers: [
        { name: "offset", options: { offset: [0, 0] } },
        {
          name: "preventOverflow",
          options: {
            altAxis: true,
            padding: 10
          }
        }
      ]
    }
  );

  const toggleEditKebabMenuPopperHandler = () => {
    setIsEditPipelineMenuOpen((prevState) => !prevState);
  };

  const kebabIconClickAwayRef = useRef(null);

  useClickAwayListener(
    kebabIconClickAwayRef,
    toggleEditKebabMenuPopperHandler,
    referenceKebabIcon
  );

  return (
    <>
      {isOpenDeletePipelineModal && (
        <NotificationModal
          onCloseNotificationModal={closeDeletePipelineHandler}
          title={`Delete pipeline ${props.pipeline.name}?`}
          body="Deleting this pipeline cannot be undone and all corresponding stages and deals will be lost."
          cancelButton={closeDeletePipelineHandler}
          confirmButton={deletePipelineHandler}
        />
      )}
      <div className={classes.pipelineHeader}>
        <div className={classes.title}>
          <div className={classes.wrapper} onClick={togglePipelineMenuHandler}>
            <h3>{props.pipelineName}</h3>
            <div
              className={classes.dropdownArrow}
              ref={setReferencePipelineMenu}
            >
              <BiCaretDown
                style={{
                  transform: `${isPipelineMenuOpen ? "rotate(180deg" : ""}`
                }}
              />
            </div>
          </div>
          {isPipelineMenuOpen && (
            <div ref={pipelineMenuClickAwayRef}>
              <div
                ref={setPipelineMenu}
                className={classes.popper}
                style={pipelineMenuPopper.styles.popper}
                {...pipelineMenuPopper.attributes.popper}
              >
                <ul className={classes.editPipelineMenu}>
                  {props.pipelines.map((pipeline) => {
                    return (
                      <li
                        key={pipeline.id}
                        className={classes.menuItem}
                        onClick={(e) => setActivePipelineHandler(e, pipeline)}
                      >
                        {pipeline.name}
                      </li>
                    );
                  })}
                  <div
                    className={classes.menuItem}
                    onClick={addNewPipelineHandler}
                  >
                    <BsPlusLg />
                    pipeline
                  </div>
                </ul>
              </div>
            </div>
          )}
        </div>
        <div className={classes.actions}>
          <div className={classes.viewMode}>
            <div
              onClick={props.onSetViewHandler}
              className={classes.kanban}
              data-name="kanban"
            >
              <BsLayoutThreeColumns />
            </div>
            <div
              onClick={props.onSetViewHandler}
              className={classes.list}
              data-name="list"
            >
              <BsLayoutThreeColumns />
            </div>
            <div className={classes.viewModeText}>View</div>
          </div>
          <div
            className={mergeClasses(classes.kebabMenu, classes.kebab)}
            ref={setReferenceKebabIcon}
          >
            <CiMenuKebab
              onClick={toggleEditKebabMenuPopperHandler}
              size="24px"
            />
          </div>
        </div>
        {isEditPipelineMenuOpen && (
          <div ref={kebabIconClickAwayRef}>
            <div
              ref={setEditPipelineMenu}
              className={classes.popper}
              style={editPipelineMenuPopper.styles.popper}
              {...editPipelineMenuPopper.attributes.popper}
            >
              <div>
                <ul className={classes.editPipelineMenu}>
                  <li
                    className={classes.menuItem}
                    onClick={editPipelineHandler}
                  >
                    <CiEdit size="25px" className={classes.editIcon} />
                    Edit Name
                  </li>
                  <li
                    className={classes.menuItem}
                    onClick={openDeletePipelineHandler}
                  >
                    <RxTrash size="20px" className={classes.trashIcon} />
                    Delete Pipeline
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PipelineHeader;
