import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SpinnerButton from "../UIElements/SpinnerButton/SpinnerButton";
import FileUploader from "../UIElements/FileUploader/FileUploader";
import ProgressBar from "../UIElements/ProgressBar/ProgressBar";
import TrashIcon from "../../../assets/imgs/delete.png";
import { createNote } from "../../../store/contact-note-actions";
import { createDealNote } from '../../../store/deal-note-actions';
import classes from "./NotesForm.module.css";
import { GiPaperClip } from "react-icons/gi";
import { RxTrash } from "react-icons/rx";

const NotesForm = (props) => {
  const dispatch = useDispatch();

  const [dragDropFileIsActive, setDragDropFileIsActive] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const [note, setNote] = useState("");

  const fileUploadProgress = useSelector((state) => state.contactNoteReducer.uploadProgress);

  const creator = useSelector((state) => state.userReducer.user.userId);
  const textareaRef = useRef();

  const contactId = props.contactId;
  const dealId = props.dealId;

  const openFileUploadHandler = () => {
    setDragDropFileIsActive(true);
  };

  const closeFileUploadHandler = () => {
    setDragDropFileIsActive(false);
  };

  const uploadFilesHandler = (uploadedFiles) => {
    setUploadedFiles(uploadedFiles);
    closeFileUploadHandler();
  };

  const removeFilesHandler = (fileName, contactId) => {
    const filter = uploadedFiles.filter((file) => {
      return file.name !== fileName;
    });
    setUploadedFiles(filter);
  };

  const noteInputHandler = (event) => {
    setNote(event.target.value)
    textareaRef.current.style.height = "5px"
    textareaRef.current.style.height = textareaRef.current.scrollHeight + "px"
  }

  const submitHandler = (event) => {
    event.preventDefault();



    if (props.contactId) {
      const newNote = {
        text: note,
        file: uploadedFiles,
        belongsTo: contactId,
      };
      dispatch(createNote(newNote));
    }

    if (props.dealId) {
      const newNote = {
        text: note,
        file: uploadedFiles,
        belongsTo: dealId,
      };
      dispatch(createDealNote(newNote));
    }

    setNote("");
    setUploadedFiles();

    // textareaRef.current.style.height = "0px"
    textareaRef.current.style.height = "1rem"
  };

  let uploadingFiles;

  if (uploadedFiles && uploadedFiles.length >= 1) {
    uploadingFiles = uploadedFiles.map((file) => {
      return (
        <div key={file.name} className={classes["uploading-files"]}>
          <div className={classes.fileName}>{file.name}</div>
          <div
            className={classes["trash-icon"]}
            onClick={() => removeFilesHandler(file.name, contactId)}
          >
            <RxTrash size="20px" className={classes["trash-icon"]} />
          </div>
        </div>
      );
    });
  }


  return (
    <div className={classes["notes-form-container"]}>
      {fileUploadProgress > 0 && (
        <ProgressBar color="rgb(118, 234, 130)" progress={fileUploadProgress} />
      )}
      <form onSubmit={submitHandler} encType="multipart/form-data">
        {!dragDropFileIsActive && (
          <>
            <div className={classes["form__control"]}>
              <textarea className={classes["text-area-element"]} rows="1" onChange={noteInputHandler} ref={textareaRef} value={note} placeholder="Write a note" autoFocus />
            </div>
            {!dragDropFileIsActive && uploadingFiles}
            <div className={classes["actions__container"]}>
              <div className={classes["actions__buttons"]}>
                <div onClick={openFileUploadHandler}>
                  {/* <PaperclipIcon /> */}
                  <GiPaperClip size="20px" />
                </div>
              </div>
              <SpinnerButton color="button--blue" disabled={!uploadedFiles && note === ""}>
                Add Note
              </SpinnerButton>
            </div>
          </>
        )}
        {dragDropFileIsActive && (
          <FileUploader
            closeButton
            contactId={contactId}
            onUploadFilesHandler={uploadFilesHandler}
            onCloseFileUpload={closeFileUploadHandler}
          />
        )}
      </form>
    </div>
  );
};

export default NotesForm;
