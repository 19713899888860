//Import styles
import classes from "./Input.module.css";
import mergeClasses from "classnames";

const Input = (props) => {
  const inputClasses = mergeClasses(
    classes["form__control"],
    props.isInvalid ? classes.invalid : ""
  );
  return (
    <>
      <div
        className={inputClasses}
        style={{ width: props.width && props.width }}
      >
        <input
          className={classes["custom-input"]}
          type={props.type}
          onChange={props.onChange}
          onBlur={props.onBlur}
          value={props.value}
          placeholder={props.label}
          name={props.name}
          autoFocus={props.autoFocus}
        />
        {props.isInvalid && (
          <div className={classes["error-message"]}>{props.errorMessage}</div>
        )}
      </div>
    </>
  );
};

export default Input;
