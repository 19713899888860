import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Message from "./Message";
import classes from "./Messages.module.css";

const Messages = (props) => {
    let messageData = useSelector((state) =>
      state.messageReducer.messages.filter(
        (message) => message.belongsTo === props.contactId
      )
    );

    if (props.messageData) {
      messageData = props.messageData;
    }
  
    const endMessage = useRef(null);
  
    useEffect(() => {
      setTimeout(() => {
        endMessage.current?.scrollIntoViewIfNeeded();
      }, 300);
    }, [messageData]);
  
    let messages;
    if (messageData.length >= 1) {
      messages = messageData.map((message, index) => {
        return (
            <Message key={index} message={message} />
        );
      });
    } else {
      messages = (
        <p className={classes["enter-message"]}>
          Start a text message conversation by entering some text...
        </p>
      );
    }
    return (
      <div className={classes["messages"]}>
        {messages}
        {/* Scrolls to most recent message */}
        <div ref={endMessage}></div>
      </div>
    );
  };

  export default Messages;