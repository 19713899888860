import { combineReducers, configureStore, createSlice, getDefaultMiddleware } from "@reduxjs/toolkit";
import userSlice from "./user";
import contactSlice from "./contact";
import contactNoteSlice from "./contact-note";
import workflowCardNoteSlice from "./workflow-card-note";
import messageSlice from "./message";
import voiceMessageSlice from "./voiceMessage";
import callSlice from "./call";
import filterSlice from "./filter";
import checkboxSlice from "./checkbox";
import eventSlice from "./event";
import uiSlice from "./ui";
import modalSlice from "./modal";
import twilioSlice from "./twilio";
import fileUploaderSlice from "./file-uploader";
import notificationSlice from "./notifications";
import pipelineSlice from './pipeline';
import dealNoteSlice from './deal-note';
import taskSlice from './task';

const store = combineReducers({
  userReducer: userSlice,
  contactReducer: contactSlice,
  contactNoteReducer: contactNoteSlice,
  workflowCardNoteReducer: workflowCardNoteSlice,
  messageReducer: messageSlice,
  voiceMessageReducer: voiceMessageSlice,
  callReducer : callSlice,
  filterReducer: filterSlice,
  checkboxReducer: checkboxSlice,
  uiReducer: uiSlice,
  modalReducer: modalSlice,
  twilioReducer: twilioSlice,
  fileUploaderReducer: fileUploaderSlice,
  notificationReducer: notificationSlice,
  eventReducer: eventSlice,
  pipelineReducer: pipelineSlice,
  dealNoteReducer: dealNoteSlice,
  taskReducer: taskSlice
})

const rootReducer = (state, action) => {
  // console.log(action)
  if (action.type === 'userSlice/logout') {
    state = undefined;
  }
  return store(state, action);
};

export default configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware({
    serializableCheck: {
      //ignore twilio device action types and paths as device is non-serialized
      ignoredActions: ['twilio/connectTwilioDevice', 'twilio/setDeviceReadyForRegistration', 'twilio/setCurrentCall'],
      ignoredPaths: ['twilioReducer.twilioDevice', 'twilioReducer.currentCall']
    }
  })]
});