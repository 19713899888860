import classes from "./PipelineBox.module.css";
import PipelineHeader from "./PipelineHeader";
import PipelineBoard from "./PipelineBoard";
import WorkflowList from "./WorkflowList";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Outlet } from "react-router-dom";

const PipelineBox = () => {
  const params = useParams();

  //set view between 'kanban' and 'list'
  const [view, setView] = useState("kanban");

  //set kanban or list view
  const setViewHandler = (e) => {
    const view = e.target.dataset.name;
    setView(view);
  };

  //import pipelines from store
  const pipelines = useSelector((state) => state.pipelineReducer.pipelines);

  //import pipeline from store using params
  const pipeline = useSelector((state) =>
    state.pipelineReducer.pipelines.find(
      (pipeline) => pipeline.id === params.pipelineId
    )
  );

  //filter stages from pipeline stages array
  const stages = useSelector((state) =>
    state.pipelineReducer.stages.filter(
      (stage) => stage.belongsTo === pipeline?.id
    )
  );

  //filter deals from stages deals array
  const deals = useSelector((state) => state.pipelineReducer.deals).filter(
    (deal) => {
      return stages.some((stage) => stage.deals.includes(deal.id));
    }
  );

  return (
    <>
      <div className={classes.pipelineBox}>
        <PipelineHeader
          onSetViewHandler={setViewHandler}
          view={view}
          pipelineName={pipeline?.name}
          pipelines={pipelines}
          pipeline={pipeline}
        />
        {view === "kanban" && (
          <PipelineBoard pipeline={pipeline} stages={stages} deals={deals} />
        )}
        {view === "list" && <WorkflowList />}
      </div>
      <Outlet />
    </>
  );
};

export default PipelineBox;
