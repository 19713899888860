import classes from "./CalendarIcon.module.css";
import { ReactComponent as CalendarIconSVG } from "../../../../assets/imgs/calendar-icon.svg";
import { useEffect } from "react";
import { MdEventRepeat } from "react-icons/md";
import { FiPhone } from "react-icons/fi";
import Dot from "../Dot/Dot";
import { BsCalendar4 } from "react-icons/bs";

const CalendarIcon = (props) => {
  // useEffect(() => {
  //   if (props.color) {
  //     const stroke = document.querySelector(".cal1").style.stroke = "green";
  //     const line = document.querySelector(".cal2").style.fill = "green";
  //     const dot = document.querySelector(".cal3").style.fill = "green";
  //   }
  // }, [])

  return (
    <div className={`${classes["calendar-icon"]}`}>
      <BsCalendar4 className={props.color ? classes.red : ''} size='14px'/>
      <span className={classes["title"]}>{props.children}</span>
    </div>
  );
};

export default CalendarIcon;
