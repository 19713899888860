import Firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyB-piW7MW8z1GL0jJdigUoOJSO7Qf-DyBI",
    authDomain: "dots-b71e3.firebaseapp.com",
    databaseURL: "https://dots-b71e3-default-rtdb.firebaseio.com",
    projectId: "dots-b71e3",
    storageBucket: "dots-b71e3.appspot.com",
    messagingSenderId: "1091452335645",
    appId: "1:1091452335645:web:a9b346a6bfbcf0881638f4", 
    storageBucket: 'gs://dots-b71e3.appspot.com/'
  };

const firebase =  Firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

const storage = getStorage(firebase);

export { firebase, db, storage } ;